import React, { createContext, useContext, useState, useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Confetti from 'react-confetti';
import useWindowSize from '../hooks/useWindowSize';

import {
  IAlertContextValue,
  TimedConfettiProps,
  IAlertState,
  AlertCallback,
} from './IAlertContext';

const AlertContext = createContext<IAlertContextValue>({
  alertSuccess: (m, d) => {
    // default empty
  },
  alertError: (m, d) => {
    // default empty
  },
  alertWarning: (m, d) => {
    // default empty
  },
  alertInfo: (m, d) => {
    // default empty
  },
  rainConfetti: () => {
    // default empty
  },
});
AlertContext.displayName = 'AlertContext';

const TimedConfetti = ({ party, setParty }: TimedConfettiProps) => {
  const { width, height } = useWindowSize();
  return (
    <Confetti
      width={width}
      height={height}
      style={{ pointerEvents: 'none' }}
      recycle={false}
      numberOfPieces={party ? 500 : 0} // increase number of pieces to increase time
      onConfettiComplete={(confetti) => {
        setParty(false);
        confetti?.reset();
      }}
    />
  );
};

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alertState, setAlertState] = useState<IAlertState>({
    open: false,
    severity: 'success',
    message: '',
    duration: 6000,
  });
  const [confetti, setConfetti] = useState(false);

  const handleClose = (e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setAlertState((prev) => ({ ...prev, open: false }));
  };

  const alertSuccess = useCallback<AlertCallback>(
    (message, duration = 6000) =>
      setAlertState({
        open: true,
        severity: 'success',
        message,
        duration,
      }),
    []
  );

  const alertError = useCallback<AlertCallback>(
    (message, duration = 6000) =>
      setAlertState({
        open: true,
        severity: 'error',
        message,
        duration,
      }),
    []
  );

  const alertWarning = useCallback<AlertCallback>(
    (message, duration = 6000) =>
      setAlertState({
        open: true,
        severity: 'warning',
        message,
        duration,
      }),
    []
  );

  const alertInfo = useCallback<AlertCallback>(
    (message, duration = 6000) =>
      setAlertState({
        open: true,
        severity: 'info',
        message,
        duration,
      }),
    []
  );

  const rainConfetti = useCallback(() => setConfetti(true), []);

  return (
    <AlertContext.Provider
      value={{
        alertSuccess,
        alertError,
        alertWarning,
        alertInfo,
        rainConfetti,
      }}
    >
      {children}
      <Snackbar
        open={alertState.open}
        autoHideDuration={alertState.duration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleClose}
          severity={alertState.severity}
        >
          {alertState.message}
        </MuiAlert>
      </Snackbar>
      <TimedConfetti party={confetti} setParty={setConfetti} />
    </AlertContext.Provider>
  );
};

const useAlert = () => useContext(AlertContext);

export { useAlert, AlertProvider };
