import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styles from './Store.module.css';
import itemStyles from './ItemCard.module.css';
import i18next from 'i18next';
import Button from '@material-ui/core/Button';
import { addResizingListener } from 'utils/Responsiveness';
import defaultItem from '../../../assets/images/home/defaultItem.png';
import { paths, storesEndpoints } from 'stocateConstants';
import Share from '../../../components/reusable/Share';
import Carousel from 'react-material-ui-carousel';
import ItemSustainabilityBadgesGroup from 'components/reusable/ItemSustainabilityBadgesGroup';
import Edit from 'components/reusable/Edit';
import Copy from 'components/reusable/Copy';
import CopyItem from '../addItem/CopyItem';
import { sendItemView } from 'utils/GoogleAnalytics';
import ObjectCell from 'components/reusable/ObjectCell';
import { getItemIngredients, rsvpItem } from 'api/ItemsApi';
import { useAuth } from 'context/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';
import loadingIcon from '../../../assets/images/general/loading.gif';

ItemCard.propType = {
  item: PropTypes.any,
  viewList: PropTypes.bool,
};

ItemCard.defaultProps = {
  item: null,
  viewList: false,
};

function ItemCard({ item, tagHandler, viewList }) {
  const history = useHistory();
  const [title, setTitle] = useState(i18next.t('itempage.rsvp'));
  const [copyItem, isCopyItem] = useState(false);
  const [itemIngredients, setItemIngredients] = useState([]);
  const [isRsvp, setIsRsvp] = useState(item != null ? item.isRsvp : false);
  const [isWaitlist, setIsWaitlist] = useState(
    item != null ? item.isWaitlist : false
  );
  const { state: authState } = useAuth();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { pathname } = useLocation();
  const isBrowsePage = pathname === '/browse';

  const successCallback = React.useCallback((body) => {
    setItemIngredients(body);
  }, []);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const sendToItem = () =>
    (window.location.href =
      '/' + storesEndpoints + item.storeHandle + '?item=' + item.itemHandle);
  const sendToStore = () =>
    (window.location.href = '/' + storesEndpoints + item.storeHandle);
  const sendToAddItem = () => {
    const url = `${paths.ADD_ITEM}?item=${encodeURIComponent(item.itemHandle)}`;
    window.location.href = url;
  };

  const buttonColor = isRsvp || isWaitlist ? 'default' : 'secondary';
  useEffect(() => {
    addResizingListener(setIsMobile);
    if (item != null) {
      getItemIngredients(item.id, successCallback, null);
      setIsRsvp(item != null ? item.isRsvp : false);
      setTitle(
        isRsvp
          ? i18next.t('itempage.rsvp_cancel')
          : isWaitlist
          ? i18next.t('itempage.waitlist_cancel')
          : item.isLimit
          ? i18next.t('itempage.waitlist')
          : i18next.t('itempage.rsvp')
      );
    }
  }, [isRsvp, isWaitlist, item, successCallback]);

  function copyingLink() {
    if (viewList) {
      let linkWithList = window.location.href.substring(
        0,
        window.location.href.lastIndexOf('/') - 1
      );
      let initialLink = linkWithList.substring(
        0,
        linkWithList.lastIndexOf('/')
      );
      navigator.clipboard.writeText(
        initialLink + '/stores/' + item.storeHandle + '?item=' + item.itemHandle
      );
    } else {
      if (window.location.href.indexOf('?') === -1) {
        navigator.clipboard.writeText(
          'https://stocate.com/stores/' +
            item.storeHandle +
            '?item=' +
            item.itemHandle
        );
      } else {
        navigator.clipboard.writeText(window.location.href);
      }
    }
  }

  if (item) {
    sendItemView(item.id, item.name);
  }

  return (
    <>
      {item === null ? (
        <div></div>
      ) : (
        <div style={{backgroundColor: isBrowsePage ? '#FFFFFF' : 'transparent', height:'100vh', paddingTop: '20px'}}>
          {copyItem === true ? (
            <CopyItem
              itemId={item.id}
              isCopyItem={() => {
                isCopyItem(false);
              }}
            ></CopyItem>
          ) : (
            <div>
              <div className={styles.storeImage}>
                {item.images[0] ? (
                  item.images.length === 1 ? (
                    <img
                      src={item.images[0]}
                      alt='mainIcon'
                      className={`${styles.singleStoreImage} ${styles.centered}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => openModal(item.images[0])}
                    />
                  ) : (
                    <Carousel
                      autoPlay={true}
                      stopAutoPlayOnHover={true}
                      navButtonsAlwaysVisible={true}
                    >
                      {item.images.map((imageUrl, i) => (
                        <div key={i} className={styles.centered} style={{ height: '300px' }}>
                          <img
                            src={imageUrl}
                            alt='mainIcon'
                            className={styles.singleStoreImage}
                            style={{ cursor: 'pointer' }}
                            onClick={() => openModal(imageUrl)}
                          />
                        </div>
                      ))}
                    </Carousel>
                  )
                ) : (
                  <img
                    src={defaultItem}
                    alt='shopping bag'
                    className={`${styles.singleStoreImage} ${styles.centered}`}
                    style={{ filter: 'brightness(0.5)' }}
                    onClick={() => openModal(defaultItem)}
                  />
                )}
              </div>

              <div className={itemStyles.globalFrame}>
                <div className={itemStyles.title}>
                  <h2>
                    <a
                      onClick={sendToItem}
                      href={sendToItem}
                      className={styles.buttonStyleHover}
                      style={{color: 'black'}}
                    >
                      {item.name}
                    </a>
                    <Share entity={item} copyingLink={copyingLink} />
                    { window.location.href.includes('browse') ||
                      <Edit onClick={sendToAddItem} />
                    }
                    <Copy
                      onClick={() => {
                        isCopyItem(true);
                      }}
                    />
                  </h2>
                  <span style={{marginBottom: '7px'}}>
                    <a
                      onClick={sendToStore}
                      href={sendToStore}
                      className={styles.buttonStyleHover}
                      style={{color: 'black'}}
                    >
                      {item.storeName}
                    </a>
                  </span>
                  {item.price === null ? <h4>$-</h4> : <h4>${item.price}</h4>}
                  <ItemSustainabilityBadgesGroup
                    packagingNumber={item.sustainabilityMetrics.packaging}
                    fsc={item.sustainabilityMetrics.fsc - 1}
                    eolRecollection={item.sustainabilityMetrics.eolRecollection}
                    organic={item.sustainabilityMetrics.organic - 1}
                    ecoLogo={item.sustainabilityMetrics.ecoLogo - 1}
                    asc={item.sustainabilityMetrics.asc - 1}
                    palmOil={item.sustainabilityMetrics.palmOil - 1}
                    byom={item.sustainabilityMetrics.byom - 1}
                    greenSeal={item.sustainabilityMetrics.greenSeal - 1}
                    oceanWise={item.sustainabilityMetrics.oceanWise - 1}
                    rainforest={item.sustainabilityMetrics.rainforest - 1}
                    msc={item.sustainabilityMetrics.msc - 1}
                    fairTrade={item.sustainabilityMetrics.fairTrade - 1}
                    kosher={item.sustainabilityMetrics.kosher - 1}
                    peanut={item.sustainabilityMetrics.peanut - 1}
                    dairy={item.sustainabilityMetrics.dairy - 1}
                    sugar={item.sustainabilityMetrics.sugar - 1}
                    vegan={item.sustainabilityMetrics.vegan - 1}
                    halal={item.sustainabilityMetrics.halal - 1}
                    gluten={item.sustainabilityMetrics.gluten - 1}
                    vegetarian={item.sustainabilityMetrics.vegetarian - 1}
                    constituent={item.sustainabilityMetrics.constituent}
                    landTransport={
                      item.sustainabilityMetrics.landTransportation
                    }
                    origin={item.sustainabilityMetrics.origin}
                    modal
                  />
                </div>
              </div>
              {item.itemTypeId !== 2 ? null : (
                <div className={itemStyles.itemDesc}>
                  <Button
                    variant='contained'
                    color={buttonColor}
                    style={{
                      height: 56,
                      fontSize: isMobile ? 14 : 24,
                      fontWeight: 'bold',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: isMobile ? '60%' : '35%',
                    }}
                    onClick={() => {
                      if (!authState.isAuthenticated) {
                        history.push(paths.SIGNIN, {
                          redirectBackTo: window.location.pathname,
                          query: window.location.search,
                        });
                      } else {
                        setTitle(
                          <img
                            style={{ width: '30px' }}
                            src={loadingIcon}
                            alt='loading'
                          ></img>
                        );
                        rsvpItem(
                          item.id,
                          i18next.language,
                          () => {
                            if (isRsvp) {
                              setIsRsvp(!isRsvp);
                              item.isRsvp = !isRsvp;
                            } else if (isWaitlist) {
                              setIsWaitlist(!isWaitlist);
                              item.isWaitlist = !isWaitlist;
                            } else {
                              if (item.isLimit) {
                                item.isWaitlist = !isWaitlist;
                                setIsWaitlist(!isWaitlist);
                              } else {
                                setIsRsvp(!isRsvp);
                                item.isRsvp = !isRsvp;
                              }
                            }
                          },
                          () => {
                            // do nothing
                          }
                        );
                      }
                    }}
                    data-cy='additem-submit'
                  >
                    {title}
                  </Button>
                </div>
              )}
              {item.description.length === 0 ? null : (
                <div className={itemStyles.itemDesc}>
                  <h5>{i18next.t('itempage.description')}</h5>
                  <p>{item.description}</p>
                </div>
              )}
              {item.schedule === null || item.schedule.length === 0 ? null : (
                <div className={itemStyles.itemDesc}>
                  <h5>{i18next.t('itempage.schedule')}</h5>
                  <p>{item.schedule}</p>
                </div>
              )}
              {item.location === null ? null : (
                <div className={itemStyles.itemDesc}>
                  <h5>{i18next.t('itempage.address')}</h5>
                  <p>
                    {item.location.streetNumber +
                      ' ' +
                      item.location.streetName +
                      ' ' +
                      item.location.city +
                      ' ' +
                      item.location.province +
                      ' ' +
                      item.location.postalCode}
                  </p>
                </div>
              )}
              {item.storeOrganizers.concat(item.userOrganizers).length ===
              0 ? null : (
                <div className={itemStyles.itemDesc}>
                  <h5>{i18next.t('itempage.organizers')}</h5>
                  {item.storeOrganizers
                    .concat(item.userOrganizers)
                    .map((item) => {
                      return (
                        <ObjectCell type='store' entity={item} noLink={true} />
                      );
                    })}
                </div>
              )}
              {itemIngredients.length === 0 ? null : (
                <div className={itemStyles.itemDesc}>
                  <h5>{i18next.t('itempage.ingredients')}</h5>
                  {itemIngredients.map((item) => {
                    return (
                      <ObjectCell type='item' entity={item} noLink={true} />
                    );
                  })}
                </div>
              )}
              <br />
              {item.tags.length === 0 ? null : (
                <div className={itemStyles.itemDesc}>
                  <h5>{i18next.t('itempage.tags')}</h5>
                  {item.tags.map((tags) => {
                    return (
                      <Button
                        variant='outlined'
                        style={{
                          borderRadius: 50,
                          marginRight: 5,
                          textTransform: 'lowercase',
                          fontWeight: 'normal',
                        }}
                        onClick={() => tagHandler(tags)}
                      >
                        {'#' + tags}
                      </Button>
                    );
                  })}
                </div>
              )}
              {item.ingredients.length === 0 ? null : (
                <div className={itemStyles.itemDesc}>
                  <h5>{i18next.t('itempage.ingredients')}</h5>
                  <p>{item.ingredients.join(' • ')}</p>
                </div>
              )}
              <br />
            </div>
          )}
          {
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel='Enlarged Image'
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
                content: {
                  width: 'auto',
                  height: 'auto',
                  margin: 'auto',
                  maxWidth: isMobile ? '90%' : '50%',
                  maxHeight: isMobile ? '60%' : '70%',
                },
              }}
            >
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt='Enlarged'
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              )}
            </Modal>
          }
        </div>
      )}
    </>
  );
}

export default ItemCard;
