export default function getUserLocation(
  setCoordinates: any,
  setPermission: any,
  setShowAlert: (value: React.SetStateAction<boolean>) => void
) {
  if (navigator.geolocation) {
    navigator.permissions.query({ name: 'geolocation' }).then((permissions) => {
      handlePermissionStatus({
        permissions,
        setCoordinates,
        setPermission,
        setShowAlert,
      });
      permissions.onchange = () => {
        handlePermissionStatus({
          permissions,
          setCoordinates,
          setPermission,
          setShowAlert,
        });
      };
    });
  }
}

const handlePermissionStatus = ({
  permissions,
  setCoordinates,
  setPermission,
  setShowAlert,
}: {
  permissions: any;
  setCoordinates: (value: React.SetStateAction<{}>) => void;
  setPermission: (value: React.SetStateAction<boolean>) => void;
  setShowAlert: (value: React.SetStateAction<boolean>) => void;
}) => {
  if (permissions.state === 'granted') {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
        setPermission(true);
        setShowAlert(false);
      },
      null,
      { maximumAge: 360000 }
    ); // Max time position is keept in cache in ms
  } else if (permissions.state === 'prompt') {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position !== null) {
          setCoordinates({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
          setPermission(true);
          setShowAlert(false);
        } else setPermission(false);
        setShowAlert(true);
      },
      null,
      { maximumAge: 360000 }
    );
  } else if (permissions.state === 'denied') {
    setPermission(false);
    setShowAlert(true);
  }
};
