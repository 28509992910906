import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const SectionTitle = ({
  iconSrc,
  title,
  required,
}: {
  iconSrc: string;
  title: string;
  required?: boolean;
}) => {
  return (
    <Grid
      container={true}
      alignItems='center'
      style={{ marginBottom: '0.5em' }}
    >
      <img
        src={iconSrc}
        style={{ width: '2.75em', marginRight: '1em' }}
        alt=''
      />
      <Typography variant='h6' color='initial' style={{ fontWeight: 'bolder' }}>
        {title}
        {required && <span style={{ color: 'red' }}> *</span>}
      </Typography>
    </Grid>
  );
};

export default SectionTitle;
