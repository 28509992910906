import React from 'react';
import editIcon from '../../assets/images/store/pen.png';
import styles from '../main/store/Store.module.css';
import i18next from 'i18next';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

interface EditProps {
  onClick: () => void;
}

function Edit({ onClick }: EditProps): JSX.Element {
  return (
    <>
      <Button
        style={{
          maxWidth: '24px',
          maxHeight: '24px',
          minWidth: '24px',
          minHeight: '24px',
          borderRadius: 5,
          marginLeft: 20,
        }}
        onClick={() => onClick()} // Correctly bind the onClick function to the button's click event.
      >
        <Tooltip title={i18next.t('storepage.edit_store') as string}>
          <img
            src={editIcon}
            className={styles.shareIcon}
            alt=''
          />
        </Tooltip>
      </Button>
    </>
  );
}

export default Edit;