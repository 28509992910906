import React from 'react';

const ImageGallery = ({ images, onImageRemove }) => {
  const handleImageRemove = (index) => {
    onImageRemove(index);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }} className='image-gallery'>
      {images.map((imageUrl, index) => (
        <div key={index} style={{ position: 'relative' }} className='image-container'>
          <img src={imageUrl} alt={`${index}`} style={{ width: '150px', height: '150px' }} className='image' />
          <button
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              backgroundColor: 'red',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              lineHeight: '1',
            }}
            className='remove-button'
            onClick={() => handleImageRemove(index)}
          >
            X
          </button>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;