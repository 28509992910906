import React from 'react';

import { LevelTask } from '../TestingUtils';

import FeedbackInstructions from '../FeedbackInstructions';
import DownloadInstructions from '../DownloadInstructions';
import NavigationImg from '../../../../assets/images/pilot-testing/howto-appfeedback/levelPhotos/NavigateTask.png';

const ENlvl1Tasks: LevelTask[] = [
  {
    taskNum: 1,
    task: 'Download the Stocate application on your mobile device:',
    afterTaskComponent: <DownloadInstructions />,
  },
  {
    taskNum: 2,
    task: 'Sign into your Stocate account.',
  },
  {
    taskNum: 3,
    task: 'Navigate around the app briefly, to get a feel for the overall design of the app',
    taskImage: NavigationImg,
    taskImageAlt: 'navigation',
    textBoxLabel:
      'Do you have feedback based on your first impression of the app?\nThis could relate to the design, content, ease of use, or anything else you would like!',
  },
  {
    taskNum: 4,
    task: `Do you have any other feedback? Or anything that reduced your experience?\nPlease follow these steps to let us know:`,
    afterTaskComponent: <FeedbackInstructions />,
  },
];

const FRlvl1Tasks: LevelTask[] = [
  {
    taskNum: 1,
    task: `Téléchargez l'application Stocate sur votre appareil mobile:`,
    afterTaskComponent: <DownloadInstructions />,
  },
  {
    taskNum: 2,
    task: 'Connectez-vous à votre compte Stocate.',
  },
  {
    taskNum: 3,
    task: `Explorez l'application brièvement pour vous faire une idée générale de la plateforme.`,
    taskImage: NavigationImg,
    taskImageAlt: 'navigation',
    textBoxLabel: `Quelles sont vos première impressions de l'application?\nCela pourrait être liée à la conception, au contenu, à la facilité d'utilisation ou à tout autre aspect que vous souhaitez!`,
  },
  {
    taskNum: 4,
    task: `Avez-vous d'autres commentaires? Y a-t-il quelque chose qui a réduit votre expérience?\nVeuillez suivre les instructions suivantes:`,
    afterTaskComponent: <FeedbackInstructions />,
  },
];

export { ENlvl1Tasks, FRlvl1Tasks };
