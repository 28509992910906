import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './ListCard.module.css';
import defaultList from '../../../../assets/images/home//defaultList.png';
import { listEndpoints } from '../../../../stocateConstants';
import Share from '../../../../components/reusable/Share';
import Carousel from 'react-material-ui-carousel';
import { sendListView } from 'utils/GoogleAnalytics';
import { addResizingListener } from 'utils/Responsiveness';
import { useLocation } from 'react-router-dom';

ListCard.propType = {
  list: PropTypes.any,
  viewList: PropTypes.any,
};

ListCard.defaultProps = {
  list: null,
  viewList: false,
};

function ListCard({ list, tagHandler, viewList }) {
  function copyingLink() {
    let initialLink = window.location.href.substring(
      0,
      window.location.href.lastIndexOf('/')
    );
    viewList
      ? navigator.clipboard.writeText(window.location.href)
      : navigator.clipboard.writeText(
          initialLink + '/' + listEndpoints + list.id
        );
  }

  const sendToList = () =>
    (window.location.href = '/' + listEndpoints + list.id);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { pathname } = useLocation();
  const isBrowsePage = pathname === '/browse';

  useEffect(() => {
    addResizingListener(setIsMobile);
  }, []);
  
  const openImageModal = (index) => {
    setSelectedImageIndex(index);
    setImageModalIsOpen(true);
  };

  const closeImageModal = () => {
    setImageModalIsOpen(false);
  };

  if (list) {
    sendListView(list.id, list.name);
  }

  return (
    <>
      {list === null ? (
        <div></div>
      ) : (
        <div style={{backgroundColor: isBrowsePage ? '#FFFFFF' : 'transparent', height:'100vh', paddingTop: '20px'}}>
          <div className={styles.centered}>
            {list.images[0] ? (
              list.images.length === 1 ? (
                <img
                  src={list.images[0]}
                  alt='list'
                  className={`${styles.singleListImage} ${styles.centered}`}
                  style={{ cursor: 'pointer' }}
                />
              ) : (
                <Carousel
                  autoPlay={true}
                  stopAutoPlayOnHover={true}
                  navButtonsAlwaysVisible={true}
                >
                  {list.images.map((imageUrl, i) => (
                    <div key={i} style={{ height: '300px' }} className={styles.centered}>
                      <img
                        src={imageUrl}
                        alt='list'
                        className={styles.singleListImage}
                        style={{ cursor: 'pointer' }}
                        onClick={() => openImageModal(i)}
                      />
                    </div>
                  ))}
                </Carousel>
              )
            ) : (
              <img
                src={defaultList}
                alt='list'
                className={`${styles.singleListImage} ${styles.centered}`}
                style={{ filter: 'brightness(0.5)' }}
              />
            )}
          </div>
          <div className={styles.globalFrame}>
            <div className={styles.title}>
              <h2>
                <a
                  onClick={sendToList}
                  href={sendToList}
                  className={styles.buttonStyleHover}
                >
                  {list.name}
                </a>
                <Share entity={list} copyingLink={copyingLink} />
              </h2>
            </div>
          </div>
          {list.description.length === 0 ? null : (
            <div className={styles.itemDesc}>
              <p>{list.description}</p>
            </div>
          )}
          {list.tags.length === 0 ? null : (
            <div className={styles.itemDesc}>
              {list.tags.map((tags) => {
                return (
                  <span
                    style={{
                      marginRight: 5,
                      textTransform: 'lowercase',
                      fontWeight: 'normal',
                    }}
                  >
                    {'#' + tags}
                  </span>
                );
              })}
            </div>
          )}
          {list.author.length === 0 ? null : (
            <div className={styles.itemDesc}>
              <p>{list.author}</p>
            </div>
          )}
          {/* Image Modal */}
          <Modal
            isOpen={imageModalIsOpen}
            onRequestClose={closeImageModal}
            contentLabel='Enlarged Image'
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                maxWidth: isMobile ? '90%' : '50%',
                maxHeight: isMobile ? '60%' : '70%',
                margin: 'auto',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          >
            {list.images.length === 1 ? (
                <img
                  src={list.images[0]}
                  alt='list'
                  className={`${styles.singleStoreImage} ${styles.centered}`}
                  style={{ objectFit: 'contains', maxHeight: '90%', maxWidth: '90%'}}
                />
              ) : (
            <Carousel
              autoPlay={false}
              navButtonsAlwaysVisible={true}
              index={selectedImageIndex}
            >
              {list.images.map((imageUrl, i) => (
                <img
                  key={i}
                  src={imageUrl}
                  style={{
                    maxWidth: '80%',
                    maxHeight: '80%',
                    objectFit: 'contain',
                    display: 'block',
                    margin: 'auto',
                    borderRadius: '15px',
                  }}
                  alt={'store'}
                />
              ))}
            </Carousel>)}
          </Modal>
        </div>
      )}
    </>
  );
}

export default ListCard;
