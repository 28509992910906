import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from './SectionTitle';
import useAddStoreStyles from '../addStore/SharedAddStoreStyles';
import sectionsIcon from '../../../assets/images/addPages/sections.webp';

import Creatable from 'react-select/creatable';

import { Box } from '@material-ui/core';

const GetSectionHandles = (props) => 
{
  const classes = useAddStoreStyles();
  const { t } = useTranslation();
  const placeholder = props.entity != null ? props.sections.find((obj) => obj.id === props.entity.selectedSection)?.name : ''

  const creatableOnChange = (value) => {
    if (value.id) {
      props.setSection(value.id)
    } else {
      props.setNewSection(value.label)
    }
  }

  return (
    <>
      <SectionTitle
          iconSrc={sectionsIcon}
          title={t('additem.section_label')}
      />
      <Box className={classes.root}>
          <div className='storeHandles' id='storeHandles'>
          <Creatable
              placeholder={placeholder}
              value={props.entity != null ? props.sections.find((obj) => obj.id === props.entity.sectionId) : null}
              options={props.sections} 
              isClearable={true}
              isSearchable={true}
              isLoading={props.sections.length<1}
              onChange={(x) => creatableOnChange(x)}
          />
          </div>
      </Box>
    </>
  );
};

export default React.memo(GetSectionHandles);
