import React, { useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import profileImage from '../../../assets/images/profile/profile.webp';
import profileIcon from '../../../assets/images/sign-up/profile-black.png';
import emailIcon from '../../../assets/images/sign-up/email.png';
import TextInputField from '../../reusable/TextInputField';
import { useAuth } from '../../../context/AuthContext';
import { useAlert } from '../../../context/AlertContext';
import { paths } from '../../../stocateConstants';
import { editUserInfo, deleteUser } from '../../../api/UserApi';
import {
  Container,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import {
    isEmail,
} from '../../../utils/Validation';
import IUser from '../../../types/dto/user';

const useStyles = makeStyles((theme) => ({
    icon: {
      maxWidth: '300px',
      width: '80%',
      height: '100%',
    },
    stocateBtn: {
      backgroundColor: '#01647B',
      color: 'white',
    },
    change_password_button: {
      backgroundColor: 'black',
      color: 'white',
    },
    delete_account_button: {
      backgroundColor: 'red',
      color: 'white',
    },
}));

interface InitialProfileInfo {
  nameFirst: string;
  nameLast: string;
  username: string;
  email: string;
}

const EditProfile = () => {
    const { alertSuccess, alertWarning, alertError } = useAlert();
    const { dispatch, state: authState } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();

    const [userInfo, setUserInfo] = useState<InitialProfileInfo>({
      nameFirst: authState.user?.firstName ?? '',
      nameLast: authState.user?.lastName ?? '',
      username: authState.user?.username ?? '',
      email: authState.user?.email ?? '',
    });

    const [dialogueOpen, setDialogueOpen] = useState(false);
    const handleOnDelete = () => {
      setDialogueOpen(true);
    }
    const handleConfirmDelete = () => {
      alertWarning(t('message.processing'));
      deleteUser(successCallbackDelete, failCallbackDelete)
    }
    const handleCloseDelete = () => {
      setDialogueOpen(false);
    }

    const formEmpty = Object.values(userInfo).every(
      (field) => field.trim() === ''
    );
    const noEmptyFields = Object.values(userInfo).every(
      (field) => field.trim() !== ''
    );
    const isValidEmail: boolean = isEmail(userInfo.email);
    const emailEmpty = userInfo.email.trim() === '';
    const showEmptyFieldsError = !formEmpty && !noEmptyFields;
    const showEmailError = !emailEmpty && !isValidEmail;
    const isBtnDisabled = !noEmptyFields || !isValidEmail;

    const updateUserInfo: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
    setUserInfo({
        ...userInfo,
        [e.target.id]: e.target.value,
    });
    };

    const onSubmit = () => {
      alertWarning(t('message.processing'));
      editUserInfo(userInfo, successCallback, failCallback);
    };

    const successCallback = (body: IUser) => {
      dispatch({
        type: 'UPDATEUSER',
        payload: body,
      });
      alertSuccess(t('editProfile.update_success'));
    };
    const failCallback = (statusCode: number, body: any) => {
      if (statusCode === 401) {
        if (body.includes('Username'))
          alertError(t('signup.username_exist_error'));
        if (body.includes('Email'))
          alertError(t('signup.email_exist_error'));
        else alertError(t('errorCode.unexpected'));
      } else alertError(t('errorCode.unexpected'));
    };

    const successCallbackDelete = (body: IUser) => {
      dispatch({
        type: 'SIGNOUT'
      });
      history.push(paths.HOME);
      alertSuccess(t('editProfile.delete_success'));
    };
    const failCallbackDelete = (res: any) => {
      alertError(res);
    }
    const navigateToChangePassword = () => {
      window.location.href = '/changePassword'
    };

    return (
        <Container>
          <Grid
            container={true}
            direction='column'
            justify='center'
            alignItems='center'
            >
              <img className={classes.icon} src={profileImage} alt='' />
          </Grid>
        <Grid
          item={true}
          container={true}
          justify='center'
          style={{
            marginTop: 5,
          }}
        >
          <Grid
            item={true}
            container={true}
            direction='row'
            alignItems='center'
            justify='space-between'
          >
            <TextInputField
              id='nameFirst'
              labelIcon={profileIcon}
              label={t('signup.firstname')}
              type='text'
              value={userInfo.nameFirst}
              onChange={updateUserInfo}
            />
            <TextInputField
              id='nameLast'
              labelIcon={profileIcon}
              label={t('signup.lastname')}
              type='text'
              value={userInfo.nameLast}
              onChange={updateUserInfo}
            />
          </Grid>

          <TextInputField
            id='username'
            labelIcon={profileIcon}
            label={t('signup.username')}
            type='text'
            value={userInfo.username}
            onChange={updateUserInfo}
            xs={12}
          />
          <TextInputField
            id='email'
            labelIcon={emailIcon}
            label={t('signup.email')}
            type='text'
            value={userInfo.email}
            onChange={updateUserInfo}
            xs={12}
          />
          <Grid item={true} container={true} direction='column'>
            {showEmptyFieldsError ? (
              <Typography variant='subtitle2' color='error'>
                {t('signup.warning1')}
              </Typography>
            ) : showEmailError ? (
              <Typography variant='subtitle2' color='error'>
                {t('signup.warning2')}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          container={true}
          direction='row'
          justify='center'
          alignItems='center'
          spacing={4}
          style={{
            marginTop: 5,
          }}
        >
          <Grid item={true}>
            <Button
              fullWidth={false}
              variant='contained'
              className={classes.stocateBtn}
              disabled={isBtnDisabled}
              onClick={onSubmit}
              size='large'
              style={{ width: '15rem', height: '3rem' }}
            >
              {t('editProfile.save_button')}
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              fullWidth={false}
              variant='contained'
              className={classes.stocateBtn}
              disabled={isBtnDisabled}
              onClick={navigateToChangePassword}
              size='large'
              style={{ width: '15rem', height: '3rem' }}
            >
              {t('editProfile.change_password')}
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              fullWidth={false}
              variant='contained'
              className={classes.delete_account_button}
              disabled={isBtnDisabled}
              onClick={handleOnDelete}
              size='large'
              style={{ width: '15rem', height: '3rem' }}
            >
              {t('editProfile.delete_button')}
            </Button>
          </Grid>
        </Grid>
        <Dialog open={dialogueOpen} onClose={handleCloseDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color='primary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
        </Container>
    )
}

export default EditProfile;