import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from './SectionTitle';
import useAddStoreStyles from '../addStore/SharedAddStoreStyles';
import tagsIcon from '../../../assets/images/addPages/tag.webp';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IAddStore from '../../../types/add/IAddStore';
import IAddList from '../../../types/add/IAddList';
import IAddItem from '../../../types/add/IAddItem';

const TagPicker = ({
  setEntity,
  entity,
}: {
  setEntity:
    | React.Dispatch<React.SetStateAction<IAddItem>>
    | React.Dispatch<React.SetStateAction<IAddStore>>
    | React.Dispatch<React.SetStateAction<IAddList>>;
  entity: any;
}) => {
  const classes = useAddStoreStyles();
  const { t } = useTranslation();

  const onTagsChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    event.persist();
    setEntity((prevStore: any) => ({
      ...prevStore,
      tags: value,
    }));
  };

  return (
    <>
      <SectionTitle iconSrc={tagsIcon} title={t('addstore.tags_label')} />
      <Autocomplete
        value={entity != null ? entity.tags : []}
        multiple={true}
        options={[]}
        freeSolo={true}
        className={classes.root}
        onChange={onTagsChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            size='small'
            data-cy='addentity-tags'
            placeholder={t('addstore.tags_placeholder')}
          />
        )}
      />
    </>
  );
};

export default React.memo(TagPicker);
