import React, { useState, useEffect } from 'react'
import StocateLogo from './../../assets/images/navbar/stocateLogo.png'
import { Grid } from '@material-ui/core';
import styles from '../navigation-bar/NavigationBar.module.css'
import { paths, stocateFacebook, stocateInstagram, stocateLinkedin } from './../../stocateConstants.js'
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import facebookIcon from '../../assets/images/general/stocateFacebook.png';
import instagramIcon from '../../assets/images/general/stocateInstagram.png';
import linkedinIcon from '../../assets/images/general/stocateLinkedin.png';
import { addResizingListener } from 'utils/Responsiveness';
import Navbar from 'react-bootstrap/Navbar';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { color } from 'stocateConstants';

const useStyles = makeStyles(() => ({
    stickyFooter: {
        width: '100vw',
        position: 'fixed',
        paddingLeft: 10,
        paddingRight: 10,
        bottom: 0,
        backgroundColor: color.veryLightGray
    },
    padding: {
        minHeight: '10vh'
    },
    miniFooter: { // for browse page specifically
        width: '100vw',
        position: 'fixed',
        paddingLeft: 10,
        paddingRight: 10,
        bottom: 0,
        backgroundColor: color.veryLightGray
    },
    mobileFooter: {
        width: '100vw',
        padding: 10,
        zIndex: 100
    },
    dispNone: {
        display: 'none'
    }
    .footerText = {
        fontSize:15,
        color: '#767676',
        marginBottom: 0,
        lineHeight: 1.5
    }
}));

const useStylesMedia = makeStyles(() => ({
    bottomNavStocateLogo: {
        '@media (max-width: 1024px)': {
            display: 'none'
        }
    }
}));

const date = new Date().getFullYear()

function Footer() { 
    const [isMobile, setIsMobile] = useState(false);
    const [onBrowsePage, setOnBrowsePage] = useState(false);
    const location = useLocation();
    const classes = useStyles();
    const classesMedia = useStylesMedia();
    
    useEffect(() => {
        addResizingListener(setIsMobile);
            setOnBrowsePage(location.pathname === paths.BROWSE);
    }, [location.pathname]);
    return (
        <>
        <div className={(isMobile)? classes.dispNone : classes.padding}></div>
        <div className={(isMobile)? classes.mobileFooter : 
            (onBrowsePage)? classes.miniFooter : classes.stickyFooter}> 
            <Grid container={true} justify='space-between' alignItems='center' spacing={5}>
                <Grid className = {classesMedia.bottomNavStocateLogo} item={true} xs={isMobile ? 12 : 2} >
                    <Navbar.Brand href='/'><img src={StocateLogo} className={styles.logoImg} alt='Stocate logo'></img></Navbar.Brand>
                </Grid>

                <Grid 
                    container
                    direction='row'
                    justify='space-around'
                    item xs={isMobile ? 12 : 6}
                >
                    <a href='/about' style={{color: '#767676'}} className={ classes.footerText }>{i18next.t('titles.about')}</a>
                    <span style={{color: '#767676'}} className={ classes.footerText }>© {date} Stocate</span>
                    <a href='/serviceAgreement' style={{color: '#767676'}} className={ classes.footerText }>{i18next.t('footer.agreement')}</a>
                    <a href='/privacy' style={{color: '#767676'}} className={ classes.footerText }>{i18next.t('footer.privacy')}</a>
                    <a href='/terms' style={{color: '#767676'}} className={ classes.footerText }>{i18next.t('footer.term')}</a>
                </Grid>
                    
                <Grid item={true} container
                    direction='row'
                    justify={'center'}
                    xs={isMobile ? 12 : 3}
                    spacing={1}
                >
                    <Grid item={true}>
                        <a id='instagram' href={stocateInstagram} style={{ color: 'inherit' }} target='_blank' 
                            rel='noopener noreferrer'><img src={instagramIcon} className={styles.navbarItem} alt='Stocate logo'
                        /></a>
                    </Grid>
                    <Grid item={true}>
                        <a id='facebook' href={stocateFacebook} style={{ color: 'inherit' }} target='_blank' 
                            rel='noopener noreferrer'><img src={facebookIcon} className={styles.navbarItem} alt='Stocate logo'
                        /></a>
                    </Grid>
                    <Grid item={true}>
                        <a id='linkedin' href={stocateLinkedin} style={{ color: 'inherit' }} target='_blank' rel='noopener noreferrer'>
                            <img src={linkedinIcon} className={styles.navbarItem} alt='Stocate logo'
                        /></a>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        </>
    );
}

export default withTranslation()(Footer);