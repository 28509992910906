import { feedbackEndpoints, onlineAPI } from 'stocateConstants';
import { checkLocalStorageForToken } from './CommonApi';

export async function addFeedback(feedbackInfo, images, successCallback, failCallback) {
    var jsonBody = {
        'userId': feedbackInfo.data.userId,
        'text': feedbackInfo.data.text,
        'platform': 'website',
        'name': feedbackInfo.data.name, 
        'email': feedbackInfo.data.email,
        'phone': feedbackInfo.data.phone,
        'idNumber': Number.isNaN(parseInt(feedbackInfo.data.idNumber, 10)) ? null: parseInt(feedbackInfo.data.idNumber, 10),
        'currentPage': feedbackInfo.data.currentPage
    }
	await fetch(onlineAPI + feedbackEndpoints, {
        method: 'POST',
        body: JSON.stringify(jsonBody),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
	.then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    if(images !== undefined && images !== null){
                        if(images.length >= 1)
                            addFeedbackPicture(jsonRes, images, successCallback, failCallback)
                        else{
                            successCallback(jsonRes)
                        }
                    }else{
                        successCallback(jsonRes)
                    }
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}

export async function getFeedback(successCallback, failCallback) {
    await fetch(onlineAPI + feedbackEndpoints, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
    .then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
    })
}

export async function getAllFeedbackStatuses(successCallback, failCallback) {
    return fetch(onlineAPI + feedbackEndpoints + 'feedbackStatuses', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
    .then(res => {
        if(res.status === 500){
            failCallback(res.status)
        } else {
            return res.json();
        }
    })
    .then(jsonRes => {
        if (jsonRes) {
            successCallback(jsonRes);
            return jsonRes;
        } else {
            throw new Error('Failed to fetch feedback status');
        }
    })
    .catch(error => {
        failCallback(error);
        throw error;
    });
}

export async function getNumAllFeedbackStatuses( successCallback, failCallback) {
    return fetch(onlineAPI + feedbackEndpoints + 'numFeedbackStatuses', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
    .then(res => {
        if(res.status === 500){
            failCallback(res.status)
        } else {
            return res.json();
        }
    })
    .then(jsonRes => {
        if (jsonRes) {
            successCallback(jsonRes);
            return jsonRes;
        } else {
            throw new Error('Failed to fetch feedback status count');
        }
    })
    .catch(error => {
        failCallback(error);
        throw error;
    });
}

export async function getAllLabelDTOs(successCallback, failCallback) {
    await fetch(onlineAPI + feedbackEndpoints + 'labels', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
    .then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
    })
}

export async function getLabelsFromFeedback(feedbackId, successCallback, failCallback) {
    await fetch(onlineAPI + feedbackEndpoints + feedbackId + '/labels', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
    .then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
    })
}

export async function addLabelToFeedback(feedbackId, labelId, successCallback, failCallback) {
    await fetch(onlineAPI + feedbackEndpoints + feedbackId + '/label/add/' + labelId, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
    .then(res => {
		if(res.status === 200){
			successCallback()
		}else{
			failCallback(res.status)
		}
	})
}

export async function removeLabelFromFeedback(feedbackId, labelId, successCallback, failCallback) {
    await fetch(onlineAPI + feedbackEndpoints + feedbackId + '/label/remove/' + labelId, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
    .then(res => {
		if(res.status === 200){
			successCallback()
		}else{
			failCallback(res.status)
		}
	})
}

export async function setFeedbackStatus(feedbackId, statusId) {
    await fetch(onlineAPI + feedbackEndpoints + feedbackId + '/status/' + statusId, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
}

export async function setFeedbackStatusCounter(statusId, addFeedbacksIn, addFeedbacksOut) {
    await fetch(onlineAPI + feedbackEndpoints + statusId + '/statusCountUpdate/' + addFeedbacksIn + '/' + addFeedbacksOut, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
}

export async function setFeedbackPriority(feedbackId, priorityId) {
    await fetch(onlineAPI + feedbackEndpoints + feedbackId + '/priority/' + priorityId, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
}

async function addFeedbackPicture(feedback, images, successCallback, failCallback) {
	await fetch(onlineAPI + feedbackEndpoints + 'images/add', {
		method: 'POST',
		header: {
            'Accept': '/',
            'Content-Type': '/'
		},
		body: createFormData(feedback, images),
	})
	.then(res => {
		if(res.status === 200){
			successCallback()
		}else{
			failCallback(res.status)
		}
	})
}

function createFormData(feedback, images){
	const formData = new FormData();
	formData.append('name', feedback.name);
	formData.append('id', feedback.id);
    images.forEach(image => formData.append('ImageList', image));
	return formData;
};

export async function addFeedbackLabel(text, successCallback, failCallback) {
    var jsonBody = {
        'text': text
    }
	await fetch(onlineAPI + feedbackEndpoints + 'label', {
        method: 'POST',
        body: JSON.stringify(jsonBody),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
	.then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                successCallback(jsonRes)
            })
        }
	})
}

export async function setFeedbackLabelPriority(labelId, priorityId) {
    await fetch(onlineAPI + feedbackEndpoints + labelId + '/label/priority/' + priorityId, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
}

export async function setFeedbackLabelStatus(labelId, statusId) {
    await fetch(onlineAPI + feedbackEndpoints + labelId + '/label/status/' + statusId, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
}

export async function setFeedbackJiraID(feedbackId, jiraId, jiraType) {
    const numericJiraId = parseInt(jiraId, 10);

    if (isNaN(numericJiraId) || !Number.isInteger(numericJiraId) || numericJiraId <= 0) {
        return;
    }

    const feedbackJiraDTO = {
        Id: feedbackId,
        JiraId: jiraId,
        JiraType: jiraType,
    };  
    await fetch(onlineAPI + feedbackEndpoints + 'jira', {
        method: 'PUT',
        body: JSON.stringify(feedbackJiraDTO),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    });
}

export async function setFeedbackLabelName(labelId, newName) {
    await fetch(onlineAPI + feedbackEndpoints + labelId + '/label/text/' + newName, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
}

export async function deleteFeedbackLabel(labelId, successCallback, failCallback) {
    await fetch(onlineAPI + feedbackEndpoints + 'label/' + labelId, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    }).then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            successCallback()
        }
	})
}
