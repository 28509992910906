import React, { useState, useEffect } from 'react';
import i18n from '../../i18n';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { addResizingListener } from 'utils/Responsiveness';

function TranslationToggle() {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    addResizingListener(setIsMobile);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  const handleMouseEnter = () => {
    if(!isMobile) {
     setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if(!isMobile) {
      setIsDropdownOpen(false);
    }
  };

  return (
    <Dropdown 
      className='justify-content-center mx-3' 
      data-cy='translationToggle'
      style={{ fontWeight: 'normal', color: 'white'}} 
      show={isDropdownOpen}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={() => setIsDropdownOpen(false)}
    >
      <Dropdown.Toggle id='collasible-nav-dropdown' onClick={() => setIsDropdownOpen(!isDropdownOpen)} style={{position: 'relative', backgroundColor: 'transparent', border: 'none', outline: 'none', boxShadow: 'none'}}>
        {i18next.t('titles.lng')}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ position: 'absolute' }}>
        <Dropdown.Item onMouseDown={() => changeLanguage('en')} data-cy='enToggle'>
          EN
        </Dropdown.Item>
        <Dropdown.Item onMouseDown={() => changeLanguage('fr')} data-cy='frToggle'>
          FR
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default withTranslation()(TranslationToggle);
