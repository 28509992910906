import React, { useState, useEffect } from 'react';
import './BottomPopup.css';
import { addResizingListener } from '../../../utils/Responsiveness';
import StocateLogo from '../../../assets/images/navbar/stocateLogo.png';
import ChromeLogo from '../../../assets/images/browserLogo/googleChrome.png';
import SafariLogo from '../../../assets/images/browserLogo/safari.png';
import FirefoxLogo from '../../../assets/images/browserLogo/firefox.png';
import OperaLogo from '../../../assets/images/browserLogo/opera.png';
import BraveLogo from '../../../assets/images/browserLogo/brave.png';
import InternetExplorerLogo from '../../../assets/images/browserLogo/internetExplorer.png';
import { Modal, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
}));

const BottomPopup = () => {
    const classes = useStyles();
    const [isMobile, setIsMobile] = useState(false);
    const [closed, setClosed] = useState<boolean>(
        sessionStorage.getItem('popupClosed') === 'true'
    );
    const [isChrome, setIsChrome] = useState(false);
    const [isSafari, setIsSafari] = useState(false);
    const [isFirefox, setIsFirefox] = useState(false);
    const [isOpera, setIsOpera] = useState(false);
    const [isBrave, setIsBrave] = useState(false);
    const [isIE, setIsIE] = useState(false);
    useEffect(() => {
        addResizingListener(setIsMobile);
        const userAgent = navigator.userAgent;
        if (userAgent.match(/Chrome/i)) {
            setIsChrome(true);
        } else if (userAgent.match(/Safari/i)) {
            setIsSafari(true);
        }
        else if (userAgent.match(/Firefox/i)) {
            setIsFirefox(true);
        }
        else if (userAgent.match(/Opera|OPR/i)) {
            setIsOpera(true);
        }
        else if (userAgent.match(/Brave/i)) {
            setIsBrave(true);
        }
        else if (userAgent.match(/MSIE|Trident/i)) {
            setIsIE(true);
        }
    }, []);
    const handleContinueBrowser = () => {
        setClosed(true);
        sessionStorage.setItem('popupClosed', 'true');
    };

    const handleUseApp = () => {
        sessionStorage.setItem('popupClosed', 'true');
        window.open('https://www.stocate.com/mobileApp', '_blank');
    };

    return (!closed && isMobile) ? (
        <Modal
        open={!closed}
        onClose={undefined}
        className={classes.modal}
        aria-labelledby='bottom-modal'
        aria-describedby='modal-to-appear-at-bottom'
        >
            <div className='bottom-popup'>
                <h3 style={{ color: 'white', marginTop: '1vh'}}>See content in...</h3>
                <div className='bottomContent'>
                    <div className='topRow'>
                        <div className='logoAndName'>
                            <img
                            src={StocateLogo}
                            className='logoImg'
                            alt='Stocate logo'
                            />
                            <p className='optionText' >Stocate App</p>
                        </div>
                        <button className='openButton' onClick={handleUseApp}>Open</button>
                    </div>
                    <div className='bottomRow'>
                        <div className='logoAndName'>
                            { isChrome && <img src={ChromeLogo} className='browserLogoImg' alt='Stocate logo'/>}
                            { isSafari && <img src={SafariLogo} className='browserLogoImg' alt='Safari logo'/>}
                            { isFirefox && <img src={FirefoxLogo} className='browserLogoImg' alt='Firefox logo'/>}
                            { isOpera && <img src={OperaLogo} className='browserLogoImg' alt='Opera logo'/>}
                            { isBrave && <img src={BraveLogo} className='browserLogoImg' alt='Brave logo'/>}
                            { isIE && <img src={InternetExplorerLogo} className='browserLogoImg' alt='Internet Explorer logo'/>}
                            <p className='optionText'>Browser</p>
                        </div>
                        <button className ='continueButton' onClick={handleContinueBrowser} data-cy='bottomPopUp-useContinue'>Continue</button>
                    </div>
                </div>
            </div>
        </Modal>
    ) : null;
};
export default BottomPopup;