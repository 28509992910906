import React, { useState } from 'react';

import { color } from '../../../../stocateConstants';
import options3dotIcon from '../../../../assets/images/3dotoptions/moreIcon.webp';
import ShareMenuOption from './listMenuOptions/ShareMenuOption';
import CopyMenuOption from './listMenuOptions/CopyMenuOption';
import AddToListMenuOption from './listMenuOptions/AddToListMenuOption';
// import EditMenuOption from './listMenuOptions/EditMenuOption';
import DeleteMenuOption from './listMenuOptions/DeleteMenuOption';
import { optionIcon } from './SharedOptionStyles';

import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';

import IList from '../../../../types/dto/list';

const useStyles = makeStyles((theme) => ({
  optionIcon,
  list: {
    padding: '0',
  },
  menu: {
    backgroundColor: color.veryLightGray,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    // borderRadius: '0.5em',
  },
  buttonPosition: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    display: 'relative',
  },
  icon3dots: {
    width: '36px',
    height: 'auto',
  },
}));

const ListCellOptions = ({ list }: { list: IList }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const closeMenuAfter: ICloseMenuAfter = (func) => (e) => {
    func();
    handleClose(e);
  };

  const optionProps = { list, closeMenuAfter };
  const UserOwnsListOptions = [
    // EditMenuOption, -----> Uncomment when edit page is done (reuse add List component)
    ShareMenuOption,
    CopyMenuOption,
    DeleteMenuOption,
  ];
  const DoesntOwnListOptions = [
    ShareMenuOption,
    AddToListMenuOption,
    CopyMenuOption,
  ];

  return (
    <>
      <IconButton
        className={classes.buttonPosition}
        size='small'
        aria-controls={LIST_CONTROLS_ID}
        aria-haspopup='true'
        onClick={handleClick}
        data-cy={`optionsBtn-${list.id}`}
      >
        <img
          className={classes.icon3dots}
          src={options3dotIcon}
          alt='item menu button'
        />
      </IconButton>
      <Menu
        id={LIST_CONTROLS_ID}
        classes={{ paper: classes.menu, list: classes.list }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted={true}
      >
        {(list.isOwner ? UserOwnsListOptions : DoesntOwnListOptions)
          // Pass the same props to each menu option
          .map((OptionComponent: typeof ShareMenuOption, i: number) => (
            <OptionComponent {...optionProps} key={i} />
          ))
          // Place a divider between each element
          .flatMap((v, i, arr) =>
            arr.length - 1 !== i ? [v, <Divider key={`divider-${i}`} />] : v
          )}
      </Menu>
    </>
  );
};

type ICloseMenuAfter = (func: () => void) => (e: React.SyntheticEvent) => void;

export interface ListOptionProps {
  closeMenuAfter: ICloseMenuAfter;
  list: IList;
}

const LIST_CONTROLS_ID = 'item-menu';

export default ListCellOptions;
