import { RefObject } from 'react'

import useEventListener from './useEventListener'

type Handler = (event: MouseEvent) => void

// See: https://usehooks-ts.com/react-hook/use-on-click-outside
function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  refs: RefObject<T>[],
  handler: Handler,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
): void {
  useEventListener(mouseEvent, event => {
    for (const ref of refs) {
        const el = ref?.current
        // Do nothing if clicking one of the ref's element or descendent elements
        if (!el || el.contains(event.target as Node)) return;
    }
    // Explicit type for "mousedown" event.
    handler(event as unknown as MouseEvent)
  });
}

export default useOnClickOutside;