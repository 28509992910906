import { loggingEndpoints, onlineAPI } from 'stocateConstants';
import { checkLocalStorageForToken } from './CommonApi';

export async function postSearch(searchString, currentPage, signal) {
  try {
    const response = await fetch(onlineAPI + loggingEndpoints + 'search', {
      signal,
      method: 'POST',
      body: JSON.stringify({
        searchString: searchString,
        currentPage: currentPage,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: checkLocalStorageForToken().exists
          ? 'Bearer ' + checkLocalStorageForToken().token
          : '',
      },
    });

    if (!response.ok) {
      // Handle error responses here
      throw new Error('Network response was not ok');
    }
    const data = await response.json(); // Parse the response as JSON
    return data; // Return the parsed JSON data
  } catch (error) {
    throw error;
  }
}

export async function postShare(shareJSON) {
  fetch(onlineAPI + loggingEndpoints + 'share', {
    method: 'POST',
    body: JSON.stringify(shareJSON),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: checkLocalStorageForToken().exists
        ? 'Bearer ' + checkLocalStorageForToken().token
        : '',
    },
  });
}
