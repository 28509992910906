import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import trashIcon from '../../../../../assets/images/3dotoptions/trash.webp';
import { useOptionStyles } from '../SharedOptionStyles';
import { useAlert } from '../../../../../context/AlertContext';
import { useDeleteDialog } from '../../../../../context/DeleteDialogContext';
import useDeleteList from '../../../../../hooks/useDeleteList';
import { StocateQuery, queryClient } from '../../../../../utils/queryClient';

import MenuItem from '@material-ui/core/MenuItem';

import { ListOptionProps } from '../ListCellOptions';

const DeleteMenuOption = forwardRef(
  ({ closeMenuAfter, list }: ListOptionProps, ref) => {
    const classes = useOptionStyles();
    const { alertSuccess, alertWarning, alertError } = useAlert();
    const { openDeleteDialog } = useDeleteDialog();
    const deleteList = useDeleteList();
    const { t } = useTranslation();

    const onDeleteClick = () => {
      const title = `${t('delete_modal.text')} - ${list.name}`;
      const warning = t('delete_modal.list_warning');
      const onConfirmClick = () => {
        alertWarning(t('message.processing'));
        deleteList.mutate(
          { listId: list.id },
          {
            onSuccess: () => {
              alertSuccess(t('delete_modal.list_success'));
              queryClient.invalidateQueries(StocateQuery.MYLISTS);
              queryClient.invalidateQueries(StocateQuery.LISTS);
            },
            onError: (error) => {
              const { code, message } = error;
              if (code === 401) {
                if (message.includes('User does not have permissions'))
                  alertError(t('delete_modal.permission_error'));
                else if (message.includes('Cannot delete favorites list'))
                  alertError(t('delete_modal.favourite_permission_error'));
                else alertError(t('errorCode.unexpected'));
              } else if (code === 404) alertError(t('errorCode.listView_404'));
              else alertError(t('errorCode.unexpected'));
            },
          }
        );
      };
      openDeleteDialog(title, warning, onConfirmClick);
    };

    return (
      <MenuItem
        onClick={closeMenuAfter(onDeleteClick)}
        {...({ ref } as any)}
        data-cy={`deleteList-${list.id}`}
      >
        <img
          src={trashIcon}
          className={classes.optionIcon}
          alt='delete list icon'
        />
        {t('entityOptions.delete')}
      </MenuItem>
    );
  }
);

export default DeleteMenuOption;
