import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import hoursIcon from '../../../assets/images/addPages/clock.webp';
import DayOfWeekHourPicker from './DayOfWeekHourPicker';
import { DayKeys } from '../addPages/AddUtils';
import SectionTitle from '../addPages/SectionTitle';

import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IAddStore from '../../../types/add/IAddStore';
import { sharedStyles } from './SharedAddStoreStyles';

const useStyles = makeStyles((theme) => ({
  dayLabel: {
    marginRight: '0.5em',
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75em',
    },
  },
  ...sharedStyles,
}));

const HoursPickers = ({
  setStore,
  entity,
}: {
  setStore: React.Dispatch<React.SetStateAction<IAddStore>>;
  entity: any;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  const daysOfWeek = [
    t('storepage.monday'),
    t('storepage.tuesday'),
    t('storepage.wednesday'),
    t('storepage.thursday'),
    t('storepage.friday'),
    t('storepage.saturday'),
    t('storepage.sunday'),
  ];

  return (
    <>
      <SectionTitle iconSrc={hoursIcon} title={t('storepage.hours')} />
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: `min-content ${isMobile ? '' : 'min-content'}`,
          rowGap: '0.5em',
          alignItems: 'center',
        }}
        className={classes.root}
      >
        {Object.values(DayKeys).map((daykey, i) => (
          <Fragment key={daykey}>
            <Typography className={classes.dayLabel}>
              {daysOfWeek[i]}
            </Typography>
            <DayOfWeekHourPicker entity={entity.openHours.find((obj: any) => obj.day === daysOfWeek[i])} day={daykey} setStore={setStore} />
          </Fragment>
        ))}
      </Box>
    </>
  );
};

export default React.memo(HoursPickers);
