import React, { KeyboardEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { postSearch } from '../../api/LoggingApi';
import useAbortController from '../../hooks/useAbortController';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import FilterIcon from '../../assets/images/general/filterIcon.png';


const SearchInBrowse = ({
  setInput,
  setModalIsOpen,
  setModalStoreIsOpen,
  modalIsOpen,
  modalStoreIsOpen,
  currentTab,
  placeholder,
  fullWidth,
  initialSearchTerm,
  clearSearchResult,
}: {
  setInput: (input: string) => void;
  setModalIsOpen: (isOpen: boolean) => void;
  setModalStoreIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  modalStoreIsOpen: boolean;
  currentTab: string;
  placeholder?: string;
  fullWidth?: boolean;
  initialSearchTerm?: string;
  clearSearchResult: (val: []) => void;
}) => {
  const { t } = useTranslation();
  const getSignal = useAbortController();
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    setSearchTerm(initialSearchTerm || '');
  }, [initialSearchTerm]);

  const logSearch = (searchString: string) => {
    const currentPage = window.location.href;
    if (!searchString || searchString.length === 0) return;
    postSearch(searchString, currentPage, getSignal());
  };

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
        setSearchTerm(searchTerm);
        setInput(searchTerm);
        logSearch(searchTerm);
        clearSearchResult([])
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setInput('');
  };

  return (
    <div className='search-bar' data-cy='searchBoxBrowse'>
    <TextField
      fullWidth={fullWidth}
      variant='outlined'
      placeholder={placeholder || t('storepage.search_placeholder')}
      autoComplete='off'
      onKeyDown={handleKeyboardEvent}
      onChange={(e) => setSearchTerm(e.target.value)}
      value={searchTerm}
      InputProps={{
        style: { borderRadius: 25, backgroundColor: '#ffffff' },
        endAdornment: (
          <>
          <IconButton
            onClick={handleClearSearch}
            aria-label='clear'
            style={{ visibility: searchTerm ? 'visible' : 'hidden' }}
          >
            <Close />
          </IconButton>
          {currentTab !== 'list' && (
          <IconButton
            onClick={() => {
              if (currentTab === 'store') {
                setModalStoreIsOpen(true);
              }
              if (currentTab === 'item') {
                setModalIsOpen(true);
              }
            }}
            size='small'
            color='primary'
          >
            <img src={FilterIcon} alt='Filter Icon' style={{ width: '20px', height: '20px' }} />
            </IconButton>
          )}
          </>
        ),
      }}
    />
  </div>
  );
};

export default SearchInBrowse;