import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({
    showAlert, msg, setShowAlert
} : {
    showAlert: boolean, msg: string,
    setShowAlert: (value: React.SetStateAction<boolean>) => void
}) {
    const [open, setOpen] = useState(showAlert)

    const handleClose = () => {
        setOpen(!showAlert);
        setShowAlert(!showAlert);
    };

    useEffect(() => {
        setOpen(showAlert);
    }, [showAlert])

    return (
        <div>
        <Dialog
            open={open}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>{'Use Googles location service?'}</DialogTitle>
            <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                {msg}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color='primary' autoFocus={true}>
                Close
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}
