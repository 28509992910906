import React from 'react';
import { useTranslation } from 'react-i18next';

import { postSearch } from '../../api/LoggingApi';
import useAbortController from '../../hooks/useAbortController';

import TextField from '@material-ui/core/TextField';

const Search = ({
  setInput,
  placeholder,
  fullWidth,
}: {
  setInput: (input: string) => void;
  placeholder?: string;
  fullWidth?: boolean;
}) => {
  const { t } = useTranslation();
  const getSignal = useAbortController();

  const logSearch = (searchString: string) => {
    const currentPage = window.location.href;
    if (!searchString || searchString.length === 0) return;
    postSearch(searchString, currentPage, getSignal());
  };

  const onSearchChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setInput(e.target.value);
  };

  const onBlur: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    onSearchChange(e);
    logSearch(e.target.value);
  };

  return (
    <TextField
      fullWidth={fullWidth}
      variant='outlined'
      placeholder={placeholder || t('storepage.search_placeholder')}
      autoComplete='off'
      data-cy='searchBox'
      onChange={onSearchChange}
      onBlur={onBlur}
    />
  );
};

export default Search;
