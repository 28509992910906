import { useRef, useCallback, useEffect } from 'react';

// This hook is used to cancel in-progress api requests when the user navigates away from
// a page, and the component dismounts. This is important to avoid memory leaks & a bunch of error messages.
//
// To use this hook, at the top of your component do:
// const getSignal = useAbortController();
//
// Then, when you're doing a request (usually in the useEffect of your component), pass this getSignal()
// as the "signal" argument to the API method. If it doesn't have one, add the argument.

// Check if abort controller is supported by the browser
const isAbortControllerSupported = window.hasOwnProperty('AbortController');

const dummyAbortController = {
  abort: () => {
    // empty
  },
  signal: undefined,
};

const useAbortController = () => {
  const abortControllerRef = useRef<AbortController>();
  const getAbortController = useCallback(() => {
    if (isAbortControllerSupported) return dummyAbortController;
    if (!abortControllerRef.current)
      abortControllerRef.current = new AbortController();
    return abortControllerRef.current;
  }, []);

  useEffect(() => {
    return () => getAbortController().abort();
  }, [getAbortController]);

  const getSignal = useCallback(
    () => getAbortController().signal,
    [getAbortController]
  );

  return getSignal;
};

export default useAbortController;