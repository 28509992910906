import React from 'react';
import Looks1Icon from '@material-ui/icons/LooksOne';
import Looks2Icon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import Looks4Icon from '@material-ui/icons/Looks4';
import Looks5Icon from '@material-ui/icons/Looks5';
import Looks6Icon from '@material-ui/icons/Looks6';
import { ENlvl1Tasks, FRlvl1Tasks } from './levelTasks/Level1';
import { ENlvl2Tasks, FRlvl2Tasks } from './levelTasks/Level2';
import { ENlvl3Tasks, FRlvl3Tasks } from './levelTasks/Level3';

const iconStyle = {
  fontSize: '3rem',
};

const getIconByTaskNum = (num: number) => {
  switch (num) {
    case 1:
      return <Looks1Icon style={iconStyle} />;
    case 2:
      return <Looks2Icon style={iconStyle} />;
    case 3:
      return <Looks3Icon style={iconStyle} />;
    case 4:
      return <Looks4Icon style={iconStyle} />;
    case 5:
      return <Looks5Icon style={iconStyle} />;
    case 6:
      return <Looks6Icon style={iconStyle} />;
    default:
      return null;
  }
};

export interface LevelTask {
  taskNum: number;
  task: string | JSX.Element;
  taskImage?: string;
  taskImageAlt?: string;
  afterTaskComponent?: JSX.Element;
  textBoxLabel?: string;
}

const ENLevelTasks = [ENlvl1Tasks, ENlvl2Tasks, ENlvl3Tasks];

const FRLevelTasks = [FRlvl1Tasks, FRlvl2Tasks, FRlvl3Tasks];

const getTasksByLanguage = (lang: string) => {
  switch (lang) {
    case 'fr':
      return FRLevelTasks;
    case 'en':
    default:
      return ENLevelTasks;
  }
};

const formatFeedbackText = (
  levelId: number,
  tasks: LevelTask[],
  formData: { [taskId: number]: string }
) => {
  if (!formData) return null;

  let str = ''
  for (const [taskId, answer] of Object.entries(formData)) {
    if (!answer)
      continue;
    // eslint-disable-next-line eqeqeq
    const task = tasks?.find(
      (lvl) => lvl.taskNum === parseInt(taskId, 10)
    )?.textBoxLabel;
    if (!task) return null;

    str += `Task ${taskId}: ${task}\n\n`;
    str += `A: ${answer}\n\n`;
  }
  if (str !== '')
    str = `From Beta-tester Level ${levelId}:\n\n` + str;

  return str;
};

export { getIconByTaskNum, getTasksByLanguage, formatFeedbackText };
