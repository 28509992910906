import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { paths, defaultPreviewLinkImage, defaultPreviewLinkDescription } from 'stocateConstants';
import { sendPageView } from 'utils/GoogleAnalytics';

function Terms() {
  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - Terms of Use');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/terms');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  useEffect(() => {
    updateMetaTags();
    sendPageView(paths.TERMS);
  }, []);
  return (
    <>
      <div style={{ margin: 50 }}>
        <h1>Terms and Conditions</h1>
        <h4>1. Introduction</h4>
        <p>
          Stocate is an online marketplace that depends on content uploads
          (including images, text, video etc.) from users to promote local
          sellers and small businesses on its various platforms. We (Stocate)
          operate the Stocate website at www.stocate.com (the “Site”) and all
          related websites, software, mobile apps, and other services that we
          provide (together, the “Service”) with the goal of celebrating and
          enabling contributions from our community. Your use of the Service,
          and our provision of the Service to you, constitutes an agreement by
          you and Stocate to be bound by the terms and conditions in these Terms
          of Service.
          <br />
          <br />
          Please read these terms of service carefully. By registering for or
          otherwise using the service, you are telling us that you have read,
          understood, and agree to be bound by these terms of service, including
          our privacy policy (together, these “terms”). If you don’t agree with
          anything we propose in these Terms, please don’t (and you don’t have
          our permission to) use any part of the Service.
        </p>

        <h4>2. Eligibility</h4>
        <p>
          The Service is designed for use by people who are at least 13 years
          old. If you are not yet 13, then you can’t use the Service. If you are
          13 or older, you promise to us that you have never been suspended from
          the Service, and promise that your use of the Service won’t break any
          laws or regulations. If you are using the Service on behalf of a
          company, organization, or other kind of entity, you represent to us
          that you have authority to bind the organization to these Terms on its
          behalf.
        </p>

        <h4>3. User Content</h4>
        <p>
          You own all of your user content, including any photos that you upload
          to the site.
          <br />
          <b>At a High Level.</b>The Service lets you upload and post Photos, as
          well as written text, images, web links, location information, and
          other content (“User Content”). Any User Content that you make
          available on the Service will belong to you. We will not claim any
          ownership in your User Content.
          <br />
          <b>Limited License to Us.</b>You grant us a worldwide, non-exclusive,
          royalty-free license (with the right to sublicense) to host, store,
          transfer, display, adapt, perform, reproduce, modify, translate, and
          distribute your User Content (in whole or in part) in any media
          formats and through any media channels (now known or hereafter
          developed). You understand that we will not pay you for any use of
          your Photos, text etc. and that your Photos, text etc. will be made
          available to the public throughout our Service without providing you
          attribution or compensation.
          <br />
          <b>Representations and Warranties.</b>You are solely responsible for
          your User Content and any consequences that occur because you’ve
          uploaded or posted the User Content on the Service. Each time you
          upload or post publish User Content, you represent and warrant to us
          that:
          <p style={{ marginLeft: 40 }}>
            You are the creator and owner of the User Content or have all
            necessary rights from other people or companies to use, and permit
            other users to use, your User Content on the Service as envisioned
            in this agreement; and Your User Content (including as used by you,
            us, or other users of the Service) does not and will not infringe or
            misappropriate any third party right, including copyright and other
            intellectual property rights, privacy rights, rights of publicity,
            or moral rights, or slander, defame, or libel anyone. In other
            words, your User Content must be your original work and you must
            have the permission of any third parties that have rights in the
            User Content before you upload or post the User Content to the
            Service.
          </p>
          Disclaimer. There is no reasonable way for us to monitor all of the
          User Content that gets uploaded to or posted on the Service, and we
          are under no obligation to you or the other users to monitor, edit, or
          control the User Content that you and other users upload or post to
          the Service. This means that we are not responsible for any User
          Content on the Service and you agree not to make any claims against us
          on account of User Content. That said, we may at any time remove,
          edit, screen, or block any User Content from the Service (without
          notifying you first) for any reason, including if we think the User
          Content violates these Terms or is otherwise objectionable. When you
          use the Service, you will be exposed to the User Content of other
          users, some of which may be offensive, inaccurate, or indecent. We may
          investigate claims alleging that User Content violates these Terms
          and, in those cases, we alone will decide what actions to take (if
          any) regarding the User Content in question.
        </p>

        <h4>4. Suspected Infringements</h4>
        <p>
          If you think that materials uploaded to or posted on the Service
          infringe any of your copyrights, you may contact us at:
          info@stocate.com
          <br />
          <br />
          Any notice that alleges materials hosted by or distributed through the
          Service infringe intellectual property rights must include:
          <ul>
            <li>
              an electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright or other right that is
              alleged to be infringed;
            </li>
            <li>
              a description of the copyrighted work or other intellectual
              property that you claim has been infringed;
            </li>
            <li>
              a description of the material that you claim is infringing and
              where it is located on the Service; your address, telephone
              number, and email address;
            </li>
            <li>
              a statement from you that you have a good faith belief that the
              use of the materials on the Service of which you are complaining
              is not authorized by the copyright owner, its agent, or the law;
              and
            </li>
            <li>
              a statement by you that the information in your notice is accurate
              and that, under penalty of perjury, you are the copyright or
              intellectual property owner or authorized to act on the copyright
              or intellectual property owner's behalf.
            </li>
          </ul>
        </p>

        <h4>5. Prohibited Conduct</h4>
        <p>
          BY USING THE SERVICE YOU AGREE NOT TO:
          <ul>
            <li>
              Use the Service for any illegal purpose or in violation of any
              laws or regulations;
            </li>
            <li>
              Violate or encourage others to violate third party rights,
              including the infringement or misappropriation of intellectual
              property rights;
            </li>
            <li>
              Upload, post, or publish any User Content that is unlawful,
              defamatory, libelous, objectionable, profane, indecent,
              pornographic, harassing, threatening, hateful, or otherwise
              inappropriate;
            </li>
            <li>
              Interfere with the security features of the Service (e.g., don’t
              disable or circumvent features that limit your use or copying of
              any User Content or reverse engineer the Service to discover the
              Source Code of the Service);
            </li>
            <li>
              Interfere with our operation of the Service or another user’s use
              of the Service (this means don’t upload or spread any viruses,
              adware, or spyware, don’t make unsolicited offers or promotions,
              don’t collect other people’s personal information, and don’t
              interfere with the networks or equipment that we use to provide
              the Service);
            </li>
            <li>
              Perform fraudulent activities, such as impersonating another
              person or lying about your date of birth;
            </li>
            <li>
              Sell copies of Photos without first significantly or meaningfully
              updating, modifying, or otherwise incorporating new creative
              elements into the Photos beyond simple retouches, resizing, or
              other minimal changes so long as they are not done by automated
              means (i.e., selling unaltered, slightly altered, or altered by
              automatic script copies of the Photos), including selling them as
              prints or printed on physical goods;
            </li>
            <li>
              Except and solely to the extent such a restriction is
              impermissible under applicable law, you may not:
            </li>
            <ol type='a'>
              <li>
                reproduce, distribute, publicly display, or publicly perform the
                Service;
              </li>
              <li>make modifications to the Service; or</li>
              <li>
                interfere with or circumvent any feature of the Service,
                including any security or access control mechanism;
              </li>
            </ol>
            <li>
              Except for use of the Unsplash API in accordance with our API
              Terms, access the Service using any bots, spiders, scripts,
              crawlers, scrapers, or other automated tools or applications
              (other than your web browser or any mobile app that we may
              publish);
            </li>
            <li>
              Copy the look and feel of the Site or access, download, copy,
              modify, distribute, perform, or use any Photos to create a similar
              or competitive service or to contribute the Photos to an existing
              similar or competitive service;
            </li>
            <li>
              Transfer your rights to use the Service or to view, access, or use
              any Materials; or
            </li>
            <li>
              Attempt to do, or assist anyone else to do, any of these things.
            </li>
          </ul>
        </p>

        <h4>6. Modifying these Terms</h4>
        <p>
          We may, at times, make changes to these Terms. If and when we do, we
          will make reasonable efforts to notify you of the changes. We may
          provide these notices through a pop-up banner, by sending an email to
          an email address associated with your account on the Service, or in
          some other way, and we will let you know when the new version of these
          Terms will go into effect. If you don’t accept the new Terms that we
          are proposing, we may cancel your account immediately and terminate
          your access to the Service. If we have any disputes regarding the
          Service, they will be resolved in accordance with the version of these
          Terms that was in effect when the dispute arose.
        </p>

        <h4>7. Ownership of the Service</h4>
        <p>
          Stocate owns and operates the Service. All of the software, visual
          interfaces, graphics, designs, information, compilation of Photos, and
          all other elements of the Service (the “Materials”) that we provide
          are protected by intellectual property and other laws. We or our
          licensors own all of the Materials contained in the Service and you
          cannot use the Materials except as you are specifically permitted
          under these Terms.
        </p>

        <h4>8. Indemnity</h4>
        <p>
          You are responsible for your use of the Service. You agree to defend
          and indemnify us, including our directors, officers, employees,
          consultants, affiliates, and agents, from and against any and all
          claims, liabilities, damages, losses, and expenses (including
          reasonable attorneys’ fees and costs) arising out of or in any way
          connected with
          <ol type='i'>
            <li>your access to or use of the Service;</li>
            <li>your violation of these Terms or any law or regulation;</li>
            <li>
              your violation of anyone’s rights, including intellectual property
              rights; or
            </li>
            <li>any disputes between you and a third party.</li>
          </ol>
          If you do have to defend us, we will have the right to assume
          exclusive control of the matter (without limiting your indemnification
          responsibilities) and, if that happens, you will cooperate with our
          defense of the claim.
        </p>
      </div>
    </>
  );
}

export default withTranslation()(Terms);
