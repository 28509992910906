import React from 'react';

function Analytics() {
    return (
        <div style={{ margin: 0, padding: 0, width: '100%' }}>
            <iframe src='https://lookerstudio.google.com/embed/u/0/reporting/a811bbe0-9258-45fc-9c6f-d90cd4d11d6b/page/tWk4D'
                title='Looked Dashboard'
                allowfullscreen
                sandbox='allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
                style={{ width: '100%', height: '100%', border: 0, position: 'absolute', top: 60, left: 0 }}></iframe>
        </div>
    );
}

export default Analytics;