import ReactGA from 'react-ga4';

export function sendPageView(location){
    ReactGA.send({ hitType: 'pageview', page: location, title: location });
}

export function sendStoreView(storeId, storeName){
    ReactGA.event('store_view', {id: storeId, name: storeName});
}

export function sendItemView(itemId, itemName){
    ReactGA.event('item_view', {id: itemId, name: itemName});
}

export function sendListView(listId, listName){
    ReactGA.event('list_view', {id: listId, name: listName});
}