import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { paths } from 'stocateConstants';
import { useAuth } from '../../../context/AuthContext';

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const {
    state: { isAuthenticated },
  } = useAuth();
  const location = useLocation();

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: paths.SIGNIN,
              state: { redirectBackTo: location.pathname, query: window.location.search },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
