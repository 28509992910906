import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';

ForumImageModal.propTypes = {
  src: PropTypes.any,
};

ForumImageModal.defaultProps = {
  src: null,
};

function ForumImageModal({ src }) {
  const customStyles = {
    overlay: {
      zIndex: '7',
      backgroundColor: 'rgba(220, 220, 220, 0.8)'
    },
    content: {
      display: 'flex',
      top: '10px',
      height: 'auto',
      minHeight: '580px',
      padding: '1%',
      position: 'relative',
      width: '60%',
      margin: '4% 17%',
      borderRadius: '4%',
      justifyContent: 'center' 
    }
  }

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Button onClick={openModal}>
        <img src={src} alt='' style={{ width: 100 }} />
      </Button>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <img src={src} alt='' style={{ maxHeight: '550px'}} />
      </Modal>
    </>
  );
}

export default ForumImageModal;
