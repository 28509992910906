import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { queryClient, StocateQuery } from '../../../utils/queryClient';
import {
  defaultPreviewLinkImage,
  defaultPreviewLinkDescription,
  paths,
  branchesNumber,
  employeesNumber,
  mealCare,
  bipocOwned,
  womenOwned,
  queerOwned,
  handicapFriendly,
  communityEngage,
  waste
} from '../../../stocateConstants';
import { initialStore } from '../addPages/AddUtils';
import useAbortController from '../../../hooks/useAbortController';
import { useAlert } from '../../../context/AlertContext';
import ImagePicker from '../addPages/ImagePicker';
import TagPicker from '../addPages/TagPicker';
import DescriptionField from '../addPages/DescriptionField';
import NameInput from '../addPages/NameInput';
import AddressInput from './AddressInput';
import HoursPickers from './HoursPickers';
import PaymentPicker from './PaymentPicker';
import AddStoreLinks from './AddStoreLinks';
import AddStoreWebsiteInput from './AddStoreWebsiteInput';
import SustainabilityPicker from './SustainabilityPicker';
import { addStorePicture, getStoreWithHandle } from '../../../api/StoresApi';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SusBadgeList from '../../../types/add/ISusBadgeList';
import IAddStore from '../../../types/add/IAddStore';
import IStore from '../../../types/dto/store';
import { sendPageView } from '../../../utils/GoogleAnalytics';
import { useAddStore, useEditStore } from '../../../hooks/useAddStore';
import ImageGallery from './ImageGallery';

const AddStore = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const getSignal = useAbortController();
  const { alertSuccess, alertInfo, alertWarning, alertError } = useAlert();
  const addStore = useAddStore();
  const editStore = useEditStore();

  const [store, setStore] = useState<IAddStore>(initialStore);
  const [storeImages, setStoreImages] = useState<any>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]); // Explicitly define type as string[]
  const [title, setTitle] = useState<any>(t('addstore.title'));

  const queryParams = new URLSearchParams(window.location.search);
  const storeParam = queryParams.get('store');

  const handleImageRemove = (index: any) => {
    const removedImage = storeImages[index];
    const newImages = storeImages.filter((_: any, i: any) => i !== index);
    setStoreImages(newImages);

    // Add the removed image to the imagesToDelete array
    setImagesToDelete((prevImagesToDelete) => [...prevImagesToDelete, removedImage]);
  };

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - Add Store');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/add/store');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  const successCallbackStore = React.useCallback((body: any) => {
    setStoreImages(body.images);
    setStore(body);
    updateMetaTags();
  }, []);

  const failCallbackStore = (statusCode: any, body: any) => {
    // do nothing
  };

  useEffect(() => {
    sendPageView(paths.ADD_STORE);
    const queryParams = new URLSearchParams(window.location.search);
    const storeParam = queryParams.get('store');
    if (storeParam != null){
      setTitle(t('addstore.edit_title'))
      getStoreWithHandle(storeParam, successCallbackStore, failCallbackStore)
    }
  }, [location.state?.requestInterrupted, alertInfo, t, successCallbackStore]);

  const badgeLists: SusBadgeList[] = [
    {
      key: 'branchesNumber',
      title: t('newStore.branches_in_total'),
      badges: branchesNumber,
    },
    {
      key: 'employeesNumber',
      title: t('newStore.full_time_employees'),
      badges: employeesNumber,
    },
    {
      key: 'communityEngage',
      title: t('newStore.communityEngage'),
      badges: communityEngage,
    },
    {
      key: 'waste',
      title: t('newStore.waste'),
      badges: waste,
    },
    {
      key: 'mealCare',
      title: t('newStore.mealCare'),
      badges: mealCare,
    },
    {
      key: 'bipocOwned',
      title: t('newStore.bipoc'),
      badges: bipocOwned,
    },
    {
      key: 'womenOwned',
      title: t('newStore.women'),
      badges: womenOwned,
    },
    {
      key: 'queerOwned',
      title: t('newStore.queer'),
      badges: queerOwned,
    },
    {
      key: 'handicapFriendly',
      title: t('newStore.handicap'),
      badges: handicapFriendly,
    }
  ];

  const onSubmit = () => {
    const nameMissing = store.name.trim() === '';
    const addressMissing = store.addLocation.address.trim() === '';
    if (nameMissing || addressMissing)
      return alertError(
        t(nameMissing ? 'addstore.name_missing' : 'addstore.address_missing')
      );
    alertError(t('addstore.address_missing'));
    alertWarning(t('message.processing'));
    const queryParams = new URLSearchParams(window.location.search);
    const storeParam = queryParams.get('store');
    if (storeParam != null){
      editStore.mutate(
        { store: { ...store, images: [], imagesLeft: storeImages, imagesToDelete: imagesToDelete } },
        {
          onSuccess: (newStore: IStore) => {
            const redirectPath = generatePath(paths.STORES, {
              storeHandle: newStore.storeHandle,
            });
            const successCallback = () => {
              alertSuccess(t('addstore.success_modal_header'));
              queryClient.invalidateQueries(StocateQuery.STORES);
              history.push(redirectPath);
            };
            if (store.images.length > 0) {
              addStorePicture(
                getSignal(),
                newStore,
                store.images,
                newStore.imagesLeft?.length
              )
                .then(successCallback)
                .catch(() => {
                  alertSuccess(t('addstore.success_modal_header'));
                  history.push(redirectPath);
                });
            }
            else{
              successCallback();
            }
          },
          onError: (error) => {
            const { code, message } = error;
            if (code === 401 && message != null) {
              if (message.includes('User does not have permissions'))
                alertError(t('addstore.permission_error'));
              else if (message.includes('Store handle already exists'))
                alertError(t('addstore.storeHandle_exists_error'));
              else alertError(t('errorCode.unexpected'));
            } else if (code === 404) {
              if (message.includes('Missing the following fields'))
                alertError(t('addstore.input_error'));
              else if (message.includes('Platform not found'))
                alertError(t('addstore.platform_error'));
              else if (message.includes('Language not found'))
                alertError(t('addstore.language_error'));
              else if (message.includes('Payment type not found'))
                alertError(t('addstore.paymentTypes_error'));
              else if (message.includes('Empty slug inserted'))
                alertError(t('addstore.storeHandle_empty_error'));
              else if (message.includes('Address not found'))
                alertError(t('addstore.address_api_error'));
              else alertError(t('errorCode.unexpected'));
            } else alertError(t('errorCode.unexpected'));
          },
        }
      );
    }
    else{
      addStore.mutate(
        { store: { ...store, images: [] } },
        {
          onSuccess: (newStore: IStore) => {
            const redirectPath = generatePath(paths.STORES, {
              storeHandle: newStore.storeHandle,
            });
            const successCallback = () => {
              alertSuccess(t('addstore.success_modal_header'));
              queryClient.invalidateQueries(StocateQuery.STORES);
              history.push(redirectPath);
            };
            if (store.images.length > 0) {
              addStorePicture(
                getSignal(),
                newStore,
                store.images,
                newStore.imagesLeft?.length
              )
                .then(successCallback)
                .catch(() => {
                  alertError(t('addstore.images_error'));
                  history.push(redirectPath);
                });
            } else successCallback();
          },
          onError: (error) => {
            const { code, message } = error;
            if (code === 401 && message != null) {
              if (message.includes('User does not have permissions'))
                alertError(t('addstore.permission_error'));
              else if (message.includes('Store handle already exists'))
                alertError(t('addstore.storeHandle_exists_error'));
              else alertError(t('errorCode.unexpected'));
            } else if (code === 404) {
              if (message.includes('Missing the following fields'))
                alertError(t('addstore.input_error'));
              else if (message.includes('Platform not found'))
                alertError(t('addstore.platform_error'));
              else if (message.includes('Language not found'))
                alertError(t('addstore.language_error'));
              else if (message.includes('Payment type not found'))
                alertError(t('addstore.paymentTypes_error'));
              else if (message.includes('Empty slug inserted'))
                alertError(t('addstore.storeHandle_empty_error'));
              else if (message.includes('Address not found'))
                alertError(t('addstore.address_api_error'));
              else alertError(t('errorCode.unexpected'));
            } else alertError(t('errorCode.unexpected'));
          },
        }
      );
    }
  };

  return (
    <Container style={{ maxWidth: '800px' }}>
      <Grid container={true} direction='column' spacing={4}>
        <Grid item={true}>
          <Typography variant='h4' style={{ textAlign: 'center' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item={true}>
          <NameInput
            setEntity={setStore}
            placeholder={t('addstore.name_placeholder')}
            entity={store}
          />
        </Grid>
        <Grid item={true}>
          <AddressInput entity={store} setStore={setStore} />
        </Grid>
        <Grid item={true}>
          <DescriptionField
            entity={store}
            setEntity={setStore}
            placeholder={t('addstore.description_placeholder')}
          />
        </Grid>
        {(storeParam != null) ?
          <Grid item={true}>
            <ImageGallery images={storeImages} onImageRemove={handleImageRemove} />
          </Grid>
        : null}
        <Grid item={true}>
          <ImagePicker setEntity={setStore} />
        </Grid>
        <Grid item={true}>
          <AddStoreWebsiteInput entity={store} setStore={setStore} />
        </Grid>
        <Grid item={true}>
          <TagPicker entity={store} setEntity={setStore} />
        </Grid>
        <Grid item={true}>
          <SustainabilityPicker entity={store} setEntity={setStore} badgeLists={badgeLists} titleText={t('addstore.sustainability_metrics')}/>
        </Grid>
        <Grid item={true}>
          <AddStoreLinks entity={store} setStore={setStore} />
        </Grid>
        <Grid item={true}>
          <PaymentPicker entity={store} setStore={setStore} />
        </Grid>
        <Grid item={true}>
          <HoursPickers entity={store} setStore={setStore} />
        </Grid>
        <Grid item={true} style={{ textAlign: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            style={{ width: 241, height: 56, fontSize: 24, fontWeight: 'bold' }}
            onClick={onSubmit}
            data-cy='addstore-submit'
          >
            {t('addstore.submit')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddStore;
