import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from './SectionTitle';
import useAddStoreStyles from '../addStore/SharedAddStoreStyles';
import ingredientsIcon from '../../../assets/images/addPages/Ingredients.png';


import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IAddItem from '../../../types/add/IAddItem';

const IngredientPicker = ({
  setEntity,
  entity,
}: {
  setEntity:
  | React.Dispatch<React.SetStateAction<IAddItem>>;
  entity: any;
}) => {
  const classes = useAddStoreStyles();
  const { t } = useTranslation();

  const onIngredientsChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    event.persist();
    setEntity((prevStore: any) => ({
      ...prevStore,
      ingredients: value,
    }));
  };

  return (
    <>
      <SectionTitle iconSrc={ingredientsIcon} title={t('additem.ingredients_label')} />
      <Autocomplete
        value={entity != null ? entity.ingredients : []}
        multiple={true}
        options={[]}
        freeSolo={true}
        className={classes.root}
        onChange={onIngredientsChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            size='small'
            data-cy='addentity-ingredients'
            placeholder={t('additem.ingredients_placeholder')}
          />
        )}
      />
    </>
  );
};

export default React.memo(IngredientPicker);
