import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import StoreField from '../addPages/StoreField';
import SectionField from '../addPages/SectionField';
import { initialItem, initialStore } from '../addPages/AddUtils';
import { getStoreSections } from 'api/StoresApi';
import { listEndpoints, onlineAPI } from 'stocateConstants';
import { checkLocalStorageForToken } from 'api/CommonApi';
import { FetchError } from 'types/common';
import { copyItem } from 'api/ItemsApi';
import { useAlert } from 'context/AlertContext';

function CopyItem(props) {
    const { t } = useTranslation();
    const [store,] = useState(initialStore);
    const [item, setItem] = useState(initialItem);
    const [sections, setSections] = useState([]);
    const [newSection, setNewSection] = useState('');
    const [storeSectionsListId, setStoreSectionsListId] = useState(-1);
    const { alertSuccess, alertWarning } = useAlert();

    const failCallback = (statusCode, body) => {
        // do nothing
    }

    const successCallback = (body) => {
        setStoreSectionsListId(body.sectionlistId)
        const array = body.sections.map((x) => {
            x.label = x.name;
            x.value = x.id
            return x;
        });
        setSections(array);
    }

    function getSections(id) {
        setItem({ ...item, storeId: id })
        getStoreSections(id, successCallback, failCallback);
    }

    function setSection(id) {
        setItem({ ...item, selectedSection: id })
    }

    async function onSubmit() {
        alertWarning(t('message.processing'));
        if (newSection !== '') {
            const sendSection = {
                'name': newSection,
                'sectionslistId': storeSectionsListId,
                'type': 'store'
            }
            const sectionResponse = await fetch(onlineAPI + listEndpoints + 'sections', {
                method: 'POST',
                body: JSON.stringify(sendSection),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: checkLocalStorageForToken().exists
                        ? `Bearer ${checkLocalStorageForToken().token}`
                        : '',
                },
            });
            const data = await sectionResponse.json();
            if (sectionResponse.ok) {
                item.selectedSection = data.id
            } else throw new FetchError(sectionResponse.status, data);
        }
        await copyItem(item.storeId, props.itemId, item.selectedSection, () => {
            alertSuccess(t('additem.success_modal_header'));
        }, () => {
            // do nothing
        })
    }

    return (
        <>
            <Container>
                <Grid container={true} direction='column' spacing={4}>
                    <Grid item={true}>
                        <StoreField entity={store} store={store} getSections={getSections} />
                    </Grid>
                    <Grid item={true}>
                        <SectionField sections={sections} setSection={setSection} setNewSection={setNewSection} />
                    </Grid>
                    <Grid item={true} style={{ textAlign: 'center' }}>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ width: 241, height: 56, fontSize: 24, fontWeight: 'bold' }}
                            onClick={async () => {
                                await onSubmit()
                            }}
                            data-cy='additem-submit'
                        >
                            {t('addstore.submit')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default withTranslation()(CopyItem);