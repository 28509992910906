/* eslint-disable eqeqeq */
import { onlineAPI, listEndpoints } from '../stocateConstants';
import IList from '../types/dto/list';
import { checkLocalStorageForToken } from './CommonApi';

export function addListPicture(
  signal: AbortSignal | undefined,
  list: IList,
  images: any[],
  count: number
) {
  let queryVar = '';
  if (count != null) queryVar += '?count=' + count;
  return fetch(onlineAPI + listEndpoints + 'images/add' + queryVar, {
    signal,
    method: 'POST',
    headers: {
      Authorization: checkLocalStorageForToken().exists
        ? `Bearer ${checkLocalStorageForToken().token}`
        : '',
    },
    body: createFormData(list, images),
  });
}

function createFormData(list: IList, images: any[]) {
  const formData = new FormData();
  formData.append('name', list.name.replace(/\s+/g, ''));
  formData.append('id', `${list.id}`);
  images.forEach((image) => formData.append('ImageList', image));
  return formData;
}

// export async function addSection(section, successCallback, failCallback) {
// 	await fetch(onlineAPI + listEndpoints + "sections", {
//         method: 'POST',
//         body: JSON.stringify(section),
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         }
//     })
// 	.then(res => {
//         if(res.status == 500){
//             failCallback(res.status)
//         }else{
//             res.json()
//             .then(jsonRes => {
//                 if(res.status == 200){
//                     successCallback(jsonRes)
//                 }else{
//                     failCallback(res.status, jsonRes)
//                 }
//             })
//         }
// 	})
// }

// export async function editSection(section, sectionId, successCallback, failCallback) {
// 	await fetch(onlineAPI + listEndpoints + "sections/" + sectionId, {
//         method: 'PUT',
//         body: JSON.stringify(section),
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         }
//     })
// 	.then(res => {
//         if(res.status == 500){
//             failCallback(res.status)
//         }else{
//             res.json()
//             .then(jsonRes => {
//                 if(res.status == 200){
//                     successCallback(jsonRes)
//                 }else{
//                     failCallback(res.status, jsonRes)
//                 }
//             })
//         }
// 	})
// }

// export async function addToSection(addToSection, type, successCallback, failCallback) {
// 	await fetch(onlineAPI + listEndpoints + "sections/add/" + type, {
//         method: 'POST',
//         body: JSON.stringify(addToSection),
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         }
//     })
// 	.then(res => {
//         if(res.status == 500){
//             failCallback(res.status)
//         }else{
//             res.json()
//             .then(jsonRes => {
//                 if(res.status == 200){
//                     successCallback(jsonRes)
//                 }else{
//                     failCallback(res.status, jsonRes)
//                 }
//             })
//         }
// 	})
// }

// export async function editInSection(addToSection, type, successCallback, failCallback) {
// 	await fetch(onlineAPI + listEndpoints + "sections/edit/" + type, {
//         method: 'PUT',
//         body: JSON.stringify(addToSection),
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         }
//     })
// 	.then(res => {
//         if(res.status == 500){
//             failCallback(res.status)
//         }else{
//             res.json()
//             .then(jsonRes => {
//                 if(res.status == 200){
//                     successCallback(jsonRes)
//                 }else{
//                     failCallback(res.status, jsonRes)
//                 }
//             })
//         }
// 	})
// }

// //Public List APIs
// export async function toggleListPrivacy(listId, successCallback, failCallback) {
// 	await fetch(onlineAPI + listEndpoints + listId +"/privacy", {
//         method: 'PUT',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         }
//     })
// 	.then(res => {
//         if(res.status == 500){
//             failCallback(res.status)
//         }else{
//             res.json()
//             .then(jsonRes => {
//                 if(res.status == 200){
//                     successCallback()
//                 }else{
//                     failCallback(res.status, jsonRes)
//                 }
//             })
//         }
// 	})
// }

export async function copyList(
  listId: number,
  libraryId: number,
  successCallback: (body: IList) => void,
  failCallback: (status?: number, body?: any) => void,
  signal?: AbortSignal
) {
  try {
    const response = await fetch(
      onlineAPI + listEndpoints + listId + '/library/' + libraryId,
      {
        signal,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
        },
      }
    );
    const data = await response.json();

    if (response.ok) successCallback(data);
    else failCallback(response.status, data);
  } catch (error) {
    if (!signal?.aborted) failCallback(500, error);
  }
}

export async function followList(
  listId: number,
  libraryId: number,
  successCallback: (body: IList) => void,
  failCallback: (status?: number, body?: any) => void,
  signal?: AbortSignal
) {
  try {
    const response = await fetch(
      onlineAPI + listEndpoints + listId + '/library/' + libraryId,
      {
        signal,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
        },
      }
    );
    const data = await response.json();

    if (response.ok) successCallback(data);
    else failCallback(response.status, data);
  } catch (error) {
    if (!signal?.aborted) failCallback(500, error);
  }
}

// export async function editListOwners(owners, successCallback, failCallback) {
// 	await fetch(onlineAPI + listEndpoints + "owners/edit", {
//         method: 'PUT',
//         body: JSON.stringify(owners),
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         }
//     })
// 	.then(res => {
//         if(res.status == 500){
//             failCallback(res.status)
//         }else{
//             res.json()
//             .then(jsonRes => {
//                 if(res.status == 200){
//                     successCallback(jsonRes)
//                 }else{
//                     failCallback(res.status, jsonRes)
//                 }
//             })
//         }
// 	})
// }
