import React from 'react';
import { useTranslation } from 'react-i18next';

import paymentIcon from '../../../assets/images/addPages/payment.webp';
import SectionTitle from '../addPages/SectionTitle';
import useAddStoreStyles from './SharedAddStoreStyles';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import IAddStore from '../../../types/add/IAddStore';

const PaymentPicker = ({
  setStore,
  entity,
}: {
  setStore: React.Dispatch<React.SetStateAction<IAddStore>>;
  entity: any;
}) => {
  const classes = useAddStoreStyles();
  const { t } = useTranslation();

  const paymentTypes = [
    { key: 'credit', title: t('storepage.credit') },
    { key: 'debit', title: t('storepage.debit') },
    { key: 'cash', title: t('storepage.cash') },
    { key: 'coupon', title: t('storepage.coupon') },
    { key: 'paypal', title: t('storepage.paypal') },
  ];

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // To prevent issues with value being null when async state finally changes
    if (event.target.checked) {
      setStore((prevStore) => ({
        ...prevStore,
        paymentTypes: [...prevStore.paymentTypes, event.target.value],
      }));
    } else {
      setStore((prevStore) => ({
        ...prevStore,
        paymentTypes: prevStore.paymentTypes.filter(
          (type) => type !== event.target.value
        ),
      }));
    }
  };

  return (
    <>
      <SectionTitle iconSrc={paymentIcon} title={t('storepage.payment')} />
      <FormGroup onChange={onCheckboxChange} className={classes.root} row={true}>
        {paymentTypes.map(({ key, title }) => (
          <FormControlLabel
            key={key}
            value={key}
            data-cy={key}
            control={<Checkbox checked={entity.paymentTypes.includes(key)} style={{ color: 'black' }} />}
            label={title}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default React.memo(PaymentPicker);
