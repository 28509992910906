import React from 'react';
import { useTranslation } from 'react-i18next';

import chatBoxIcon from '../../../assets/images/addPages/chatbox.webp';
import SectionTitle from './SectionTitle';
import useAddPageStyles from '../addStore/SharedAddStoreStyles';

import TextField from '@material-ui/core/TextField';

import IAddStore from '../../../types/add/IAddStore';
import IAddList from '../../../types/add/IAddList';
import Box from '@material-ui/core/Box';
import IAddItem from '../../../types/add/IAddItem';

const DescriptionField = ({
  setEntity,
  placeholder,
  entity,
}: {
  setEntity:
    | React.Dispatch<React.SetStateAction<IAddItem>>
    | React.Dispatch<React.SetStateAction<IAddStore>>
    | React.Dispatch<React.SetStateAction<IAddList>>;
  placeholder: string;
  entity: any;
}) => {
  const classes = useAddPageStyles();
  const { t } = useTranslation();

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setEntity((prevEntity: any) => ({
      ...prevEntity,
      description: event.target.value,
    }));
  };

  return (
    <>
      <SectionTitle
        iconSrc={chatBoxIcon}
        title={t('addstore.description_label')}
      />
      <Box className={classes.root}>
        <TextField
          value={entity != null ? entity.description : null}
          fullWidth={true}
          multiline={true}
          rows={5}
          variant='outlined'
          size='small'
          data-cy='addentity-desc'
          placeholder={placeholder}
          onChange={onDescriptionChange}
        />
      </Box>
    </>
  );
};

export default React.memo(DescriptionField);
