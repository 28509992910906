import { QueryClient } from 'react-query';

// Init react-query for data fetching/caching
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // we can change this later, but for now starting simple
      staleTime: Infinity,
      retry: false,
    },
  },
});

export enum StocateQuery {
  MYLISTS = 'myLists',
  STORES = 'stores',
  ITEMS = 'items',
  LISTS = 'lists',
  TESTER_LEVELS = 'tester-levels',
}