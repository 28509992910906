import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Modal from 'react-modal';
import {getItemSustainabilityBagdgesById} from 'stocateConstants';
import SustainabilityBadge from './SustainabilityBadge';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const ItemSustainabilityBadgesGroup = ({ packagingNumber, fsc, eolRecollection, organic, ecoLogo, asc, palmOil, byom, greenSeal, oceanWise, rainforest, msc, fairTrade, kosher, peanut, dairy, sugar, vegan, halal, gluten, vegetarian, constituent, landTransport, origin, modal }) => {
    const styles = useStyles();
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const customStyles = {
        overlay: {
          zIndex: '7',
          backgroundColor: 'rgba(220, 220, 220, 0.8)'
        },
        content: {
          top: '10px',
          height: 'auto',
          padding: '1%',
          position: 'relative',
          maxWidth: '500px',
          margin: '15% 27%',
          borderRadius: '6%',
          justifyContent: 'center',
          fontFamily: 'Roboto,sans-serif'
        }
      }

    function openModal() {
      setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
    }

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const badges = getItemSustainabilityBagdgesById(packagingNumber, fsc, eolRecollection, organic, ecoLogo, asc, palmOil, byom, greenSeal, oceanWise, rainforest, msc, fairTrade, kosher, peanut, dairy, sugar, vegan, halal, gluten, vegetarian, constituent, landTransport, origin);
    const badgeDivs = <>{badges.map((badgeData, i) => <SustainabilityBadge key={i} badge={badgeData} />)}</>;

    if (!modal)
        return badgeDivs;

    return <>
        <div onClick={() => { if (isMobile) openModal(); }}>
            {badgeDivs}
        </div>
        {modal &&
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <div className={styles.modalHeader} data-cy='susBadgeModalTitle'>
                    {i18next.t('sustainability_badges_modal.title')}
                </div>
                <Grid container={true} direction='column' className={styles.columnContainer}>
                    {badges.map((badgeData, index) => {
                        return <Grid item={true} container direction='row' key={index} className={styles.rowContainer}>
                            <SustainabilityBadge badge={badgeData} />
                            <div>
                                <div className={styles.badgeLabel}>{badgeData.desc}{':'}</div>
                                <div>{badgeData.label}</div>
                            </div>
                        </Grid>;
                    })}
                </Grid>
            </Modal>
        }
    </>
}

const useStyles = makeStyles((theme) => ({
    badgeLabel: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: 14
    },
    rowContainer: {
        marginBottom: 10,
    },
    columnContainer: {
        padding: '1em'
    },
    modalHeader: {
        textAlign: 'center',
        fontSize: 24,
        fontWeight: 'bold',
    },
}));

ItemSustainabilityBadgesGroup.propTypes = {
    badgeIds: PropTypes.shape({
        packagingNumber: PropTypes.number,
        organic: PropTypes.number,
        fsc: PropTypes.number,
        eolRecollection: PropTypes.number,
        ecoLogo: PropTypes.number,
        asc: PropTypes.number,
        palmOil: PropTypes.number,
        byom: PropTypes.number,
        greenSeal: PropTypes.number,
        oceanWise: PropTypes.number,
        rainforest: PropTypes.number,
        msc: PropTypes.number,
        fairTrade: PropTypes.number,
        // kosher: PropTypes.number,
        // peanut: PropTypes.number,
        // dairy: PropTypes.number,
        // sugar: PropTypes.number,
        // vegan: PropTypes.number,
        // halal: PropTypes.number,
        // vegetarian: PropTypes.number,
        // landtransport: PropTypes.number,
        // origin: PropTypes.number
    }),
    modal: PropTypes.bool
}

ItemSustainabilityBadgesGroup.defaultProps = {
    badgeIds: null,
    modal: false
}

export default ItemSustainabilityBadgesGroup;
