import React, { useEffect, useState } from 'react';
import { addResizingListener } from '../../../utils/Responsiveness';

function Menu() {
  const [isMobile, setIsMobile] = useState(false);
  const url = window.location.href;
  const urlSplit = url.split('/');
  const store = urlSplit[3];
  const link = 'https://storage.googleapis.com/stocate-images/menu/' + store + '.pdf';

  useEffect(() => {
    addResizingListener(setIsMobile);
  }, []);

  if (isMobile)
      window.location.href = link;

  return (
    <iframe title={store} src={link} width='100%' height='750'>
      <p>Your web browser doesn't have a PDF plugin. Instead you can <a href={link}>click here to download the PDF file.</a></p>
    </iframe>
  );
}

export default Menu;