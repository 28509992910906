export function checkLocalStorageForToken() {
    const token = localStorage.getItem('token');
    let user;
    let expiry;
    let receivedAt;
    try {
        user = JSON.parse(localStorage.getItem('user'));
        expiry = parseInt(localStorage.getItem('tokenExpiry'), 10);
        receivedAt = parseInt(localStorage.getItem('tokenReceived'), 10);
    } catch (error) {
        // console.error('Error when parsing user object from Local Storage');
    }
    return {
        exists: token != null, // != also checks for undefined
        token,
        expiry,
        receivedAt,
        user
    }
}