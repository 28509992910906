import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import IAddItem from '../../../types/add/IAddItem';

const IsAvailable = ({
  setEntity,
  entity,
}: {
    setEntity: React.Dispatch<React.SetStateAction<IAddItem>>;
    entity: any;
}) => {
  const { t } = useTranslation();

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEntity((prevItem) => ({
      ...prevItem,
      available: event.target.checked,
    }));
  };

  return (
    <Grid container={true} justify='space-between' wrap='nowrap'>
      <Grid item={true} container={true} alignItems='center'>
        <Grid item={true}>
          <Typography
            variant='h6'
            color='initial'
            style={{ fontWeight: 'bolder' }}
          >
            {t('additem.available_label')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item={true}
        container={true}
        alignItems='center'
        justify='center'
        style={{ marginBottom: '-0.5em' }}
      >
        <Grid item={true}>
          <Typography variant='body1' color='initial'>
            {t('No')}
          </Typography>
        </Grid>
        <Grid item={true}>
          <Switch checked={entity != null ? entity.available : false} onChange={handleToggle} />
        </Grid>
        <Grid item={true}>
          <Typography variant='body1' color='initial'>
            {t('Yes')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(IsAvailable);