import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialStartTime, initialEndTime } from '../addPages/AddUtils';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import IAddStore from '../../../types/add/IAddStore';
import IWeeklyHrs from '../../../types/dto/weeklyhrs';

type DayHours = IWeeklyHrs & { open: boolean };

const initialDayHours = {
  open: true,
  day: '',
  startTime: initialStartTime,
  endTime: initialEndTime,
};

const DayOfWeekHourPicker = ({
  day,
  setStore,
  entity,
}: {
  day: string;
  setStore: React.Dispatch<React.SetStateAction<IAddStore>>;
  entity: any;
}) => {
  const { t } = useTranslation();
  const [dayHours, setDayHours] = useState(initialDayHours);

  const handleChange = (partial: Partial<DayHours>) => {
    const newDayHours = {
      ...dayHours,
      ...partial,
    };
    const { open, ...weeklyHrs } = newDayHours;

    setStore((prevStore: IAddStore) => {
      if (open) {
        // If day already existed, replace it with new value
        if (prevStore.openHours.find((d) => d.day === dayHours.day)) {
          return {
            ...prevStore,
            openHours: prevStore.openHours.map((d) =>
              d.day === dayHours.day ? weeklyHrs : d
            ),
          };
        } else {
          // If day was closed, add it again
          return {
            ...prevStore,
            openHours: [...prevStore.openHours, weeklyHrs],
          };
        }
      }
      // If day is now closed, remove it from openHours
      else
        return {
          ...prevStore,
          openHours: prevStore.openHours.filter((d) => d.day !== dayHours.day),
        };
    });
    setDayHours(newDayHours);
  };

  useEffect(() => {
    setDayHours(entity === undefined ? {
      ...initialDayHours,
      day,
      open: false,
    } : {
      ...entity,
      open: true
    });
  }, [day, entity]);

  const handleOpenChanged = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleChange({ open: event.target.checked });
  const handleStartTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleChange({ startTime: event.target.value });
  const handleEndTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleChange({ endTime: event.target.value });

  return (
    <Grid container={true} wrap='nowrap' alignItems='center'>
      <Grid
        item={true}
        container={true}
        xs='auto'
        style={{
          position: 'relative',
        }}
      >
        {!dayHours.open && (
          <Typography
            style={{
              position: 'absolute',
              textAlign: 'center',
              margin: 'auto',
              height: 'fit-content',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            {t('addstore.hours_closed')}
          </Typography>
        )}
        <Grid
          item={true}
          container={true}
          xs='auto'
          wrap='nowrap'
          spacing={1}
          alignItems='center'
          style={{ visibility: dayHours.open ? 'visible' : 'hidden' }}
        >
          <Grid item={true}>
            <TextField
              size='small'
              variant='outlined'
              type='time'
              defaultValue={initialStartTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              onChange={handleStartTimeChanged}
              disabled={!dayHours.open}
            />
          </Grid>
          <Grid item={true} style={{ padding: '0.5em' }}>
            {' - '}
          </Grid>
          <Grid item={true}>
            <TextField
              variant='outlined'
              type='time'
              size='small'
              defaultValue={initialEndTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              onChange={handleEndTimeChanged}
              disabled={!dayHours.open}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <Checkbox
          data-cy={`addstore-${day}`}
          checked={dayHours.open}
          onChange={handleOpenChanged}
          style={{
            color: 'green',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DayOfWeekHourPicker;
