import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import arrowDownIcon from '../../assets/images/general/arrowDown.png';
import arrowRightIcon from '../../assets/images/general/arrowRight.png';
import Collapsible from 'react-collapsible';
import { useState } from 'react';
import styles from '../main/store/Store.module.css'
import { color } from 'stocateConstants';

CollapsibleCard.propType = {
    body_content: PropTypes.string,
    title_left: PropTypes.string,
    title_right: PropTypes.string,
    borderRadius: PropTypes.number,
    width: PropTypes.string,
    collapsible_header_font_size: PropTypes.number,
    startOpen: PropTypes.bool
}

CollapsibleCard.defaultProps = {
    body_content: '',
    title_left: '',
    title_right: '',
    borderRadius: 10,
    width: '100%',
    collapsible_header_font_size: 20
}

function CollapsibleCard({
    body_content,
    title_left,
    title_right,
    borderRadius,
    width,
    collapsible_header_font_size,
    body_padding,
    startOpen
}){
    
    const [arrowIcon, setArrowIcon] = useState(startOpen ? arrowDownIcon : arrowRightIcon);
    const [collapsibleBorderHeaderRadius, setCollapsibleHeaderBorderRadius] = useState(borderRadius);
    return(
        <>
        <div style={{border: '1px solid rgba(0,0,0,.125)', borderRadius: borderRadius, backgroundColor: '#F8F8F9'}}>
            <Collapsible 
            open={startOpen}
            trigger={ 
                <div className={styles.collapsibleCardHeader + ' '+ styles.hoverEffectWithPointer} 
                    style={{borderRadius: collapsibleBorderHeaderRadius}}
                >
                    <div style={{textAlign:'left'}}>
                        <img src={arrowIcon} alt='' style={{width:20, height: collapsible_header_font_size, marginRight: '5px'}}/>
                    </div>
                    <div style={{textAlign:'left', width: '50%', flex: 1, fontSize: collapsible_header_font_size}}>
                        {title_left}
                    </div>
                    <div style={{textAlign:'right', marginRight: 25, fontSize: collapsible_header_font_size}}>
                        {title_right}
                    </div>
                </div>
            } 
            onTriggerOpening={() => {
                setArrowIcon(arrowDownIcon);
                setCollapsibleHeaderBorderRadius(borderRadius + 'px '+ borderRadius + 'px 0px 0px');
                }}
            onClose={() => {
                setArrowIcon(arrowRightIcon);
                setCollapsibleHeaderBorderRadius(borderRadius);
                }}>
                <Card style={{borderRadius: borderRadius, border: 'initial', backgroundColor: color.veryLightGray, width: width }}>
                    <Card.Body style={{borderRadius: '0px 0px ' + borderRadius + 'px ' + borderRadius + 'px', backgroundColor: color.veryLightGray, padding: body_padding }}>
                        {body_content}
                    </Card.Body>
                </Card>
            </Collapsible>
        </div>
        </>
    );
}

export default CollapsibleCard;