import { onlineAPI, itemsEndpoints, storesEndpoints, sortList } from 'stocateConstants';
import { checkLocalStorageForToken } from './CommonApi';
import { FetchError } from '../types/common';

export async function getItemWithHandle(itemHandle, successCallback, failCallback) {
	await fetch(onlineAPI + itemsEndpoints + 'i/' + itemHandle, {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
		}
	})
	.then(res => {
		if(res.status === 500){
			failCallback(res.status)
		}else{
			res.json()
			.then(jsonRes => {
				if(res.status === 200)
					successCallback(jsonRes)
				else
					failCallback(res.status, jsonRes)
			})
		}
	})
}

export function addItemPicture(signal, item, images, count) {
	let queryVar = '';
	if (count != null) queryVar += '?count=' + count;
	return fetch(onlineAPI + itemsEndpoints + 'images/add' + queryVar, {
        signal,
		method: 'POST',
		headers: {
            'Authorization': checkLocalStorageForToken().exists ? `Bearer ${checkLocalStorageForToken().token}` : ''
		},
		body: createFormData(item, images),
	});
}

export default async function getAllItemsLimit(failCallback, count, search, sort, itemFilters) {
    let url = count != null ? onlineAPI + storesEndpoints + 'items/limit?count=' + count : onlineAPI + storesEndpoints + 'items';
    if (search != null) {
        if (search.trim() !== '') {
            url = url + '&search=' + search;
        }
    }
	if (sort != null) {
		if (sort.trim() !== '') {
			url = url + '&sort=' + sort;
		}
	}
    
    try {
        const response = await fetch(url, {
            method: 'POST',
			body: JSON.stringify(itemFilters),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': checkLocalStorageForToken().exists ? `Bearer ${checkLocalStorageForToken().token}` : '',
            }
        });
        
        if (response.ok) {
            const data = await response.json();
			if(sort !== null)
				return data;
			else
				return (sortList(data));
        } else {
            throw new FetchError(response.status, 'Failed to fetch data');
        }
    } catch (error) {
        // Handle any exceptions here
        failCallback();
	}
}

export async function copyItem(storeId, itemId, sectionId, successCallback, failCallback) {
	await fetch(onlineAPI + itemsEndpoints + 'store/' + storeId + '/section/' + sectionId + '/copy/' + itemId, {
        method: 'POST',
        headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
		}
    })
	.then(res => {
		if(res.status === 500){
			failCallback(res.status)
		}else{
			res.json()
			.then(jsonRes => {
				if(res.status === 200)
					successCallback(jsonRes)
				else
					failCallback(res.status, jsonRes)
			})
		}
	})
}

export async function rsvpItem(itemId, lang, successCallback, failCallback) {
	await fetch(onlineAPI + itemsEndpoints + itemId + '/rsvp?lang=' + lang, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
			'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
	.then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
					successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}

export async function getItemIngredients(itemId, successCallback, failCallback) {
	await fetch(onlineAPI + itemsEndpoints + itemId + '/ingredients', {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
		}
	})
	.then(res => {
		if(res.status === 500){
			failCallback(res.status)
		}else{
			res.json()
			.then(jsonRes => {
				if(res.status === 200)
					successCallback(jsonRes)
				else
					failCallback(res.status, jsonRes)
			})
		}
	})
}

function createFormData(item, images){
	const formData = new FormData();
	formData.append('name', item.itemHandle);
	formData.append('id', item.id);
    images.forEach(image => formData.append('ImageList', image));
	return formData;
};