// import moment from 'moment';

export const checkLength = (str: string) => {
  return str.length >= 8;
};

export const checkHasLetter = (str: string) => {
  const regExp = /[a-zA-Z]/;
  return regExp.test(str);
};

export const checkHasNumber = (str: string) => {
  const regExp = /\d+/;
  return regExp.test(str);
};

export const checkHasSpecialChar = (str: string) => {
  return str.match(/[^a-zA-Z0-9]+/) ? true : false;
};

export const isEmail = (input: string) => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(input);
};

export const isValidUrl = (input: string) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    input
  );
};

// removes all non-alphanumericals, newlines and multiple white spaces
export const removeNonAlphaNumOrMultipleWhiteSpaces = (input: string) => {
  return input.replace(/[\W_]+/g, ' ');
};

// auto capitalize each new word
export const autoCapitalize = (input: string) => {
  return input
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

// auto cap all letters
export const autoAllCapitalize = (input: string) => {
  return input.toUpperCase();
};

// lowercase all letters
export const allLowercase = (input: string) => {
  return input.toLowerCase();
};

// remove all non-alphanumericals
export const removeNonAlphaNum = (input: string) => {
  return input.replace(/[^\w\s]/gi, '');
};

// check if later date greater or equal to the first
// export const isTimeGreaterThan = (end, start) => {
//   return moment(end, 'HH:mm').isSameOrAfter(moment(start, 'HH:mm'));
// };

// // check if later date earier to the first
// export const isTimeEarlierThan = (start, end) => {
//   return moment(start, 'HH:mm').isBefore(moment(end, 'HH:mm'));
// };
