import React, { useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { sendPageView } from 'utils/GoogleAnalytics';
import { paths, stocateEmail, defaultPreviewLinkImage, defaultPreviewLinkDescription } from 'stocateConstants';
import { Grid } from '@material-ui/core';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import Footer from '../../footer/Footer';
import styles from './Subscriptions.module.css';

function Subscriptions() {
  const { state: authState } = useAuth();
  const destinationPath = authState.isLoggedIn ? paths.SIGNIN : paths.ADD_STORE;

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - Subscriptions');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/subscriptions');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  useEffect(() => {
    updateMetaTags();
    sendPageView(paths.SUBSCRIPTIONS);
  }, []);

  return (
    <Grid className={ styles.container }>
      <Grid className={ styles.planContainer }>
        <div className={ styles.planTitle}>{i18next.t('subscriptions.seed_title')}</div>
          <div className= {styles.planPrice} dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.seed_price') }} />
          <div dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.seed_body_dark') }} />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <a className={ styles.button } id='addStore' href={destinationPath}>{i18next.t('subscriptions.addStore')}</a>
        </div>
      </Grid>
      <Grid className={ styles.planContainer }>
        <div className={ styles.planTitle}>{i18next.t('subscriptions.leaf_title')}</div>
          <div className= {styles.planPrice} dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.leaf_price') }} />
          <div className= {styles.planLight} dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.leaf_body_light') }} />
          <div dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.leaf_body_dark') }} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <a className={ styles.button } id='mail' href={stocateEmail}>{i18next.t('subscriptions.contact')}</a>
        </div>
      </Grid>
      <Grid className={ styles.planContainer }>
        <div className={ styles.planTitle}>{i18next.t('subscriptions.root_title')}</div>
          <div className= {styles.planPrice} dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.root_price') }} />
          <div className= {styles.planLight} dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.root_body_light') }} />
          <div dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.root_body_dark') }} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <a className={ styles.button } id='mail' href={stocateEmail}>{i18next.t('subscriptions.contact')}</a>
        </div>
      </Grid>
      <Grid className={ styles.planContainer }>
        <div className={ styles.planTitle}>{i18next.t('subscriptions.tree_title')}</div>
          <div className= {styles.planPrice} dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.tree_price') }} />
          <div className= {styles.planLight} dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.tree_body_light') }} />
          <div dangerouslySetInnerHTML={{ __html: i18next.t('subscriptions.tree_body_dark') }} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <a className={ styles.button } id='mail' href={stocateEmail}>{i18next.t('subscriptions.contact')}</a>
        </div>
      </Grid>
      <span className={ styles.circleLight } style={{ height: '700px', width: '700px', top: '-230px', left:'-335px'}}></span>
      <span className={ styles.circleDark } style={{ height: '600px', width: '600px', top: '415px', right:'-400px'}}></span>
      <span className={ styles.circleLight } style={{ height: '300px', width: '300px', top: '850px', right:'-220px'}}></span>
      <span className={ styles.circleDark } style={{ height: '400px', width: '400px', top: '1200px', left:'-220px'}}></span>
      <span className={ styles.circleLight } style={{ height: '800px', width: '800px', top: '1350px', left:'-500px'}}></span>
      <Footer/>
    </Grid>
  );
}

export default withTranslation()(Subscriptions);