import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const LoadingSpinner = () => {
  return (
    <Grid
      item={true}
      container={true}
      justify='center'
      alignItems='center'
      style={{ height: '86vh' }}
    >
      <CircularProgress color='primary' />
    </Grid>
  );
};

export default LoadingSpinner;