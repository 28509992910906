import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReactGA from 'react-ga4';
import { paths } from './stocateConstants';
import ScrollToTop from './components/main/routing/ScrollToTop';
import ProtectedRoute from './components/main/routing/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import { AlertProvider } from './context/AlertContext';
import { DeleteDialogProvider } from './context/DeleteDialogContext';
import { queryClient } from './utils/queryClient';
import NavigationBar from './components/navigation-bar/NavigationBar';
import Home from './components/main/home/Home';
import Team from './components/main/about/Team';
import JoinTheTeam from './components/main/join-us/JoinTheTeam';
import Browse from './components/main/browse/Browse';
import Store from './components/main/store/Store';
import Library from './components/main/library/Library';
import List from './components/main/list/List';
import FeedbackModal from './components/main/feedback/FeedbackModal';
import SignUp from './components/main/signup/SignUp';
import SignIn from './components/main/signin/SignIn';
import PasswordReset from './components/main/password/PasswordReset';
import Profile from './components/main/profile/Profile';
import Error from './components/main/error-404/ErrorPage';
import ServiceAgreement from './components/main/serviceAgreement/ServiceAgreement';
import Privacy from './components/main/privacy/Privacy';
import Terms from './components/main/terms/Terms';
import Career from './components/main/career/Career';
import TestingSwitch from './components/main/pilot-testing/TestingSwitch';
import AddStore from './components/main/addStore/AddStore';
import AddList from './components/main/addList/AddList';
import FeedbackDashboard from './components/main/feedbackDashboard/FeedbackDashboard';
import PDF from './components/main/career/PDF';
import AddItem from './components/main/addItem/AddItem';
import ForgotPassword from './components/main/password/ForgotPassword';
import Menu from './components/main/menu/Menu';
import Subscriptions from './components/main/subscriptions/Subscriptions';
import EditProfile from './components/main/editProfile/EditProfile';
import ChangePassword from './components/main/editProfile/ChangePassword';
import RedirectToAppStore from './utils/RedirectToAppStore';
import BottomPopup from './components/main/popup/BottomPopup';
import Analytics from './components/main/Analytics';
import './App.css';

const TRACKING_ID = 'G-LBLYPPRPJK'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AlertProvider>
            <DeleteDialogProvider>
              <NavigationBar data-testid='nav-bar' />
              <BottomPopup />
              <FeedbackModal />
              <Switch>
                <Route path={paths.HOME} exact={true} component={Home} />
                <Route path={paths.TEAM} exact={true} component={Team} />
                <Route path={paths.JOIN} exact={true} component={JoinTheTeam} />
                <Route path={paths.BROWSE} exact={true} component={Browse} />
                <Route path={paths.STORES} exact={true} component={Store} />
                <Route path={paths.LIST} exact={true} component={List} />
                <Route path={paths.SIGNUP} exact={true} component={SignUp} />
                <Route path={paths.SIGNIN} exact={true} component={SignIn} />
                <Route path={paths.PWDRESET} exact={true} component={PasswordReset} />
                <Route path={paths.FORGOTPWD} exact={true} component={ForgotPassword} />
                <Route path={paths.TESTING} component={TestingSwitch} />
                <Route path={paths.AGREEMENT} exact={true} component={ServiceAgreement}/>
                <Route path={paths.PRIVACY} exact={true} component={Privacy} />
                <Route path={paths.TERMS} exact={true} component={Terms} />
                <Route path={paths.SUBSCRIPTIONS} exact={true} component={Subscriptions} />
                <Route path={paths.CAREER} exact={true} component={Career} />
                <Route path={paths.JOB} exact={true} component={PDF} />
                <Route path={paths.MENU} exact={true} component={Menu} />
                <Route path={paths.REDIRECT_STORE} exact={true} component={RedirectToAppStore} />
                <Route path={paths.LEVELS_LEGACY}>
                  <Redirect to={paths.TESTING_REGISTER} />
                </Route>
                <ProtectedRoute path={paths.MYLISTS} exact={true} component={Library} />
                <ProtectedRoute path={paths.ADD_STORE} exact={true} component={AddStore} />
                <ProtectedRoute path={paths.ADD_ITEM} exact={true} component={AddItem} />
                <ProtectedRoute path={paths.ADD_LIST} exact={true} component={AddList} />
                <ProtectedRoute path={paths.PROFILE} exact={true} component={Profile} />
                <ProtectedRoute path={paths.EDIT_PROFILE} exact={true} component={EditProfile} />
                <ProtectedRoute path={paths.CHANGE_PASSWORD} exact={true} component={ChangePassword} />
                <ProtectedRoute path={paths.FEEDBACK_DASHBOARD} exact={true} component={FeedbackDashboard} />
                <ProtectedRoute path={paths.ANALYTICS} exact={true} component={Analytics} />
                <Route path={paths.ERROR} exact={true} component={Error} />
              </Switch>
            </DeleteDialogProvider>
          </AlertProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  );
}

export default App;