import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'bolder',
    whiteSpace: 'pre-wrap',
  },
}));

const NumberedTask = ({
  NumberIcon,
  task,
  alignStart,
}: {
  NumberIcon: JSX.Element | null;
  task: JSX.Element | string;
  alignStart?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid
      item={true}
      container={true}
      alignItems={alignStart ? 'flex-start' : 'center'}
    >
      <Grid item={true} xs='auto'>
        {NumberIcon}
      </Grid>
      <Grid item={true} xs={true}>
        <Typography className={classes.label} component='div'>
          {task}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NumberedTask;
