import React from 'react';
import { withTranslation } from 'react-i18next';
import styles from './../Team.module.css';
import Typography from '@material-ui/core/Container';

function MemberCard(props) {
  return (
    <div className={styles.centeringFlex}>
      {props.isMember === true ? (
        <div className={styles.teamMember}>
          <div className={styles.card}>
            <div className={styles.headshot}>
              <div className={styles.profilePic}>
                <img
                  className={styles.imageStyle}
                  src={props.teamImage}
                  alt='{props.name}'
                />
              </div>
              <Typography
                component='h4'
                style={props.advisor ? { fontStyle: 'italic' } : null}
              >
                {props.name}
              </Typography>
              <Typography variant='body1' className={styles.role}>
                {props.role}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.teamMember}>
          <div className={styles.mentorscard}>
            <div className={styles.headshot}>
              <div className={styles.profilePic}>
                <img
                  className={styles.imageStyle}
                  src={props.teamImage}
                  alt='{props.name}'
                />
              </div>

              <Typography className={styles.paddingBottom10px} component='h3'>
                {props.name}
              </Typography>
              <Typography className={styles.paddingBottom20px} variant='body1'>
                {props.role}
              </Typography>
              <Typography className={styles.paddingBottom20px} variant='body1'>
                {props.company}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withTranslation()(MemberCard);