import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { sendPageView } from '../../../utils/GoogleAnalytics';
import { paths, searchShowing, defaultPreviewLinkImage, defaultPreviewLinkDescription } from '../../../stocateConstants';
import Search from '../../reusable/Search';
import StoresMap from '../browse/storesMap/StoresMap';
import ObjectCell from '../../reusable/ObjectCell';
import ListCard from './listCard/ListCard';
import LoadingSpinner from '../../reusable/LoadingSpinner';
import ApiErrorView from '../../reusable/ApiErrorView';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useMyLists from '../../../hooks/useMyLists';
import useStores from '../../../hooks/useStores';
import useSharedBrowseStyles from '../../../components/main/browse/SharedBrowseStyles';
import Grid from '@material-ui/core/Grid';
import IList from '../../../types/dto/list';
import { useMediaQuery } from '@material-ui/core';

const Library = () => {
  const [input, setInput] = useState('');
  const [selectedList, setSelectedList] = useState<IList | null>(null);
  const classes = useSharedBrowseStyles();
  const { t } = useTranslation();
  const {
    isFetching,
    isLoading: listsLoading,
    error: myListsErr,
    data: myLists,
  } = useMyLists();
  const {
    isLoading: storesLoading,
    error: storesErr,
    data: stores,
  } = useStores();
  const error = myListsErr || storesErr;

  // Put refs of components which don't de-select the 'selectedList' on click
  const searchBarRef = useRef<HTMLElement>(null);
  const myListsColRef = useRef<HTMLElement>(null);
  const itemCardRef = useRef<HTMLElement>(null);
  useOnClickOutside(
    [searchBarRef, myListsColRef, itemCardRef],
    () => selectedList && setSelectedList(null)
  );

  const isMobile = useMediaQuery((screen: any) => screen.breakpoints.down('sm'))

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - My Lists');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/lists');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  useEffect(() => {
    updateMetaTags();
    sendPageView(paths.MYLISTS);
  }, []);

  if (listsLoading) return <LoadingSpinner />;

  if (error) return <ApiErrorView code={error?.code} text={error?.message} />;

  const onClickList = (list: IList) => () => {
    if (selectedList && list.id === selectedList.id) setSelectedList(null);
    else setSelectedList(list);
  };

  return (
    <Grid container={true}>
      <Grid
        item={true}
        container={true}
        xs={12}
        md={6}
        direction='column'
        wrap='nowrap'
        className={classes.leftListPanel}
      >
        <Grid
          item={true}
          innerRef={searchBarRef}
          style={{ marginBottom: '1em' }}
        >
          <Search
            setInput={setInput}
            placeholder={t('library.search_bar_my_list_placeholder')}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} className={classes.listScrollContainer}>
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <Grid
              container={true}
              spacing={0}
              data-cy='myLists'
              className={classes.listScrollColumn}
              {...({ ref: myListsColRef } as any)}
            >
              {myLists.reduce((visibleLists: JSX.Element[], list: IList) => {
                const onClick = onClickList(list);
                if (searchShowing(list, input))
                  visibleLists.push(
                    <Grid item={true} xs={12} key={list.id} onClick={onClick}>
                      <ObjectCell type='list' entity={list} />
                    </Grid>
                  );
                return visibleLists;
              }, [])}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item={true}
        container={true}
        sm={isMobile ? 12 : 6 }
        direction='column'
        className={classes.rightMapPanel}
        style={{paddingBottom: isMobile ? 0 : 50}}
      >
        {selectedList ? (
          <Grid item={true} {...({ ref: itemCardRef } as any)}>
            <ListCard list={selectedList} tagHandler={setInput}/>
          </Grid>
        ) : storesLoading ? (
          <LoadingSpinner />
        ) : null}
        {/* We want to keep the StoresMap mounted, so just change isVisible */}
        <StoresMap
          stores={stores}
          isVisible={!selectedList && !storesLoading}
        />
      </Grid>
    </Grid>
  );
};

export default Library;
