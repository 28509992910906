import { useMutation } from 'react-query';
import { onlineAPI, listEndpoints } from '../stocateConstants';
import { checkLocalStorageForToken } from '../api/CommonApi';
import { FetchError } from '../types/common';

interface UseDeleteListVariables {
  listId: number;
}

const useDeleteList = () => {
  return useMutation<unknown, FetchError, UseDeleteListVariables>(
    async ({ listId }: UseDeleteListVariables) => {
      const response = await fetch(`${onlineAPI}${listEndpoints}${listId}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
        },
      });
      const data = await response.json();
      if (response.ok) return data;
      else throw new FetchError(response.status, data);
    }
  );
};

export default useDeleteList;
