import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowDownward from '../../../../assets/images/general/ArrowDownwards.png';
import ArrowUpward from '../../../../assets/images/general/ArrowUpwards.png';

type sortParam = {
  field: string;
  direction: string;
};
type clickFunction = () => void;

export default function SortingOption({
  sortingParam,
  sortingType,
  clickSortingfunction,
}: {
  sortingParam: sortParam;
  sortingType: string;
  clickSortingfunction: clickFunction;
}) {

  return (
    <Button
      style={{
        textTransform: 'none',
        border: 'none',
        width: 'fit-content',
        boxShadow: 'none',
      }}
      onClick={clickSortingfunction}
    >
      <span style={{margin: '0 1px'}}>{sortingType} </span>
      {sortingParam.field === sortingType.toLowerCase() &&
        (sortingParam.direction === 'desc' ? (
          <img
            src={ArrowDownward}
            alt='ArrowDownward'
            style={{
              width: '11px',
              height: '11px',
              filter: 'brightness(0) invert(0)',
              margin: '0 3px'
            }}
          />
        ) : (
          <img
            src={ArrowUpward}
            alt='ArrowUpward'
            style={{
              width: '11px',
              height: '11px',
              filter: 'brightness(0) invert(0)',
              margin: '0 3px'
            }}
          />
        ))}
    </Button>
  );
}
