import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { paths } from '../../../stocateConstants.js';
import { sendPageView } from '../../../utils/GoogleAnalytics';
import { postLevel } from '../../../api/LevelsApi';
import useAbortController from '../../../hooks/useAbortController';
import { useAlert } from '../../../context/AlertContext';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  getIconByTaskNum,
  formatFeedbackText,
  LevelTask,
} from './TestingUtils';
import LevelInputText from './LevelInputText';
import NumberedTask from './NumberedTask';
import { queryClient, StocateQuery } from '../../../utils/queryClient';

const useStyles = makeStyles((theme) => ({
  successMessage: {
    whiteSpace: 'pre-wrap',
  },
  stocateBtn: {
    backgroundColor: '#01647B',
  },
}));

interface LevelProps {
  levelNum: number;
  nextLevelPath: string;
  tasks: LevelTask[];
}

const Level = ({ levelNum, nextLevelPath, tasks }: LevelProps) => {
  const classes = useStyles();
  const history = useHistory();
  const getSignal = useAbortController();
  const { alertError } = useAlert();
  const {
    handleSubmit,
    control,
  } = useForm({ mode: 'onChange' });
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const successCallback = useCallback(() => {
    queryClient.invalidateQueries(StocateQuery.TESTER_LEVELS);
    history.push(nextLevelPath);
  }, [history, nextLevelPath]);

  const failCallback = useCallback(
    (statusCode) => {
      setLoading(false);
      if (statusCode === 401) alertError(t('pilot_testing.error_403'));
      else alertError(t('errorCode.unexpected'));
    },
    [alertError, t]
  );

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = useCallback(
    (levelsData) => {
      setLoading(true);
      const feedbackText = formatFeedbackText(levelNum, tasks, levelsData);
      postLevel(
        levelNum,
        feedbackText,
        successCallback,
        failCallback,
        getSignal()
      );
    },
    [levelNum, tasks, successCallback, failCallback, getSignal]
  );

  useEffect(() => {
    sendPageView(paths.LEVEL_PAGE + levelNum); // for Google Analytics
  }, [levelNum]);

  return (
    <Container maxWidth='md'>
      <Grid container={true} spacing={2} direction='column'>
        <Grid item={true} container={true} spacing={3} direction='column'>
          {tasks?.map(({ taskNum, task, taskImage, taskImageAlt, textBoxLabel, afterTaskComponent }) => (
            <Fragment key={taskNum}>
              <NumberedTask
                task={task}
                NumberIcon={getIconByTaskNum(taskNum)}
                key={taskNum}
              />
              {taskImage && (
                <Grid item={true} container={true} justify='center'>
                  <img src={taskImage} alt={taskImageAlt} style={{ maxWidth: '100%', height: 'auto' }}/>
                </Grid>
              )}
              {textBoxLabel && (
                <Grid item={true}>
                  <LevelInputText
                    label={textBoxLabel}
                    name={`${taskNum}`}
                    control={control}
                  />
                </Grid>
              )}
              {!!afterTaskComponent && (
                <Grid item={true}>
                  {afterTaskComponent}
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
        <Grid
          item={true}
          container={true}
          spacing={3}
          direction='row'
          justify='center'
          alignItems='center'
          style={{ margin: '20px' }}
        >
          {loading ? (
              <CircularProgress color='primary' />
            ) : (
              <div style={{display: 'flex'}}>
                <Grid style={{padding: 5}} item={true}>
                  <Button
                    onClick={onBack}
                    variant='contained'
                    style={{ color: 'white' }}
                    classes={{ root: classes.stocateBtn }}
                    data-cy='level_back'
                  >
                    {t('pilot_testing.back')}
                  </Button>
                </Grid>
                <Grid style={{padding: 5}} item={true}>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    variant='contained'
                    style={{ color: 'white' }}
                    classes={{ root: classes.stocateBtn }}
                    data-cy='level_next'
                  >
                    {t('pilot_testing.next')}
                  </Button>
                </Grid>
              </div>
            )
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default Level;