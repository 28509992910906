import moment from 'moment';
import { socialMediaTypes } from 'stocateConstants';
import PropTypes from 'prop-types';

// Creates a Store object with initial state
export const InitialStoreStateBuilder = () => {
    return Store();
}

const Store = () => {
    return {
        data: {
            id: Number,
            storeHandle: String,
            name: String,
            description: String,
            phone: String,
            email: String,
            website: String,
            language: String,
            availableOnline: Boolean,
            open: Boolean,
            delivery: Boolean,
            pickup: Boolean,
            views: Number,
            location: {
                streetNumber: String,
                streetName: String,
                city: String,
                province: String,
                country: String,
                postalCode: String
            },
            coordinates: {
                latitude: Number,
                longitude: Number
            },
            socialMedia: [{
                handle: String,
                platform: String,
                url: String
            }],
            openHours: [{
                day: String,
                startTime: String,
                endTime: String
            }],
            paymentTypes: [],
            tags: [],
            sections:[
                {
                    name: String,
                    storeList:[{
                        data:{
                            id: Number,
                            storeHandle: String,
                            name: String,
                            description: String,
                            phone: String,
                            email: String,
                            website: String,
                            language: String,
                            availableOnline: Boolean,
                            open: Boolean,
                            delivery: Boolean,
                            pickup: Boolean,
                            views: Number,
                            location: {
                                streetNumber: String,
                                streetName: String,
                                city: String,
                                province: String,
                                country: String,
                                postalCode: String
                            },
                            coordinates: {
                                latitude: Number,
                                longitude: Number
                            },
                            socialMedia: [{
                                handle: String,
                                platform: String,
                                url: String
                            }],
                            openHours: [{
                                day: String,
                                startTime: String,
                                endTime: String
                            }],
                            paymentTypes: [],
                            tags: [],
                            images: [],
                            owners: [],
                            dateCreated: String
                        }
                    }],
                    itemList:[
                        {
                            storeName: String,
                            storeHandle: String,
                            name: String,
                            price: Number,
                            description: String,
                            available: Boolean,
                            images: [],
                            tags: [],
                            ingredients: []
                        }
                    ]
                }
            ],
            images: [],
            owners: [],
            dateCreated: String
        },
    }
}

export const storeHasThirdPartyDelivery = (store) => {
    for(let i = 0; i < store.socialMedia.length; ++i){
        if(store.socialMedia[i].platform === socialMediaTypes.OPEN_TABLE ||
            store.socialMedia[i].platform === socialMediaTypes.SKIP_THE_DISHES ||
            store.socialMedia[i].platform === socialMediaTypes.UBER_EATS 
        )
            return true
    }
    return false;
}

// Find link for "socialMedia". "link" is array with all the social media links of the store. 
// Return empty string if "socialMedia" isnt in "links"
export const findSocialMediaLink = (store, socialMedia) => {
    var result = store.socialMedia.find(link => link.platform === socialMedia);
    if (result === undefined) {
        return '';
    }
    return result;
}

// Find opening hours for "day". "openHours" is array with all opening hours. 
// Show closed if "day" is not present in "openHours"
export const findHoursForDay= (store, day) =>{
    var removeSeconds = 3;
    var result = store.openHours.find(a => a.day === day);
    if (result === undefined) {
        // return day + ": Closed";
        return ' Closed';
    }
    return ' ' + result.startTime.slice(0, -removeSeconds) + ' - ' + result.endTime.slice(0, -removeSeconds);

}

// Check if restaurant is open at current time
export const checkIfOpen = (store) =>{
    let isOpen = false;
    let today = new Date();
    let weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let todayString = weekdays[today.getDay()];

    if(store.openHours.length === 0){
        return null;
    }

    store.openHours.forEach(obj => {
        if (obj.day === todayString) {

            let startTime = moment(obj.startTime, 'HH:mm:ss');
            let endTime = moment(obj.endTime, 'HH:mm:ss');
            let currentTime = moment(today.getHours() + ':' + today.getMinutes(), 'HH:mm:ss');

            // calculate total duration
            let endRelative = moment.duration(endTime.diff(currentTime));
            let startRelative = moment.duration(currentTime.diff(startTime));

            // duration in minutes
            let minutesRemaindingBeforeClose = parseInt(endRelative.asMinutes(), 10);
            let minutesBeenSinceOpen = parseInt(startRelative.asMinutes(), 10);

            if (minutesRemaindingBeforeClose >=0 && minutesBeenSinceOpen >=0) {
                isOpen = true;
            }
        }
    })
    return isOpen;
    
}

export const addStore = () => {
    return {
        isSmall: true,
        name: '',
        unit: '',
        streetAddress: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
        description: '',
        username: '',

        availableOnline: false,
        open: false,
        delivery: false,
        pickup: false,

        debit: false,
        credit: false,
        cash: false,
        coupon: false,

        //Opening Hours
        isMon: true,
        startMon: '09:00',
        endMon: '17:00',
        isTues: true,
        startTues: '09:00',
        endTues: '17:00',
        isWed: true,
        startWed: '09:00',
        endWed: '17:00',
        isThurs: true,
        startThurs: '09:00',
        endThurs: '17:00',
        isFri: true,
        startFri: '09:00',
        endFri: '17:00',
        isSat: true,
        startSat: '09:00',
        endSat: '17:00',
        isSun: true,
        startSun: '',
        endSun: '',

        phone: '',
        email: '',
        website: '',
        facebook: '',
        instagram: '',
        uberEats: '',
        skipTheDishes: '',
        openTable: '',
        language: 'English',
        deals: '',
        tags: '',
        images: ''
    };
}

export const storePropShape = PropTypes.shape({
    id: PropTypes.number,
    storeHandle: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    language: PropTypes.string,
    availableOnline: PropTypes.bool,
    open: PropTypes.bool,
    delivery: PropTypes.bool,
    pickup: PropTypes.bool,
    views: PropTypes.number,
    location: PropTypes.shape({
        streetNumber: PropTypes.string,
        streetName: PropTypes.string,
        city: PropTypes.string,
        province: PropTypes.string,
        country: PropTypes.string,
        postalCode: PropTypes.string
    }),
    coordinates: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number
    }),
    socialMedia: PropTypes.arrayOf(PropTypes.shape({
        handle: PropTypes.string,
        platform: PropTypes.string,
        url: PropTypes.string
    })),
    openHours: PropTypes.arrayOf(PropTypes.shape({
        day: PropTypes.string,
        startTime: PropTypes.string,
        endTime: PropTypes.string
    })),
    paymentTypes: PropTypes.arrayOf(PropTypes.any),
    tags: PropTypes.arrayOf(PropTypes.any),
    sections: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        storeList: PropTypes.arrayOf(PropTypes.shape({
            data: PropTypes.shape({
                id: PropTypes.number,
                storeHandle: PropTypes.string,
                name: PropTypes.string,
                description: PropTypes.string,
                phone: PropTypes.string,
                email: PropTypes.string,
                website: PropTypes.string,
                language: PropTypes.string,
                availableOnline: PropTypes.bool,
                open: PropTypes.bool,
                delivery: PropTypes.bool,
                pickup: PropTypes.bool,
                views: PropTypes.number,
                location: PropTypes.shape({
                    streetNumber: PropTypes.string,
                    streetName: PropTypes.string,
                    city: PropTypes.string,
                    province: PropTypes.string,
                    country: PropTypes.string,
                    postalCode: PropTypes.string
                }),
                coordinates: PropTypes.shape({
                    latitude: PropTypes.number,
                    longitude: PropTypes.number
                }),
                socialMedia: PropTypes.arrayOf(PropTypes.shape({
                    handle: PropTypes.string,
                    platform: PropTypes.string,
                    url: PropTypes.string
                })),
                openHours: PropTypes.arrayOf(PropTypes.shape({
                    day: PropTypes.string,
                    startTime: PropTypes.string,
                    endTime: PropTypes.string
                })),
                paymentTypes: PropTypes.arrayOf(PropTypes.any),
                tags: PropTypes.arrayOf(PropTypes.any),
                images: PropTypes.arrayOf(PropTypes.any),
                owners: PropTypes.arrayOf(PropTypes.any),
                dateCreated: PropTypes.string
            })
        })),
        itemList: PropTypes.arrayOf(PropTypes.shape({
            storeName: PropTypes.string,
            storeHandle: PropTypes.string,
            name: PropTypes.string,
            price: PropTypes.number,
            description: PropTypes.string,
            available: PropTypes.bool,
            images: PropTypes.arrayOf(PropTypes.any),
            tags: PropTypes.arrayOf(PropTypes.any),
            ingredients: PropTypes.arrayOf(PropTypes.any)
        })),
    })),
    images: PropTypes.arrayOf(PropTypes.any),
    owners: PropTypes.arrayOf(PropTypes.any),
    dateCreated: PropTypes.string
});

export const storesProps = {
	data: PropTypes.arrayOf(storePropShape)
}