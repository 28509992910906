// User Journey portion: > add (nav) > create store > add store to a list
import React from 'react';
import feedbackIcon from '../../../../assets/images/general/feedback.png';
import { LevelTask } from '../TestingUtils';
import AddTab from '../../../../assets/images/pilot-testing/howto-appfeedback/levelPhotos/Add Tab.png';
import AddFavourite from '../../../../assets/images/pilot-testing/howto-appfeedback/levelPhotos/Favourites.png';

const ENlvl3Tasks: LevelTask[] = [
  {
    taskNum: 1,
    task: 'Use the "Add" tab to add your favorite local store to our platform (that doesn\'t already exist).',
    taskImage: AddTab,
    taskImageAlt: 'add tab',
    textBoxLabel:
      'How was the experience of adding a store? Did you encounter any issues? What could be better?',
  },
  {
    taskNum: 2,
    task: 'Once you\'re done adding your store, use the "Search" tab to go view it!',
  },
  {
    taskNum: 3,
    task: 'Within the store\'s page, add it to your "Favorites" list!',
    taskImage: AddFavourite,
    taskImageAlt: 'add favourite',
    textBoxLabel:
      'Did you encounter any issues when adding the store to your "Favorites" list? Were any of the menus unintuitive? What should we improve?',
  },
  {
    taskNum: 4,
    task: (
      <>
        {`Do you have any other feedback? Or anything that reduced your experience?\nUse the "Feedback" tab in the app or the `}
        <img src={feedbackIcon} alt='' style={{ height: 25, width: 25 }} />
        {' button in the bottom-right of this page.'}
      </>
    ),
  },
];

const FRlvl3Tasks: LevelTask[] = [
  {
    taskNum: 1,
    task: `Utilisez l'onglet « Ajouter » pour ajouter votre magasin local préféré à notre plateforme (s'il n'existe pas déjà sur la plateforme).`,
    taskImage: AddFavourite,
    taskImageAlt: 'ajouter un magasin',
    textBoxLabel: `Comment a été votre expérience d'ajout d'un magasin? Avez-vous rencontré des difficultés? Que pourrait-on améliorer?`,
  },
  {
    taskNum: 2,
    task: `Une fois que vous avez terminé d'ajouter votre magasin, utilisez l'onglet « Rechercher » pour aller le voir!`,
  },
  {
    taskNum: 3,
    task: 'Dans la page du magasin, ajoutez-le à votre liste « Favoris »!',
    taskImage: AddFavourite,
    taskImageAlt: 'ajoutez-le à votre liste « Favoris »',
    textBoxLabel: `Avez-vous rencontré des difficultés lors de l'ajout du magasin à votre liste « Favoris »? Y avait-t-il des menus inintuitives? Que pourrait-on améliorer?`,
  },
  {
    taskNum: 4,
    task: (
      <>
        {`Avez-vous d'autres commentaires? Y a-t-il quelque chose qui a réduit votre expérience?\nUtilisez l'onglet « Commentaires » de l'application ou clickez le bouton `}
        <img src={feedbackIcon} alt='' style={{ height: 25, width: 25 }} />
        {' en bas à droite de cette page.'}
      </>
    ),
  },
];

export { ENlvl3Tasks, FRlvl3Tasks };
