export const FeedbackBuilder = (data) => {
    let feedback = Feedback();
    feedback.data = data;
    return feedback
}

export const InitialFeedbackStateBuilder = (currentPage) => {
    return Feedback(currentPage);
}

const Feedback = (currentPage) => {
    return {
        data: {
            userId: '',
            name: '',
            text: '',
            phone: '',
            email: '',
            idNumber: '',
            currentPage: currentPage
        },

        validate() {
            return this.noEmptyField();
        },

        noEmptyField() {
            let textValid = true;
            if(this.data.text != null){
                if(this.data.text.trim() === ''){
                    textValid = false;
                }
            }
            return this.data.text != null && textValid;
        }
    }
}