import React from 'react';
import { Tooltip } from '@material-ui/core';


interface BadgeTooltipData {
  desc: string;
  label: string;
  image: string;
}

const BadgeWithTooltipAdjustable = ({ data, size }: { data: BadgeTooltipData, size: number }) => {
    if (data.image) {
        const title = <>
            <u>{data.desc}</u>{': '}
            {data.label}
        </>;
        return <Tooltip title={title} arrow={true}>
            <img
                src={data.image}
                alt='sustainability badge'
                style={{ width: size, height: size}}
            />
        </Tooltip>
    } else {
        return null;
    }
}

export default BadgeWithTooltipAdjustable;