import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { queryClient, StocateQuery } from '../../../utils/queryClient';
import { defaultPreviewLinkImage, defaultPreviewLinkDescription, asc, byom, constituent, dairy, ecoLogo, fairTrade, gluten, greenSeal, halal, kosher, landTransportation, msc, oceanWise, organic, packaging, palmOil, paths, peanut, rainforest, sugar, vegan, vegetarian, origin, fsc, eolRecollection } from '../../../stocateConstants';
import { initialItem, initialStore } from '../addPages/AddUtils';
import useAbortController from '../../../hooks/useAbortController';
import { useAlert } from '../../../context/AlertContext';
import ImagePicker from '../addPages/ImagePicker';
import TagPicker from '../addPages/TagPicker';
import DescriptionField from '../addPages/DescriptionField';
import NameInput from '../addPages/NameInput';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import IAddItem from '../../../types/add/IAddItem';
import { addItemPicture, getItemWithHandle } from '../../../api/ItemsApi';
import IngredientPicker from '../addPages/IngredientPicker';
import PriceField from '../addPages/PriceField';
import StoreField from '../addPages/StoreField';
import SectionField from '../addPages/SectionField';
import { getStoreSections, getStoreWithHandle } from '../../../api/StoresApi';
import AvailabilityField from '../addPages/AvailabilityField';
import SusBadgeList from '../../../types/add/ISusBadgeList';
import SustainabilityPicker from '../addStore/SustainabilityPicker';
import { sendPageView } from '../../../utils/GoogleAnalytics';
import { useAddItem, useEditItem } from '../../../hooks/useAddItem';
import ImageGallery from '../addStore/ImageGallery';
import IAddStore from '../../../types/add/IAddStore';

const AddItem = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const getSignal = useAbortController();
  const { alertSuccess, alertWarning, alertError } = useAlert();
  const addItem = useAddItem();
  const editItem = useEditItem();

  const [store, setStore] = useState<IAddStore>(initialStore);
  const [item, setItem] = useState<IAddItem>(initialItem);
  const [sections, setSections] = useState([]);
  const [newSection, setNewSection] = useState('');
  const [storeSectionsListId, setStoreSectionsListId] = useState(-1);

  const [itemImages, setItemImages] = useState<any>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]); // Explicitly define type as string[]
  const [title, setTitle] = useState<any>(t('additem.title'));

  const queryParams = new URLSearchParams(window.location.search);
  const itemParam = queryParams.get('item');

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - Add Item');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/add/item');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  const successCallbackItem = (body: any) => {
    setItemImages(body.images);
    setItem(body);
  };

  const failCallbackItem = (statusCode: any, body: any) => {
    // do nothing
  };

  const successCallbackStore = (body: any) => {
    setStore(body);
  };

  const failCallbackStore = (statusCode: any, body: any) => {
    // do nothing
  };

  const sustainabilityBadgeList: SusBadgeList[] = [
    {
      key: 'packaging',
      title: t('newStore.type_packaging'),
      badges: packaging,
    },
    {
      key: 'constituent',
      title: t('newStore.constituents'),
      badges: constituent,
    },
    {
      key: 'eolRecollection',
      title: t('newStore.eolRecollection'),
      badges: eolRecollection,
    },
    {
      key: 'landTransportation',
      title: t('newStore.landtransport'),
      badges: landTransportation,
    },
    {
      key: 'origin',
      title: t('newStore.origin'),
      badges: origin,
    },
    {
      key: 'fsc',
      title: t('newStore.fsc'),
      badges: fsc,
    },
    {
      key: 'organic',
      title: t('newStore.organic'),
      badges: organic,
    },
    {
      key: 'ecoLogo',
      title: t('newStore.ecoLogo'),
      badges: ecoLogo,
    },
    {
      key: 'asc',
      title: t('newStore.asc'),
      badges: asc,
    },
    {
      key: 'palmOil',
      title: t('newStore.palmOil'),
      badges: palmOil,
    },
    {
      key: 'byom',
      title: t('newStore.byom'),
      badges: byom,
    },
    {
      key: 'greenSeal',
      title: t('newStore.greenSeal'),
      badges: greenSeal,
    },
    {
      key: 'oceanWise',
      title: t('newStore.oceanWise'),
      badges: oceanWise,
    },
    {
      key: 'rainforest',
      title: t('newStore.rainforest'),
      badges: rainforest,
    },
    {
      key: 'msc',
      title: t('newStore.msc'),
      badges: msc,
    },
    {
      key: 'fairTrade',
      title: t('newStore.fairTrade'),
      badges: fairTrade,
    }
  ];

  const dietBadgeList: SusBadgeList[] = [
    {
      key: 'kosher',
      title: t('newStore.kosher'),
      badges: kosher,
    },
    {
      key: 'peanut',
      title: t('newStore.peanut'),
      badges: peanut,
    },
    {
      key: 'dairy',
      title: t('newStore.dairy'),
      badges: dairy,
    },
    {
      key: 'sugar',
      title: t('newStore.sugar'),
      badges: sugar,
    },
    {
      key: 'vegan',
      title: t('newStore.vegan'),
      badges: vegan,
    },
    {
      key: 'halal',
      title: t('newStore.halal'),
      badges: halal,
    },
    {
      key: 'vegetarian',
      title: t('newStore.vegetarian'),
      badges: vegetarian,
    },
    {
      key: 'gluten',
      title: t('newStore.gluten'),
      badges: gluten,
    }
  ];

  const handleImageRemove = (index: any) => {
    const removedImage = itemImages[index];
    const newImages = itemImages.filter((_: any, i: any) => i !== index);
    setItemImages(newImages);

    // Add the removed image to the imagesToDelete array
    setImagesToDelete((prevImagesToDelete) => [...prevImagesToDelete, removedImage]);
  };

  const commonSubmitButtonStyles = {
    width: 241,
    height: 56,
    fontSize: 24,
    fontWeight: 'bold',
  };

  const setSection = useCallback((id: number) => {
    setItem({ ...item, selectedSection: id })
  }, [item, setItem])

  useEffect(() => {
    sendPageView(paths.ADD_STORE);
    updateMetaTags();
    const queryParams = new URLSearchParams(window.location.search);
    const itemParam = queryParams.get('item');
    const storeParam = queryParams.get('store');
    const sectionParam = queryParams.get('section');
    if (itemParam != null) {
      setTitle(t('additem.edit_title'))
      getItemWithHandle(itemParam, successCallbackItem, failCallbackItem)
    }
    else if (storeParam != null) {
      getStoreWithHandle(storeParam, successCallbackStore, failCallbackStore)
    }
    if (sectionParam != null) {
      setSection(parseInt(sectionParam, 10));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getSections(id: number): any {
    setItem({ ...item, storeId: id })
    getStoreSections(id, successCallback, failCallback);
  }

  const failCallback = (statusCode: any, body: any) => {
    // do nothing
  }

  const successCallback = (body: any) => {
    setStoreSectionsListId(body.sectionlistId)
    const array = body.sections.map((x: { label: any; name: any; value: any; id: any; }) => {
      x.label = x.name;
      x.value = x.id
      return x;
    });
    setSections(array);
  }

  const onSubmit = () => {
    const nameMissing = item.name.trim() === '';
    const storeMissing = item.storeId === null || item.storeId === undefined;
    if (nameMissing)
      return alertError(
        t('additem.name_empty_error')
      );
    if (storeMissing)
      return alertError(
        t('additem.store_id_error')
      );
    alertWarning(t('message.processing'));
    const queryParams = new URLSearchParams(window.location.search);
    const itemParam = queryParams.get('item');
    if (itemParam != null) {
      item.itemIngredients = []
      editItem.mutate(
        { item: { ...item, storeSectionsListId: storeSectionsListId, newSection: newSection, images: [], imagesLeft: itemImages, imagesToDelete: imagesToDelete } },
        {
          onSuccess: (newItem: any) => {
            let redirectPath = generatePath(paths.STORES, {
              storeHandle: newItem.storeHandle
            });
            redirectPath += '?item=' + newItem.itemHandle;
            const addItemSuccessCallback = () => {
              alertSuccess(t('additem.success_modal_header'));
              queryClient.invalidateQueries(StocateQuery.ITEMS);
              history.replace(redirectPath);
            };
            if (item.images.length > 0) {
              addItemPicture(
                getSignal(),
                newItem,
                item.images,
                newItem.imagesLeft?.length
              )
                .then(addItemSuccessCallback)
                .catch(() => {
                  alertSuccess(t('additem.success_modal_header'));
                  history.push(redirectPath);
                });
            } else addItemSuccessCallback();
          },
          onError: (error) => {
            const { code, message } = error;
            if (code === 401 && message != null) {
              if (message.includes('User does not have permissions'))
                alertError(t('addstore.permission_error'));
              else if (message.includes('Item handle already exists'))
                alertError(t('additem.itemHandle_exists_error'));
              else alertError(t('errorCode.unexpected'));
            } else if (code === 404) {
              if (message.includes('Missing the following fields'))
                alertError(t('additem.input_error'));
              else if (message.includes('Platform not found'))
                alertError(t('additem.platform_error'));
              else if (message.includes('Language not found'))
                alertError(t('additem.language_error'));
              else if (message.includes('Empty slug inserted'))
                alertError(t('additem.itemHandle_empty_error'));
              else alertError(t('errorCode.unexpected'));
            } else alertError(t('errorCode.unexpected'));
          },
        }
      );
    }
    else {
      item.itemIngredients = []
      addItem.mutate(
        { item: { ...item, storeSectionsListId: storeSectionsListId, newSection: newSection, images: [] } },
        {
          onSuccess: (newItem: any) => {
            let redirectPath = generatePath(paths.STORES, {
              storeHandle: newItem.storeHandle
            });
            redirectPath += '?item=' + newItem.itemHandle;
            const addItemSuccessCallback = () => {
              alertSuccess(t('additem.success_modal_header'));
              queryClient.invalidateQueries(StocateQuery.ITEMS);
              history.replace(redirectPath);
            };
            if (item.images.length > 0) {
              addItemPicture(
                getSignal(),
                newItem,
                item.images,
                newItem.imagesLeft?.length
              )
                .then(addItemSuccessCallback)
                .catch(() => {
                  alertError(t('additem.images_error'));
                  history.push(redirectPath);
                });
            } else addItemSuccessCallback();
          },
          onError: (error) => {
            const { code, message } = error;
            if (code === 401 && message != null) {
              if (message.includes('User does not have permissions'))
                alertError(t('addstore.permission_error'));
              else if (message.includes('Item handle already exists'))
                alertError(t('additem.itemHandle_exists_error'));
              else alertError(t('errorCode.unexpected'));
            } else if (code === 404) {
              if (message.includes('Missing the following fields'))
                alertError(t('additem.input_error'));
              else if (message.includes('Platform not found'))
                alertError(t('additem.platform_error'));
              else if (message.includes('Language not found'))
                alertError(t('additem.language_error'));
              else if (message.includes('Empty slug inserted'))
                alertError(t('additem.itemHandle_empty_error'));
              else alertError(t('errorCode.unexpected'));
            } else alertError(t('errorCode.unexpected'));
          },
        }
      );
    }
  };

  return (
    <Container style={{ maxWidth: '800px' }}>
      <Grid container={true} direction='column' spacing={4}>
        <Grid item={true}>
          <Typography variant='h4' style={{ textAlign: 'center' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item={true}>
          <StoreField entity={item} store={store} getSections={getSections} />
        </Grid>
        <Grid item={true}>
          <SectionField entity={item} sections={sections} setSection={setSection} setNewSection={setNewSection} />
        </Grid>
        <Grid item={true}>
          <NameInput
            entity={item}
            setEntity={setItem}
            placeholder={t('additem.name_placeholder')}
          />
        </Grid>
        <Grid item={true}>
          <PriceField entity={item} setEntity={setItem} />
        </Grid>
        <Grid item={true}>
          <DescriptionField
            entity={item}
            setEntity={setItem}
            placeholder={t('addstore.description_placeholder')}
          />
        </Grid>
        {(itemParam != null) ?
          <Grid item={true}>
            <ImageGallery images={itemImages} onImageRemove={handleImageRemove} />
          </Grid>
          : null}
        <Grid item={true}>
          <ImagePicker setEntity={setItem} />
        </Grid>
        <Grid item={true}>
          <TagPicker entity={item} setEntity={setItem} />
        </Grid>
        <Grid item={true}>
          <IngredientPicker entity={item} setEntity={setItem} />
        </Grid>
        <Grid item={true}>
          <SustainabilityPicker entity={item} setEntity={setItem} badgeLists={sustainabilityBadgeList} titleText={t('addstore.sustainability_metrics')} />
        </Grid>
        <Grid item={true}>
          <SustainabilityPicker entity={item} setEntity={setItem} badgeLists={dietBadgeList} titleText={t('addstore.dietary_restrictions')} />
        </Grid>
        <Grid item={true}>
          <AvailabilityField entity={item} setEntity={setItem} />
        </Grid>
        <Grid item={true} style={{ textAlign: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            style={commonSubmitButtonStyles}
            onClick={onSubmit}
            data-cy='additem-submit'
          >
            {t(itemParam ? 'additem.submit_edit_button_text' : 'additem.submit_add_button_text')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddItem