import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { paths, defaultPreviewLinkImage, defaultPreviewLinkDescription } from 'stocateConstants';
import { sendPageView } from 'utils/GoogleAnalytics';

function ServiceAgreement() {

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - Service Agreement');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/serviceAgreement');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  useEffect(() => {
    sendPageView(paths.AGREEMENT);
    updateMetaTags();
  }, []);
  return (
    <>
      <div style={{ margin: 50 }}>
        <h1>Stocate Terms of Service</h1>

        <h4>1. Terms</h4>
        <p>
          By accessing our app, Stocate, you are agreeing to be bound by these
          terms of service, all applicable laws and regulations, and agree that
          you are responsible for compliance with any applicable local laws. If
          you do not agree with any of these terms, you are prohibited from
          using or accessing Stocate. The materials contained in Stocate are
          protected by applicable copyright and trademark law.
        </p>

        <h4>2. Use License</h4>
        <p>
          Permission is granted to temporarily download one copy of Stocate per
          device for personal, non-commercial transitory viewing only. This is
          the grant of a license, not a transfer of title, and under this
          license you may not:
        </p>
        <ul>
          <li>Modify or copy the materials</li>
          <li>
            Use the materials for any commercial purpose, or for any public
            display (commercial or non-commercial)
          </li>
          <li>
            Attempt to decompile or reverse engineer any software contained in
            Stocate
          </li>
          <li>
            Remove any copyright or other proprietary notations from the
            materials
          </li>
          <li>
            Transfer the materials to another person or "mirror" the materials
            on any other server.
          </li>
        </ul>
        <p>
          This license shall automatically terminate if you violate any of these
          restrictions and may be terminated by Stocate at any time. Upon
          terminating your viewing of these materials or upon the termination of
          this license, you must destroy any downloaded materials in your
          possession whether in electronic or printed format.
        </p>

        <h4>3. Disclaimer</h4>
        <p>
          The materials within Stocate are provided on an 'as is' basis. Stocate
          makes no warranties, expressed or implied, and hereby disclaims and
          negates all other warranties including, without limitation, implied
          warranties or conditions of merchant ability, fitness for a particular
          purpose, or non-infringement of intellectual property or other
          violation of rights. Further, Stocate does not warrant or make any
          representations concerning the accuracy, likely results, or
          reliability of the use of the materials on its website or otherwise
          relating to such materials or on any sites linked to Stocate.
        </p>

        <h4>4. Limitations</h4>
        <p>
          In no event shall Stocate or its suppliers be liable for any damages
          (including, without limitation, damages for loss of data or profit, or
          due to business interruption) arising out of the use or inability to
          use Stocate, even if Stocate or a Stocate authorized representative
          has been notified orally or in writing of the possibility of such
          damage. Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </p>

        <h4>5. Accuracy of materials</h4>
        <p>
          The materials appearing in Stocate could include technical,
          typographical, or photographic errors. Stocate does not warrant that
          any of the materials on Stocate are accurate, complete or current.
          Stocate may make changes to the materials contained in Stocate at any
          time without notice. However Stocate does not make any commitment to
          update the materials.
        </p>

        <h4>6. Links</h4>
        <p>
          Stocate has not reviewed all of the sites linked to its app and is not
          responsible for the contents of any such linked site. The inclusion of
          any link does not imply endorsement by Stocate of the site. Use of any
          such linked website is at the user's own risk.
        </p>

        <h4>7. Modifications</h4>
        <p>
          Stocate may revise these terms of service for its app at any time
          without notice. By using Stocate you are agreeing to be bound by the
          then current version of these terms of service.
        </p>

        <h4>8. Governing Law</h4>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of Montreal and you irrevocably submit to the exclusive
          jurisdiction of the courts in that State or location.
        </p>
      </div>
    </>
  );
}

export default withTranslation()(ServiceAgreement);
