import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
    severity: 'info'
  });

export const setSeverity = (servity) => {
    setGlobalState('severity', (v) => ({...v, servity}));
};  

export  { useGlobalState };