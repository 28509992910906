import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import { addResizingListener } from 'utils/Responsiveness';
import Search from '../../../components/reusable/Search';
import CollapsibleCard from '../../reusable/CollapsibleCard';
import ObjectCell from '../../reusable/ObjectCell';
import Modal from 'react-modal';
import { useHistory, useParams } from 'react-router-dom';
import styles from './Store.module.css';
import { withTranslation } from 'react-i18next';
import StoreCard from './StoreCard';
import ItemCard from './ItemCard';
import { getStoreWithHandle, getStoreSections } from '../../../api/StoresApi';
import { paths, searchShowing, defaultPreviewLinkImage } from 'stocateConstants';
import i18next from 'i18next';
import ForumImageModal from './ForumImageModal';
import { getStoreForum } from '../../../api/ForumApi';
import ApiErrorView from '../../../components/reusable/ApiErrorView';
import { sortList, joinedDate, color } from '../../../stocateConstants';
import { sendPageView } from '../../../utils/GoogleAnalytics';
import SectionTab from '../../reusable/SectionTab';
import contentIcon from '../../../assets/images/store/contentIcon.png';
import forumIcon from '../../../assets/images/store/forumIcon.png'
import Nav from 'react-bootstrap/Nav';

//var selectedCard = null;
//var displayCard = null;
var displayListItemCard = null;
var displayListStoreCard = null;
var displayStoreCard = null;
showStoreCard();

function showStoreCard() {
  displayListItemCard = 'none';
  displayListStoreCard = 'none';
  displayStoreCard = 'block';
}

function showListStoreCard() {
  displayListItemCard = 'none';
  displayListStoreCard = 'block';
  displayStoreCard = 'none';
}

function showListItemCard() {
  displayListItemCard = 'block';
  displayListStoreCard = 'none';
  displayStoreCard = 'none';
}

var count = 0; //to ensure that the page rerender once when checking the url

function Store(props) {
  //Indicate not mobile
  const [isMobile, setIsMobile] = useState(false);
  //Set store to Json fron GET request
  const [store, setStore] = useState();
  const [sections, setSections] = useState();
  const [forum, setForum] = useState();
  //Set items to all items from store
  const [item, setItem] = useState(null);
  const [listStore, setListStore] = useState(null);
  const [input, setInput] = useState('');
  const [forumSearchInput, setForumSearchInput] = useState('');
  const [showingForum, setShowingForum] = useState(false);

  // Error handling
  const [apiSuccess, setApiSuccess] = useState(true);
  const [code, setCode] = useState('');
  const [errorText, setErrorText] = useState('');

  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);

  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  var linkItem = query.get('item');
  var linkStore = query.get('store');
  const { storeHandle } = useParams();

  const itemCard = useRef(null);

  // on mobile only show content OR forum OR about at one time
  const [mobileActiveTab, setMobileActiveTab] = useState('content');

  const handleMobileTabClick = (selectedKey) => {
    setMobileActiveTab(selectedKey);
  };

  function queryRequest() {
    if (linkItem !== null && linkItem !== '') {
      for (let i = 0; i < sections.sections.length; i++) {
        for (let x = 0; x < sections.sections[i].itemList.length; x++) {
          if (linkItem === sections.sections[i].itemList[x].itemHandle) {
            showListItemCard();
            //displayCard = sections.sections[i].itemList[x];
            setItemWithMetaTags(sections.sections[i].itemList[x]);
            return;
          }
        }
      }
    } else {
      if (linkStore !== null && linkStore !== '') {
        for (let i = 0; i < sections.sections.length; i++) {
          for (let x = 0; x < sections.sections[i].storeList.length; x++) {
            if (linkStore === sections.sections[i].storeList[x].storeHandle) {
              showListStoreCard();
              //displayCard = sections.sections[i].storeList[x];
              setListStore(sections.sections[i].storeList[x]);
              return;
            }
          }
        }
      }
    }
  }

  /* function showCard() {
    let urlPush = '';

    if (displayStoreCard === 'none') {
      if (selectedCard === displayCard) {
        showStoreCard();
        displayCard = null;
        urlPush = '';
      } else {
        if (displayCard.itemHandle != null && selectedCard.itemHandle == null) {
          showListStoreCard();
          urlPush = '?store=' + selectedCard.storeHandle;
        } else if (
          displayCard.itemHandle != null &&
          selectedCard.itemHandle != null
        ) {
          urlPush = '?item=' + selectedCard.itemHandle;
        } else if (
          displayCard.itemHandle == null &&
          selectedCard.itemHandle == null
        ) {
          urlPush = '?store=' + selectedCard.storeHandle;
        } else {
          showListItemCard();
          urlPush = '?item=' + selectedCard.itemHandle;
        }
        displayCard = selectedCard;
      }
    } else {
      if (selectedCard.itemHandle !== null && selectedCard.itemHandle !== '') {
        showListItemCard();
        urlPush = '?item=' + selectedCard.itemHandle;
      } else {
        showListStoreCard();
        urlPush = '?store=' + selectedCard.storeHandle;
      }
      displayCard = selectedCard;
    }
    history.push('/' + storesEndpoints + store.storeHandle + urlPush);
  } */

  const sendToAddItemFromStore = (sectionId) => {
    const url = `${paths.ADD_ITEM}?store=${encodeURIComponent(
      store.storeHandle
    )}&section=${encodeURIComponent(
      sectionId
    )}`;
    window.location.href = url;
  };

  const updateMetaTags = React.useCallback(
    (objectInfo) => {
      const metaImage =
        objectInfo.images.length > 0
          ? objectInfo.images[0]
          : defaultPreviewLinkImage;
      const metaDescription =
        objectInfo.description.length !== 0
          ? objectInfo.description.length > 75
            ? objectInfo.description.slice(0, 75) + '...'
            : objectInfo.description
          : linkItem !== null && linkItem !== ''
          ? `From ${objectInfo.storeName} discover ${objectInfo.name} on Stocate!`
          : `Explore ${objectInfo.name} on Stocate!`;

      document
        .querySelector('meta[name="og:title"]')
        ?.setAttribute('content', objectInfo.name);
      document
        .querySelector('meta[name="og:image"]')
        ?.setAttribute('content', metaImage);
      document
        .querySelector('meta[name="og:url"]')
        ?.setAttribute('content', window.location.href);
      document
        .querySelector('meta[name="og:description"]')
        ?.setAttribute('content', metaDescription);
    },
    [linkItem]
  );

  const setStoreWithMetaTags = React.useCallback(
    (storeInfo) => {
      setStore(storeInfo);
      updateMetaTags(storeInfo);
    },
    [updateMetaTags]
  );

  const setItemWithMetaTags = (itemInfo) => {
    setItem(itemInfo);
    updateMetaTags(itemInfo);
  };

  const successCallbackStore = React.useCallback(
    (body) => {
      setStoreWithMetaTags(body);
      getStoreSections(body.id, successCallbackSection, failCallbackSection);
      getStoreForum(body.forumId, successCallbackForum, failCallbackForum);
    },
    [setStoreWithMetaTags]
  );

  useEffect(() => {
    addResizingListener(setIsMobile);
    getStoreWithHandle(storeHandle, successCallbackStore, failCallbackStore);
    sendPageView(history.location.pathname);
  }, [storeHandle, history, successCallbackStore]);

  const failCallbackStore = (statusCode, body) => {
    setApiSuccess(false);
    setCode(statusCode);
    if (statusCode === 404) setErrorText(i18next.t('errorCode.storeView_404'));
    else setErrorText(i18next.t('errorCode.unexpected'));
  };

  const successCallbackForum = (body) => {
    setForum(body);
  };

  const failCallbackForum = (statusCode, body) => {
    setApiSuccess(false);
    setCode(statusCode);
    if (statusCode === 404) setErrorText(i18next.t('errorCode.forum_404'));
    else setErrorText(i18next.t('errorCode.unexpected'));
  };

  const successCallbackSection = (body) => {
    setSections(body);
  };

  const failCallbackSection = (statusCode, body) => {
    setApiSuccess(false);
    setCode(statusCode);
    if (statusCode === 404) {
      setErrorText(i18next.t('errorCode.addItem_404'));
    } else {
      setErrorText(i18next.t('errorCode.unexpected'));
    }
  };

  const openItemModal = (index) => {
    setItemModalIsOpen(true);
  };

  const closeItemModal = () => {
    setItemModalIsOpen(false);
  };

  // !!! TODO: Remove this hack
  if (count === 0) {
    if (sections != null && store != null) {
      count += 1;
      queryRequest();
    }
  }
  return (
    <>
      {sections == null || store == null || forum == null ? (
        <Grid
          container
          justify='center'
          alignItems='center'
          style={{ minHeight: 700 }}
        >
          {apiSuccess ? (
            <CircularProgress color='primary' />
          ) : (
            <ApiErrorView code={code} text={errorText} />
          )}
        </Grid>
      ) : (
        <Grid>
          {isMobile && (
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'sticky', top:'75px', zIndex: '100'}}>
                <Nav variant='tabs' fill defaultActiveKey='content'>
                  <Nav.Item activeClassName={styles.activeTab} className={styles.inactiveTab}>
                    <Nav.Link eventKey='content' data-cy='contentTab' onClick={() => handleMobileTabClick('content')} style={{ color: '#0b86a2' }}>{i18next.t('storepage.content')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item activeClassName={styles.activeTab} className={styles.inactiveTab}>
                    <Nav.Link eventKey='forum' data-cy='forumTab' onClick={() => handleMobileTabClick('forum')} style={{ color: '#0b86a2' }}>{i18next.t('storepage.forum')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item activeClassName={styles.activeTab} className={styles.inactiveTab}>
                    <Nav.Link eventKey='about' data-cy='aboutTab' onClick={() => handleMobileTabClick('about')} style={{ color: '#0b86a2' }}>{i18next.t('storepage.about')}</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div style={{ height: '2px', backgroundColor: '#dee2e6' }}></div>
              </div>
            </div>
          )}
          <Grid
            container
            direction='row'
            justify='space-between'
          >
            <Grid item={true} xs={isMobile ? 12 : 7} style={{ padding: 5 }}>
              {(!isMobile || mobileActiveTab !== 'about') && (<div style={{ flex: 1, padding: '20px' }}>
                <Search
                  setInput={showingForum ? setForumSearchInput : setInput}
                  fullWidth={true}
                />
              </div>)}
              {!isMobile && (<Grid
                container
                justify='space-around'
                style={{ padding: '10px' }}
              >
                <SectionTab
                  selected={!showingForum}
                  onClick={() => {
                    setShowingForum(false);
                  }}
                >
                  <span className={ styles.buttonStyleHover } data-cy='contentTab'>
                    <img src={contentIcon} style={{ height: '18px', marginRight: '5px' }} alt='menu'></img>
                    {i18next.t('storepage.content')}
                  </span>
                </SectionTab>
                <SectionTab
                  selected={showingForum}
                  onClick={() => {
                    setShowingForum(true);
                  }}
                >
                  <span className={ styles.buttonStyleHover }>
                    <img src={forumIcon} style={{ height: '20px', marginRight: '5px' }} alt='forum'></img>
                    {i18next.t('storepage.forum')}
                  </span>
                </SectionTab>
              </Grid>)}
              {((!isMobile && showingForum) || mobileActiveTab === 'forum') ? (
                <div id='forum' style={{ overflow: 'auto', maxHeight: 700 }}>
                  {forum.posts.map((post) => {
                    if (searchShowing(post, forumSearchInput)) {
                      return (
                        <div style={{ marginBottom: 5 }}>
                          <CollapsibleCard
                            title_left={post.title}
                            startOpen={forum.posts.length === 1}
                            borderRadius={10}
                            body_padding = {'0px'}
                            body_content={
                              <div>
                                <div 
                                  className={ styles.hoverEffect } 
                                  style={{
                                    backgroundColor: color.veryLightGray, 
                                    borderColor: '#d9d9db', 
                                    borderWidth: '1px', 
                                    padding: '10px', 
                                    borderStyle: 'solid' 
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <p>{post.author}</p>
                                    <p>{joinedDate(post.dateCreated)}</p>
                                  </div>
                                  <h4>{post.text}</h4>
                                  {post.images.map((i) => {
                                    return <ForumImageModal src={i} />;
                                  })}
                                </div>
                                {post.replies.map((reply) => {
                                  return (
                                    <div 
                                      className={ styles.hoverEffect } 
                                      style={{
                                        backgroundColor: color.veryLightGray, 
                                        borderColor: '#d9d9db', 
                                        borderWidth: '1px', 
                                        padding: '10px', 
                                        borderStyle: 'solid' 
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <p>{reply.author}</p>
                                        <p>{joinedDate(reply.dateCreated)}</p>
                                      </div>
                                      <p>{reply.text}</p>
                                      {reply.images.map((i) => {
                                        return <ForumImageModal src={i} />;
                                      })}
                                    </div>
                                  );
                                })}
                              </div>
                            }
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : (
                <> {(!isMobile || mobileActiveTab === 'content') && (
                  <div id='content' style={{ overflow: 'auto', maxHeight: 700 }}>
                    {sections.sections.map((section) => {
                      if(!section.uncategorized){
                        return (
                          <div key={section.name} style={{ marginBottom: 5 }}>
                            <CollapsibleCard
                              title_left={section.name}
                              startOpen={sections.sections.length === 1}
                              borderRadius={10}
                              body_padding = {'0px'}
                              body_content={
                                <div className={styles.itemList}>
                                  {sortList(section.itemList).map((itemMap) => {
                                    if (
                                      itemMap.available &&
                                      searchShowing(itemMap, input)
                                    ) {
                                      return (
                                        <div
                                          key={itemMap.itemHandle}
                                          onClick={() => {
                                            setItemWithMetaTags(itemMap);
                                            //selectedCard = itemMap;
                                            openItemModal();
                                          }}
                                        >
                                          <Grid item={true}>
                                            <ObjectCell
                                              type={'item'}
                                              name={itemMap.name}
                                              description={itemMap.description}
                                              price={itemMap.price}
                                              isMobile={isMobile}
                                              image={itemMap.images[0]}
                                              width={'55vw'}
                                              entity={itemMap}
                                            />
                                          </Grid>
                                        </div>
                                      );
                                    }
                                    return true;
                                  })}
                                  {sortList(section.storeList).map((listStoreMap) => {
                                    if (searchShowing(listStoreMap, input)) {
                                      return (
                                        <div
                                          onClick={() => {
                                            setListStore(listStoreMap);
                                            //selectedCard = listStoreMap;
                                            openItemModal();
                                          }}
                                        >
                                          <Grid item={true}>
                                            <ObjectCell
                                              type={'store'}
                                              name={listStoreMap.name}
                                              description={listStoreMap.description}
                                              isMobile={isMobile}
                                              handle={listStoreMap.storeHandle}
                                              image={listStoreMap.images[0]}
                                              width={'55vw'}
                                              verified={listStoreMap.verified}
                                              sustainabilityBadges={
                                                store.sustainabilityMetrics
                                              }
                                              entity={listStoreMap}
                                            />
                                          </Grid>
                                        </div>
                                      );
                                    }
                                    return true;
                                  })}
                                  <div className={styles.centered}>
                                    <span className={`${styles.addItemButton} ${styles.hoverEffect}`} onClick={() => sendToAddItemFromStore(section.id)}>
                                      {i18next.t('storepage.add_item')}
                                    </span>
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        );
                      }
                      return true
                    })}
                    <div className={styles.itemList}>
                      {sortList(sections.sections.find(obj => obj.uncategorized === true).itemList).map((itemMap) => {
                        if (
                          itemMap.available &&
                          searchShowing(itemMap, input)
                        ) {
                          return (
                            <div
                              key={itemMap.itemHandle}
                              onClick={() => {
                                setItemWithMetaTags(itemMap);
                                //selectedCard = itemMap;
                                openItemModal();
                              }}
                            >
                              <Grid item={true}>
                                <ObjectCell
                                  type={'item'}
                                  name={itemMap.name}
                                  description={itemMap.description}
                                  price={itemMap.price}
                                  isMobile={isMobile}
                                  image={itemMap.images[0]}
                                  width={'55vw'}
                                  entity={itemMap}
                                />
                              </Grid>
                            </div>
                          );
                        }
                        return true;
                      })}
                      {sortList(sections.sections.find(obj => obj.uncategorized === true).storeList).map((listStoreMap) => {
                        if (searchShowing(listStoreMap, input)) {
                          return (
                            <div
                              onClick={() => {
                                setListStore(listStoreMap);
                                //selectedCard = listStoreMap;
                                openItemModal();
                              }}
                            >
                              <Grid item={true}>
                                <ObjectCell
                                  type={'store'}
                                  name={listStoreMap.name}
                                  description={listStoreMap.description}
                                  isMobile={isMobile}
                                  handle={listStoreMap.storeHandle}
                                  image={listStoreMap.images[0]}
                                  width={'55vw'}
                                  verified={listStoreMap.verified}
                                  sustainabilityBadges={
                                    store.sustainabilityMetrics
                                  }
                                  entity={listStoreMap}
                                />
                              </Grid>
                            </div>
                          );
                        }
                        return true;
                      })}
                      <div className={styles.centered}>
                        <span className={`${styles.addItemButton} ${styles.hoverEffect}`} onClick={() => sendToAddItemFromStore(sections.sections.find(obj => obj.uncategorized === true).id)}>
                          {i18next.t('storepage.add_item')}
                        </span>
                      </div>
                    </div>
                  </div>
                )}</>
              )}
            </Grid>
            {(!isMobile || mobileActiveTab === 'about') && (<Grid xs={isMobile ? 12 : 5} style={{ padding: 20 }}>
              <div
                ref={itemCard}
                id='itemCard'
                style={{
                  display: displayListItemCard,
                  position: 'sticky',
                  top: 0,
                }}
              >
                <ItemCard item={item} viewList={true} />
              </div>
              <div
                id='listStoreCard'
                style={{
                  display: displayListStoreCard,
                  position: 'sticky',
                  top: 0,
                }}
              >
                <StoreCard store={listStore} />
              </div>
              <div
                id='storeCard'
                style={{ display: displayStoreCard, position: 'sticky', top: 0 }}
              >
                <StoreCard store={store} />
              </div>
            </Grid>)}
          </Grid>
        </Grid>
      )}
      <Modal
        isOpen={itemModalIsOpen}
        onRequestClose={closeItemModal}
        contentLabel='Item information'
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            maxWidth: isMobile ? '90%' : '50%',
            maxHeight: isMobile ? '60%' : '70%',
            margin: 'auto',
            borderRadius: '15px',
          },
        }}
      >
        <div className='p-2 pt-5'>
          <ItemCard item={item} viewList={false} />
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(Store);
