import React from 'react';
import ImageUploader from 'react-images-upload';
import Box from '@material-ui/core/Box';
import IAddStore from '../../../types/add/IAddStore';
import IAddList from '../../../types/add/IAddList';
import IAddItem from '../../../types/add/IAddItem';

const IconlessImagePicker = ({
  setEntity,
}: {
  setEntity:
    | React.Dispatch<React.SetStateAction<IAddItem>>
    | React.Dispatch<React.SetStateAction<IAddStore>>
    | React.Dispatch<React.SetStateAction<IAddList>>;
}) => {

  const onImageChange = (files: File[], _: string[]) => {
    setEntity((prevStore: any) => ({
      ...prevStore,
      images: files,
    }));
  };

  return (
    <>
      <Box>
        <ImageUploader
          withIcon={false}
          withPreview={true}
          buttonText='Choose images'
          onChange={onImageChange}
          imgExtension={['.jpg', '.gif', '.png']}
          maxFileSize={5242880}
        />
      </Box>
    </>
  );
};


export default React.memo(IconlessImagePicker);
