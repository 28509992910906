import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Modal from 'react-modal';
import {getStoreSustainabilityBadgesById} from 'stocateConstants';
import SustainabilityBadge from './SustainabilityBadge';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const StoreSustainabilityBadgesGroup = ({ branchesNumber, employeesNumber, mealCare, bipocOwned, womenOwned, queerOwned, handicapFriendly, communityEngage, waste, modal }) => {
    const styles = useStyles();
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const customStyles = {
        overlay: {
          zIndex: '7',
          backgroundColor: 'rgba(220, 220, 220, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          top: '10px',
          height: 'auto',
          padding: '1%',
          position: 'relative',
          maxWidth: '500px',
          margin: '15% 27%',
          borderRadius: '6%',
          justifyContent: 'center',
          fontFamily: 'Roboto,sans-serif'
        }
    }

    function openModal() {
      setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
    }
    const badges = getStoreSustainabilityBadgesById(branchesNumber, employeesNumber, mealCare, bipocOwned, womenOwned, queerOwned, handicapFriendly, communityEngage, waste);
    const badgeDivs = <>{badges.map((badgeData, i) => <SustainabilityBadge key={i} badge={badgeData} />)}</>;

    if (!modal)
        return badgeDivs;

    return <>
        <div data-cy={`toggleSusModal-${branchesNumber}${employeesNumber}`} onClick={() => {openModal();}} style={{ cursor: 'pointer' }}>
            {badgeDivs}
        </div>
        {modal &&
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <div className={styles.modalHeader} data-cy='susBadgeModalTitle'>
                    {i18next.t('sustainability_badges_modal.title')}
                </div>
                <Grid container={true} direction='column' className={styles.columnContainer}>
                    {badges.map((badgeData, index) => {
                        return <Grid item={true} container direction='row' key={index} className={styles.rowContainer}>
                            <SustainabilityBadge badge={badgeData} />
                            <div>
                                <div className={styles.badgeLabel}>{badgeData.desc}{':'}</div>
                                <div>{badgeData.label}</div>
                            </div>
                        </Grid>;
                    })}
                </Grid>
            </Modal>
        }
    </>
}

const useStyles = makeStyles((theme) => ({
    badgeLabel: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: 14
    },
    rowContainer: {
        marginBottom: 10,
    },
    columnContainer: {
        padding: '1em'
    },
    modalHeader: {
        textAlign: 'center',
        fontSize: 24,
        fontWeight: 'bold',
    },
}));

StoreSustainabilityBadgesGroup.propTypes = {
    badgeIds: PropTypes.shape({
        branchesNumber: PropTypes.number,
        employeesNumber: PropTypes.number,
        mealCare: PropTypes.number,
        bipocOwned: PropTypes.number,
        womenOwned: PropTypes.number,
        queerOwned: PropTypes.number,
        handicapFriendly: PropTypes.number,
        communityEngage: PropTypes.number,
        waste: PropTypes.number
    }),
    modal: PropTypes.bool
}

StoreSustainabilityBadgesGroup.defaultProps = {
    badgeIds: null,
    modal: false
}

export default StoreSustainabilityBadgesGroup;
