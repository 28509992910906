import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  IDeleteDialogContextValue,
  IDeleteDialogState,
} from './IDeleteDialogContext';
import { makeStyles } from '@material-ui/core';

const defaultContextValue: IDeleteDialogContextValue = {
  openDeleteDialog: (t, w, o) => {
    // empty
  },
};

const defaultState: IDeleteDialogState = {
  open: false,
  title: '',
  specificWarning: '',
  onConfirmClick: () => {
    // empty
  },
};

const DeleteDialogContext =
  createContext<IDeleteDialogContextValue>(defaultContextValue);
DeleteDialogContext.displayName = 'DeleteDialogContext';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    '& .MuiDialog-paper': {
      padding: '1em',
    },
  },
  dialogBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.palette.common.white,
    background: theme.palette.common.black,
    width: '6em',
  },
}));

const DeleteDialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [dialogState, setDialogState] =
    useState<IDeleteDialogState>(defaultState);
  const classes = useStyles();
  const { t } = useTranslation();

  const openDeleteDialog = (
    title: string,
    specificWarning: string,
    onConfirmClick: () => void
  ) =>
    setDialogState({
      open: true,
      title,
      specificWarning,
      onConfirmClick: closeAfter(onConfirmClick),
    });

  const closeDeleteDialog = () =>
    setDialogState((prev) => ({ ...prev, open: false }));

  const closeAfter = (onClick: () => void) => () => {
    onClick();
    closeDeleteDialog();
  };

  return (
    <DeleteDialogContext.Provider value={{ openDeleteDialog }}>
      {children}
      <Dialog
        open={dialogState.open}
        onClose={closeDeleteDialog}
        aria-labelledby='delete-dialog-title'
        aria-describedby='delete-dialog-description'
        className={classes.dialogRoot}
        style={{ textAlign: 'center' }}
      >
        <DialogTitle id='delete-dialog-title'>
          <Typography
            variant='h5'
            component='p'
            style={{ fontWeight: 'bolder' }}
          >
            {dialogState.title}
          </Typography>
        </DialogTitle>
        <DialogContent id='delete-dialog-description'>
          <Typography>{dialogState.specificWarning}</Typography>
          <Typography>{t('delete_modal.cannotbeundone')}</Typography>
          <Typography>{t('delete_modal.areyousure')}</Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            variant='contained'
            onClick={closeDeleteDialog}
            className={classes.dialogBtn}
          >
            {t('delete_modal.cancel')}
          </Button>
          <Button
            variant='contained'
            onClick={dialogState.onConfirmClick}
            autoFocus={true}
            className={classes.dialogBtn}
            data-cy='deleteDialogConfirm'
          >
            {t('delete_modal.confirmation')}
          </Button>
        </DialogActions>
      </Dialog>
    </DeleteDialogContext.Provider>
  );
};

const useDeleteDialog = () => useContext(DeleteDialogContext);

export { useDeleteDialog, DeleteDialogProvider };
