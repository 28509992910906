import { useMutation } from 'react-query';
import { onlineAPI, listEndpoints } from '../stocateConstants';
import { checkLocalStorageForToken } from '../api/CommonApi';

import IAddList from '../types/add/IAddList';
import IList from '../types/dto/list';
import { FetchError } from '../types/common';

interface UseAddListVariables {
  list: IAddList;
}

const useAddList = () => {
  return useMutation<IList, FetchError, UseAddListVariables>(
    async ({ list }: { list: IAddList }) => {
      const response = await fetch(onlineAPI + listEndpoints, {
        method: 'POST',
        body: JSON.stringify(list),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
        },
      });
      const data = await response.json();
      if (response.ok) return data;
      else throw new FetchError(response.status, data);
    }
  );
};

export default useAddList;
