import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import HttpsRedirect from 'react-https-redirect';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { color } from 'stocateConstants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: color.stocateDarkBlue,
      dark: color.gray,
    },
    secondary: {
      main: color.stocateBlue,
      dark: color.stocateDarkGreen,
    },
  },
});

ReactDOM.render(
  <HttpsRedirect>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
  </HttpsRedirect>,
  document.getElementById('root')
);
