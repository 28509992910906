import { useQuery } from 'react-query';
import { onlineAPI, sortList, storesEndpoints } from '../stocateConstants';
import { checkLocalStorageForToken } from '../api/CommonApi';
import IStore from '../types/dto/store';
import { StocateQuery } from '../utils/queryClient';
import { FetchError } from '../types/common';

const useStores = () => {
  const query = useQuery<IStore[], FetchError>(
    StocateQuery.STORES,
    async ({ signal }) => {
      try {
        const response = await fetch(`${onlineAPI}${storesEndpoints}`, {
          signal,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: checkLocalStorageForToken().exists
              ? `Bearer ${checkLocalStorageForToken().token}`
              : '',
          },
        });
        const data = await response.json();
        if (response.ok) return sortList(data);
        else throw new FetchError(response.status, data);
      } catch (error) {
        if (!signal?.aborted) throw error;
      }
    }
  );

  return {
    ...query,
    data: query.data ?? [], // use empty array until data arrives
  };
};

export default useStores;
