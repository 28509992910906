import React, { useEffect, createContext, useContext, useReducer } from 'react';
import jwt_decode from 'jwt-decode';

import { checkLocalStorageForToken } from '../api/CommonApi';
import { TOKEN_REFRESH_AFTER } from '../stocateConstants';
import { validate, signingOut } from '../api/UserApi';
import { queryClient, StocateQuery } from '../utils/queryClient';
import useTesterLevels from '../hooks/useTesterLevels';

import IUser from '../types/dto/user';
import {
  IAuthState,
  IAuthAction,
  IDecodedToken,
  IAuthContextValue,
} from './IAuthContext';

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const initialContextValue = {
  state: initialState,
  dispatch: () => {
    // empty
  },
};

const AuthContext = createContext<IAuthContextValue>(initialContextValue);
AuthContext.displayName = 'AuthContext';

const emptyCallback = (status: number, body: string) => {
  // Do nothing
};

const AuthContextReducer = (state: IAuthState, action: IAuthAction) => {
  switch (action.type) {
    case 'UPDATEUSER': {
      const { newToken, ...user } = action.payload;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', newToken);
      // Decode token to get expiry date & save in storage
      const decodedToken: IDecodedToken = jwt_decode(newToken);
      localStorage.setItem('tokenExpiry', `${decodedToken.exp}`);
      localStorage.setItem('tokenReceived', `${decodedToken.iat}`);

      return {
        ...state,
        isAuthenticated: true,
        user,
        newToken,
      };
    }
    case 'LOADUSER': {
      const { token, user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
        token,
      };
    }
    case 'SIGNIN': {
      const { token, ...user } = action.payload;
      localStorage.clear();
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);

      try {
        // Decode token to get expiry date & save in storage
        const decodedToken: IDecodedToken = jwt_decode(token);
        localStorage.setItem('tokenExpiry', `${decodedToken.exp}`);
        localStorage.setItem('tokenReceived', `${decodedToken.iat}`);
      } catch (error) {
        // Error when decoding token
      }

      return {
        ...state,
        isAuthenticated: true,
        user,
        token,
      };
    }
    case 'SIGNOUT': {
      signingOut(state.token, emptyCallback, emptyCallback);
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };
    }
    case 'DELETEUSER': {
      localStorage.clear();
      window.location.href = '/signup'
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };
    }
    default:
      return state;
  }
};

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(AuthContextReducer, initialState);
  const { refetch: refetchLevelsData } = useTesterLevels();

  const successCallback = (body: IUser) => {
    const { newToken, ...user } = body;
    dispatch({
      type: 'SIGNIN',
      payload: {
        ...user,
        token: newToken,
      },
    });
  };

  useEffect(() => {
    const { exists, token, user, expiry, receivedAt } =
      checkLocalStorageForToken();
    // Return if local storage was empty or invalid
    if (!exists || user == null || expiry == null || receivedAt == null) return;

    const dateNow = new Date();
    // JWT dates are in seconds since current epoch (dateNow.getTime() in milliseconds)
    if (expiry >= Math.floor(dateNow.getTime() / 1000)) {
      dispatch({
        type: 'LOADUSER',
        payload: {
          user,
          token,
        },
      });
      // Refresh the user's token if it's been past a week from its issued date
      if (
        receivedAt + TOKEN_REFRESH_AFTER <
        Math.floor(dateNow.getTime() / 1000)
      )
        validate(token, successCallback, emptyCallback);
    }
  }, []);

  useEffect(() => {
    if (!state.isAuthenticated) {
      queryClient.setQueryData(StocateQuery.TESTER_LEVELS, []);
      return;
    } else refetchLevelsData();
  }, [refetchLevelsData, state.isAuthenticated]);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
