import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core'
import { addResizingListener } from '../../../utils/Responsiveness';
import Search from '../../../components/reusable/Search';
import CollapsibleCard from '../../reusable/CollapsibleCard';
import ObjectCell from '../../reusable/ObjectCell';
import { useHistory } from 'react-router-dom';
import styles from './List.module.css';
import { withTranslation } from 'react-i18next';
import StoreCard from '../store/StoreCard';
import ItemCard from '../store/ItemCard';
import { listEndpoints, paths, searchShowing, defaultPreviewLinkImage } from 'stocateConstants';
import { getListSectionWithId, getListWithId } from '../../../api/ListApi';
import ListCard from '../library/listCard/ListCard';
import ApiErrorView from '../../../components/reusable/ApiErrorView';
import i18next from 'i18next';
import { sortList, sort2Lists, mergingLists } from '../../../stocateConstants'
import { sendPageView } from '../../../utils/GoogleAnalytics';
import Nav from 'react-bootstrap/Nav';

var selectedCard = null;
var displayCard = null;
var displayListItemCard = null;
var displayListStoreCard = null;
var displayListCard = null;
showStoreCard();

function showStoreCard(){
    displayListItemCard = 'none';
    displayListStoreCard = 'none';
    displayListCard = 'block';
}

function showListStoreCard(){
    displayListItemCard = 'none';
    displayListStoreCard = 'block';
    displayListCard = 'none';
}

function showListItemCard(){
    displayListItemCard = 'block';
    displayListStoreCard = 'none';
    displayListCard = 'none';
}

var count = 0; //to ensure that the page rerender once when checking the url

function List(props) {
    //Indicate not mobile
    const [isMobile, setIsMobile] = useState(false);
    //Set store to Json fron GET request
    const [list, setList] = useState();
    const [sections, setSections] = useState();
    //Set items to all items from store
    const [item, setItem] = useState(null);
    const [listStore, setListStore] = useState(null);
    const [input, setInput] = useState('');

    // Error handling
    const [apiSuccess, setApiSuccess] = useState(true);
    const [code, setCode] = useState('');
    const [errorText, setErrorText] = useState('');
    
    const history = useHistory();
    const query = new URLSearchParams(props.location.search);
    var linkItem = query.get('item');
    var linkStore = query.get('store');

    var currentURL = window.location.href;
    var currentURLSplit = currentURL.split('/');
    var currentList = currentURLSplit[currentURLSplit.length-1];
    if(currentList.includes('?'))
        currentList = currentList.split('?')[0];
    
    function queryRequest(){
        if(linkItem !== null && linkItem !== ''){
            for (let i=0; i<sections.length; i++){
                for(let x=0; x<sections[i].itemList.length; x++){
                    if(linkItem === sections[i].itemList[x].itemHandle){
                        showListItemCard();
                        displayCard = sections[i].itemList[x];
                        setItem(sections[i].itemList[x]);
                        return;
                    }
                }
            }
        }else{
            if(linkStore !== null && linkStore !== ''){
                for (let i=0; i<sections.length; i++){
                    for(let x=0; x<sections[i].storeList.length; x++){
                        if(linkStore === sections[i].storeList[x].storeHandle){
                            showListStoreCard();
                            displayCard = sections[i].storeList[x];
                            setListStore(sections[i].storeList[x]);
                            return;
                        }
                    }
                }
            }
        }
    }

    function showCard(){
        let urlPush = '';
        
        if(displayListCard === 'none'){
            if(selectedCard === displayCard){
                showStoreCard();
                displayCard = null;
                urlPush = ''
            }else{
                if(selectedCard.itemHandle == null){
                    showListStoreCard();
                    urlPush = '?store=' + selectedCard.storeHandle
                }else{
                    showListItemCard();
                    urlPush = '?item=' + selectedCard.itemHandle
                }
                displayCard = selectedCard;
            }
        }else{
            if(selectedCard.itemHandle == null){
                showListStoreCard();
                urlPush = '?store=' + selectedCard.storeHandle
            }else{
                showListItemCard();
                urlPush = '?item=' + selectedCard.itemHandle
            }
            displayCard = selectedCard;
        }
        history.push('/' + listEndpoints + list.id + urlPush);
    }

    const updateMetaTags = React.useCallback((listInfo) => {
        const metaImage = listInfo.images.length > 0 ? listInfo.images[0] : defaultPreviewLinkImage;
        const metaDescription = listInfo.description.length !== 0 
            ? (listInfo.description.length > 75 
                ? listInfo.description.slice(0, 75) + '...' 
                : listInfo.description) 
            : `Explore ${listInfo.name} on Stocate!`;

        document.querySelector('meta[name="og:title"]')?.setAttribute('content', listInfo.name);
        document.querySelector('meta[name="og:image"]')?.setAttribute('content', metaImage);
        document.querySelector('meta[name="og:url"]')?.setAttribute('content', currentURL);
        document.querySelector('meta[name="og:description"]')?.setAttribute('content', metaDescription);
    }, [currentURL])

    const successCallbackList = React.useCallback((body) => {
		setList(body);
        updateMetaTags(body);
    }, [updateMetaTags])
  
    useEffect(() => {
        addResizingListener(setIsMobile);
        getListWithId(currentList, successCallbackList, failCallback)
        getListSectionWithId(currentList, successCallbackListSection, failCallback)
        sendPageView(paths.LIST);
    }, [currentList, successCallbackList]);

    const failCallback = (statusCode, body) => {
        setApiSuccess(false)
        setCode(statusCode)
        if(statusCode === 401)
            setErrorText(i18next.t('errorCode.library_401'))
        else if(statusCode === 404)
            setErrorText(i18next.t('errorCode.listView_404'))
        else
            setErrorText(i18next.t('errorCode.unexpected'))
    }

    const successCallbackListSection = (body) => {
        setSections(body.sections);
    }

    // on mobile only show content OR about at one time
    const [mobileActiveTab, setMobileActiveTab] = useState('content');

    const handleMobileTabClick = (selectedKey) => {
        setMobileActiveTab(selectedKey);
    };

    // !!! TODO: Remove this hack
    if(count === 0){
        if(sections != null && list != null){
            count += 1
            queryRequest()
        }
    } 
    return (
        <>
            {sections == null || list == null ? (
                <Grid
                    container
                    justify='center'
                    alignItems='center'
                    style={{minHeight:700}}
                >
                    {apiSuccess?
                        <CircularProgress color='primary' />
                    :
                        <ApiErrorView
                            code = {code}
                            text = {errorText}
                        />
                    }
                </Grid>
            ):(
                <>
                    {isMobile && (
                        <div style={{ position: 'sticky', top: '75px', zIndex: '100' }}>
                            <Nav variant='tabs' fill defaultActiveKey='content'>
                                <Nav.Item activeClassName={styles.activeTab} className={styles.inactiveTab}>
                                    <Nav.Link eventKey='content' data-cy='contentTab' onClick={() => handleMobileTabClick('content')} style={{ color: '#0b86a2' }}>{i18next.t('storepage.content')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item activeClassName={styles.activeTab} className={styles.inactiveTab}>
                                    <Nav.Link eventKey='about' data-cy='aboutTab' onClick={() => handleMobileTabClick('about')} style={{ color: '#0b86a2' }}>{i18next.t('storepage.about')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div style={{ height: '2px', backgroundColor: '#dee2e6' }}></div>
                        </div>
                    )}
                    <Grid
                        container
                        direction='row'
                        justify='space-between'
                    >
                        {(!isMobile || mobileActiveTab === 'content')  && 
                            <Grid 
                                item
                                xs={isMobile ? 12 : 7}
                                style={{padding: '10px'}}
                            >      
                                <div className={styles.storeTitle}>
                                    {list.name}
                                </div>
                                <div className={styles.searchBar} style={{padding:'10px'}}>
                                    <Search
                                        setInput={setInput}	
                                        fullWidth={true}
                                    />
                                </div>
                                <div id = 'content' style={{overflow: 'auto', maxHeight: 700}}>
                                    {sortList(sections).map(section =>{
                                        return(
                                            <div>
                                                <CollapsibleCard
                                                    title_left = {section.name}
                                                    startOpen={sections.length === 1}
                                                    borderRadius = {10}
                                                    body_padding = {'0px'}
                                                    body_content={
                                                        <div className={styles.itemList}>
                                                            {sort2Lists(mergingLists(section.itemList, section.storeList))
                                                            .map(itemMap => {
                                                                if (itemMap.item && itemMap.item.available && searchShowing(itemMap.item, input)){
                                                                    return(
                                                                        <div onClick = {()=>{setItem(itemMap.item); selectedCard = itemMap.item; showCard();}}>
                                                                            <Grid item={true}>
                                                                                <ObjectCell
                                                                                    type={'item'}
                                                                                    name={itemMap.item.name}
                                                                                    description={itemMap.item.description}
                                                                                    price={itemMap.item.price}
                                                                                    isMobile={isMobile}
                                                                                    image={itemMap.item.images[0]}
                                                                                    width = {'55vw'}
                                                                                    entity = {itemMap.item}
                                                                                />
                                                                            </Grid>
                                                                        </div>
                                                                    )
                                                                }
                                                                if (itemMap.store && searchShowing(itemMap.store, input)){
                                                                    return(
                                                                        <div onClick = {()=>{setListStore(itemMap.store); selectedCard = itemMap.store; showCard();}}>
                                                                            <Grid item={true}>
                                                                                <ObjectCell
                                                                                    type={'store'}
                                                                                    name={itemMap.store.name}
                                                                                    description={itemMap.store.description}
                                                                                    isMobile={isMobile}
                                                                                    handle = {itemMap.store.storeHandle}
                                                                                    image={itemMap.store.images[0]}
                                                                                    width = {'55vw'}
                                                                                    verified = {itemMap.store.verified}
                                                                                    sustainabilityBadges = {itemMap.store.sustainabilityMetrics}
                                                                                    entity = {itemMap.store}
                                                                                />
                                                                            </Grid>
                                                                        </div>
                                                                    )
                                                                }
                                                                return true;
                                                            })}
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        )
                                    })}
                                </div> 
                            </Grid>
                        }
                        {(!isMobile || mobileActiveTab === 'about') &&
                            <Grid 
                                item
                                xs={isMobile ? 12 : 5}
                                style={{padding: '10px'}}
                            >
                                <div id = 'itemCard' style={{display: displayListItemCard, position: 'sticky', top: 0}}>
                                    <ItemCard item = {item} viewList = {true}/>
                                </div>
                                <div id = 'listStoreCard' style={{display: displayListStoreCard, position: 'sticky', top: 0}}>
                                    <StoreCard store={listStore} viewList = {true}/>
                                </div>
                                <div id = 'storeCard' style={{display: displayListCard, position: 'sticky', top: 0}}>
                                    <ListCard list={list} viewList = {true}/>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </>
            )}
        </>
    )
}

export default withTranslation()(List);