import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
	checkLength,
	checkHasLetter,
	checkHasNumber,
	checkHasSpecialChar,
} from '../../../utils/Validation';
import { confirmReset } from '../../../api/UserApi'
import { addResizingListener } from '../../../utils/Responsiveness';
import { InitialPasswordResetConfirmationStateBuilder } from '../../../model/PasswordResetConfirmationModel'
import lockIcon from '../../../assets/images/sign-up/lock.png';
import profileIcon from '../../../assets/images/sign-up/profile-black.png';
import lockResetIcon from '../../../assets/images/sign-up/lock-reset.png';

import TextInputField from '../../reusable/TextInputField';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { paths } from '../../../stocateConstants';

function PasswordReset({ match, location }) {
    const {
        state: { isAuthenticated },
        dispatch,
    } = useAuth();
	const { t } = useTranslation();
	const [isMobile, setIsMobile] = useState(false);
	const [userInfo, setUserInfo] = useState(InitialPasswordResetConfirmationStateBuilder())
	const [apiResponse, setApiResponse] = useState({});
	const [open, setOpen] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
	const passwordEmpty = userInfo.data.password.trim() === '';
	const has8Characters = checkLength(userInfo.data.password);
	const hasNumber = checkHasNumber(userInfo.data.password);
	const hasLetter = checkHasLetter(userInfo.data.password);
	const hasSpecialC = checkHasSpecialChar(userInfo.data.password);

	const handleChange = (prop) => (event) => {
		setUserInfo({
			...userInfo,
			data: {
				...userInfo.data,
				[prop]: event.target.value
			}
		});
	};

	const onSubmit = () => {
        setApiResponse({})
		confirmReset(userInfo, setResponseCallback);
		handleNotificationOpen();
	}

	const onCancel = () => {
        setIsCancel(true);
	}

    const setResponseCallback = (status, data) => {
        setApiResponse( data )
        if ( status === 200 ){
            dispatch({
                type: 'SIGNIN',
                payload: data,
            });
        }
    };

	const handleNotificationOpen = () => {
		setOpen(true);
	};

	const handleNotificationClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const errorMessage = () => {
		if (!userInfo.noEmptyField()) {
			return t('resetpwd.warning1')
		}
	}

	useEffect(() => {
        localStorage.clear();
        addResizingListener(setIsMobile);
		const queryParams = queryString.parse(location.search);
		setUserInfo({
			...userInfo,
			data: {
				...userInfo.data,
				email: queryParams.email ? queryParams.email : '',
				token: queryParams.token ? queryParams.token : ''
			}
		});
	}, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (isAuthenticated) {
        return (
            <Redirect
                push={true}
                to={{pathname: paths.PROFILE}}
            />
        );
    }
    if (isCancel) {
        return (
            <Redirect
                push={true}
                to={{pathname: paths.HOME}}
            />
        );
    }

	return (
		<>
			<Grid
				container
				direction='column'
				wrap='nowrap'
				justify='flex-start'
				alignItems='center'
				style={{
					maxHeight: '100%',
					maxWidth: isMobile ? '80vw' : '600px', //50vw',
					top: '40%',
                    left: '50%',
                    position: 'relative',
                    transform: 'translate(-50%, -50%)'
				}}>

				<Grid
					container
					direction='column'
					alignItems='center'
					style={{
						marginTop: 15,
					}}
				>
					<Typography variant='h4' color='secondary'>{t('resetpwd.title')}</Typography>
				</Grid>

				<Grid container={true} spacing={3}
					style={{
						marginTop: 15,
					}}>

					<Grid
					item={true}
					container={true}
					justify='center'
					style={{
						marginTop: 5,
					}}
					>
                        <TextInputField
                            id='email'
                            labelIcon={profileIcon}
                            label={t('resetpwd.username')}
                            type='text'
                            value={userInfo.data.email}
                            onChange={handleChange('email')}
                            xs={12}
                        />
                        <TextInputField
                            id='token'
                            labelIcon={lockIcon}
                            label={t('resetpwd.token')}
                            type='text'
                            value={userInfo.data.token}
                            onChange={handleChange('token')}
                            xs={12}
                        />
                        <TextInputField
                            id='password'
                            labelIcon={lockResetIcon}
                            label={t('resetpwd.password')}
                            value={userInfo.data.password}
                            onChange={handleChange('password')}
                            xs={12}
                            type='password'
                        />
					</Grid>

					<Grid item={true} container={true} direction='column'>
						<Typography variant='subtitle2'>
							{t('resetpwd.passwordRequirement')}
						</Typography>
                        <Grid style={{width:'fit-width', display:'flex', flexWrap: 'wrap',
                            marginRight: '8px'
                        }}> 
                            <Typography color={(has8Characters || passwordEmpty ? 'textPrimary' : 'error')}>
                                {t('resetpwd.passwordRequirement_8character')} &nbsp;
                            </Typography>
                            
                            <Typography color={ (hasNumber || passwordEmpty ? 'textPrimary' : 'error')}>
                                {t('resetpwd.passwordRequirement_oneNumber') } &nbsp;
                            </Typography>
                            
                            <Typography color={(hasLetter || passwordEmpty ? 'textPrimary' : 'error')}>
                                {t('resetpwd.passwordRequirement_oneLetter') } &nbsp;
                            </Typography>
                            <Typography color={(hasSpecialC || passwordEmpty ? 'textPrimary' : 'error')}>
                                {t('resetpwd.passwordRequirement_specialCharacter')}
                            </Typography> 
                        </Grid>
					</Grid>
				</Grid>
                <Grid 
                    style={{
                        marginTop:'30px',
                        display:'flex',
                        gap:'3em',
                        width:'100%',
                        alignItems:'center',
                        flexWrap:'wrap'
                    }}
                >  
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onCancel}
                    >
                        {t('resetpwd.cancelbtn')}
                    </Button>
                
                    <Button
                        variant='contained'
                        color='secondary'
                        disabled={!userInfo.validate()||!hasSpecialC||!hasLetter||!hasNumber||!has8Characters}
                        onClick={onSubmit}
                    >
                        {t('resetpwd.button')}
                    </Button>

                    {!userInfo.validate() && !apiResponse.waiting && <Typography variant='subtitle2' color='secondary'>{errorMessage()}</Typography>}
                                      
                </Grid>
			</Grid>
            
			{apiResponse.id === undefined && apiResponse !== 'failure' && apiResponse.message === undefined &&
				<Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
					<MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='warning'>
						{t('message.processing')}
					</MuiAlert>
				</Snackbar>
			}
			{apiResponse.id !== undefined &&
				<Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
					<MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='success'>
						{t('message.pwdResetSuccess')}
					</MuiAlert>
				</Snackbar>
			}
			{apiResponse.message !== undefined &&
				<Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
					<MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='error'>
						{apiResponse.message}
					</MuiAlert>
				</Snackbar>
			}
			{apiResponse === 'failure' &&
				<Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
					<MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='error'>
						{t('message.pwdResetFail')}
					</MuiAlert>
				</Snackbar>
			}
		</>
	)
}

export default PasswordReset
