import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { socialMediaTypes, socialMediaTypesURLs } from '../../../stocateConstants';
import phoneIcon from '../../../assets/images/socialMedia/phone.webp';
import emailIcon from '../../../assets/images/socialMedia/email.webp';
import facebookIcon from '../../../assets/images/socialMedia/facebook.webp';
import instragramIcon from '../../../assets/images/socialMedia/instagram.webp';
import twitterIcon from '../../../assets/images/socialMedia/twitter.webp';
import openTableIcon from '../../../assets/images/socialMedia/openTable.webp';
import uberEatsIcon from '../../../assets/images/socialMedia/uberEats.webp';
import skipTheDishesIcon from '../../../assets/images/socialMedia/skipTheDishes.webp';
import socialIcon from '../../../assets/images/addPages/social.webp';
import useAddStoreStyles from './SharedAddStoreStyles';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import IAddStore from '../../../types/add/IAddStore';
import PhoneTextField from './PhoneInput';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SectionTitle from '../addPages/SectionTitle';

const AddStoreLinks = ({
  setStore,
  entity,
}: {
  setStore: React.Dispatch<React.SetStateAction<IAddStore>>;
  entity: any;
}) => {
  const classes = useAddStoreStyles();
  const { t } = useTranslation();

  const onContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setStore((prevStore) => ({
      ...prevStore,
      [event.target.name]: event.target.value,
    }));
  };

  const onSocialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.value.trim() === '') {
      setStore((prevStore) => ({
        ...prevStore,
        socialMedia: prevStore.socialMedia.filter(
          (socialMedia) => socialMedia.platform !== event.target.name
        ),
      }));
    } else {
      setStore((prevStore) => {
        const url = event.target.value;
        const parts = url.split('/');

        // The last part after the last '/' will be 'ezz'
        const handle = parts[parts.length - 1];
        const platform = event.target.name;
        if (prevStore.socialMedia.some((s) => s.platform === platform)){
          return {
            ...prevStore,
            socialMedia: prevStore.socialMedia.map((s) =>
              s.platform === platform ? { handle, platform, url: socialMediaTypesURLs.find((obj: any) => obj.platform === platform)?.url } : s
            ),
          };
        }
        else{
          return {
            ...prevStore,
            socialMedia: [...prevStore.socialMedia, { handle, platform, url: socialMediaTypesURLs.find((obj: any) => obj.platform === platform)?.url }],
          };
        }
      });
    }
  };

  const links = [
    {
      type: 'phone',
      label: t('addstore.phone_label'),
      iconSrc: phoneIcon,
      onChange: onContactChange,
      TextComponent: PhoneTextField,
      value: entity.phone,
    },
    {
      type: 'email',
      label: t('addstore.email_label'),
      placeholder: 'E.g. stocate.montreal@gmail.com',
      iconSrc: emailIcon,
      onChange: onContactChange,
      TextComponent: TextField,
      value: entity.email,
    },
    {
      type: socialMediaTypes.FACEBOOK,
      placeholder: 'E.g. facebook.com/stocate',
      iconSrc: facebookIcon,
      onChange: onSocialChange,
      TextComponent: TextField,
      value: entity.socialMedia.find((obj: any) => obj.platform === 'Facebook') !== undefined ? entity.socialMedia.find((obj: any) => obj.platform === 'Facebook').url + entity.socialMedia.find((obj: any) => obj.platform === 'Facebook').handle : '',
    },
    {
      type: socialMediaTypes.INSTAGRAM,
      placeholder: 'E.g. instagram.com/sto.cate',
      iconSrc: instragramIcon,
      onChange: onSocialChange,
      TextComponent: TextField,
      value: entity.socialMedia.find((obj: any) => obj.platform === 'Instagram') !== undefined ? entity.socialMedia.find((obj: any) => obj.platform === 'Instagram').url + entity.socialMedia.find((obj: any) => obj.platform === 'Instagram').handle : '',
    },
    {
      type: socialMediaTypes.TWITTER,
      placeholder: 'E.g. twitter.com/sto_cate',
      iconSrc: twitterIcon,
      onChange: onSocialChange,
      TextComponent: TextField,
      value: entity.socialMedia.find((obj: any) => obj.platform === 'Twitter') !== undefined ? entity.socialMedia.find((obj: any) => obj.platform === 'Twitter').url + entity.socialMedia.find((obj: any) => obj.platform === 'Twitter').handle : '',
    },
    {
      type: socialMediaTypes.OPEN_TABLE,
      placeholder: 'E.g. opentable.ca/r/example',
      iconSrc: openTableIcon,
      onChange: onSocialChange,
      TextComponent: TextField,
      value: entity.socialMedia.find((obj: any) => obj.platform === 'OpenTable') !== undefined ? entity.socialMedia.find((obj: any) => obj.platform === 'OpenTable').url + entity.socialMedia.find((obj: any) => obj.platform === 'OpenTable').handle : '',
    },
    {
      type: socialMediaTypes.UBER_EATS,
      placeholder: 'E.g. ubereats.com/ca/store/example/XXXX',
      iconSrc: uberEatsIcon,
      onChange: onSocialChange,
      TextComponent: TextField,
      value: entity.socialMedia.find((obj: any) => obj.platform === 'UberEats') !== undefined ? entity.socialMedia.find((obj: any) => obj.platform === 'UberEats').url + entity.socialMedia.find((obj: any) => obj.platform === 'UberEats').handle : '',
    },
    {
      type: socialMediaTypes.SKIP_THE_DISHES,
      placeholder: 'E.g. skipthedishes.com/example',
      iconSrc: skipTheDishesIcon,
      onChange: onSocialChange,
      TextComponent: TextField,
      value: entity.socialMedia.find((obj: any) => obj.platform === 'SkipTheDishes') !== undefined ? entity.socialMedia.find((obj: any) => obj.platform === 'SkipTheDishes').url + entity.socialMedia.find((obj: any) => obj.platform === 'SkipTheDishes').handle : '',
    },
  ];

  return (
    <>
      <SectionTitle iconSrc={socialIcon} title={t('storepage.links')} />
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: `min-content auto`,
          rowGap: '0.5em',
          columnGap: '0.5em',
          alignItems: 'center',
        }}
        className={classes.root}
      >
        {links.map(
          ({ label, TextComponent, type, iconSrc, ...restOfProps }) => (
            <Fragment key={type}>
              <Grid
                container={true}
                wrap='nowrap'
                alignItems='center'
                spacing={1}
              >
                <Grid item={true}>
                  <img src={iconSrc} alt='' style={{ width: '1.5em' }} />
                </Grid>
                <Grid item={true}>
                  <Typography style={{ whiteSpace: 'nowrap' }}>
                    {label ?? type}
                  </Typography>
                </Grid>
              </Grid>

              <TextComponent
                variant='outlined'
                size='small'
                fullWidth={true}
                name={type}
                {...restOfProps}
              />
            </Fragment>
          )
        )}
      </Box>
    </>
  );
};

export default React.memo(AddStoreLinks);
