import React from 'react';

import earthIcon from '../../../assets/images/addPages/earth.png';
import SusBadgeRadio from './SusBadgeRadio';
import SectionTitle from '../addPages/SectionTitle';

import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SusBadgeList from '../../../types/add/ISusBadgeList';
import IAddStore from '../../../types/add/IAddStore';
import IAddItem from '../../../types/add/IAddItem';

const SustainabilityPicker = ({
  setEntity,
  badgeLists,
  entity,
  titleText
}: {
  setEntity: React.Dispatch<React.SetStateAction<IAddStore>> | React.Dispatch<React.SetStateAction<IAddItem>>;
  badgeLists: SusBadgeList[];
  entity: any;
  titleText: string;
}) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  return (
    <>
      <SectionTitle
        iconSrc={earthIcon}
        title={titleText}
      />
      <Grid container={true} justify='space-around' spacing={2}>
        {badgeLists.map((badgeList, i) => {
          return (
            <Grid
            key={`suspicker-${i}`}
            item={true}
            xs={isMobile ? 12 : false}
            style={{ marginRight: isMobile ? '1em' : '' }}
          >
              <SusBadgeRadio entity={entity} badgeList={badgeList} setEntity={setEntity} />
            </Grid>
          )

        })}
      </Grid>
    </>
  );
};

export default React.memo(SustainabilityPicker);