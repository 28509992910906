import React from 'react';

import QRappStore from '../../../assets/images/qrcodes/appStore.png';
import QRplayStore from '../../../assets/images/qrcodes/playStore.png';
import { playStore, appleStore } from '../../../stocateConstants';

import { makeStyles } from '@material-ui/core/styles';
import MuiLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  qrImg: {
    height: '150px',
  },
  appBox: {
    marginRight: '1em',
    marginLeft: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  label: {
    fontWeight: 'bolder',
    whiteSpace: 'pre-wrap',
  },
}));

const DownloadInstructions = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container={true} justify='space-around' wrap='nowrap'>
      <Grid item={true}>
        <MuiLink
          href={appleStore}
          className={classes.appBox}
          variant='caption'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Box style={{ textAlign: 'center' }}>
            <img src={QRappStore} className={classes.qrImg} alt='' />
          </Box>
          {t('pilot_testing.download_instructions.appstore')}
        </MuiLink>
      </Grid>
      <Grid item={true}>
        <MuiLink
          href={playStore}
          className={classes.appBox}
          variant='caption'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Box style={{ textAlign: 'center' }}>
            <img src={QRplayStore} className={classes.qrImg} alt='' />
          </Box>
          {t('pilot_testing.download_instructions.google_play')}
        </MuiLink>
      </Grid>
    </Grid>
  );
};

export default DownloadInstructions;
