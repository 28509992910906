import { useQuery } from 'react-query';
import { onlineAPI, levelsUserEndpoint } from '../stocateConstants';
import { checkLocalStorageForToken } from '../api/CommonApi';
import ILevelRecordUser from '../types/dto/levelRecordUser';
import { FetchError } from '../types/common';
import { StocateQuery } from '../utils/queryClient';
import { useAuth } from '../context/AuthContext';

export const fetchLevels = async ({ signal }: { signal?: AbortSignal }) => {
  try {
    const response = await fetch(`${onlineAPI}${levelsUserEndpoint}`, {
      signal,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: checkLocalStorageForToken().exists
          ? `Bearer ${checkLocalStorageForToken().token}`
          : '',
      },
    });
    const data = await response.json();
    if (response.ok) return data;
    else throw new FetchError(response.status, data);
  } catch (error) {
    if (!signal?.aborted) throw error;
  }
};

const useTesterLevels = () => {
  const {
    state: { isAuthenticated },
  } = useAuth();

  const options = {
    enabled: isAuthenticated,
  };

  const query = useQuery<ILevelRecordUser[], FetchError>(
    StocateQuery.TESTER_LEVELS,
    fetchLevels,
    options
  );

  const data = query.data ?? []; // use empty array until data arrives

  return {
    ...query,
    data,
    isTester: data.length > 0,
    userNewestLevel: Math.max(...data.map((level) => level.levelId)) + 1,
  };
};

export default useTesterLevels;
