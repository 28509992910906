import { useMutation } from 'react-query';
import { onlineAPI, storesEndpoints } from '../stocateConstants';
import { checkLocalStorageForToken } from '../api/CommonApi';

import IAddStore from '../types/add/IAddStore';
import IStore from '../types/dto/store';
import { FetchError } from '../types/common';

interface UseAddStoreVariables {
  store: IAddStore;
}

export const useAddStore = () => {
  return useMutation<IStore, FetchError, UseAddStoreVariables>(
    async ({ store }: { store: IAddStore }) => {
      const response = await fetch(onlineAPI + storesEndpoints, {
        method: 'POST',
        body: JSON.stringify(store),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
        },
      });
      const data = await response.json();
      if (response.ok) return data;
      else throw new FetchError(response.status, data);
    }
  );
};

export const useEditStore = () => {
  return useMutation<IStore, FetchError, UseAddStoreVariables>(
    async ({ store }: { store: IAddStore }) => {
      const response = await fetch(onlineAPI + storesEndpoints + store.id, {
        method: 'PUT',
        body: JSON.stringify(store),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
        },
      });
      const data = await response.json();
      if (response.ok) return data;
      else throw new FetchError(response.status, data);
    }
  );
};