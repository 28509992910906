import { useMutation } from 'react-query';
import { onlineAPI, listEndpoints } from '../stocateConstants';
import { checkLocalStorageForToken } from '../api/CommonApi';
import { FetchError } from '../types/common';

interface UseCopyListVariables {
  listId: number;
  libraryId: number;
}

// Remember to call queryClient.invalidateQueries(StocateQuery.MYLISTS/LISTS);
// inside the "onSuccess" callback when using the mutate method from this hook.
// I decided to not call invalidateQueries here, because it would unmount
// the menu items and would prevent them from showing the success/error messages
const useCopyList = () => {
  return useMutation<unknown, FetchError, UseCopyListVariables>(
    async ({ listId, libraryId }: UseCopyListVariables) => {
      const response = await fetch(
        `${onlineAPI}${listEndpoints}${listId}/library/${libraryId}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: checkLocalStorageForToken().exists
              ? `Bearer ${checkLocalStorageForToken().token}`
              : '',
          },
        }
      );
      const data = await response.json();
      if (response.ok) return data;
      else throw new FetchError(response.status, data);
    }
  );
};

export default useCopyList;
