import React from 'react';
import { Control, FieldValues, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

interface LevelInputTextProps {
  name: string;
  control: Control<FieldValues, object> | undefined;
  label: string;
}

const LevelInputText = ({ name, control, label }: LevelInputTextProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={{ required: false }}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth={true}
            id={name}
            multiline={true}
            label={t('pilot_testing.feedback_placeholder')}
            rows={2}
            value={value}
            onChange={onChange}
            variant='outlined'
            style={{ marginBottom: '1em' }}
            data-cy={`input-task-${name}`}
          />
        )}
      />
    </>
  );
};

export default LevelInputText;
