import { useMutation } from 'react-query';
import { onlineAPI, listEndpoints } from '../stocateConstants';
import { checkLocalStorageForToken } from '../api/CommonApi';
import { FetchError } from '../types/common';

interface UseAddToMyListsVariables {
  listId: number;
  libraryId: number;
}

const useAddToMyLists = () => {
  return useMutation<unknown, FetchError, UseAddToMyListsVariables>(
    async ({ listId, libraryId }: UseAddToMyListsVariables) => {
      const response = await fetch(
        `${onlineAPI}${listEndpoints}${listId}/library/${libraryId}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: checkLocalStorageForToken().exists
              ? `Bearer ${checkLocalStorageForToken().token}`
              : '',
          },
        }
      );
      const data = await response.json();
      if (response.ok) return data;
      else throw new FetchError(response.status, data);
    }
  );
};

export default useAddToMyLists;
