import React, { useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { addFeedback } from '../../../api/FeedbackApi.js';
import { InitialFeedbackStateBuilder } from '../../../model/FeedbackModel';
import i18next from 'i18next';
import { useTranslation, withTranslation } from 'react-i18next';
import TextInputField from '../../reusable/TextInputField';
import { color } from './../../../stocateConstants';
import profileIcon from '../../../assets/images/sign-up/profile-black.png';
import chatboxIcon from '../../../assets/images/sign-up/chatbox.png';
import emailIcon from '../../../assets/images/sign-up/email.png';
import { useGlobalState } from './SharedSeverity';
import IconlessImagePicker from '../addPages/IconlessImagePicker';
import ImagePicker from '../addPages/ImagePicker';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert'

function Feedback(props) {
  // Context variable for accessing user info
  const { state: authState } = useAuth();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState('waiting');

  // User information
  const userId = authState.user !== null ? authState.user.id : null;
  const userRole = authState.user?.role;
  const userFullName =
    authState.user !== null
      ? `${authState.user.firstName} ${authState.user.lastName}`
      : null;
  const userEmail = authState.user !== null ? authState.user.email : null;
  const [feedbackInfo, setFeedbackInfo] = useState(
    InitialFeedbackStateBuilder(window.location.href)
  );

  // eslint-disable-next-line
  const [severity, setSeverity] = useGlobalState('severity');

  const handleNotificationOpen = () => {
      setOpen(true);
  };

  const handleNotificationClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setOpen(false);
  };

  const handleChange = (prop) => (event) => {
    if (userId != null) {
      setFeedbackInfo({
        ...feedbackInfo,
        data: {
          ...feedbackInfo.data,
          name: userFullName,
          email: userEmail,
          userId: userId,
          phone: '',
          [prop]: event.target.value,
        },
      });
    } else {
      setFeedbackInfo({
        ...feedbackInfo,
        data: {
          ...feedbackInfo.data,
          phone: '',
          [prop]: event.target.value,
        },
      });
    }
  };

  const onSubmit = (prop) => {
    setApiResponse('waiting');
    addFeedback(feedbackInfo, feedbackInfo.images, successCallback, failCallback);
    handleNotificationOpen();
  };

  const successCallback = (body) => {
    setFeedbackInfo(InitialFeedbackStateBuilder(window.location.href));
    setApiResponse('success');
    setTimeout(function () {
      props.hide();
    }, 3000);
  };

  const failCallback = (statusCode, body) => {
    setApiResponse('failure');
  };

  return (
    <>
      <Grid
        container
        direction='column'
        wrap='nowrap'
        justify='flex-start'
        alignItems='center'
        style={{
          minHeight: '60vh',
          maxHeight: '50vh',
        }}
      >
        <Grid
          container
          direction='column'
          alignItems='center'
          style={{
            marginTop: 15,
          }}
        >
          <Typography variant='h4'>{i18next.t('feedback.title')}</Typography>
          <Typography variant='h5'>
            {i18next.t('feedback.label_text')}
          </Typography>
        </Grid>
        <Grid
          container={true}
          spacing={0}
          style={{
            marginTop: 15,
            justifyContent: 'center',
          }}
        >
          <Grid item={true} xs={12} container justify='center'>
            {userId !== null ? null : (
              <TextInputField
                id='name'
                labelIcon={profileIcon}
                label={i18next.t('feedback.name')}
                type='text'
                value={feedbackInfo.data.name}
                onChange={handleChange('name')}
                xs={8}
              ></TextInputField>
            )}

            <TextInputField
              id='feedback'
              labelIcon={chatboxIcon}
              label={i18next.t('feedback.title')}
              asterix='*'
              type='text'
              value={feedbackInfo.data.text}
              onChange={handleChange('text')}
              multiline={true}
              xs={8}
            ></TextInputField>

            {userId !== null ? null : (
              <TextInputField
                id='email'
                labelIcon={emailIcon}
                label={i18next.t('feedback.email')}
                type='email'
                value={feedbackInfo.data.email}
                onChange={handleChange('email')}
                xs={8}
              ></TextInputField>
            )}

            {userRole === 'admin' && window.location.href.includes('dashboard/feedback') ? 
             (
              <TextInputField
                id='idNumber'
                label={i18next.t('feedback.idNumber')}
                value={feedbackInfo.data.idNumber}
                onChange={handleChange('idNumber')}
                xs={8}
              ></TextInputField>
            ) 
            : (null)}
          </Grid>

          <Grid item={true} style={{boxShadow: 'none'}}>
          {!(userRole === 'admin' && window.location.href.includes('dashboard/feedback')) ? 
            <ImagePicker setEntity={setFeedbackInfo} /> 
            : 
            <IconlessImagePicker setEntity={setFeedbackInfo} />
          }
          </Grid>

        </Grid>

        <Grid
          container
          direction='column'
          alignItems='center'
          style={{
            marginTop: 30,
          }}
        >
          <Grid>
            <Button
              disabled={!feedbackInfo.validate()}
              onClick={onSubmit}
              style={
                feedbackInfo.validate()
                  ? {
                      width: 241,
                      height: 56,
                      borderRadius: 8,
                      backgroundColor: color.black,
                      color: color.white,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto',
                    }
                  : {
                      width: 241,
                      height: 56,
                      borderRadius: 8,
                      backgroundColor: color.gray,
                      color: color.white,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto',
                    }
              }
            >
              {i18next.t('feedback.submit')}
            </Button>
          </Grid>

          {apiResponse === 'waiting' &&
            <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='warning'>
                {t('message.feedbackProcessing')}
              </MuiAlert>
            </Snackbar>
          }
          {apiResponse === 'success' &&
            <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='success'>
                {t('message.feedbackSuccess')}
              </MuiAlert>
            </Snackbar>
          }
          {apiResponse === 'failure' &&
            <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <MuiAlert elevation={6} variant='filled' onClose={handleNotificationClose} severity='error'>
                {t('message.feedbackFail')}
              </MuiAlert>
            </Snackbar>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default withTranslation()(Feedback);
