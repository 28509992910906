import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../addPages/SectionTitle';
import locationIcon from '../../../assets/images/addPages/location.webp';
import useAddStoreStyles from './SharedAddStoreStyles';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import IAddStore from '../../../types/add/IAddStore';

const AddressInput = ({
  setStore,
  entity,
}: {
  setStore: React.Dispatch<React.SetStateAction<IAddStore>>;
  entity: any;
}) => {
  const classes = useAddStoreStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setStore((prevStore) => ({
      ...prevStore,
      addLocation: {
        ...prevStore.addLocation,
        address: entity.location != null ? `${entity.location.streetNumber} ${entity.location.streetName} ${entity.location.city} ${entity.location.province} ${entity.location.country} ${entity.location.postalCode}` : ''
      },
    }));
  }, [entity.location, setStore]);
  const onUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const unit = event.target.value;
    setStore((prevStore) => ({
      ...prevStore,
      addLocation: {
        ...prevStore.addLocation,
        unit: unit === '' ? undefined : unit,
      },
    }));
  };

  const onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setStore((prevStore) => ({
      ...prevStore,
      addLocation: {
        ...prevStore.addLocation,
        address: event.target.value,
      },
    }));
  };

  return (
    <>
      <SectionTitle
        iconSrc={locationIcon}
        title={t('addstore.address_label')}
        required={true}
      />
      <Box className={classes.root}>
        <TextField
          value={entity.location != null ? `${entity.location.streetNumber} ${entity.location.streetName} ${entity.location.city} ${entity.location.province} ${entity.location.country} ${entity.location.postalCode}` : null}
          fullWidth={true}
          variant='outlined'
          size='small'
          placeholder={t('addstore.streetAddress_label')}
          data-cy='addstore-address'
          onChange={onAddressChange}
          style={{ marginBottom: '1em' }}
        />
        <TextField
          fullWidth={true}
          variant='outlined'
          size='small'
          placeholder={t('addstore.unit_label')}
          onChange={onUnitChange}
        />
      </Box>
    </>
  );
};

export default React.memo(AddressInput);
