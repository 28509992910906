import React from 'react';
import { useTranslation } from 'react-i18next';

import appFeedbackStep1 from '../../../assets/images/pilot-testing/howto-appfeedback/step1.png';
import appFeedbackStep2 from '../../../assets/images/pilot-testing/howto-appfeedback/step2.png';
import appFeedbackStep3 from '../../../assets/images/pilot-testing/howto-appfeedback/step3.png';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  feedbackImg: {
    maxWidth: '100%',
    borderRadius: theme.spacing(1),
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  alignCenter: {
    alignText: 'center',
  },
}));

const FeedbackInstructions = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container={true} spacing={2} style={{ textAlign: 'center' }}>
      <Grid item={true} xs={true}>
        <img
          className={classes.feedbackImg}
          src={appFeedbackStep1}
          alt='stocate app feedback step 1'
        />
        <Typography>{t('pilot_testing.extra_feedback_app_1')}</Typography>
      </Grid>
      <Grid item={true} xs={true}>
        <img
          className={classes.feedbackImg}
          src={appFeedbackStep2}
          alt='stocate app feedback step 2'
        />
        <Typography>{t('pilot_testing.extra_feedback_app_2')}</Typography>
      </Grid>
      <Grid item={true} xs={true}>
        <img
          className={classes.feedbackImg}
          src={appFeedbackStep3}
          alt='stocate app feedback step 3'
        />
        <Typography>{t('pilot_testing.extra_feedback_app_3')}</Typography>
      </Grid>
    </Grid>
  );
};

export default FeedbackInstructions;
