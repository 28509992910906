import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { paths } from '../../../stocateConstants';

import Level from './Level';
import Error from '../error-404/ErrorPage';
import { getTasksByLanguage } from './TestingUtils';
import NoMoreLevels from './NoMoreLevels';
import TestingRegister from './TestingRegister';

const LevelsSwitch = () => {
  const { i18n } = useTranslation();

  const levelTasks = getTasksByLanguage(i18n.resolvedLanguage) ?? [];

  return (
    <Switch>
      {levelTasks.map((tasks, index) => {
        const levelNum = index + 1;
        const lvlComponent = () => (
          <Level
            levelNum={levelNum}
            tasks={tasks}
            nextLevelPath={
              levelNum + 1 > levelTasks.length
                ? paths.TESTING_CHECK_BACK_LATER
                : `${paths.LEVEL_PAGE}${levelNum + 1}`
            }
          />
        );

        return (
          <Route
            exact={true}
            path={`${paths.LEVEL_PAGE}${levelNum}`}
            key={levelNum}
            component={lvlComponent}
          />
        );
      })}
      <Route exact={true} path={paths.TESTING}>
        <Redirect to={paths.TESTING_REGISTER} />
      </Route>
      <Route exact={true} path={paths.TESTING_REGISTER}>
        <TestingRegister maxLevel={levelTasks.length} />
      </Route>
      <Route
        exact={true}
        path={paths.TESTING_CHECK_BACK_LATER}
        component={NoMoreLevels}
      />
      <Route path={paths.ERROR} exact={true} component={Error} />
    </Switch>
  );
};

export default LevelsSwitch;
