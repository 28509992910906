import React, { useEffect, useState } from 'react';
import { addResizingListener } from '../../../utils/Responsiveness';

function PDF() {
  const [isMobile, setIsMobile] = useState(false);
  var url = window.location.href;
  var urlSplit = url.split('career/');
  var job = urlSplit[1];
  let link = '';

  useEffect(() => {
    addResizingListener(setIsMobile);
  }, []);

  if(job === 'volunteer/business')
    link = 'https://storage.googleapis.com/stocate-images/jobs/volunteer-business.pdf';
  else if(job === 'volunteer/software')
    link = 'https://storage.googleapis.com/stocate-images/jobs/volunteer-software-dev.pdf';
  else if(job === 'volunteer/ui')
    link = 'https://storage.googleapis.com/stocate-images/jobs/volunteer-ui.pdf';
  else if(job === 'volunteer/hybrid-ui-business')
    link = 'https://storage.googleapis.com/stocate-images/jobs/volunteer-hybrid-ui-business.pdf';

  else if(job === 'intern/2023/software')
    link = 'https://storage.googleapis.com/stocate-images/jobs/intern-2023-software-dev.pdf';
  else if(job === 'intern/2023/ui')
    link = 'https://storage.googleapis.com/stocate-images/jobs/intern-2023-ui.pdf';
  else if(job === 'intern/2023/ux')
    link = 'https://storage.googleapis.com/stocate-images/jobs/intern-2023-ux.pdf';
  else
    window.location.replace('/' + job);
  
  if (isMobile)
    window.location.href = link;

  return (
    <object data={link} type='application/pdf' width='100%' height='750'>
      <p>Link <a href={link}>to the PDF!</a></p>
    </object>
  );
}

export default PDF;