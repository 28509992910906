import React, { useState, useEffect } from 'react';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { paths } from '../../../stocateConstants';
import logo from '../../../assets/images/navbar/20200531 Logo_TM.png';
import emailIcon from '../../../assets/images/sign-up/email.png';
import lockIcon from '../../../assets/images/sign-up/lock.png';
import profileIcon from '../../../assets/images/sign-up/profile-black.png';
import TextInputField from '../../reusable/TextInputField';
import { signUp } from '../../../api/UserApi';
import {
  isEmail,
  checkLength,
  checkHasLetter,
  checkHasNumber,
  checkHasSpecialChar,
} from '../../../utils/Validation';
import { sendPageView } from '../../../utils/GoogleAnalytics.js';
import { useAuth } from '../../../context/AuthContext';
import { useAlert } from '../../../context/AlertContext';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import IUser from '../../../types/dto/user';
import ISignUp from '../../../types/authmodels/signUp';

const initialState: ISignUp = {
  nameFirst: '',
  nameLast: '',
  username: '',
  email: '',
  password: '',
};

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  stocateBtn: {
    backgroundColor: '#01647B',
    width: 241,
    height: 56,
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
  marginBox: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const SignUp = () => {
  const classes = useStyles();
  const location = useLocation();
  const {
    state: { isAuthenticated },
    dispatch,
  } = useAuth();
  const { alertSuccess, alertWarning, alertError } = useAlert();
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState<ISignUp>(initialState);

  const formEmpty = Object.values(userInfo).every(
    (field) => field.trim() === ''
  );
  const noEmptyFields = Object.values(userInfo).every(
    (field) => field.trim() !== ''
  );
  const isValidEmail: boolean = isEmail(userInfo.email);
  const emailEmpty = userInfo.email.trim() === '';
  const showEmptyFieldsError = !formEmpty && !noEmptyFields;
  const showEmailError = !emailEmpty && !isValidEmail;

  const passwordEmpty = userInfo.password.trim() === '';
  const has8Characters = checkLength(userInfo.password);
  const hasNumber = checkHasLetter(userInfo.password);
  const hasLetter = checkHasNumber(userInfo.password);
  const hasSpecialC = checkHasSpecialChar(userInfo.password);

  const isBtnDisabled =
    !noEmptyFields ||
    !isValidEmail ||
    !has8Characters ||
    !hasNumber ||
    !hasLetter ||
    !hasSpecialC;

  const updateUserInfo: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.id]: e.target.value,
    });
  };

  const onSubmit = () => {
    alertWarning(t('message.processing'));
    signUp(userInfo, successCallback, failCallback);
  };

  const successCallback = (body: IUser) => {
    if (!location.state?.requestInterrupted)
      alertSuccess(t('signup.success_message'));
    dispatch({
      type: 'SIGNIN',
      payload: body,
    });
  };

  const failCallback = (statusCode: number, body: any) => {
    if (statusCode === 401) {
      if (body.includes('must contain'))
        if (body.includes('8 characters'))
          alertError(t('signup.passwordRequirement_8character'));
      if (body.includes('one number'))
        alertError(t('signup.passwordRequirement_oneNumber'));
      if (body.includes('one letter'))
        alertError(t('signup.passwordRequirement_oneLetter'));
      if (body.includes('special character'))
        alertError(t('signup.passwordRequirement_specialCharacter'));
      else if (body.includes('Username'))
        alertError(t('signup.username_exist_error'));
      else if (body.includes('Email'))
        alertError(t('signup.email_exist_error'));
      else alertError(t('errorCode.unexpected'));
    } else alertError(t('errorCode.unexpected'));
  };

  useEffect(() => {
    sendPageView(paths.SIGNUP);
  }, []);

  if (isAuthenticated) {
    const { redirectBackTo, ...remainingState } = location.state || {};
    return (
      <Redirect
        push={true}
        to={{
          pathname: redirectBackTo ? redirectBackTo : paths.PROFILE,
          // pass along state if it was sent to Signin
          state: { ...remainingState },
        }}
      />
    );
  }

  return (
    <Container component='main' style={{ maxWidth: '800px' }}>
      <Grid
        container={true}
        direction='column'
        wrap='nowrap'
        justify='center'
        alignItems='center'
      >
        <Grid
          item={true}
          container={true}
          direction='column'
          alignItems='center'
          style={{
            marginTop: 15,
          }}
        >
          <img
            src={logo}
            alt=''
            style={{
              height: '166.4px',
              width: '142.2px',
            }}
          />
          <Typography variant='h5'>{t('signup.title')}</Typography>
          <Grid item={true}>
            <MuiLink component={Link} to={paths.SIGNIN} variant='body2'>
              {t('signup.has_account_signin')}
            </MuiLink>
          </Grid>
        </Grid>

        <Grid
          item={true}
          container={true}
          justify='center'
          style={{
            marginTop: 5,
          }}
        >
          <Grid
            item={true}
            container={true}
            direction='row'
            alignItems='center'
            justify='space-between'
          >
            <TextInputField
              id='nameFirst'
              labelIcon={profileIcon}
              label={t('signup.firstname')}
              type='text'
              value={userInfo.nameFirst}
              onChange={updateUserInfo}
            />
            <TextInputField
              id='nameLast'
              labelIcon={profileIcon}
              label={t('signup.lastname')}
              type='text'
              value={userInfo.nameLast}
              onChange={updateUserInfo}
            />
          </Grid>

          <TextInputField
            id='username'
            labelIcon={profileIcon}
            label={t('signup.username')}
            type='text'
            value={userInfo.username}
            onChange={updateUserInfo}
            xs={12}
          />
          <TextInputField
            id='email'
            labelIcon={emailIcon}
            label={t('signup.email')}
            type='text'
            value={userInfo.email}
            onChange={updateUserInfo}
            xs={12}
          />
          <TextInputField
            id='password'
            labelIcon={lockIcon}
            label={t('signup.password')}
            value={userInfo.password}
            onChange={updateUserInfo}
            xs={12}
            type='password'
          />
        </Grid>
        <Grid item={true} container={true} direction='column'>
          {showEmptyFieldsError ? (
            <Typography variant='subtitle2' color='error'>
              {t('signup.warning1')}
            </Typography>
          ) : showEmailError ? (
            <Typography variant='subtitle2' color='error'>
              {t('signup.warning2')}
            </Typography>
          ) : null}
          <Typography variant='subtitle2'>
            {t('signup.passwordRequirement')}
          </Typography>
          <Typography
            variant='subtitle2'
            color={has8Characters || passwordEmpty ? 'textPrimary' : 'error'}
          >
            {t('signup.passwordRequirement_8character')}
          </Typography>
          <Typography
            variant='subtitle2'
            color={hasNumber || passwordEmpty ? 'textPrimary' : 'error'}
          >
            {t('signup.passwordRequirement_oneNumber')}
          </Typography>
          <Typography
            variant='subtitle2'
            color={hasLetter || passwordEmpty ? 'textPrimary' : 'error'}
          >
            {t('signup.passwordRequirement_oneLetter')}
          </Typography>
          <Typography
            variant='subtitle2'
            color={hasSpecialC || passwordEmpty ? 'textPrimary' : 'error'}
          >
            {t('signup.passwordRequirement_specialCharacter')}
          </Typography>
        </Grid>
        <Grid
          container={true}
          style={{
            marginTop: 30,
            justifyContent: 'center',
          }}
        >
          <Button
            fullWidth={true}
            variant='contained'
            className={classes.stocateBtn}
            disabled={isBtnDisabled}
            size='large'
            onClick={onSubmit}
            data-cy='signupSubmit'
          >
            {t('signup.button')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUp;
