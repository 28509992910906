export const PasswordResetConfirmationBuilder = (data) => {
    let pwdReset = PasswordResetConfirmation();
    pwdReset.data = data;
    return pwdReset;
}

export const InitialPasswordResetConfirmationStateBuilder = () => {
    return PasswordResetConfirmation();
}

const PasswordResetConfirmation = () => {
    return {
        data: {
            email: '',
            token: '',
            password: ''
        },

        validate() {
            return this.noEmptyField();
        },

        noEmptyField() {
            return this.data.email.trim() !== '' &&
                this.data.token.trim() !== '' && this.data.password.trim() !== '';
        }
    }
}