/*
    Helper methods to automatically change a boolean on screensize changes.

    Currently we only have 2 levels. In the future we could have more.
    For example we can have 3 levels: s (less than 450px), m (450px to 960px), l (more than 960px).
*/

const defaultMobileBreakpoint = 1024;

export const addResizingListener = (setIsMobile) => {
    addCustomResizingListener(setIsMobile, defaultMobileBreakpoint);
}

export const addCustomResizingListener = (setIsMobile, breakpoint) => {
    // update isMobile.
    customResize(setIsMobile, breakpoint)();
    // Attach a resize listener to update isMobile on screen resize.
    window.addEventListener('resize', customResize(setIsMobile, breakpoint));
}

// This has to be a function that returns a function since "window.addEventListener" takes in a function not a value.
const customResize = (setIsMobile, breakpoint) => () => {
    if (window.innerWidth <= breakpoint) {
        setIsMobile(true);
    } else {
        setIsMobile(false);
    }
}
