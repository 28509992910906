import React from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import StocateButton from 'components/reusable/StocateButton';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ASCIcon from '../../../assets/images/sustainableIcons/ASC.png';
import BYOM from '../../../assets/images/sustainableIcons/BYOM.png';
import GreenSealIcon from '../../../assets/images/sustainableIcons/Green Seal.png'
import PackagingReusable from '../../../assets/images/sustainableIcons/Packaging_Reusable.png'
import OrganicIcon from '../../../assets/images/sustainableIcons/Organic.png'
import EcoLocoIcon from '../../../assets/images/sustainableIcons/EcoLogo.png'
import PalmOilIcon from '../../../assets/images/sustainableIcons/Palm Oil Free.png'
import OceanWiseIcon from '../../../assets/images/sustainableIcons/Ocean Wise.png'
import RainForestIcon from '../../../assets/images/sustainableIcons/Rainforest Alliance.png'
import MSCIcon from '../../../assets/images/sustainableIcons/MSC.png'
import FairTradeIcon from '../../../assets/images/sustainableIcons/Fair Trade.png'
import ConstituentIcon from '../../../assets/images/sustainableIcons/Constituents.png'
import LandTransportationIcon from '../../../assets/images/sustainableIcons/Land Transportation 2.png'
import ItemOriginIcon from '../../../assets/images/sustainableIcons/Origin.png'
import EolRecollectionIcon from '../../../assets/images/sustainableIcons/EolRecollection_full.png'
import FscIcon from '../../../assets/images/sustainableIcons/FSC.png'

import KosherIcon from '../../../assets/images/sustainableIcons/Kosher.png'
import PeanutFreeIcon from '../../../assets/images/sustainableIcons/Peanut-free.png'
import DairyFreeIcon from '../../../assets/images/sustainableIcons/Dairy-free.png'
import LowSugarContentIcon from '../../../assets/images/sustainableIcons/sugar-free.png'
import VeganIcon from '../../../assets/images/sustainableIcons/Vegan.png'
import HalalIcon from '../../../assets/images/sustainableIcons/Halal.png'
import VegetarianIcon from '../../../assets/images/sustainableIcons/Vegetarian.png'
import GlutenFreeIcon from '../../../assets/images/sustainableIcons/Gluten-free.png'
import ArrowDown from '../../../assets/images/general/arrowDown.png'
import ArrowUp from '../../../assets/images/general/arrowUp.png'
import DietRestrictionsIcon from '../../../assets/images/sustainableIcons/diet_restrictions.webp'
import SustainabilityBadgesIcon from '../../../assets/images/sustainableIcons/sustainability_badges.webp'

Modal.setAppElement('#root'); 

const FilterSection = ({ title, children, icon }) => {
    const [isOpen, setIsOpen] = useState(false);
    const useStyles = makeStyles((theme) => ({
        arrowIcon: {
          width: '15px',
          height: '15px',
        },
        titleIcon: {
        width: '18px',
        height: '18px',
        marginRight: '5px',
        verticalAlign: 'middle',
        },
      }));

    const classesArrow = useStyles();
    const classestitleIcon = useStyles();

    const toggleSection = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <button onClick={toggleSection} style={{ fontWeight: 'bold', color: 'black', cursor: 'pointer', border: 'none', background: 'none' }}>
            {icon ? <img src={icon} alt='' className={classestitleIcon.titleIcon}/> : null}
            {title} {isOpen ? <img src={ArrowUp} alt='Arrow Up' className={classesArrow.arrowIcon}/> : <img src={ArrowDown} alt='Arrow Down' className={classesArrow.arrowIcon}/>}</button>
            {isOpen && children}
        </div>
    );
};

const FilterBadges = ({ isOpen, closeModal, children, setItemFilters, fetchDataItem, setAllCountersToZero, setItems }) => {
    const { t } = useTranslation();
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            maxHeight: '70vh',
            position: 'relative',
            width: '50%',
            height: 'auto',
        }
    };

    const [selectedFilters, setSelectedFilters] = useState([]);
    
    const handleCheckboxChange = (event) => {
        const checkboxId = event.target.id;
        const isChecked = event.target.checked;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [checkboxId]: isChecked 
        }));
    };

    const handleDoneAndApplyFilters = () => {
        setItemFilters(selectedFilters); 
        setAllCountersToZero();
        setItems([]);
        setSelectedFilters([]);
        closeModal(); 
    };

    const handleClearAllFilters = () => {
        document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
            checkbox.checked = false;
        });
        setSelectedFilters({});
    };

    useEffect(() => {
        fetchDataItem();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel='Example Modal'
        >
            <button onClick={closeModal} style={{ float: 'right', border: 'none', background: 'none' }}>
                <span aria-hidden>×</span>
            </button>
            <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', alignItems: 'center' }}>
                
                <div style={{ marginRight: '10px' }}> 
                    <StocateButton onClick={handleClearAllFilters} size='small'>
                        {t('filter-sort.clearAll')}
                    </StocateButton>
                </div>
                <div>
                    <StocateButton onClick={handleDoneAndApplyFilters} size='small' color='primary'>
                        {t('filter-sort.done')}
                    </StocateButton>
                </div>
            </div>
            {children}

            <FilterSection title={t('filter-sort.sustainability_badges')} icon={SustainabilityBadgesIcon}>

            <div>
                <label style={{ marginLeft: '2em' }}>
                <input 
                    type='checkbox' 
                    id='Packaging'
                    onChange={handleCheckboxChange} 
                    />
                    <img src={PackagingReusable} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.type_packaging')+': '+t('newStore.type_reusable')}
                </label>
            </div>
            <div>
                <label style={{ marginLeft: '2em' }}>
                <input 
                    type='checkbox' 
                    id='Organic'
                    onChange={handleCheckboxChange} 
                    />
                    <img src={OrganicIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.organic')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                <input 
                    type='checkbox' 
                    id='Ecologo'
                    onChange={handleCheckboxChange} 
                    />
                    <img src={EcoLocoIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.ecoLogo')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                <input 
                    type='checkbox' 
                    id='Asc'
                    onChange={handleCheckboxChange} 
                    />
                    <img src={ASCIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.asc')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Palmoil' onChange={handleCheckboxChange} />
                    <img src={PalmOilIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.palmOil')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Byom' onChange={handleCheckboxChange} />
                    <img src={BYOM} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.byom')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Greenseal' onChange={handleCheckboxChange} />
                    <img src={GreenSealIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.greenSeal')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Oceanwise' onChange={handleCheckboxChange} />
                    <img src={OceanWiseIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.oceanWise')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Rainforest' onChange={handleCheckboxChange} />
                    <img src={RainForestIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.rainforest')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Msc' onChange={handleCheckboxChange} />
                    <img src={MSCIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.msc')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Fairtrade' onChange={handleCheckboxChange} />
                    <img src={FairTradeIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.fairTrade')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Constituents' onChange={handleCheckboxChange} />
                    <img src={ConstituentIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.constituents')+': '+t('newStore.constituents_0')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='LandTransportation' onChange={handleCheckboxChange} />
                    <img src={LandTransportationIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.landtransport')+': '+t('newStore.minimal_land_transport')}
                </label>
            </div>
            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Origin' onChange={handleCheckboxChange} />
                    <img src={ItemOriginIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.origin')+': '+t('newStore.local')}
                </label>
            </div>
            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='EolRecollection' onChange={handleCheckboxChange} />
                    <img src={EolRecollectionIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.eolRecollection')+': '+t('newStore.eolRecollection_full')}
                </label>
            </div>
            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Fsc' onChange={handleCheckboxChange} />
                    <img src={FscIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.fsc')}
                </label>
            </div>
            </FilterSection>
            <FilterSection title={t('filter-sort.diet')} icon={DietRestrictionsIcon}>
            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Kosher' onChange={handleCheckboxChange} />
                    <img src={KosherIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.kosher')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Peanut' onChange={handleCheckboxChange} />
                    <img src={PeanutFreeIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.peanut')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Dairy' onChange={handleCheckboxChange} />
                    <img src={DairyFreeIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.dairy')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Sugar' onChange={handleCheckboxChange} />
                    <img src={LowSugarContentIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.sugar_free')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Vegan' onChange={handleCheckboxChange} />
                    <img src={VeganIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.vegan')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Halal' onChange={handleCheckboxChange} />
                    <img src={HalalIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.halal')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Vegetarian' onChange={handleCheckboxChange} />
                    <img src={VegetarianIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.vegetarian')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Gluten' onChange={handleCheckboxChange} />
                    <img src={GlutenFreeIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.gluten')}
                </label>
            </div>
            </FilterSection>
        </Modal>
    );
};

export default withTranslation()(FilterBadges);