import { forumEndpoints, onlineAPI } from 'stocateConstants';
import { checkLocalStorageForToken } from './CommonApi';

export async function getStoreForum(forumId, successCallback, failCallback) {
	await fetch(onlineAPI + forumEndpoints + forumId, {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
		}
	})
	.then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}