import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  tabBtn: {
    flex: 1,
    fontFamily: 'Roboto',
  },
  unselected: {
    color: 'rgba(0,0,0,.5)',
    '&:hover': {
      fontWeight: 700,
      color: 'rgba(0,0,0,.7)'
    },
  },
  selected: {
    color: '#00809e',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -2,
      height: 3,
      background: '#00809e',
    },
    '&:hover': {
      fontWeight: 700
    },
  },
}));

interface SectionTabProps {
  children: JSX.Element;
  selected: boolean;
  onClick: () => void;
  dataCy?: string;
}

const SectionTab = ({ children, selected, onClick, dataCy }: SectionTabProps) => {
  const classes = useStyles();

  return (
    <Grid item={true}>
      <ButtonBase
        disableRipple={true}
        className={`${classes.tabBtn} ${
          selected ? classes.selected : classes.unselected
        }`}
        onClick={onClick}
        data-cy={dataCy}
      >
        {children}
      </ButtonBase>
    </Grid>
  );
};

export default SectionTab;
