import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';

import SusBadgeList from '../../../types/add/ISusBadgeList';
import IAddStore from '../../../types/add/IAddStore';
import IAddItem from '../../../types/add/IAddItem';

const SusBadgeRadio = ({
  setEntity,
  badgeList,
  entity,
}: {
  setEntity: React.Dispatch<React.SetStateAction<IAddStore>> | React.Dispatch<React.SetStateAction<IAddItem>>;
  badgeList: SusBadgeList;
  entity: any;
}) => {
  const { t } = useTranslation();
  const [selectedBadgeKey, setSelectedBadgeKey] = useState(0);

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    setSelectedBadgeKey(value);
    setEntity((prev: any) => ({
      ...prev,
      sustainabilityMetrics: {
        ...prev.sustainabilityMetrics,
        [badgeList.key]: badgeList.badges.length === 2 ? value + 1 : value,
      },
    }));
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked ? 0 : 1;
    setSelectedBadgeKey(value);
    setEntity((prev: any) => ({
      ...prev,
      sustainabilityMetrics: {
        ...prev.sustainabilityMetrics,
        [badgeList.key]: badgeList.badges.length === 2 ? value + 1 : value,
      },
    }));
  };

  const handleRowClick = () => {
    // Toggle the checkbox when the row is clicked
    const syntheticEvent = {
      target: {
        type: 'checkbox',
        checked: selectedBadgeKey !== 0,
      },
    };
    onCheckboxChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
  };

  const selectedBadgeImage = badgeList?.badges[selectedBadgeKey]?.image;

  useEffect(() => {
    setSelectedBadgeKey(badgeList.badges.length === 2 ? entity.sustainabilityMetrics[badgeList.key] - 1 : entity.sustainabilityMetrics[badgeList.key])
  }, [entity.sustainabilityMetrics, badgeList]);

  return (
    <Grid container={true} direction='column'>
      {badgeList.badges.length === 2 ? (
        <Grid item={true} onClick={handleRowClick} style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={selectedBadgeKey === 0}
            onChange={onCheckboxChange}
            value={selectedBadgeKey}
            style={{ color: 'black' }}
          />
          <img
            src={badgeList?.badges[0]?.image || ''}
            alt='sustainability badge'
            style={{ marginRight: 5, width: 30, height: 30 }}
            data-cy='susBadge'
          />
          <label style={{ width: '225px', display: 'flex', alignItems: 'center', margin: '0' }}>
            <Typography variant='subtitle2'>{t(badgeList.title)}</Typography>
          </label>
        </Grid>
      ) : (
        <Grid>
          <Grid item={true} container={true} alignItems='center'>
            <Grid item={true}>
              <Box style={{ marginRight: 5, width: 30, height: 30 }}>
                {selectedBadgeImage && (
                  <img
                    src={selectedBadgeImage}
                    alt='sustainability badge'
                    style={{ marginRight: 5, width: 30, height: 30 }}
                    data-cy='susBadge'
                  />
                )}
              </Box>
            </Grid>
            <Grid item={true}>
              <Typography>{badgeList.title}</Typography>
            </Grid>
          </Grid>
          <Grid item={true}>
            <RadioGroup
              aria-labelledby={`${badgeList.title}-options`}
              defaultValue={0}
              value={selectedBadgeKey}
              onChange={onRadioChange}
              name={`${badgeList.title}-radio-select`}
            >
              {badgeList.badges.map((badge) => (
                <FormControlLabel
                  key={`${badgeList.title}-${badge.key}`}
                  data-cy={`${badgeList.key}-${badge.key}`}
                  value={badge.key}
                  control={<Radio color='primary' />}
                  label={
                    <Typography variant='subtitle2'>{t(badge.labelKey)}</Typography>
                  }
                  labelPlacement='end'
                  style={{ justifyContent: 'start', marginBottom: 0, marginLeft: 45}}
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SusBadgeRadio;
