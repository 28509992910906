import { onlineAPI, usersEndpoints } from 'stocateConstants';
import { isEmail } from '../utils/Validation';
import { checkLocalStorageForToken } from './CommonApi';

export async function signIn(body, successCallback, failCallback){
	await fetch(onlineAPI + usersEndpoints + 'auth/signin', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
    .then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes);
                } else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}

export async function editUserInfo(body, successCallback, failCallback) {
    await fetch(onlineAPI + usersEndpoints , {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
    .then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes);
                } else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
	.catch(error => {
        failCallback(500, 'An error occurred'); // Example error message
    });
}

export async function deleteUser(successCallback, failCallback) {
	await fetch(onlineAPI + usersEndpoints, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
	.then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
	.catch(error => {
        failCallback(500, 'An error occurred'); // Example error message
    });
}

export async function changePassword(body, successCallback, failCallback) {
    await fetch(onlineAPI + usersEndpoints + 'auth/changepass', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
        }
    })
    .then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(jsonRes)
                }
            })
        }
	})
	.catch(error => {
        failCallback(500, 'An error occurred'); // Example error message
    });
}

export async function validate(token, successCallback, failCallback) {
    await fetch(onlineAPI + usersEndpoints + 'auth/validate', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
    .then(res => {
        if(res.status === 500 || res.status === 401){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}

export async function signUp(body, successCallback, failCallback) {
    await fetch(onlineAPI + usersEndpoints + 'auth/signup', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
    .then(res => {
        if(res.status === 500){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}

export async function signingOut(token, successCallback, failCallback) {
    await fetch(onlineAPI + usersEndpoints + 'auth/signout', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
        }
    })
    .then(res => {
        if(res.status === 500 || res.status === 401){
            failCallback(res.status)
        }else{
            res.json()
            .then(jsonRes => {
                if(res.status === 200){
                    successCallback(jsonRes)
                }else{
                    failCallback(res.status, jsonRes)
                }
            })
        }
	})
}

// !!! TODO: add error handling
export async function requestPasswordReset(email, setResponse) {
	var jsonBody = {}
	if (isEmail(email)) {
		jsonBody = {
			'Email': email
		}
	} else {
		jsonBody = {
			'Username': email
		}
	}
	fetch(onlineAPI + 'users/auth/forgotpass', {
		method: 'POST',
		body: JSON.stringify(jsonBody),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	})
    .then(res => res.json())
    .then(jsonRes => {
        setResponse('success');
    })
    .catch(error => {
        setResponse('failure');
    })
}

// !!! TODO: add error handling
export async function confirmReset(userInfo, setResponseCallback) {
	var jsonBody = {}
	if (isEmail(userInfo.data.email)) {
		jsonBody = {
			'Email': userInfo.data.email,
            'Token': userInfo.data.token,
			'Password': userInfo.data.password
		}
	} else {
		jsonBody = {
			'Username': userInfo.data.email,
            'Token': userInfo.data.token,
			'Password': userInfo.data.password
		}
	}

	fetch(onlineAPI + 'users/auth/confirmreset', {
		method: 'POST',
		body: JSON.stringify(jsonBody),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	})
    .then(res => {
        res.json()
        .then(jsonRes => {
            if(res.status === 200){
                setResponseCallback(res.status, jsonRes)
                localStorage.setItem('token', jsonRes.token)
            }
            else if (res.status === 404)
                setResponseCallback(res.status, {'message': jsonRes})
            else if (res.status === 401)
                setResponseCallback(res.status, {'message': jsonRes})
            else
                setResponseCallback(res.status, 'failure')
        })
    })
}