import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

interface PhoneTextFieldProps {
  name?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  placeholder?: string;
  size?: 'medium' | 'small';
  fullWidth?: boolean;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const PhoneTextField = ({
  value,
  variant,
  size,
  onChange,
  placeholder,
  fullWidth,
  name,
}: PhoneTextFieldProps) => (
  <InputMask
    mask='+1 (999) 999-9999'
    value={value}
    name={name}
    onChange={onChange}
    alwaysShowMask={true}
    placeholder={placeholder}
  >
    {(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
      <TextField
        {...inputProps}
        type='tel'
        data-cy='phoneinput'
        size={size}
        fullWidth={fullWidth}
        variant={variant}
      />
    )}
  </InputMask>
);

export default PhoneTextField;
