import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { listEndpoints } from '../../../../../stocateConstants';
import { useOptionStyles } from '../SharedOptionStyles';
import { useAlert } from '../../../../../context/AlertContext';
import shareIcon from '../../../../../assets/images/store/share.png';

import MenuItem from '@material-ui/core/MenuItem';
import { ListOptionProps } from '../ListCellOptions';

const ShareMenuOption = forwardRef(
  ({ closeMenuAfter, list }: ListOptionProps, ref) => {
    const classes = useOptionStyles();
    const { alertSuccess } = useAlert();
    const { t } = useTranslation();

    const clipboard = () => {
      navigator.clipboard.writeText(
        `${window.location.origin}/${listEndpoints}${list.id}`
      );
      alertSuccess(t('entityOptions.share_success'), 3000);
    };

    return (
      <MenuItem onClick={closeMenuAfter(clipboard)} {...({ ref } as any)}>
        <img
          src={shareIcon}
          className={classes.optionIcon}
          alt='share list icon'
        />
        {t('entityOptions.share')}
      </MenuItem>
    );
  }
);

export default ShareMenuOption;
