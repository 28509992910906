import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import copyIcon from '../../../../../assets/images/3dotoptions/copy.webp';
import { useOptionStyles } from '../SharedOptionStyles';
import { useAuth } from '../../../../../context/AuthContext';
import { useAlert } from '../../../../../context/AlertContext';
import useCopyList from '../../../../../hooks/useCopyList';
import { StocateQuery, queryClient } from '../../../../../utils/queryClient';

import MenuItem from '@material-ui/core/MenuItem';

import { ListOptionProps } from '../ListCellOptions';

const CopyMenuOption = forwardRef(
  ({ closeMenuAfter, list }: ListOptionProps, ref) => {
    const classes = useOptionStyles();
    const {
      state: { user },
    } = useAuth();
    const { alertSuccess, alertWarning, alertError } = useAlert();
    const copyList = useCopyList();
    const { t } = useTranslation();

    const onClick = () => {
      if (!user?.libraryId) return;
      alertWarning(t('message.processing'));
      copyList.mutate(
        { listId: list.id, libraryId: user.libraryId },
        {
          onSuccess: () => {
            alertSuccess(t('library.duplicate_success'));
            queryClient.invalidateQueries(StocateQuery.MYLISTS);
          },
          onError: (error) => {
            const { code } = error;
            if (code === 401) alertError(t('errorCode.permission_list_401'));
            else if (code === 404) alertError(t('errorCode.listView_404'));
            else alertError(t('errorCode.unexpected'));
          },
        }
      );
    };

    return (
      <MenuItem
        onClick={closeMenuAfter(onClick)}
        {...({ ref } as any)}
        data-cy={`copyList-${list.id}`}
      >
        <img
          src={copyIcon}
          className={classes.optionIcon}
          alt='copy list icon'
        />
        {t('library.duplicate')}
      </MenuItem>
    );
  }
);

export default CopyMenuOption;
