import React from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import StocateButton from 'components/reusable/StocateButton';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import WomanOwnedIcon from '../../../assets/images/sustainableIcons/Women Owned.png'
import MealCareIcon from '../../../assets/images/sustainableIcons/Meal Care.png'
import QueerOwnedIcon from '../../../assets/images/sustainableIcons/Queer Owned.png'
import BipocOwnedIcon from '../../../assets/images/sustainableIcons/BIPOC Owned.png'
import WasteIcon from '../../../assets/images/sustainableIcons/Waste 1.png'
import CommunityEngageIcon from '../../../assets/images/sustainableIcons/Community Engagement.png'
import BranchesNumberIcon from '../../../assets/images/sustainableIcons/Branches_Local.png'
import EmployeesNumberIcon from '../../../assets/images/sustainableIcons/Employee_1-5.png'
import HandicapFriendlyIcon from '../../../assets/images/sustainableIcons/HandicapFriendly.png'

Modal.setAppElement('#root');

const FilterBadgesStores = ({ isOpen, closeModal, children, setStoreFilters, fetchDataStore, setAllCountersToZero, setStores }) => {
    const { t } = useTranslation();
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000 
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)', 
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto', 
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            position: 'relative',
            width: '50%', 
            height: 'auto', 
        }
    };
    
    const [selectedFilters, setSelectedFilters] = useState([]);
    
    const handleCheckboxChange = (event) => {
        const checkboxId = event.target.id;
        const isChecked = event.target.checked;
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [checkboxId]: isChecked 
        }));
    };

    const handleDoneAndApplyFilters = () => {
        setStoreFilters(selectedFilters); 
        setAllCountersToZero();
        setStores([]);
        setSelectedFilters([]);
        closeModal();
    };

    const handleClearAllFilters = () => {
        document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
            checkbox.checked = false;
        });
        setSelectedFilters({});
    };

    useEffect(() => {
        fetchDataStore();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel='Example Modal'
        >
            <button onClick={closeModal} style={{ float: 'right', border: 'none', background: 'none' }}>
                <span aria-hidden>×</span>
            </button>
            <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '10px' }}> 
                    <StocateButton onClick={handleClearAllFilters} size='small'>
                        {t('filter-sort.clearAll')}
                    </StocateButton>
                </div>
                <div>
                    <StocateButton onClick={handleDoneAndApplyFilters} size='small' color='primary'>
                        {t('filter-sort.done')}
                    </StocateButton>
                </div>
            </div>
            {children}

            <p style={{ fontWeight: 'bold', color: 'black' }}>{t('filter-sort.sustainability_badges')}</p>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='WomenOwned' onChange={handleCheckboxChange} />
                    <img src={WomanOwnedIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.women')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Mealcare' onChange={handleCheckboxChange} />
                    <img src={MealCareIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.mealCare')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='QueerOwned' onChange={handleCheckboxChange} />
                    <img src={QueerOwnedIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.queer')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='BipocOwned' onChange={handleCheckboxChange} />
                    <img src={BipocOwnedIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.bipoc')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='Waste' onChange={handleCheckboxChange} />
                    <img src={WasteIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.waste')+': '+t('newStore.low_waste')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='CommunityEngage' onChange={handleCheckboxChange} />
                    <img src={CommunityEngageIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.communityEngage')+': '+t('newStore.high_community')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='BranchesNumber' onChange={handleCheckboxChange} />
                    <img src={BranchesNumberIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.branches_in_total')+': '+t('newStore.branches_one')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='EmployeesNumber' onChange={handleCheckboxChange} />
                    <img src={EmployeesNumberIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.full_time_employees')+': '+t('newStore.full_microbusiness')}
                </label>
            </div>

            <div>
                <label style={{ marginLeft: '2em' }}>
                    <input type='checkbox' id='HandicapFriendly' onChange={handleCheckboxChange} />
                    <img src={HandicapFriendlyIcon} alt='Badge Icon' style={{ marginLeft: '10px', marginRight: '10px', width: '20px', height: '20px' }}  />
                    {t('newStore.handicap')}
                </label>
            </div>
        </Modal>
    );
};

export default withTranslation()(FilterBadgesStores);