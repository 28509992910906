import i18next from 'i18next';

// API links
export const onlineAPI = 'https://stocate-api-jqbyn5av4a-nn.a.run.app/api/';
export const onlineAPIDomain = 'https://stocate-api-jqbyn5av4a-nn.a.run.app/';

// API controllers
export const storesEndpoints = 'stores/';
export const itemsEndpoints = 'items/';
export const usersEndpoints = 'users/';
export const loggingEndpoints = 'logging/';
export const feedbackEndpoints = 'feedback/';
export const forumEndpoints = 'forum/';
export const listEndpoints = 'lists/';
export const levelsEndpoints = 'levels/';
export const levelsUserEndpoint = 'levels/user/';

// Google Cloud Storage images bucket
export const gcURL = 'https://storage.googleapis.com/stocate-images/';
export const logos = 'logos/';

// Stocate social media links
export const stocateEmail = 'mailto:info@stocate.com';
export const stocateInstagram = 'https://www.instagram.com/sto.cate';
export const stocateFacebook = 'https://www.facebook.com/stocate';
export const stocateLinkedin = 'https://www.linkedin.com/company/stocate/';
export const stocateTwitter = 'https://twitter.com/sto_cate';

// Download app
export const appleStore = 'https://apps.apple.com/ca/app/stocate/id1579972656';
export const playStore =
  'https://play.google.com/store/apps/details?id=com.stocate.stocatepilot';

// Used to refresh a token past a week from its issued date, unless it was expired
export const TOKEN_REFRESH_AFTER = 604800; // in seconds

// for metadata tags (preview link)
export const defaultPreviewLinkImage = 'https://storage.googleapis.com/stocate-images/logos/Stocate%20logo%20nowhite.png';
export const defaultPreviewLinkDescription = 'Stocate helps you fulfill your needs while staying true to your values. Find sustainable items, stores and much more on Stocate!';

export const color = {
  stocateBlue: '#00809E',
  stocateGreen: '#01647B',
  stocateDarkGreen: '#005366',
  stocateDarkBlue: '#09264F',
  black: '#131313',
  pure_black: '#000',
  veryLightGray: '#F1F1F1',
  lightGray: '#D1D1D1',
  gray: '#767676',
  ash_gray: '#E3E3E3',
  white: '#FFF',
  ash_white: '#FDFDFD',
  errorRed: '#FE5353',
  green_checkmark: '#29ab4c',
};

export const teamImages = {
  yannick: require('assets/images/about/yannick.jpg'),
  ezz: gcURL + 'teampics/Ezz.jpg',
  santiago: require('assets/images/about/santiago.JPG'),
  taejun: require('assets/images/about/taejun.jpg'),
  joey: require('assets/images/about/joey.jpg'),
  annie: require('assets/images/about/annie.jpg'),
  kevin: require('assets/images/about/kevin.jpg'),
  kenneth: require('assets/images/about/kenneth.jpg'),
  cassandra: require('assets/images/about/cassandra.jpg'),
  weiheng: require('assets/images/about/weiheng.jpg'),
  amritpal: require('assets/images/about/amritpal.jpg'),
  karena: require('assets/images/about/karena.jpg'),
  maria: require('assets/images/about/maria.jpg'),
  julia: require('assets/images/about/julia.png'),
  madeleine: require('assets/images/about/madeleine.png'),
  marlo: require('assets/images/about/marlo.png'),
  lian: require('assets/images/about/lian.png'),
  diego: require('assets/images/about/diego.jpg'),
  juan: require('assets/images/about/juan.png'),
  krystina: require('assets/images/about/krystina.png'),
  dube: require('assets/images/about/dube.jpeg'),
  kwofie: require('assets/images/about/kwofie.jpeg'),
  therriault: require('assets/images/about/therriault.jpg'),
  lemieux: require('assets/images/about/lemieux.JPG'),
  sack: require('assets/images/about/sack.JPG'),
  ginter: require('assets/images/about/ginter.JPG'),
  csinger: require('assets/images/about/csinger.JPG'),
  plant: require('assets/images/about/plant.jpeg'),
  badami: require('assets/images/about/badami.jpeg'),
};

export const flags = {
  canada: require('assets/images/about/canada-flag.jpg'),
  india: require('assets/images/about/india.png'),
  egypt: require('assets/images/about/egypt.png'),
  columbia: require('assets/images/about/columbia.jpg'),
  greece: require('assets/images/about/greece.jpg'),
  greatBritain: require('assets/images/about/great-britain.png'),
  china: require('assets/images/about/china.png'),
  malaysia: require('assets/images/about/malaysia.png'),
  bangladesh: require('assets/images/about/bangladesh.jpg'),
  pakistan: require('assets/images/about/pakistan.png'),
  romania: require('assets/images/about/romania.png'),
  korean: require('assets/images/about/korean.jpg'),
  montreal: require('assets/images/about/montreal.jpg'),
  argentina: require('assets/images/about/argentina.jpg'),
  elSalvador: require('assets/images/about/elSalvador.png')
};

export const branchesNumber = [
  { key: 0, labelKey: 'newStore.not_sure', image: null },
  {
    key: 1,
    labelKey: 'newStore.branches_one',
    image: require('assets/images/sustainableIcons/Branches_Local.png'),
  },
  {
    key: 2,
    labelKey: 'newStore.branches_all_same_city',
    image: require('assets/images/sustainableIcons/Branches_National.png'),
  },
  {
    key: 3,
    labelKey: 'newStore.branches_all_same_country',
    image: require('assets/images/sustainableIcons/Branches_International.png'),
  },
  { key: 4, labelKey: 'newStore.branches_all_over_world', image: null },
];

export const feedbackStatus = [
  { value: 1, label: 'not started' },
  { value: 2, label: 'in design' },
  { value: 3, label: 'complete' },
  { value: 4, label: 'declined' },
  { value: 5, label: 'in QA' },
  { value: 6, label: 'in tech' },
  { value: 8, label: 'thanks sent'},
  { value: 9, label: 'question answered'}
];

export const feedbackPriority = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
];

export const currencies = [

  { label: 'CAD', value: 'CAD' },
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'GBP', value: 'GBP' },
  { label: 'JPY', value: 'JPY' },
  { label: 'AUD', value: 'AUD' },
  { label: 'CHF', value: 'CHF' },
  { label: 'CNY', value: 'CNY' },
  { label: 'NZD', value: 'NZD' },

];

export const employeesNumber = [
  { key: 0, labelKey: 'newStore.not_sure', image: null },
  {
    key: 1,
    labelKey: 'newStore.full_microbusiness',
    image: require('assets/images/sustainableIcons/Employee_1-5.png'),
  },
  {
    key: 2,
    labelKey: 'newStore.full_small_business',
    image: require('assets/images/sustainableIcons/Employee_6-100.png'),
  },
  {
    key: 3,
    labelKey: 'newStore.full_medium_business',
    image: require('assets/images/sustainableIcons/Employee_101-500.png'),
  },
  { key: 4, labelKey: 'newStore.full_large_business', image: null },
];

export const mealCare = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Meal Care.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const bipocOwned = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/BIPOC Owned.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const womenOwned = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Women Owned.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const queerOwned = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Queer Owned.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const handicapFriendly = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/HandicapFriendly.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const communityEngage = [
  { key: 0, labelKey: 'newStore.not_sure', image: null },
  {
    key: 1,
    labelKey: 'newStore.high_community',
    image: require('assets/images/sustainableIcons/Community Engagement.png'),
  },
  {
    key: 2,
    labelKey: 'newStore.medium_community',
    image: require('assets/images/sustainableIcons/Community Engagement 2.png'),
  },
  {
    key: 3,
    labelKey: 'newStore.low_community',
    image: require('assets/images/sustainableIcons/Community Engagement 1.png'),
  },
];

export const waste = [
  { key: 0, labelKey: 'newStore.not_sure', image: null },
  {
    key: 1,
    labelKey: 'newStore.low_waste',
    image: require('assets/images/sustainableIcons/Waste 1.png'),
  },
  {
    key: 2,
    labelKey: 'newStore.medium_waste',
    image: require('assets/images/sustainableIcons/Waste.png'),
  },
  {
    key: 3,
    labelKey: 'newStore.high_waste',
    image: require('assets/images/sustainableIcons/Waste 2.png'),
  },
];

export const packaging = [
  { key: 0, labelKey: 'newStore.not_sure', image: null },
  {
    key: 1,
    labelKey: 'newStore.type_reusable',
    image: require('assets/images/sustainableIcons/Packaging_Reusable.png'),
  },
  {
    key: 2,
    labelKey: 'newStore.type_compostable',
    image: require('assets/images/sustainableIcons/Packaging_Recyclable.png'),
  },
  {
    key: 3,
    labelKey: 'newStore.type_recyclable',
    image: require('assets/images/sustainableIcons/Packaging_Disposable.png'),
  },
  { key: 4, labelKey: 'newStore.type_disposable', image: null },
];

export const organic = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Organic.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const fsc = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/FSC.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  }
];

export const eolRecollection = [
  {
    key: 0,
    labelKey: 'newStore.not_sure',
    image: null,
  },
  {
    key: 1,
    labelKey: 'newStore.eolRecollection_full',
    image: require('assets/images/sustainableIcons/EolRecollection_full.png'),
  },
  {
    key: 2,
    labelKey: 'newStore.eolRecollection_partial',
    image: require('assets/images/sustainableIcons/EolRecollection_partial.png'),
  },
  {
    key: 3,
    labelKey: 'newStore.eolRecollection_none',
    image: null,
  }
];

export const ecoLogo = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/EcoLogo.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const asc = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/ASC.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const palmOil = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Palm Oil Free.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const byom = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/BYOM.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const greenSeal = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Green Seal.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const oceanWise = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Ocean Wise.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const rainforest = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Rainforest Alliance.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const msc = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/MSC.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const fairTrade = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Fair Trade.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const constituent = [
  { key: 0, labelKey: 'newStore.not_sure', image: null },
  {
    key: 1,
    labelKey: 'newStore.constituents_0',
    image: require('assets/images/sustainableIcons/Constituents.png'),
  },
  {
    key: 2,
    labelKey: 'newStore.constituents_1',
    image: require('assets/images/sustainableIcons/Constituents 2.png'),
  },
  {
    key: 3,
    labelKey: 'newStore.constituents_2',
    image: require('assets/images/sustainableIcons/Constituents 3.png'),
  },
  {
    key: 4, 
    labelKey: 'newStore.constituents_3',
    image: null,
  },
];

export const kosher = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Kosher.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const peanut = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Peanut-free.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const dairy = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Dairy-free.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const sugar = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/sugar-free.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const vegan = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Vegan.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const halal = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Halal.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const vegetarian = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Vegetarian.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const gluten = [
  {
    key: 0,
    labelKey: 'newStore.badge_yes',
    image: require('assets/images/sustainableIcons/Gluten-free.png'),
  },
  {
    key: 1,
    labelKey: 'newStore.badge_no',
    image: null,
  },
];

export const landTransportation = [
  { key: 0, labelKey: 'newStore.not_sure', image: null },
  {
    key: 1,
    labelKey: 'newStore.minimal_land_transport',
    image: require('assets/images/sustainableIcons/Land Transportation 2.png'),
  },
  {
    key: 2,
    labelKey: 'newStore.land_transport',
    image: require('assets/images/sustainableIcons/Land Transportation 1.png'),
  },
  {
    key: 3,
    labelKey: 'newStore.consequent_land_transport',
    image: require('assets/images/sustainableIcons/Land Transportation.png'),
  },
];

export const origin = [
  { key: 0, labelKey: 'newStore.not_sure', image: null },
  {
    key: 1,
    labelKey: 'newStore.local',
    image: require('assets/images/sustainableIcons/Origin.png'),
  },
  {
    key: 2,
    labelKey: 'newStore.regional',
    image: require('assets/images/sustainableIcons/Origin 1.png'),
  },
  {
    key: 3,
    labelKey: 'newStore.far',
    image: require('assets/images/sustainableIcons/Origin 2.png'),
  },
  {
    key: 4,
    labelKey: 'newStore.international',
    image: null
  }
];

export function getStoreSustainabilityBadgesById(
  branchId,
  employeeId,
  mealcareId,
  bipocId,
  womenId,
  queerId,
  handicapId,
  comengageId,
  wasteId
) {
  return [
    {
      image: branchesNumber[branchId].image,
      label: i18next.t(branchesNumber[branchId].labelKey),
      desc: i18next.t('newStore.branches_in_total'),
    },
    {
      image: employeesNumber[employeeId].image,
      label: i18next.t(employeesNumber[employeeId].labelKey),
      desc: i18next.t('newStore.full_time_employees'),
    },
    {
      image: mealCare[mealcareId].image,
      label: i18next.t(mealCare[mealcareId].labelKey),
      desc: i18next.t('newStore.mealCare'),
    },
    {
      image: bipocOwned[bipocId].image,
      label: i18next.t(bipocOwned[bipocId].labelKey),
      desc: i18next.t('newStore.bipoc'),
    },
    {
      image: womenOwned[womenId].image,
      label: i18next.t(womenOwned[womenId].labelKey),
      desc: i18next.t('newStore.women'),
    },
    {
      image: queerOwned[queerId].image,
      label: i18next.t(queerOwned[queerId].labelKey),
      desc: i18next.t('newStore.queer'),
    },
    {
      image: handicapFriendly[handicapId].image,
      label: i18next.t(handicapFriendly[handicapId].labelKey),
      desc: i18next.t('newStore.handicap'),
    },
    {
      image: communityEngage[comengageId].image,
      label: i18next.t(communityEngage[comengageId].labelKey),
      desc: i18next.t('newStore.communityEngage'),
    },
    {
      image: waste[wasteId].image,
      label: i18next.t(waste[wasteId].labelKey),
      desc: i18next.t('newStore.waste'),
    }
  ];
}

export function getItemSustainabilityBagdgesById(
  packagingId,
  fscId,
  eolRecollectionId,
  organicId,
  ecologoId,
  ascId,
  palmoilId,
  byomId,
  greensealId,
  oceanwiseId,
  rainforestId,
  mscId,
  fairtradeId,
  kosherId,
  peanutId,
  dairyId,
  sugarId,
  veganId,
  halalId,
  glutenId,
  vegetarianId,
  constituentId,
  landtransportId,
  originId
) {
  return [
    {
      image: packaging[packagingId].image,
      label: i18next.t(packaging[packagingId].labelKey),
      desc: i18next.t('newStore.type_packaging'),
    },
    {
      image: organic[organicId].image,
      label: i18next.t(organic[organicId].labelKey),
      desc: i18next.t('newStore.organic'),
    },
    {
      image: fsc[fscId].image,
      label: i18next.t(fsc[fscId].labelKey),
      desc: i18next.t('newStore.fsc'),
    },
    {
      image: eolRecollection[eolRecollectionId].image,
      label: i18next.t(eolRecollection[eolRecollectionId].labelKey),
      desc: i18next.t('newStore.eolRecollection'),
    },
    {
      image: ecoLogo[ecologoId].image,
      label: i18next.t(ecoLogo[ecologoId].labelKey),
      desc: i18next.t('newStore.ecoLogo'),
    },
    {
      image: asc[ascId].image,
      label: i18next.t(asc[ascId].labelKey),
      desc: i18next.t('newStore.asc'),
    },
    {
      image: palmOil[palmoilId].image,
      label: i18next.t(palmOil[palmoilId].labelKey),
      desc: i18next.t('newStore.palmOil'),
    },
    {
      image: byom[byomId].image,
      label: i18next.t(byom[byomId].labelKey),
      desc: i18next.t('newStore.byom'),
    },
    {
      image: greenSeal[greensealId].image,
      label: i18next.t(greenSeal[greensealId].labelKey),
      desc: i18next.t('newStore.greenSeal'),
    },
    {
      image: oceanWise[oceanwiseId].image,
      label: i18next.t(oceanWise[oceanwiseId].labelKey),
      desc: i18next.t('newStore.oceanWise'),
    },
    {
      image: rainforest[rainforestId].image,
      label: i18next.t(rainforest[rainforestId].labelKey),
      desc: i18next.t('newStore.rainforest'),
    },
    {
      image: msc[mscId].image,
      label: i18next.t(msc[mscId].labelKey),
      desc: i18next.t('newStore.msc'),
    },
    {
      image: fairTrade[fairtradeId].image,
      label: i18next.t(fairTrade[fairtradeId].labelKey),
      desc: i18next.t('newStore.fairTrade'),
    },
    {
      image: kosher[kosherId].image,
      label: i18next.t(kosher[kosherId].labelKey),
      desc: i18next.t('newStore.kosher'),
    },
    {
      image: peanut[peanutId].image,
      label: i18next.t(peanut[peanutId].labelKey),
      desc: i18next.t('newStore.peanut'),
    },
    {
      image: dairy[dairyId].image,
      label: i18next.t(dairy[dairyId].labelKey),
      desc: i18next.t('newStore.dairy'),
    },
    {
      image: sugar[sugarId].image,
      label: i18next.t(sugar[sugarId].labelKey),
      desc: i18next.t('newStore.sugar'),
    },
    {
      image: vegan[veganId].image,
      label: i18next.t(vegan[veganId].labelKey),
      desc: i18next.t('newStore.vegan'),
    },
    {
      image: halal[halalId].image,
      label: i18next.t(halal[halalId].labelKey),
      desc: i18next.t('newStore.halal'),
    },
    {
      image: gluten[glutenId].image,
      label: i18next.t(gluten[glutenId].labelKey),
      desc: i18next.t('newStore.gluten'),
    },
    {
      image: vegetarian[vegetarianId].image,
      label: i18next.t(vegetarian[vegetarianId].labelKey),
      desc: i18next.t('newStore.vegetarian'),
    },
    {
      image: constituent[constituentId].image,
      label: i18next.t(constituent[constituentId].labelKey),
      desc: i18next.t('newStore.constituents'),
    },
    {
      image: landTransportation[landtransportId].image,
      label: i18next.t(landTransportation[landtransportId].labelKey),
      desc: i18next.t('newStore.landtransport'),
    },
    {
      image: origin[originId].image,
      label: i18next.t(origin[originId].labelKey),
      desc: i18next.t('newStore.origin'),
    }
  ];
}

export const socialMediaTypes = {
  INSTAGRAM: 'Instagram',
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter',
  OPEN_TABLE: 'OpenTable',
  UBER_EATS: 'UberEats',
  SKIP_THE_DISHES: 'SkipTheDishes',
};

export const socialMediaTypesURLs = [
  {
    platform: 'Instagram',
    url: 'https://www.instagram.com/'
  },
  {
    platform: 'Facebook',
    url: 'https://www.facebook.com/'
  },
  {
    platform: 'Twitter',
    url: 'https://twitter.com/'
  },
  {
    platform: 'OpenTable',
    url: 'https://www.opentable.ca/'
  },
  {
    platform: 'UberEats',
    url: 'https://www.ubereats.com/'
  },
  {
    platform: 'SkipTheDishes',
    url: 'https://www.skipthedishes.com/'
  },
];

export function sortList(itemList) {
  if (!itemList) return undefined;
  return itemList.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });
}

export function sortPriceDescending(itemList) {
  if (!itemList) return undefined;
  return itemList.sort(function (a, b) {
    if (a.price < b.price) return -1;
    if (a.price > b.price) return 1;
    return 0;
  });
}

export function sort2Lists(itemList) {
  if (!itemList) return undefined;
  return itemList.sort(function (a, b) {
    if (a.item && b.item) {
      if (a.item.name.toLowerCase() < b.item.name.toLowerCase()) return -1;
      if (a.item.name.toLowerCase() > b.item.name.toLowerCase()) return 1;
    } else if (a.store && b.store) {
      if (a.store.name.toLowerCase() < b.store.name.toLowerCase()) return -1;
      if (a.store.name.toLowerCase() > b.store.name.toLowerCase()) return 1;
    } else if (a.item && b.store) {
      if (a.item.name.toLowerCase() < b.store.name.toLowerCase()) return -1;
      if (a.item.name.toLowerCase() > b.store.name.toLowerCase()) return 1;
    } else {
      if (a.store.name.toLowerCase() < b.item.name.toLowerCase()) return -1;
      if (a.store.name.toLowerCase() > b.item.name.toLowerCase()) return 1;
    }
    return 0;
  });
}

export function mergingLists(itemList, storeList) {
  let result = [];
  for (let i = 0; i < itemList.length; i++) {
    let entity = {};
    entity.item = itemList[i];
    entity.store = null;
    result.push(entity);
  }
  for (let i = 0; i < storeList.length; i++) {
    let entity = {};
    entity.item = null;
    entity.store = storeList[i];
    result.push(entity);
  }
  return result;
}

export const joinedDate = (date) => {
  let months = [
    i18next.t('months.jan_month'),
    i18next.t('months.feb_month'),
    i18next.t('months.mar_month'),
    i18next.t('months.apr_month'),
    i18next.t('months.may_month'),
    i18next.t('months.jun_month'),
    i18next.t('months.jul_month'),
    i18next.t('months.aug_month'),
    i18next.t('months.sep_month'),
    i18next.t('months.oct_month'),
    i18next.t('months.nov_month'),
    i18next.t('months.dec_month'),
  ];
  if (date !== undefined) {
    let month = date.slice(5, 7) - 1;
    let day = date.slice(8, 10);
    let year = date.slice(0, 4);
    return months[month] + ' ' + day + ' ' + year;
  }
};

export const paths = {
  HOME: '/',
  TEAM: '/about',
  JOIN: '/join-the-team',
  BROWSE: '/browse',
  SUBSCRIPTIONS: '/subscriptions',
  STORES: '/stores/:storeName?/:storeId?/:storeHandle/',
  ADD_STORE: '/add/store',
  ADD_LIST: '/add/list',
  ADD_ITEM: '/add/item',
  MYLISTS: '/lists',
  LIST: '/lists/:listId',
  EDIT_LIST: '/lists/:listId/edit',
  SIGNIN: '/signin',
  EDIT_PROFILE: '/editProfile',
  CHANGE_PASSWORD: '/changePassword',
  PWDRESET: '/resetPassword',
  FORGOTPWD: '/forgotPassword',
  SIGNUP: '/signup',
  PROFILE: '/profile',
  FEEDBACK_DASHBOARD: '/dashboard/feedback',
  ANALYTICS: '/dashboard/analytics',
  TESTING: '/testing',
  LEVELS_LEGACY: '/levels',
  LEVEL_PAGE: '/testing/level-',
  TESTING_CHECK_BACK_LATER: '/testing/checkbacklater',
  TESTING_REGISTER: '/testing/register',
  CAREER: '/career',
  JOB: '/career/*',
  MENU: '/*/menu',
  AGREEMENT: '/serviceAgreement',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  CHAT: '/chat',
  ERROR: '*',
  REDIRECT_STORE: '/mobileApp',
};

export function searchShowing(object, searchInput) {
  if (searchInput === '') return true;

  var filterSplit = searchInput.split(' ');
  var matches = [];
  for (var filter of filterSplit) {
    filter = filter.toLowerCase();
    if (filter === '') {
      matches.push(false);
      break;
    }

    if (object.name != null) {
      if (object.name.toLowerCase().includes(filter)) {
        matches.push(true);
        continue;
      }
    }

    if (object.fullName != null) {
      if (object.fullName.toLowerCase().includes(filter)) {
        matches.push(true);
        continue;
      }
    }

    if (object.title != null) {
      if (object.title.toLowerCase().includes(filter)) {
        matches.push(true);
        continue;
      }
    }

    if (object.label != null) {
      if (object.label.toLowerCase().includes(filter)) {
        matches.push(true);
        continue;
      }
    }

    if (object.description != null) {
      if (object.description.toLowerCase().includes(filter)) {
        matches.push(true);
        continue;
      }
    }

    if (object.text != null) {
      if (object.text.toLowerCase().includes(filter)) {
        matches.push(true);
        continue;
      }
    }

    if (object.tags != null) {
      for (let i = 0; i < object.tags.length; i++) {
        if (object.tags[i].toLowerCase().includes(filter)) {
          matches.push(true);
          continue;
        }
      }
    }

    if (object.ingredients != null) {
      for (let i = 0; i < object.ingredients.length; i++) {
        if (object.ingredients[i].toLowerCase().includes(filter)) {
          matches.push(true);
          continue;
        }
      }
    }

    matches.push(false);
  }
  if (matches.find((x) => x === true)) return true;
  else return false;
}

export function inStoreViewPage() {
  return window.location.href.includes('stores');
}