import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from './SectionTitle';
import useAddStoreStyles from '../addStore/SharedAddStoreStyles';
import { getAllStoreHandles } from 'api/StoresApi';
import storeIcon from '../../../assets/images/addPages/store.webp'
import Select from 'react-select';
import { Box } from '@material-ui/core';

const StorePicker = (props) => {
    const classes = useAddStoreStyles();
    const { t } = useTranslation();
    const [stores, setStores] = useState([]);
    const [storeSelected, setStoreSelected] = useState(false);

    const failCallback = (statusCode, body) => {
        // do nothing
    }

    const successCallback = (body) => {
        let array = body.map(x => {
            x.label = x.name;
            x.value = x.id
            return x;
        });
        // Preload value and call onChange
        if (props.entity.storeId !== undefined) {
            const selectedStore = array.find((obj) => obj.id === props.entity.storeId);
            if (selectedStore)
              props.getSections(selectedStore.id);
        }
        if(props.store !== undefined){
            props.entity.storeId = props.store.id
        }
        setStores(array);
    }

    useEffect(() => {
        getAllStoreHandles(successCallback, failCallback)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.entity.storeId, props.store.id]);

    return (
        <>
        <SectionTitle
            iconSrc={storeIcon}
            title={t('additem.store_label')}
            required={true}
        />
        <Box className={classes.root}>
            <div className='storeHandles' id='storeHandles'>
            <Select
                isDisabled={props.entity.storeId != null && !storeSelected}
                value={props.entity != null ? stores.find((obj) => obj.id === props.entity.storeId) : null}
                options={stores} 
                isClearable={true}
                isSearchable={true}
                onChange={(x) => {
                    setStoreSelected(true)
                    props.getSections(x.id)
                }}
                isLoading={stores.length<1}
            />
            </div>
        </Box>
        </>
    );
};

export default React.memo(StorePicker);