import { onlineAPI, listEndpoints, sortList } from 'stocateConstants';
import { checkLocalStorageForToken } from './CommonApi';
import { FetchError } from '../types/common';

export async function getLibraryWithId(
  libraryId,
  successCallback,
  failCallback,
  signal
) {
  try {
    const response = await fetch(
      onlineAPI + listEndpoints + 'library/' + libraryId,
      {
        signal,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
        },
      }
    );
    const data = await response.json();

    if (response.ok) successCallback(data);
    else failCallback(response.status, data);
  } catch (error) {
    if (!signal.aborted) failCallback(500, error);
  }
}

export async function getListWithId(listId, successCallback, failCallback) {
  await fetch(onlineAPI + listEndpoints + listId, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: checkLocalStorageForToken().exists
        ? `Bearer ${checkLocalStorageForToken().token}`
        : '',
    },
  }).then((res) => {
    if (res.status === 500) {
      failCallback(res.status);
    } else {
      res.json().then((jsonRes) => {
        if (res.status === 200) {
          successCallback(jsonRes);
        } else {
          failCallback(res.status, jsonRes);
        }
      });
    }
  });
}

export async function getListSectionWithId(
  listId,
  successCallback,
  failCallback
) {
  await fetch(onlineAPI + listEndpoints + listId + '/sections', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: checkLocalStorageForToken().exists
        ? `Bearer ${checkLocalStorageForToken().token}`
        : '',
    },
  }).then((res) => {
    if (res.status === 500) {
      failCallback(res.status);
    } else {
      res.json().then((jsonRes) => {
        if (res.status === 200) {
          successCallback(jsonRes);
        } else {
          failCallback(res.status, jsonRes);
        }
      });
    }
  });
}

export async function getPublicLists(successCallback, failCallback) {
  await fetch(onlineAPI + listEndpoints + 'library/public', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: checkLocalStorageForToken().exists
        ? `Bearer ${checkLocalStorageForToken().token}`
        : '',
    },
  }).then((res) => {
    if (res.status === 500) {
      failCallback(res.status);
    } else {
      res.json().then((jsonRes) => {
        if (res.status === 200) {
          successCallback(jsonRes.lists);
        } else {
          failCallback(res.status, jsonRes);
        }
      });
    }
  });
}

export async function deleteList(
  listId,
  successCallback,
  failCallback,
  signal
) {
  try {
    const response = await fetch(onlineAPI + listEndpoints + listId, {
      signal,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: checkLocalStorageForToken().exists
          ? `Bearer ${checkLocalStorageForToken().token}`
          : '',
      },
    });
    const data = await response.json();

    if (response.ok) successCallback(data);
    else failCallback(response.status, data);
  } catch (error) {
    if (!signal.aborted) failCallback(500, error);
  }
}

export async function getAllPublicListLimit(failCallback, count, search, sort) {
  let url = count != null ? onlineAPI + listEndpoints + 'library/public/limit?count=' + count : onlineAPI + listEndpoints + 'library/public';
  if(search != null){
      if(search.trim() !== '')
          url = url + '&search=' + search;
  }
  if (sort != null) {
		if (sort.trim() !== '') {
			url = url + '&sort=' + sort;
		}
	}
  try {

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': checkLocalStorageForToken().exists ? 'Bearer ' + checkLocalStorageForToken().token : ''
      }
    })

    if (response.ok) {
        const data = await response.json();
        if(sort != null)
				  return data;
			  else
				  return (sortList(data));
    } else {
        throw new FetchError(response.status, 'Failed to fetch data');
    }
    } catch (error) {
      // Handle any exceptions here
      failCallback();
    }
}
// export async function deleteSection(type, sectionId, successCallback, failCallback, signal) {
//     try {
//         const response = await fetch(onlineAPI + listEndpoints + 'sections/' + type + "/" + sectionId, {
//             signal,
//             method: 'DELETE',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Authorization': checkLocalStorageForToken().exists ? `Bearer ${checkLocalStorageForToken().token}` : ''
//             }
//         })
//         const data = await response.json();

//         if (response.ok) successCallback(data);
//         else failCallback(response.status, data);
//     } catch (error) {
//         if (!signal.aborted) failCallback(500, error);
//     }
// }

// export async function deleteEntityInSection(entityToDelete, type, successCallback, failCallback, signal) {
//     try {
//         const response = await fetch(onlineAPI + listEndpoints + 'sections/delete/' + type, {
//             method: 'DELETE',
//             body: JSON.stringify(entityToDelete),
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Authorization': checkLocalStorageForToken().exists ? `Bearer ${checkLocalStorageForToken().token}` : ''
//             }
//         });
//         const data = await response.json();

//         if (response.ok) successCallback(data);
//         else failCallback(response.status, data);
//     } catch (error) {
//         if (!signal.aborted) failCallback(500, error);
//     }
// }
