import React, { useEffect } from 'react';
import {appleStore, playStore} from '../stocateConstants'

const RedirectToAppStore = () => {
    function isAndroid() {
        return /Android/.test(navigator.userAgent);
    }
    function isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }
    useEffect(() => {
        if (isIOS()) {
            window.location.href = appleStore;
        }
        else if (isAndroid()) {
            window.location.href = playStore;
        }
    }, []);
    return <div></div>;
}
export default RedirectToAppStore;