import IItem from '../types/dto/item';
import IStore from '../types/dto/store';
import IList from '../types/dto/list';

export type IEntity = IItem | IStore | IList;

export type EntityString = 'store' | 'list' | 'item';

export class FetchError extends Error {
  constructor(public code: number, message: string) {
    super(message);
  }
}
