import React from 'react';
import copyIcon from '../../assets/images/3dotoptions/copy.webp';
import styles from '../main/store/Store.module.css';
import i18next from 'i18next';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

interface CopyProps {
  onClick: () => void;
}

function Copy({ onClick }: CopyProps): JSX.Element {
  return (
    <>
      <Button
        style={{
          maxWidth: '24px',
          maxHeight: '24px',
          minWidth: '24px',
          minHeight: '24px',
          borderRadius: 5,
          marginLeft: 20,
        }}
        onClick={() => onClick()} // Correctly bind the onClick function to the button's click event.
      >
        <Tooltip title={i18next.t('library.duplicate') as string}>
          <img
            src={copyIcon}
            className={styles.shareIcon}
            alt=''
          />
        </Tooltip>
      </Button>
    </>
  );
}

export default Copy;