import React, { useEffect } from 'react'
import Container from '@material-ui/core/Typography';
import Typography from '@material-ui/core/Container';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './JoinTheTeam.module.css';
import CollapsibleCard from '../../reusable/CollapsibleCard';
import StocateButton from '../../../components/reusable/StocateButton';
import {stocateEmail, paths} from '../../../stocateConstants.js'
import { sendPageView } from 'utils/GoogleAnalytics';

function JoinTheTeam() {

    useEffect(() => {
		sendPageView(paths.JOIN);
	}, []);
    
    return (
        <>
           	<Container component='main' maxwidth='lg'>
                <div className={styles.banderole} >
					<p>{i18next.t('jointeam.title')}</p>
				</div>

                <br/>

                <Typography component='h3'>{i18next.t('jointeam.text1')}</Typography>
                <br/>
                <div style={{marginLeft:50, marginRight:50}}>
                    <CollapsibleCard
                        title_left = {i18next.t('jointeam.job1')}
                        title_right = {i18next.t('jointeam.hour1')}
                        body_content = {i18next.t('jointeam.description1')}
                        borderRadius = {36}
                        body_padding = {'10px'}
                    />
                    <br/>

                    <CollapsibleCard
                        title_left = {i18next.t('jointeam.job2')}
                        title_right = {i18next.t('jointeam.hour2')}
                        body_content = {i18next.t('jointeam.description2')}
                        borderRadius = {36}
                        body_padding = '10px'
                    />
                    <br/>
                </div>
                
                <Typography component='h2'>{i18next.t('jointeam.mission')}</Typography>
                <br/>
                <div style={{marginLeft:50, marginRight:50}}>
                    <CollapsibleCard
                        title_left = {i18next.t('jointeam.subtitle2')}
                        body_content = {i18next.t('jointeam.text8')}
                        borderRadius = {10}
                        body_padding = '10px'
                    />
                    <br/>

                    <CollapsibleCard
                        title_left = {i18next.t('jointeam.subtitle3')}
                        body_content = {i18next.t('jointeam.text9')}
                        borderRadius = {10}
                        body_padding = '10px'
                    />
                    <br/>

                    <CollapsibleCard
                        title_left = {i18next.t('jointeam.subtitle4')}
                        body_content = {i18next.t('jointeam.text10')}
                        borderRadius = {10}
                        body_padding = '10px'
                    />
                </div>
                <div style={{'text-align': 'center'}}>
                    <a id='mail' href={stocateEmail} style={{ color: 'inherit' }}>
                        <div className={styles.buttonPosition}>
                            <StocateButton href={stocateEmail}>
                                {i18next.t('jointeam.button_apply')}
                            </StocateButton>
                        </div>
                    </a>
                </div>
        </Container>
        </>
    );
}

export default withTranslation()(JoinTheTeam);