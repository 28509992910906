import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import websiteIcon from '../../../assets/images/socialMedia/website.webp';
import useAddStoreStyles from './SharedAddStoreStyles';

import TextField from '@material-ui/core/TextField';

import IAddStore from '../../../types/add/IAddStore';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SectionTitle from '../addPages/SectionTitle';

const AddStoreLinks = ({
  setStore,
  entity,
}: {
  setStore: React.Dispatch<React.SetStateAction<IAddStore>>;
  entity: any;
}) => {
  const classes = useAddStoreStyles();
  const { t } = useTranslation();

  const onContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setStore((prevStore) => ({
      ...prevStore,
      [event.target.name]: event.target.value,
    }));
  };

  const links = [
    {
      type: 'website',
      placeholder: 'E.g. www.stocate.com',
      onChange: onContactChange,
      TextComponent: TextField,
      value: entity.website,
    },
  ];

  return (
    <>
      <SectionTitle iconSrc={websiteIcon} title={t('addstore.website_label')} />
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: `min-content auto`,
          rowGap: '0.5em',
          columnGap: '0.5em',
          alignItems: 'center',
        }}
        className={classes.root}
      >
        {links.map(
          ({TextComponent, type, ...restOfProps }) => (
            <Fragment key={type}>
              <Grid
                container={true}
                wrap='nowrap'
                alignItems='center'
                spacing={1}
              >
              </Grid>

              <TextComponent
                variant='outlined'
                size='small'
                fullWidth={true}
                name={type}
                {...restOfProps}
              />
            </Fragment>
          )
        )}
      </Box>
    </>
    );
};

export default React.memo(AddStoreLinks);