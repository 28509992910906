import React from 'react';

import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import stocateLogo from '../../assets/images/about/stocate-logo-transparent.png';

import { Box, Button, Container, Typography } from '@material-ui/core';

import styles from '../main/error-404/ErrorPage.module.css';

function ApiErrorView({ code, text }: { code: number; text: string }) {
  const history = useHistory();
  const sendToBrowse = () => history.push('/browse');

  return (
    <>
      <Container component='main' maxWidth='lg'>
        <Box display='flex' justifyContent='center'>
          <div className={styles.globalFrame} style={{ minHeight: '80vh' }}>
            <div className={styles.text}>
              <Typography variant='h5'>
                {i18next.t('errorpage.title_api')}
                {code}
              </Typography>
              <div className={styles.logo}>
                <img
                  src={stocateLogo}
                  alt='Stocate Logo'
                />
                <Typography variant='h5'>{text}</Typography>
                <Typography variant='h5'>
                  {i18next.t('errorpage.contact')}
                </Typography>
                <Typography variant='h5'>
                  {i18next.t('errorpage.thanks')}
                </Typography>
                <div className={styles.buttons}>
                  <Button variant='contained' onClick={sendToBrowse}>
                    {i18next.t('titles.browse')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
}

export default ApiErrorView;