import { useQuery } from 'react-query';
import { useAuth } from '../context/AuthContext';
import { onlineAPI, listEndpoints, sortList } from '../stocateConstants';
import { checkLocalStorageForToken } from '../api/CommonApi';
import { StocateQuery } from '../utils/queryClient';
import { FetchError } from '../types/common';
import IList from '../types/dto/list';

const useMyLists = () => {
  const {
    state: { user },
  } = useAuth();
  const { libraryId } = user || {};

  const options = {
    // The query will not execute until the libraryId exists
    enabled: !!libraryId,
  };

  const query = useQuery<IList[], FetchError>(
    StocateQuery.MYLISTS,
    async ({ signal }) => {
      try {
        const response = await fetch(
          `${onlineAPI}${listEndpoints}library/${libraryId}`,
          {
            signal,
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: checkLocalStorageForToken().exists
                ? `Bearer ${checkLocalStorageForToken().token}`
                : '',
            },
          }
        );
        const data = await response.json();
        if (response.ok) return sortList(data.lists);
        else throw new FetchError(response.status, data);
      } catch (error) {
        if (!signal?.aborted) throw error;
      }
    },
    options
  );

  return {
    ...query,
    data: query.data ?? [], // use empty array until data arrives
  };
};

export default useMyLists;
