import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from './SectionTitle';
import useAddStoreStyles from '../addStore/SharedAddStoreStyles';
import paymentIcon from '../../../assets/images/addPages/payment.webp';


import TextField from '@material-ui/core/TextField';

import IAddItem from '../../../types/add/IAddItem';
import { Box, Grid, MenuItem } from '@material-ui/core';
import { currencies } from '../../../stocateConstants';

const PriceInput = ({
  setEntity,
  entity,
}: {
  setEntity:
  | React.Dispatch<React.SetStateAction<IAddItem>>;
  entity: any;
}) => {
  const classes = useAddStoreStyles();
  const { t } = useTranslation();

  const [currency, setCurrency] = React.useState('CAD');
  const onCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(event.target.value);
  };

  const onPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setEntity((prevEntity: any) => ({
      ...prevEntity,
      price: event.target.value,
    }));
  };

  return (
    <>
      <SectionTitle
        iconSrc={paymentIcon}
        title={t('additem.price_label')}
        required={false}
      />
      <Box className={classes.root}>
        <Grid container={true} spacing={2}>
          <Grid item={true}>
            <TextField
              id='outlined-select-currency'
              select={true}
              value={currency}
              onChange={onCurrencyChange}
              helperText='Please select the currency'
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true}>
            <TextField
              value={entity != null ? entity.price : null}
              fullWidth={false}
              placeholder={t('additem.price_label')}
              helperText='Please input price'
              onChange={onPriceChange}
              onKeyPress={(event) => {
                const target = event.target as HTMLInputElement; // Cast event.target to HTMLInputElement
                const value = target.value + event.key; // Concatenate the current value with the pressed key
                if (!/^\d{0,4}(?:\.\d{0,2})?$/.test(value)) {
                  event.preventDefault();
                  // Invalid input, handle accordingly
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default React.memo(PriceInput);
