import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import addToListIcon from '../../../../../assets/images/3dotoptions/add_to_list_black.webp';
import { useOptionStyles } from '../SharedOptionStyles';
import { useAlert } from '../../../../../context/AlertContext';
import { useAuth } from '../../../../../context/AuthContext';
import useAddToMyList from '../../../../../hooks/useAddToMyLists';
import { StocateQuery, queryClient } from '../../../../../utils/queryClient';

import MenuItem from '@material-ui/core/MenuItem';

import { ListOptionProps } from '../ListCellOptions';

const AddToListMenuOption = forwardRef(
  ({ closeMenuAfter, list }: ListOptionProps, ref) => {
    const classes = useOptionStyles();
    const {
      state: { user },
    } = useAuth();
    const { alertSuccess, alertWarning, alertError } = useAlert();
    const addToMyLists = useAddToMyList();
    const { t } = useTranslation();

    const onClick = () => {
      if (!user?.libraryId) return;
      alertWarning(t('message.processing'));
      addToMyLists.mutate(
        { listId: list.id, libraryId: user.libraryId },
        {
          onSuccess: () => {
            if (list.follow) alertSuccess(t('library.unfollow_success'));
            else alertSuccess(t('library.follow_success'));
            queryClient.invalidateQueries(StocateQuery.MYLISTS);
            queryClient.invalidateQueries(StocateQuery.LISTS);
          },
          onError: (error) => {
            const { code, message } = error;
            if (code === 401 && message != null) {
              if (message.includes('User does not have permissions'))
                alertError(t('errorCode.permission_list_401'));
              else if (message.includes('Cannot follow your own list'))
                alertError(t('errorCode.follow_list_401'));
              else alertError(t('errorCode.unexpected'));
            } else if (code === 404) alertError(t('errorCode.listView_404'));
            else alertError(t('errorCode.unexpected'));
          },
        }
      );
    };

    return (
      <MenuItem
        onClick={closeMenuAfter(onClick)}
        {...({ ref } as any)}
        data-cy={`addToMyLists-${list.id}`}
      >
        <img
          src={addToListIcon}
          className={classes.optionIcon}
          alt='add to my lists icon'
        />
        {list.follow ? t('library.unfollow') : t('library.follow')}
      </MenuItem>
    );
  }
);

export default AddToListMenuOption;
