import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import profileImage from '../../../assets/images/profile/profile.webp';
import { paths, joinedDate } from '../../../stocateConstants';
import { sendPageView } from '../../../utils/GoogleAnalytics';
import { useAuth } from '../../../context/AuthContext';
import { useAlert } from '../../../context/AlertContext';

import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  icon: {
    maxWidth: '300px',
    width: '80%',
    height: '100%',
  },
  stocateBtn: {
    backgroundColor: '#01647B',
    color: 'white',
  },
}));

const Profile = () => {
  const { dispatch, state: authState } = useAuth();
  const { alertInfo } = useAlert();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    sendPageView(paths.PROFILE);
  }, []);

  const handleSignout = async () => {
    alertInfo(t('signin.signout'));
    dispatch({ type: 'SIGNOUT' });
    history.push(paths.HOME);
  };

  const goToEditProfile = () => {
    window.location.href = '/editProfile'
  }


  return (
    <Container>
      <Grid
        container={true}
        spacing={3}
        direction='column'
        justify='center'
        alignItems='center'
        style={{ minHeight: '78vh' }}
      >
        <img className={classes.icon} src={profileImage} alt='' />
        <Grid item={true}>
          <Typography variant='h3' component='p'>
            {authState.user?.firstName} {authState.user?.lastName}
          </Typography>
        </Grid>
        <Grid
          item={true}
          container={true}
          spacing={2}
          direction='column'
          alignContent='center'
        >
          <Grid item={true}>
            <Typography>
              {t('signup.username')}: {authState.user?.username}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography>
              {t('signup.email')}: {authState.user?.email}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography>
              {t('profile.joined')}:
              {` ${joinedDate(authState.user?.dateCreated)}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item={true}
          container={true}
          spacing={8}
          direction='row'
          justify='center'
          alignItems='center'
        >
          <Grid item={true}>
            <Button
              onClick={handleSignout}
              classes={{ root: classes.stocateBtn }}
              variant='contained'
              data-cy='signoutBtn'
              style={{ width: '8rem', height: '3rem' }}
            >
              {t('titles.sign_out')}
            </Button>
          </Grid>
          <Grid item={true}>
            <Button
              onClick={goToEditProfile}
              classes={{ root: classes.stocateBtn }}
              variant='contained'
              // data-cy='signoutBtn'
              style={{ width: '8rem', height: '3rem' }}
            >
              {t('profile.edit_profile')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;
