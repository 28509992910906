import React from 'react';
import shareIcon from '../../assets/images/store/share.png';
import styles from '../main/store/Store.module.css';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { postShare } from './../../api/LoggingApi';

Share.propType = {
  entity: PropTypes.any,
  copyingLink: PropTypes.any,
};

Share.defaultProps = {
  entity: null,
  copyingLink: null,
};

function Share({ entity, copyingLink }: any): JSX.Element {
  const share = () => {
    // API call to track searches
    // !!! TODO: add userId once web sign in is done

    const jsonBody: any = {};
    entity.itemHandle !== undefined
      ? (jsonBody.itemId = entity.id)
      : (jsonBody.storeId = entity.id);
    postShare(jsonBody);
    copyingLink();
  };

  return (
    <>
      <Button
        style={{
          maxWidth: '24px',
          maxHeight: '24px',
          minWidth: '24px',
          minHeight: '24px',
          borderRadius: 5,
          marginLeft: 20,
        }}
      >
        <Tooltip title={i18next.t('storepage.copy_link') as string}>
          <img
            src={shareIcon}
            className={styles.shareIcon}
            alt=''
            onClick={() => share()}
          />
        </Tooltip>
      </Button>
    </>
  );
}

export default Share;
