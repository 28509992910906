// User Journey portion: > search (nav) > view store > view item
import React from 'react';
import feedbackIcon from '../../../../assets/images/general/feedback.png';
import { LevelTask } from '../TestingUtils';
import SearchStore from '../../../../assets/images/pilot-testing/howto-appfeedback/levelPhotos/Search Store.png';
import SelectStore from '../../../../assets/images/pilot-testing/howto-appfeedback/levelPhotos/Select Store.png';
import SelectItem from '../../../../assets/images/pilot-testing/howto-appfeedback/levelPhotos/Select Item.png';

const ENlvl2Tasks: LevelTask[] = [
  {
    taskNum: 1,
    task: 'Use the "Search" tab to search for a store that interests you (by searching keywords or just scrolling).',
    taskImage: SearchStore,
    taskImageAlt: 'search store',
    textBoxLabel:
      'How do you feel about the search page? What keywords did you use to search (if you used the search bar)?',
  },
  {
    taskNum: 2,
    task: `Tap on one of the search results of a store that interests you, to view that store's page.`,
    taskImage: SelectStore,
    taskImageAlt: 'select store',
    textBoxLabel: `How do you feel about the store page? Are there any symbols on the page which you didn't understand intuitively?`,
  },
  {
    taskNum: 3,
    task: `From within the store's page, tap on an item that interests you to view that item's page.`,
    taskImage: SelectItem,
    taskImageAlt: 'select item',
    textBoxLabel: `How do you feel about the item page? Is there any information about the item or functionality that's missing from the page?`,
  },
  {
    taskNum: 4,
    task: (
      <>
        {`Do you have any other feedback? Or anything that reduced your experience?\nUse the "Feedback" tab in the app or the `}
        <img src={feedbackIcon} alt='' style={{ height: 25, width: 25 }} />
        {' button in the bottom-right of this page.'}
      </>
    ),
  },
];

const FRlvl2Tasks: LevelTask[] = [
  {
    taskNum: 1,
    task: `Utilisez l'onglet « Rechercher » pour rechercher un magasin qui vous intéresse (en utilisant la barre de recherche ou en descendant simplement dans la liste de magasins).`,
    taskImage: SearchStore,
    taskImageAlt: 'rechercher un magasin',
    textBoxLabel:
      'Que pensez-vous de la page « Rechercher »? Quels mots clés avez-vous utilisés pour effectuer la recherche (si vous avez utilisé la barre de recherche)?',
  },
  {
    taskNum: 2,
    task: 'Appuyez sur un magasin qui vous intéresse parmi les résultats pour afficher sa page.',
    taskImage: SelectStore,
    taskImageAlt: 'sélectionner un magasin',
    textBoxLabel: `Que pensez-vous de la page du magasin? Y a-t-il des symboles sur la page que vous n'avez pas compris intuitivement?`,
  },
  {
    taskNum: 3,
    task: 'À partir de la page du magasin, appuyez sur un article qui vous intéresse pour afficher sa page.',
    taskImage: SelectItem,
    taskImageAlt: 'sélectionner un article',
    textBoxLabel: `Que pensez-vous de la page de l'article? Y a-t-il des renseignements manquantes sur l'article ou une fonctionnalité manquante sur cette page?`,
  },
  {
    taskNum: 4,
    task: (
      <>
        {`Avez-vous d'autres commentaires? Y a-t-il quelque chose qui a réduit votre expérience?\nUtilisez l'onglet « Commentaires » de l'application ou clickez le bouton `}
        <img src={feedbackIcon} alt='' style={{ height: 25, width: 25 }} />
        {' en bas à droite de cette page.'}
      </>
    ),
  },
];

export { ENlvl2Tasks, FRlvl2Tasks };
