import React from 'react';
import SectionTitle from './SectionTitle';
import { useTranslation } from 'react-i18next';

import penIcon from '../../../assets/images/general/pen.webp';
import useAddStoreStyles from '../addStore/SharedAddStoreStyles';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import IAddStore from '../../../types/add/IAddStore';
import IAddList from '../../../types/add/IAddList';
import IAddItem from '../../../types/add/IAddItem';

const NameField = ({
  setEntity,
  placeholder,
  entity,
}: {
  setEntity:
    | React.Dispatch<React.SetStateAction<IAddItem>>
    | React.Dispatch<React.SetStateAction<IAddStore>>
    | React.Dispatch<React.SetStateAction<IAddList>>;
  placeholder: string;
  entity: any;
}) => {
  const classes = useAddStoreStyles();
  const { t } = useTranslation();

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setEntity((prevStore: any) => ({
      ...prevStore,
      name: event.target.value,
    }));
  };

  return (
    <>
      <SectionTitle
        iconSrc={penIcon}
        title={t('addstore.name_label')}
        required={true}
      />
      <Box className={classes.root}>
        <TextField
          value={entity != null ? entity.name : null}
          fullWidth={true}
          variant='outlined'
          size='small'
          data-cy='addentity-name'
          placeholder={placeholder}
          onChange={onNameChange}
        />
      </Box>
    </>
  );
};

export default React.memo(NameField);
