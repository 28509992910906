import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { postLevel } from '../../../api/LevelsApi';
import { sendPageView } from '../../../utils/GoogleAnalytics';
import { useAlert } from '../../../context/AlertContext';
import useAbortController from '../../../hooks/useAbortController';
import useTesterLevels from '../../../hooks/useTesterLevels';
import LoadingSpinner from '../../../components/reusable/LoadingSpinner';

import { paths, defaultPreviewLinkImage, defaultPreviewLinkDescription } from '../../../stocateConstants';
import logo from '../../../assets/images/pilot-testing/howto-appfeedback/pilot_logo.png';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  stocateBtn: {
    textTransform: 'none',
    color: 'white',
    fontWeight: 'bolder',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#005366',
    },
    '&:not(:disabled):not(:hover)': {
      backgroundColor: '#01647B',
    },
  },
}));

const TestingRegister = ({ maxLevel }: { maxLevel: number }) => {
  const classes = useStyles();
  const history = useHistory();
  const getSignal = useAbortController();
  const { alertError } = useAlert();
  const { t } = useTranslation();
  const { isFetching } = useTesterLevels();
  const [loading, setLoading] = useState(false);

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - Tutorial');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/testing/register');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  const successCallback = useCallback(
    (body) => {
      history.push(paths.LEVEL_PAGE + 1);
    },
    [history]
  );

  const failCallback = useCallback(
    (statusCode) => {
      setLoading(false);
      if (statusCode === 401) alertError(t('pilot_testing.error_403'));
      else alertError(t('errorCode.unexpected'));
    },
    [alertError, t]
  );

  const onSubmit = useCallback(() => {
      setLoading(true);
      postLevel(0, null, successCallback, failCallback, getSignal());
    }, [successCallback, failCallback, getSignal]
  );

  useEffect(() => {
    updateMetaTags();
    sendPageView(paths.TESTING_REGISTER); // for Google Analytics
  }, []);

  if (isFetching) return <LoadingSpinner />;

  return (
    <Grid
      container={true}
      alignItems='center'
      justify='center'
      direction='column'
      wrap='nowrap'
      spacing={2}
      style={{ height: '77vh' }}
    >
      <Grid item={true}>
        <img
          src={logo}
          alt=''
          style={{
            height: '166.4px',
          }}
        />
      </Grid>
      <Grid item={true} style={{ textAlign: 'center' }}>
        <Typography variant='h6'>{t('pilot_testing.welcome')}</Typography>
        <Typography variant='h6'>{t('pilot_testing.stocate_brief')}</Typography>
      </Grid>
      <Grid item={true} style={{ textAlign: 'center', paddingLeft : '100px', paddingRight : '100px'}}>
        <Typography>{t('pilot_testing.explanation')}</Typography>
      </Grid>
      <Grid item={true} style={{ textAlign: 'center', paddingLeft : '100px', paddingRight : '100px'}}>
        <Typography>{t('pilot_testing.please_feedback')}</Typography>
      </Grid>
      <Grid item={true}>
        {loading ? (
          <CircularProgress color='primary' />
        ) : (
          <Button
            variant='contained'
            className={classes.stocateBtn}
            disabled={false}
            data-cy='tester_register'
            onClick={() => onSubmit()}
          >
            {t('pilot_testing.registration_submit')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default TestingRegister;
