import React, { SyntheticEvent, useState } from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import hidePasswordIcon from '../../assets/images/sign-up/hidePassword.png';
import showPasswordIcon from '../../assets/images/sign-up/showPassword.png';
import Button from '@material-ui/core/Button';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';

interface TextInputFieldProps {
  id: string;
  labelIcon: string;
  label: string;
  asterix?: string;
  type: 'text' | 'password';
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  multiline: boolean;
  xs: boolean | GridSize | undefined;
  maxLength: InputBaseComponentProps | undefined;
  placeholder: string;
  onEnter?: () => void;
}

function TextInputField({
  id,
  labelIcon,
  label,
  asterix,
  type,
  value,
  onChange,
  multiline,
  xs,
  maxLength,
  placeholder,
  onEnter
}: TextInputFieldProps) {
  const [passwordType, setPasswordType] = useState(type);
  const [passwordIcon, setPasswordIcon] = useState(hidePasswordIcon);

  const showPassword = () => {
    if (passwordType === 'text') {
      setPasswordType('password');
      setPasswordIcon(hidePasswordIcon);
    } else {
      setPasswordType('text');
      setPasswordIcon(showPasswordIcon);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
    }
  }

  return (
    <Grid
      item={true}
      container={true}
      direction='column'
      xs={xs}
      style={{ marginBottom: 20 }}
    >
      <Grid item={true} container={true} direction='row' alignItems='center'>
        {labelIcon && (
          <Grid item={true} xs='auto'>
            <img
              src={labelIcon}
              alt=''
              style={{
                height: 26,
                width: 26,
                marginBottom: 8,
                marginLeft: '0.75em',
                marginRight: '0.75em',
              }}
            />
          </Grid>
        )}
        <Grid item={true} xs='auto'>
          <InputLabel>{label}</InputLabel>
        </Grid>
        {asterix && (
          <Grid item={true} xs='auto'>
            <InputLabel style={{ marginLeft: '5px', color: 'red' }}>
              {asterix}
            </InputLabel>
          </Grid>
        )}
      </Grid>
      <Grid item={true} container={true} direction='row' alignItems='center'>
        <Grid item={true} xs={true}>
          <OutlinedInput
            id={id}
            data-cy={id}
            fullWidth={true}
            type={passwordType}
            inputProps={{ maxLength }}
            multiline={multiline}
            rows={4}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        {type === 'password' ? (
          <Grid item={true}>
            <Button
              onClick={showPassword}
              style={{
                maxWidth: '24px',
                maxHeight: '24px',
                minWidth: '24px',
                minHeight: '24px',
                borderRadius: 5,
                marginLeft: 20,
              }}
            >
              <img
                src={passwordIcon}
                alt=''
                style={{ width: 20, height: 20 }}
              />
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

TextInputField.defaultProps = {
  id: '',
  labelIcon: null,
  label: '',
  type: 'text',
  value: '',
  onChange: (e: SyntheticEvent) => {
    // console.log('on change');
  },
  multiline: false,
  xs: 5,
  maxLength: null,
  placeholder: '',
};

export default TextInputField;