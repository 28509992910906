import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/core';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    container: {
        'flex-direction': 'column',
        'max-width': '50%',
        'margin': 'auto'
    },
    mainCard: {
        display: 'flex',
        minHeight: '50px',
        height: '8vh',
        backgroundColor: '#F4F4F4',
        '&:hover': {
            cursor: 'pointer',
        },
        margin: 10
    },
    cellContent: {
        position: 'relative',
        padding: '0.35em',
        overflow: 'hidden',
        flexWrap: 'nowrap',
    },
    entityTitle: {
        width: 'auto',
        maxWidth: '100%',
        display: 'inline-block',
    }
}));

const objectCellTheme = createMuiTheme({
    overrides: {
        MuiTypography: {
            root: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            body2: {
                fontSize: '0.75rem',
            },
        },
    },
});

const Career = () => {
    const classes = useStyles();
    const jobs = [
        {
            name: 'Business Development Volunteer',
            link: 'career/volunteer/business'
        },
        {
            name: 'Software Developer Volunteer',
            link: 'career/volunteer/software'
        },
        {
            name: 'UI/UX Volunteer',
            link: 'career/volunteer/ui'
        },
        {
            name: 'UI/UX & Business Development Volunteer',
            link: 'career/volunteer/hybrid-ui-business'
        },
        // ----------------------------------------------------------------------
        {
            name: 'Software Developer Intern',
            link: 'career/intern/2023/software'
        },
        {
            name: 'UI Intern',
            link: 'career/intern/2023/ui'
        },
        {
            name: 'UX Intern',
            link: 'career/intern/2023/ux'
        }
    ]

    return (
        <Grid container={true} className={classes.container}>
            {jobs.map((job) => {
                return (
                    <Card variant='outlined' key={job.name} className={classes.mainCard}>
                        <ThemeProvider theme={objectCellTheme}>
                            <Grid
                                container={true}
                                direction='column'
                                className={classes.cellContent}
                            >
                                <Grid item={true}>
                                    <Typography
                                        component={Link}
                                        to={job.link}
                                        variant='h5'
                                        className={classes.entityTitle}
                                    >
                                        {job.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </Card>
                )
            })}
        </Grid>
    );
};

export default Career;