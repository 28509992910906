import IWeeklyhrs from '../../../types/dto/weeklyhrs';
import IAddStore from '../../../types/add/IAddStore';
import IAddList from '../../../types/add/IAddList';
import IAddItem from '../../../types/add/IAddItem';

export const initialStartTime = '09:00';
export const initialEndTime = '17:00';

// The string that we use in the DB to represent each day
export enum DayKeys {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export const initialOpenHours: IWeeklyhrs[] = Object.values(DayKeys).map(
  (day) => ({
    day,
    startTime: initialStartTime,
    endTime: initialEndTime,
  })
);

export const initialStore: IAddStore = {
  name: '',
  description: '',
  phone: '',
  email: '',
  website: '',
  addLocation: {
    address: '',
  },
  tags: [],
  images: [],
  paymentTypes: [],
  socialMedia: [],
  openHours: [],
  sustainabilityMetrics: {
    branchesNumber: 0,
    employeesNumber: 0,
    mealCare: 2,
    bipocOwned: 2,
    womenOwned: 2,
    queerOwned: 2,
    handicapFriendly: 2,
    communityEngage: 0,
    waste: 0
  },
};

export const initialList: IAddList = {
  name: '',
  description: '',
  libraryId: 0,
  public: false,
  tags: [],
  images: [],
};

export const initialItem: IAddItem = {
  storeName: '',
  name: '',
  price: 0,
  sectionName: '',
  description: '',
  currency: '',
  tags: [],
  images: [],
  ingredients: [],
  sustainabilityMetrics: {
    packaging: 0,
    fsc: 2,
    eolRecollection: 0,
    organic: 2,
    ecoLogo: 2,
    asc: 2,
    palmOil: 2,
    byom: 2,
    greenSeal: 2,
    oceanWise: 2,
    rainforest: 2,
    msc: 2,
    fairTrade: 2,
    kosher: 2,
    peanut: 2,
    dairy: 2,
    sugar: 2,
    vegan: 2,
    halal: 2,
    gluten: 2,
    vegetarian: 2,
    constituent: 0,
    landTransportation: 0,
    origin: 0
  },
  available: true,
  newSection: '',
  storeSectionsListId: -1
}