import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { queryClient, StocateQuery } from '../../../utils/queryClient';
import { paths, defaultPreviewLinkImage, defaultPreviewLinkDescription } from '../../../stocateConstants';
import { initialList } from '../addPages/AddUtils';
import useAbortController from '../../../hooks/useAbortController';
import { useAlert } from '../../../context/AlertContext';
import useAddList from '../../../hooks/useAddList';
import { addListPicture } from '../../../api/AddListApi';
import ImagePicker from '../addPages/ImagePicker';
import TagPicker from '../addPages/TagPicker';
import DescriptionField from '../addPages/DescriptionField';
import NameInput from '../addPages/NameInput';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import IAddList from '../../../types/add/IAddList';
import IList from '../../../types/dto/list';
import ListPrivacyPicker from './ListPrivacyPicker';
import { useAuth } from '../../../context/AuthContext';

const AddList = () => {
  const { t } = useTranslation();
  const {
    state: { user },
  } = useAuth();
  const history = useHistory();
  const getSignal = useAbortController();
  const { alertSuccess, alertWarning, alertError } = useAlert();
  const addList = useAddList();
  const [list, setList] = useState<IAddList>(initialList);

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - Add List');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/add/list');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  useEffect(() => {
    updateMetaTags();
  });

  const onSubmit = () => {
    const nameMissing = list.name.trim() === '';
    if (nameMissing) return alertError(t('addlist.name_missing'));
    alertWarning(t('message.processing'));
    addList.mutate(
      { list: { ...list, libraryId: user?.libraryId ?? 0, images: [] } },
      {
        onSuccess: (newList: IList) => {
          const redirectPath = generatePath(paths.LIST, {
            listId: newList.id,
          });
          const successCallback = () => {
            alertSuccess(t('addlist.success_modal_header'));
            queryClient.invalidateQueries(StocateQuery.LISTS);
            queryClient.invalidateQueries(StocateQuery.MYLISTS);
            history.push(redirectPath);
          };
          if (list.images.length > 0) {
            addListPicture(
              getSignal(),
              newList,
              list.images,
              newList.imagesLeft?.length
            )
              .then(successCallback)
              .catch(() => {
                alertError(t('addlist.images_error'));
                history.push(redirectPath);
              });
          } else successCallback();
        },
        onError: (error) => {
          const { code, message } = error;
          if (code === 401) {
            alertError(t('addlist.permission_error'));
          } else if (code === 404 && message != null) {
            if (message.includes('Library does not exist'))
              alertError(t('errorCode.library_404'));
            else if (message.includes('List does not exist'))
              alertError(t('errorCode.listView_404'));
            else alertError(t('errorCode.unexpected'));
          } else alertError(t('errorCode.unexpected'));
        },
      }
    );
  };

  return (
    <Container style={{ maxWidth: '800px' }}>
      <Grid container={true} direction='column' spacing={4}>
        <Grid item={true}>
          <Typography variant='h4' style={{ textAlign: 'center' }}>
            {t('addlist.title')}
          </Typography>
        </Grid>
        <Grid item={true}>
          <NameInput
            entity={null}
            setEntity={setList}
            placeholder={t('addlist.name_placeholder')}
          />
        </Grid>
        <Grid item={true}>
          <DescriptionField
            entity={null}
            setEntity={setList}
            placeholder={t('addlist.description_placeholder')}
          />
        </Grid>
        <Grid item={true}>
          <TagPicker entity={list} setEntity={setList} />
        </Grid>
        <Grid item={true}>
          <ImagePicker setEntity={setList} />
        </Grid>
        <Grid item={true}>
          <ListPrivacyPicker setList={setList} />
        </Grid>
        <Grid item={true} style={{ textAlign: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            style={{ width: 241, height: 56, fontSize: 24, fontWeight: 'bold' }}
            onClick={onSubmit}
            data-cy='addlist-submit'
          >
            {t('addstore.submit')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddList;
