import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import { useHistory } from 'react-router-dom';
import { storesEndpoints } from 'stocateConstants';
import markerIcon from '../../../../../assets/images/store/marker.png';

const StoreMarker = (props) => {
	const history = useHistory();

    const sendToStore = () => history.push({
        pathname: '/' + storesEndpoints + props.store.storeHandle
	});

	const onCloseClick = () => {
		props.onMarkerClick(); // Close this marker's card
	};

	return (
		<>
			<div>
				<img
					style={{
						cursor: 'pointer'
					}}
					onClick={props.onMarkerClick}
					src={markerIcon}
					alt={markerIcon}
					width={25}
				/>
				{props.isOpen && (
					<Card style={{
						position: 'relative',
						bottom: 150,
						left: '-45px',
						width: 220,
						backgroundColor: 'white',
						boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
						padding: 10,
						fontSize: 14,
						zIndex: 100
					}}>
						<Button style={{left: '80%'}} onClick={onCloseClick}>x</Button>
						<CardMedia
							wide='true'
							image={props.store.images[0] ? props.store.images[0] : require('../../../../../assets/images/home/defaultStore.png')}
							title='itemPicture'
							style={{
								height: '0px',
								paddingTop: '56.25%'
							}}
						/>
						<CardContent style={{ padding: '5px' }}>
							<Grid container={true} direction='row' justify='space-between' alignItems='center'>
								<Button onClick={() => sendToStore()}>{props.store.name}</Button>
							</Grid>
						</CardContent>
					</Card>
				)}
			</div>
		</>
	)
}

export default StoreMarker;