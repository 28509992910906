import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

const SustainabilityBadge = ({ badge: { image, desc, label } }) => {
    if (image) {
        const title = <>
            <u>{desc}</u>{': '}
            {label}
        </>;
        return <Tooltip data-cy='susBadgeTooltip' title={title} arrow>
            <img
                src={image}
                alt='sustainability badge'
                style={{ marginRight: 5, width: 30, height: 30, border: '2px solid white', borderRadius: '50%',}}
                data-cy='susBadge'
            />
        </Tooltip>
    } else {
        return null;
    }
}

SustainabilityBadge.propTypes = {
    badge: PropTypes.shape({
        desc: PropTypes.string,
        label: PropTypes.string,
        image: PropTypes.string,
    })
}

SustainabilityBadge.defaultProps = {
    badge: null
}

export default SustainabilityBadge;
