import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Typography';
import Typography from '@material-ui/core/Container';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './Team.module.css';
import { teamImages, defaultPreviewLinkImage, defaultPreviewLinkDescription } from '../../../stocateConstants.js';
import MemberCard from './MemberCard/MemberCard';
import { Grid } from '@material-ui/core';
import { paths } from 'stocateConstants';
import { sendPageView } from 'utils/GoogleAnalytics';
import Footer from '../../footer/Footer';

function Team() {
  const teamMembers = [
    {
      isMember: true,
      teamImage: teamImages.yannick,
      name: 'Dr. Yannick D\'Mello',
      role: i18next.t('team.yannick_role'),
      desc: i18next.t('team.yannick_desc'),
      advisor: false,
      isCofounder: true
    },
    {
      isMember: true,
      teamImage: teamImages.ezz,
      name: 'Ezz Aboulezz',
      role: i18next.t('team.ezz_role'),
      desc: i18next.t('team.ezz_desc'),
      advisor: false,
      isCofounder: true
    },
    {
      isMember: true,
      teamImage: teamImages.santiago,
      name: 'Santiago Bernal',
      role: i18next.t('team.santiago_role'),
      desc: i18next.t('team.santiago_desc'),
      advisor: false,
      isCofounder: true
    },
    {
      isMember: true,
      teamImage: teamImages.joey,
      name: 'Joey Koay',
      role: i18next.t('team.joey_role'),
      desc: i18next.t('team.joey_desc'),
      advisor: false,
      isCofounder: true
    },
    {
      isMember: true,
      teamImage: teamImages.maria,
      name: 'Maria Beiline',
      role: i18next.t('team.vp_marketing'),
      desc: i18next.t('team.maria_desc'),
      advisor: false,
    },
    {
      isMember: true,
      teamImage: teamImages.karena,
      name: 'Karena Mistry',
      role: i18next.t('team.lead_UI'),
      desc: i18next.t('team.karena_desc'),
      advisor: false,
    },
    {
      isMember: true,
      teamImage: teamImages.amritpal,
      name: 'Amritpal Singh',
      role: i18next.t('team.lead_UX'),
      desc: i18next.t('team.amritpal_desc'),
      advisor: false,
    },
    {
      isMember: true,
      teamImage: teamImages.kevin,
      name: 'Kevin Liang',
      role: i18next.t('team.designer'),
      desc: i18next.t('team.kevin_desc'),
      advisor: false,
    },
    {
      isMember: true,
      teamImage: teamImages.lian,
      name: 'Lian Lambert',
      role: i18next.t('team.usability_intern'),
      desc: i18next.t('team.lian_desc'),
      advisor: false,
    },
  
    {
      isMember: true,
      teamImage: teamImages.diego,
      name: 'Diego Mena',
      role: i18next.t('team.sales_lead'),
      desc: i18next.t('team.diego_desc'),
      advisor: false,
    },
    {
      isMember: false,
      teamImage: teamImages.therriault,
      name: 'Robert Therriault',
      role: i18next.t('team.therriault_role'),
      advisor: true,
    },
    {
      isMember: false,
      teamImage: teamImages.ginter,
      name: 'Jonathan Ginter',
      role: i18next.t('team.ginter_role'),
      advisor: false,
    },
    {
      isMember: false,
      teamImage: teamImages.csinger,
      name: 'Professor Andrew Csinger',
      role: i18next.t('team.csinger_role'),
      advisor: false,
    },
    {
      isMember: false,
      teamImage: teamImages.badami,
      name: 'Professor Madhav Badami',
      role: i18next.t('team.badami_role'),
      advisor: false,
    },
    {
      isMember: false,
      teamImage: teamImages.kwofie,
      name: 'Professor Ebenezer Miezah Kwofie',
      role: i18next.t('team.kwofie_role'),
      advisor: false,
    },
    {
      isMember: false,
      teamImage: teamImages.dube,
      name: 'Professor Laurette Dubé',
      role: i18next.t('team.dube_role'),
      advisor: false,
    },
  ];
  
  const partners = [
    {
      isMember: true,
      teamImage: teamImages.krystina,
      name: 'Krystina Marcoux',
      role: i18next.t('team.mira_cofounder'),
      desc: i18next.t('team.krystina_desc'),
      advisor: false,
      isCofounder: false
    },
    {
      isMember: true,
      teamImage: teamImages.juan,
      name: 'Juan Delgado',
      role: i18next.t('team.mira_cofounder'),
      desc: i18next.t('team.juan_desc'),
      advisor: false,
      isCofounder: false
    }
  ]
  
  const dpStudents1 = [
  ];
  const dpStudents2 = [
    {
      name: 'Alexandre Lavigne',
    },
    {
      name: 'Arturo Mory',
    },
    {
      name: 'Danny Tu',
    },
    {
      name: 'Menad Kessaci',
    },
  ];
  const dpStudents3 = [
    {
      name: 'Hassan Gaber',
    },
    {
      name: 'Joey Koay',
    },
    {
      name: 'Kara Best',
    },
    {
      name: 'Siddhant Puri',
    }
  ];
  const dpStudents4 = [
  ];
  
  const goToJoinUs = () => {
    window.location.href = '/join-the-team';
  };

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - About');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/about');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  useEffect(() => {
    updateMetaTags();
		sendPageView(paths.TEAM);
	}, []);
  return (
    <>
      <Container component='main'>
        <div className={styles.globalFrame}>
          <div className={styles.banderole}>
            <div className={styles.banderolePhotoOverlay}></div>
            <div className={styles.banderoleBox}>
              <p>{i18next.t('team.banner_message1')}</p>
              <p>{i18next.t('team.banner_message2')}</p>
              <p>{i18next.t('team.banner_message3')}</p>
              <p>{i18next.t('team.banner_message4')}</p>
              <Button style={{zIndex: '0'}} variant='contained' onClick={goToJoinUs}>
                {i18next.t('team.join_team')}
              </Button>
            </div>
          </div>

          {/* TEAM MEMBERS */}
          <div>
            <div className={styles.ourTeam}>
              <h1>{i18next.t('team.stocateer')}</h1>
            </div>
            <Typography variant='body1' className={styles.historyDesc}>
              {i18next.t('team.stocateer_desc1')}
            </Typography>
            <Grid
              container
              direction='row'
              justify='space-around'
              alignItems='center'
            >
              {teamMembers.map((member) => {
                if (member.isMember && member.isCofounder) {
                  return (
                      <Grid item={true} xs={6} md={3} lg={2}>
                        <MemberCard
                          isMember={member.isMember}
                          teamImage={member.teamImage}
                          name={member.name}
                          role={member.role}
                          advisor={member.advisor}
                          key={member.name}
                        />
                      </Grid>
                  );
                }
                return true;
              })}
            </Grid>
            <Grid
              container
              direction='row'
              justify='space-around'
              alignItems='center'
            >
              {teamMembers.map((member) => {
                if (member.isMember && !member.isCofounder) {
                  return (
                      <Grid item={true} xs={6} md={3} lg={2}>
                        <MemberCard
                          isMember={member.isMember}
                          teamImage={member.teamImage}
                          name={member.name}
                          role={member.role}
                          advisor={member.advisor}
                          key={member.name}
                        />
                      </Grid>
                  );
                }
                return true;
              })}
            </Grid>
            {/* DP Students */}
            <div className={styles.dpStudents}>
              <h3>{i18next.t('team.students')}</h3>
              <div className={styles.dpStudentsRow}>
                <div className={styles.dpStudentsCol}>
                  {dpStudents1.map((member) => {
                      return (
                        <Typography variant='body1'>
                          {member.name}
                        </Typography>
                      )
                  })}
                </div>
                <div className={styles.dpStudentsCol}>
                  {dpStudents2.map((member) => {
                      return (
                        <Typography variant='body1'>
                          {member.name}
                        </Typography>
                      )
                  })}
                </div>
                <div className={styles.dpStudentsCol}>
                  {dpStudents3.map((member) => {
                      return (
                        <Typography variant='body1'>
                          {member.name}
                        </Typography>
                      )
                  })}
                </div>
                <div className={styles.dpStudentsCol}>
                  {dpStudents4.map((member) => {
                      return (
                        <Typography variant='body1'>
                          {member.name}
                        </Typography>
                      )
                  })}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={styles.ourTeam}>
              <h1>{i18next.t('team.mentors')}</h1>
            </div>
            <Grid
              container
              direction='row'
              justify='space-around'
              alignItems='center'
            >
              {teamMembers.map((member) => {
                if (member.isMember === false) {
                  return (
                      <Grid item={true} xs={6} md={3} lg={2}>
                          <MemberCard
                            isMember={member.isMember}
                            teamImage={member.teamImage}
                            name={member.name}
                            role={member.role}
                            company={member.company}
                            key={member.name}
                          />
                      </Grid>
                  );
                }
                return true;
              })}
            </Grid>
          </div>

          {/* PARTNERS */}
          <div className={styles.ourTeam}>
            <h1>{i18next.t('team.partners')}</h1>
          </div>
          <Grid
              container
              direction='row'
              justify='space-around'
              alignItems='center'
            >
              {partners.map((member) => {
                  return (
                      <Grid item={true} xs={6} md={3} lg={2}>
                        <MemberCard
                          isMember={member.isMember}
                          teamImage={member.teamImage}
                          name={member.name}
                          role={member.role}
                          advisor={member.advisor}
                          key={member.name}
                        />
                      </Grid>
                  );
              })}
            </Grid>
          <Grid
            container
            direction='row'
            justify='space-around'
            alignItems='center'
            className={styles.programs}
          >
            <a href='https://canadacouncil.ca/'target='_blank' rel='noopener noreferrer'>
             <Grid>
                <img
                  src={require('../../../assets/images/about/cca_logo.png')}
                  alt='cca'
                />
              </Grid>
            </a>
            <a href='https://www.mcgill.ca/sustainability/engage/spf'target='_blank' rel='noopener noreferrer'>
              <Grid>
                <img
                  src={require('../../../assets/images/about/spf_logo.png')}
                  alt='spf'
                />
              </Grid>
            </a>
            <a href='https://www.miraconcerts.com/'target='_blank' rel='noopener noreferrer'>
              <Grid>
                <img
                  src={require('../../../assets/images/about/miraLogo.png')}
                  alt='Mira logo'
                />
              </Grid>
            </a>
          </Grid>

          {/* Sponsor */}
          <div className={styles.ourTeam}>
            <h1>{i18next.t('team.sponsors')}</h1>
          </div>
          <Grid
            container
            direction='row'
            justify='space-around'
            alignItems='center'
            className={styles.programs}
          >
            <a href='https://www.mcgill.ca/dobson/entrepreneurship-programs/x1accelerator'target='_blank' rel='noopener noreferrer'>
              <img
                src={require('../../../assets/images/about/x-1A.png')}
                alt='x-1 Accelerator'
              />
            </a>
            <a href='https://www.mcgill.ca/engine/'target='_blank' rel='noopener noreferrer'>
              <img
                src={require('../../../assets/images/about/engineMcGill.png')}
                alt='McGill Engine'
              />
            </a>
            <a href='https://technationcanada.ca/en/'target='_blank' rel='noopener noreferrer'>
              <img
                src={require('../../../assets/images/about/technation.png')}
                alt='Technation'
              />
            </a>
          </Grid>

          {/* PROGRAMS*/}
          <div className={styles.ourTeam}>
            <h1>{i18next.t('team.programs')}</h1>
          </div>
          <Grid
            container
            direction='row'
            justify='space-around'
            alignItems='center'
            className={styles.programs}
          >
            <a href='https://www.mitacs.ca/'target='_blank' rel='noopener noreferrer'>
              <Grid
                item
              >
                <img
                  src={require('../../../assets/images/about/mitacs_logo.png')}
                  alt='mitacs'
                />
              </Grid>
            </a>
            <a href='https://www.golead.ca/'target='_blank' rel='noopener noreferrer'>
              <Grid
                item
              >
                <img
                  src={require('../../../assets/images/about/goLead.png')}
                  alt='goLead'
                />
              </Grid>
            </a>
            
              <Grid
                item
                direction='row'
                justify='space-around'
                alignItems='center'
                style={{textAlign:'center'}}
              >
                <a href='https://cooperathon.ca/?lang=en'target='_blank' rel='noopener noreferrer'>
                  <img
                    src={require('../../../assets/images/about/cooperathon.png')}
                    alt='Tech Track Cooperathon'
                  />
                </a>
                <p>{i18next.t('team.cooperathon_standing')}</p>
              </Grid>
          </Grid>

          {/* JOIN OUR TEAM */}
          <div>
            <div className={styles.join}>
              <div className={styles.joinImg}>
                <img
                  src={require('../../../assets/images/about/stocate-logo-transparent.png')}
                  alt='Stocate Logo'
                />
              </div>
              <div className={styles.joinDesc}>
                <h1>{i18next.t('team.join_team')}</h1>
                <Typography className={styles.joinText} variant='body1'>
                  {i18next.t('team.join_team_message1_part1')}{' '}
                  <a style={{color: '#00809e', textDecoration: 'underline'}} id='volunteerFaqLink' href='/join-the-team'>
                    {i18next.t('team.join_team_message1_part2')}
                  </a>
                </Typography>
                <br />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer/>
    </>
  );
}

export default withTranslation()(Team);
