import React from 'react';
import PropTypes from 'prop-types';
import styles from './StocateButton.module.css';

StocateButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

StocateButton.defaultProps = {
  onClick: () => {
    // console.log('on click')
  },
  size: 'medium',
  color: 'primary',
  children: '',
};

function StocateButton({ onClick, size, color, children }) {
  const classesArr = [];

  const sizeClass = () => {
    switch (size) {
      case 'small':
        return classesArr.push(styles.small);
      case 'medium':
        return classesArr.push(styles.medium);
      case 'large':
        return classesArr.push(styles.large);
      default:
        return classesArr.push(styles.medium);
    }
  };

  const colorClass = () => {
    switch (color) {
      case 'primary':
        return classesArr.push(styles.primary);
      case 'secondary':
        return classesArr.push(styles.secondary);
      default:
        return classesArr.push(styles.primary);
    }
  };

  const buildClassName = () => {
    classesArr.push(styles.common);
    sizeClass();
    colorClass();
    return classesArr.join(' ');
  };

  return (
    <>
      <button onClick={onClick} className={buildClassName()}>
        {children}
      </button>
    </>
  );
}

export default StocateButton;
