import React, { useState, useEffect, useCallback } from 'react';
import { getFeedback } from '../../../api/FeedbackApi.js'
import { Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel, TextField } from '@material-ui/core';
import Dropdown from 'react-bootstrap/Dropdown';
import { setFeedbackStatus, setFeedbackStatusCounter, getAllFeedbackStatuses, getNumAllFeedbackStatuses } from '../../../api/FeedbackApi.js'
import { feedbackStatus } from 'stocateConstants.js';
import { setFeedbackJiraID } from '../../../api/FeedbackApi.js';
import styles from './FeedbackDashboard.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-modal';
import Carousel from 'react-material-ui-carousel';

function FeedbackDashboard() {
    const [feedback, setFeedback] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('id');
    const [filter, setFilter] = useState('');
    const [filteredFeedback, setFilteredFeedback] = useState(feedback);
    const [totalFeedbackPosts, setTotalFeedbackPosts] = useState(0);
    const [allStatusInfo, setAllStatusInfo] = useState({});
    const allCompleteStatuses = ['complete', 'declined', 'thanks sent', 'question answered'];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentFeedback, setCurrentFeedback] = useState(false);
    const [currentTab, setCurrentTab] = useState('feedback');

    const allCompleteStatusesCount = Object.entries(allStatusInfo)
        .filter(([label, info]) => allCompleteStatuses.includes(label))
        .reduce((acc, [label, info]) => acc + info.total, 0);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const failCallback = (statusCode, body) => {
        // do nothing
    }

    const successCallback = (feedback) => {
        setFeedback(feedback)
        setTotalFeedbackPosts(feedback.length)
        setFilteredFeedback(feedback)
    }

    const defaultStatusInfo = () => {
        return feedbackStatus.reduce((acc, status) => {
            acc[status.label] = { countIn: 0, countOut: 0, total: 0 };
            return acc;
        }, {});
    };

    const updateFeedbackStatusTableValues = useCallback(async () => {
        const newStatusInfo = defaultStatusInfo();
        const allFeedbackStatuses = await getAllFeedbackStatuses(statusSuccessCallback, statusFailCallback);
        const allFeedbackStatusTotals = await getNumAllFeedbackStatuses(statusSuccessCallback, statusFailCallback);

        allFeedbackStatuses.forEach(status => {
            newStatusInfo[status.text].countIn = status.countIn;
            newStatusInfo[status.text].countOut = status.countOut;
        });
        
        allFeedbackStatusTotals.forEach(count => {newStatusInfo[getStatusLabelFromId(count[0])].total = count[1];});

        const total = Object.values(newStatusInfo).reduce((acc, info) => acc + info.total, 0);

        setTotalFeedbackPosts(total);
        setAllStatusInfo(newStatusInfo);
    }, [])

    function statusSuccessCallback(data) {
        // do nothing
    }
    
    function statusFailCallback(status, data) {
        // do nothing
    }

    const getStatusIdFromLabel = (label) => {
        const status = feedbackStatus.find(status => status.label === label);
        return status.value;
    };

    const getStatusLabelFromId = (id) => {
        const status = feedbackStatus.find(status => status.value === id);
        return status.label;
    };

    function handleStatusChange(feedbackId, oldFeedbackStatusLabel, newFeedbackStatusLabel) {
        if (oldFeedbackStatusLabel !== newFeedbackStatusLabel) {
            const oldFeedbackStatusId = getStatusIdFromLabel(oldFeedbackStatusLabel);
            const newFeedbackStatusId = getStatusIdFromLabel(newFeedbackStatusLabel);
            setFeedbackStatus(feedbackId, newFeedbackStatusId);
            setFeedbackStatusCounter(oldFeedbackStatusId, 0, 1);
            setFeedbackStatusCounter(newFeedbackStatusId, 1, 0);
            setRefresh(!refresh);
        }
    }

    const resetAllCounts = () => {
        feedbackStatus.forEach(status => {
            const invertedCountIn = -allStatusInfo[status.label].countIn;
            const invertedCountOut = -allStatusInfo[status.label].countOut;
            setFeedbackStatusCounter(status.value, invertedCountIn, invertedCountOut);
        });
        setRefresh(!refresh);
    }

    const resetCount = (label) => {
        const invertedCountIn = -allStatusInfo[label].countIn;
        const invertedCountOut = -allStatusInfo[label].countOut;
        setFeedbackStatusCounter((feedbackStatus.find(status => status.label === label)).value, invertedCountIn, invertedCountOut);
        setRefresh(!refresh);
    }

    useEffect(() => {
        getFeedback(successCallback, failCallback);
        updateFeedbackStatusTableValues();
    }, [refresh, updateFeedbackStatusTableValues]);

    const getFeedbackRowColor = label => {
        switch (label) {
            case 'not started': return '#eaabab'; // Light Red
            case 'in design': return '#ADD8E6'; // Light Blue
            case 'in tech': return '#FFDAB9'; // Light Orange
            case 'in QA': return '#D8BFD8'; // Light Purple
            case 'declined': return '#E9E9E9'; // Light Grey
            default: return '#91d191'; // Light Green
        }
    };
    
    const getFeedbackButtonColor = label => {
        switch (label) {
            case 'not started': return '#FF0000'; // Red
            case 'in design': return '#0000FF'; // Blue
            case 'in tech': return '#FFA500'; // Orange
            case 'in QA': return '#800080'; // Purple
            case 'declined': return '#A9A9A9'; // Grey
            default: return '#008000'; // Green
        }
    };

    function onAttachmentClick(feedback) {
        setCurrentFeedback(feedback);
        setIsModalOpen(true);
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short'
        };
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <div style={{ margin: 20, overflow: 'auto' }}>
            <div className='d-flex align-items-center justify-content-center p-3'>
                <button onClick={() => setCurrentTab('feedback')}>
                    Feedback Posts
                </button>
                <button onClick={() => setCurrentTab('analytics')} className='ml-3'>
                    Analytics
                </button>
            </div>
            
            <div id='feedback' style={{ display: currentTab === 'feedback' ? 'block' : 'none' }}>
                    <TextField
                        label='Search'
                        value={filter}
                        onChange={handleFilterChange}
                        onBlur={() => {
                            setFilteredFeedback(feedback.filter((f) =>
                                f.text.toLowerCase().includes(filter.toLowerCase()) ||
                                f.fullName.toLowerCase().includes(filter.toLowerCase())
                            ))
                        }}
                        style={{ margin: 10, width: '300px' }}
                    />
                <Table>
                    <TableHead className={ styles.darkerRow }>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'id'}
                                    direction={orderBy === 'id' ? order : 'asc'}
                                    onClick={() => handleRequestSort('id')}
                                >
                                    ID
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'completion'}
                                    direction={orderBy === 'completion' ? order : 'asc'}
                                    onClick={() => handleRequestSort('completion')}
                                >
                                    Status
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'text'}
                                    direction={orderBy === 'text' ? order : 'asc'}
                                    onClick={() => handleRequestSort('text')}
                                >
                                    Text
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Attachments</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'platform'}
                                    direction={orderBy === 'platform' ? order : 'asc'}
                                    onClick={() => handleRequestSort('platform')}
                                >
                                    Platform
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'currentPage'}
                                    direction={orderBy === 'currentPage' ? order : 'asc'}
                                    onClick={() => handleRequestSort('currentPage')}
                                >
                                    Current Page
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'fullName'}
                                    direction={orderBy === 'fullName' ? order : 'asc'}
                                    onClick={() => handleRequestSort('fullName')}
                                >
                                    Full Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'label'}
                                    direction={orderBy === 'label' ? order : 'asc'}
                                    onClick={() => handleRequestSort('label')}
                                >
                                    Label
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Comments</TableCell>
                            <TableCell>Product Jira Ticket</TableCell>
                            <TableCell>Tech Jira Ticket</TableCell>
                            <TableCell>Last status change</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'dateCreated'}
                                    direction={orderBy === 'dateCreated' ? order : 'asc'}
                                    onClick={() => handleRequestSort('dateCreated')}
                                >
                                    Date Created
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFeedback.sort((a, b) => {
                            return a[orderBy] > b[orderBy] ? (order === 'asc' ? 1 : -1) : (order === 'asc' ? -1 : 1)
                        }).map((f) => (
                            <TableRow key={f.id} className={ styles.tableRow } style={{ backgroundColor: getFeedbackRowColor(f.completion) }}>
                                <TableCell>{f.id}</TableCell>
                                <TableCell>
                                    <Dropdown>
                                        <Dropdown.Toggle style={{ backgroundColor: getFeedbackButtonColor(f.completion), border: 'none' }}>
                                            {f.completion}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {feedbackStatus.map((s) => {
                                                return <Dropdown.Item key={s.label} onClick={(e) => { handleStatusChange(f.id, f.completion, e.target.textContent) }}>{s.label}</Dropdown.Item>
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </TableCell>
                                <TableCell>{f.text}</TableCell>
                                <TableCell>
                                    {f.images && f.images.length !== 0 ? (
                                        <button
                                            onClick={() => onAttachmentClick(f)}
                                            style={{ background: 'transparent' }}
                                        >
                                            View Image(s)
                                        </button>
                                    ) : (
                                        <span>N/A</span>
                                    )}
                                </TableCell>
                                <TableCell>{f.platform}</TableCell>
                                <TableCell>{f.currentPage}</TableCell>
                                <TableCell>{f.fullName}</TableCell>
                                <TableCell>{f.label}</TableCell>
                                <TableCell>Comments</TableCell>
                                {/* TODO: create save endpoint and replace log */}
                                <TableCell style={{ minWidth: '100px' }}>
                                    <TextField label={f.productJiraId} variant='outlined' onBlur={(e) => {
                                        setFeedbackJiraID(f.id, e.target.value, 'product');
                                        setRefresh(!refresh);
                                    }}/>
                                </TableCell>
                                <TableCell style={{ minWidth: '100px' }}>
                                    <TextField label={f.techJiraId} variant='outlined' onBlur={(e) => {
                                        setFeedbackJiraID(f.id, e.target.value, 'tech');
                                        setRefresh(!refresh);
                                    }}/>
                                </TableCell>
                                <TableCell>Last status change</TableCell>
                                <TableCell>{formatDate(f.dateCreated)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <div id='analytics' style={{ display: currentTab === 'analytics' ? 'block' : 'none' }}>
                <div className={'d-flex flex-row mb-4'} style={{ justifyContent: 'space-around', alignItems: 'center' }}>
                    <Table style={{ tableLayout: 'auto', width: '600px' }}>
                        <TableHead className={styles.darkerRow}>
                            <TableRow>
                                <TableCell>Feedback Status</TableCell>
                                <TableCell>Entered</TableCell>
                                <TableCell>Exited</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Reset Counts</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(allStatusInfo).map(([label, info], index) => (
                                <TableRow key={index} className={ styles.tableRow } style={{ backgroundColor: '#f1f1f1', padding: '0px' }}>
                                    <TableCell>{label}</TableCell>
                                    <TableCell>{info.countIn}</TableCell>
                                    <TableCell>{info.countOut}</TableCell>
                                    <TableCell>{info.total}</TableCell>
                                    <TableCell><Button variant='warning' onClick={() => resetCount(label)} style={{fontSize: '14px'}}>Reset Count</Button></TableCell>
                                </TableRow>
                            ))}
                            <TableRow className={ styles.darkerRow } style={{ padding: '0px'}}>
                                <TableCell>All Completed Statuses: </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{allCompleteStatusesCount}</TableCell>
                                <TableCell><Button variant='warning' onClick={resetAllCounts} style={{fontSize: '14px'}}>Reset All Counts</Button></TableCell>
                            </TableRow>
                            <TableRow className={ styles.darkerRow } style={{ padding: '0px'}}>
                                <TableCell>Total: </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{totalFeedbackPosts}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel='attachmentImages'
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        maxWidth: '50%',
                        maxHeight: '70%',
                        margin: 'auto',
                        borderRadius: '15px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                }}
            >
                {currentFeedback.images && (currentFeedback.images.length === 1 ? (
                    <img
                        src={currentFeedback.images[0]}
                        alt='attachment'
                        className={`${styles.singleStoreImage} ${styles.centered}`}
                        style={{ objectFit: 'contains', maxHeight: '90%', maxWidth: '90%' }}
                    />
                ) : (
                    <Carousel
                        autoPlay={false}
                        navButtonsAlwaysVisible={true}
                        style={{
                            width: '90%',
                            height: '90%'
                        }}
                    >
                        {currentFeedback.images.map((imageUrl, i) => (
                            <img
                                key={i}
                                src={imageUrl}
                                style={{
                                    maxWidth: '70%',
                                    maxHeight: '70%',
                                    objectFit: 'contain',
                                    display: 'block',
                                    margin: 'auto',
                                    borderRadius: '15px',
                                }}
                                alt={'store'}
                            />
                        ))}
                    </Carousel>
                ))}
            </Modal>
        </div>
    );
}

export default FeedbackDashboard;