import React, { useEffect } from 'react'
import { Button, Box, Container, Typography } from '@material-ui/core';
import styles from './ErrorPage.module.css'
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom'
import { sendPageView } from 'utils/GoogleAnalytics';

function ErrorPage() {
    useEffect(() => {
		sendPageView(window.location.pathname + window.location.search);
	}, []);

    const history = useHistory();
    const sendToBrowse = () => history.push('/browse');
    
    return (
        <>
            <Container component='main' maxWidth='lg'>
                <Box display='flex' justifyContent='center'>
                    <div className={styles.globalFrame} style={{minHeight:'80vh'}}>
                        <div className={styles.text}>
                            <Typography variant='h5'>{i18next.t('errorpage.title')}</Typography>
                            <div className={styles.logo}>
                                <img src={require('../../../assets/images/about/stocate-logo-transparent.png')} alt='Stocate Logo' />
                                <Typography variant='h5'>{i18next.t('errorpage.contact')}</Typography>
                                <Typography variant='h5'>{i18next.t('errorpage.thanks')}</Typography>
                                <div className={styles.buttons}>
                                    <Button variant='contained' onClick={sendToBrowse}>
                                        {i18next.t('titles.browse')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Container>
        </>
    )
}

export default withTranslation()(ErrorPage);
