import {
  levelsEndpoints,
  onlineAPI,
} from 'stocateConstants';
import { checkLocalStorageForToken } from './CommonApi';

export async function postLevel(
  levelId,
  feedbackText,
  successCallback,
  failCallback,
  signal
) {
  if(!feedbackText){
    successCallback()
    return;
  }
  try {
    const response = await fetch(onlineAPI + levelsEndpoints, {
      signal,
      method: 'POST',
      body: JSON.stringify({
        levelId: levelId,
        feedbackText: feedbackText,
        platform: 'website',
        currentPage: window.location.href,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: checkLocalStorageForToken().exists
          ? `Bearer ${checkLocalStorageForToken().token}`
          : '',
      },
    });
    const data = await response.json();

    if (response.ok) successCallback(data);
    else failCallback(response.status, data);
  } catch (error) {
    if (!signal?.aborted) failCallback(500, error);
  }
}