import { useMutation } from 'react-query';
import { itemsEndpoints, listEndpoints, onlineAPI } from '../stocateConstants';
import { checkLocalStorageForToken } from '../api/CommonApi';

import IAddItem from '../types/add/IAddItem';
import Item from '../types/dto/item';
import { FetchError } from '../types/common';

interface UseAddStoreVariables {
  item: IAddItem;
}

export const useAddItem = () => {
  return useMutation<Item, FetchError, UseAddStoreVariables>(
    async ({ item }: { item: IAddItem }) => {
      if (item.newSection !== '') {
        const sendSection = {
            'name': item.newSection,
            'sectionslistId': item.storeSectionsListId,
            'type': 'store'
        }
        const sectionResponse = await fetch(onlineAPI + listEndpoints + 'sections', {
          method: 'POST',
          body: JSON.stringify(sendSection),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
          },
        });
        const data = await sectionResponse.json();
        if (sectionResponse.ok){
          item.selectedSection = data.id
        } else throw new FetchError(sectionResponse.status, data);
      }
      const response = await fetch(onlineAPI + itemsEndpoints + 'store/' + item.storeId, {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
        },
      });
      const data = await response.json();
      if (response.ok) return data;
      else throw new FetchError(response.status, data);
    }
  );
};

export const useEditItem = () => {
  return useMutation<Item, FetchError, UseAddStoreVariables>(
    async ({ item }: { item: IAddItem }) => {
      if (item.newSection !== '') {
        const sendSection = {
            'name': item.newSection,
            'sectionslistId': item.storeSectionsListId,
            'type': 'store'
        }
        const sectionResponse = await fetch(onlineAPI + listEndpoints + 'sections', {
          method: 'POST',
          body: JSON.stringify(sendSection),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
          },
        });
        const data = await sectionResponse.json();
        if (sectionResponse.ok){
          item.selectedSection = data.id
        } else throw new FetchError(sectionResponse.status, data);
      }
      const response = await fetch(onlineAPI + itemsEndpoints + item.id + '/store/' + item.storeId, {
        method: 'PUT',
        body: JSON.stringify(item),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: checkLocalStorageForToken().exists
            ? `Bearer ${checkLocalStorageForToken().token}`
            : '',
        },
      });
      const data = await response.json();
      if (response.ok) return data;
      else throw new FetchError(response.status, data);
    }
  );
};