import React, { useState, useMemo, useEffect } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import ProfileMenu from './ProfileMenu';
import { useAuth } from '../../context/AuthContext';
import styles from './NavigationBar.module.css';
import StocateLogo from './../../assets/images/navbar/homeButtonIcon.png';
import { paths } from '../../stocateConstants.js';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Grid from '@material-ui/core/Grid';
import Button from 'react-bootstrap/Button';
import TranslationToggle from './TranslationToggle';
import TextField from '@material-ui/core/TextField';
import { addResizingListener } from '../../utils/Responsiveness';

const NavigationBar = () => {
  const { state: authState } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [searchString, setSearchString] = useState('');
  const showSearchBar = useMemo(() => {
    return location.pathname !== paths.BROWSE;
  }, [location.pathname]);

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setSearchString(e.target.value);
  };

  const onSearchBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setSearchString('');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendSearch();
    }
  };

  const sendSearch = () => {
    if (!searchString || searchString.trim() === '') return;
    const searchToSend = searchString;
    setSearchString('')
    history.push(`/browse?search=${searchToSend}`);
  };
  useEffect(() => {
    addResizingListener(setIsMobile);
  }, []);

  const handleButtonClick = () => {
    window.open('https://www.stocate.com/mobileApp', '_blank');
    collapseNavBar();
  };

  const collapseNavBar = () => {
    if (expanded && isMobile) {
      setExpanded(false);
    }
  };

  return (
    <>
      <Navbar expanded={expanded} onToggle={() => setExpanded(!expanded)} expand='lg' className={styles.navContainer} onMouseLeave={collapseNavBar}>
        <NavLink exact={true} to={paths.HOME} onClick={collapseNavBar} activeClassName={styles.activeButton} className={`${styles.buttonDark} ${styles.button}`}>
          <img src={StocateLogo} className={styles.logoImg} alt='Stocate logo' />
          {i18next.t('titles.home')}
        </NavLink>
        <Button onClick={handleButtonClick} className={`ml-auto d-lg-none`} style={{
          // had to do inline styles to override react button class styles
          backgroundColor: '#BAE3ED',
          color: '#0D86A2',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 15px',
          margin: '3px',
          height: '50px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontFamily: '"Ag", sans-serif',
          fontWeight: 'bold',
          fontSize: '15px'
        }}>
          {i18next.t('home.use_app')}
        </Button>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          data-cy='navbarHamburger'
          style={{ border: 'none', filter: 'invert(1)' }}
        />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            {authState.isAuthenticated ? (
              <>
                <NavLink to={paths.BROWSE} onClick={() => {
                  if (location.pathname === paths.BROWSE) {
                    collapseNavBar()
                    window.location.reload();
                  }
                }} activeClassName={styles.activeButton} className={`${styles.buttonDark} ${styles.button}`} data-cy='browseNav'>
                  {i18next.t('titles.browse')}
                </NavLink>
                <NavLink to={paths.MYLISTS} onClick={collapseNavBar} activeClassName={styles.activeButton} className={`${styles.buttonDark} ${styles.button}`} data-cy='libraryNav'>
                  {i18next.t('titles.library')}
                </NavLink>
                <Dropdown id='add-nav-dropdown' onClick={() => setExpanded(true)}>
                  <Dropdown.Toggle as='span' className={`${location.pathname.startsWith('/add/') ? styles.activeButton : styles.buttonDark} ${styles.button}`}>
                    {i18next.t('titles.add')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to={paths.ADD_STORE} onClick={collapseNavBar} activeClassName={styles.dropdownItemSelected}>
                      {i18next.t('titles.add_store')}
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to={paths.ADD_ITEM} onClick={collapseNavBar} activeClassName={styles.dropdownItemSelected}>
                      {i18next.t('titles.add_item')}
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to={paths.ADD_LIST} onClick={collapseNavBar} activeClassName={styles.dropdownItemSelected}>
                      {i18next.t('titles.add_list')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {showSearchBar && (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    [styles[`justifyContentLg`]]: 'center'
                  }}>
                    <div className={styles.searchDiv}>
                      <TextField
                        variant='outlined'
                        fullWidth={true}
                        placeholder={i18next.t('storepage.search_placeholder')}
                        autoComplete='off'
                        onChange={onSearchChange}
                        onBlur={onSearchBlur}
                        classes={{ root: styles.search }}
                        size={'small'}
                        onKeyPress={handleKeyPress}
                        value={searchString}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <NavLink to={paths.BROWSE} onClick={() => {
                  if (location.pathname === paths.BROWSE) {
                    collapseNavBar()
                    window.location.reload();
                  }
                }} activeClassName={styles.activeButton} className={`${styles.buttonDark} ${styles.button}`}>
                  {i18next.t('titles.browse')}
                </NavLink>
                <NavLink to={paths.TEAM} onClick={collapseNavBar} activeClassName={styles.activeButton} className={`${styles.buttonDark} ${styles.button}`}>
                  {i18next.t('titles.about')}
                </NavLink>
                <div style={{ display: 'flex', alignItems: 'center', height: '55px' }}>
                  <div className={styles.searchDiv}>
                    <TextField
                      variant='outlined'
                      fullWidth={true}
                      placeholder={i18next.t('storepage.search_placeholder')}
                      autoComplete='off'
                      onChange={onSearchChange}
                      onBlur={onSearchBlur}
                      onKeyPress={handleKeyPress}
                      size={'small'}
                      value={searchString}
                      data-cy='navbarSearch'
                    />
                  </div>
                </div>
              </>
            )}
          </Nav>
          <Nav>
            <Grid
              container={true}
              direction='row'
              alignItems='center'
            >
              <NavLink to={paths.SUBSCRIPTIONS} onClick={collapseNavBar} activeClassName={styles.activeButton} className={`${styles.buttonLight} ${styles.button}`}>
                {i18next.t('titles.subscriptions')}
              </NavLink>
              {!authState.isAuthenticated && (
                <NavLink to={paths.SIGNIN} onClick={collapseNavBar} activeClassName={styles.activeButton} className={`${styles.buttonLight} ${styles.button}`} data-cy='signInNav'>
                  {i18next.t('titles.signin')}
                </NavLink>
              )}
              {authState.isAuthenticated && (
                <NavLink to={paths.TESTING_REGISTER} onClick={collapseNavBar} activeClassName={styles.activeButton} className={`${styles.buttonLight} ${styles.button}`} data-cy='testPilotBtn'>
                  {i18next.t('titles.pilottest')}
                </NavLink>
              )}
              {authState.isAuthenticated && (
                <span onClick={() => setExpanded(true)}>
                  <ProfileMenu authState={authState} />
                </span>
              )}
              {!authState.isAuthenticated && (
                <span onClick={() => setExpanded(true)}>
                  <TranslationToggle></TranslationToggle>
                </span>
              )}
            </Grid>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default withTranslation()(NavigationBar);
