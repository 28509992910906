import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {changePassword} from '../../../api/UserApi';
import { useAuth } from '../../../context/AuthContext';
import { useAlert } from '../../../context/AlertContext';
import {
    Container,
    Grid,
    Button,
    Typography,
    makeStyles,
    Link,
} from '@material-ui/core';
import TextInputField from '../../reusable/TextInputField';
import {
    checkLength,
    checkHasLetter,
    checkHasNumber,
    checkHasSpecialChar,
} from '../../../utils/Validation';
import IUser from '../../../types/dto/user';


const useStyles = makeStyles((theme) => ({
    requirementList: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        listStyleType: 'disc'
    },
    indentedListItem: {
        marginLeft: theme.spacing(3)
    },
}));
const ChangePassword = () => {
    const { state: authState } = useAuth();
    const { t } = useTranslation();
    const { alertSuccess, alertError } = useAlert();
    const username = authState.user?.username ?? '';
    const email = authState.user?.email ?? '';
    const classes = useStyles();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmNewPassword(event.target.value);
    };

    const handleGoBack = () => {
        window.location.href = '/editProfile'
    };

    const handleSubmit = () => {
        if (newPassword !== confirmNewPassword) {
        alert(t('changePassword.passwordNotMatch'));
        return;
        }
        if (!checkLength(newPassword)) {
        alert(t('changePassword.passwordRequirement_8character'));
        return;
        }
        if (!checkHasNumber(newPassword)) {
            alert(t('changePassword.passwordRequirement_oneNumber'));
            return;
        }
        if (!checkHasLetter(newPassword)) {
            alert(t('changePassword.passwordRequirement_oneLetter'));
            return;
        }
        if (!checkHasSpecialChar(newPassword)) {
            alert(t('changePassword.passwordRequirement_specialCharacter'));
            return;
        }
        const requestData = {
            Username: username,
            Email: email,
            OldPassword: oldPassword,
            NewPassword: newPassword
        };
        changePassword (requestData, successCallback, failCallback);

    }
    const successCallback = (body: IUser)  => {
        alertSuccess(t('changePassword.success'));
    }
    const failCallback = (statusCode: number, body: any) => {
        alertError(body)
    }

    return (
        <Container>
            <Typography variant='subtitle2'>
                <Link component='button' onClick={handleGoBack}>
                    &lt; {t('changePassword.go_back')} {/* Use your translation key */}
                </Link>
            </Typography>
            <Grid
                container = {true}
                direction='column'
                justify='center'
                alignItems='center'
            >
                <Grid style={{ margin: '16px'}} >
                    <Typography variant = 'h3'> {t('changePassword.title') }</Typography>
                    <Typography variant='subtitle2'>
                    {t('changePassword.passwordRequirement')}
                    </Typography>
                    <ul className={classes.requirementList}>
                        <li className={classes.indentedListItem}>
                            {t('changePassword.passwordRequirement')}
                        </li>
                        <li className={classes.indentedListItem}>
                            {t('changePassword.passwordRequirement_8character')}
                        </li>
                        <li className={classes.indentedListItem}>
                            {t('changePassword.passwordRequirement_oneNumber')}
                        </li>
                        <li className={classes.indentedListItem}>
                            {t('changePassword.passwordRequirement_oneLetter')}
                        </li>
                        <li className={classes.indentedListItem}>
                            {t('changePassword.passwordRequirement_specialCharacter')}
                        </li>
                    </ul>
                </Grid>
                <Grid
                    item = {true}
                    container = {true}
                    direction='column'
                    justify='center'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid
                    item = {true}
                    xs = {12}
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <TextInputField
                        label= {t('changePassword.old_password')} // change this
                        type='password'
                        value={oldPassword}
                        onChange={handleOldPasswordChange}
                        />
                    </Grid>
                    <Grid item= {true}
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }} >
                        <TextInputField
                        label={t('changePassword.new_password')}
                        type='password'
                        value={newPassword}
                        onChange={handleNewPasswordChange}

                        />
                    </Grid>
                    <Grid item={true}
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <TextInputField
                        label={t('changePassword.confirm_new_password')}
                        type='password'
                        value={confirmNewPassword}
                        onChange={handleConfirmNewPasswordChange}
                        />
                    </Grid>
                    <Grid item={true}>
                        <Button variant='contained' color='primary' onClick={handleSubmit}>
                        Change Password
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
export default ChangePassword;