/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { sendPageView } from '../../../utils/GoogleAnalytics';
import { paths, searchShowing, defaultPreviewLinkImage, defaultPreviewLinkDescription } from '../../../stocateConstants';
import StoresMap from './storesMap/StoresMap';
import ObjectCell from '../../reusable/ObjectCell';
import StoreCard from '../store/StoreCard';
import ItemCard from '../store/ItemCard';
import ListCard from '../library/listCard/ListCard';
import SectionTab from '../../../components/reusable/SectionTab';
import SearchInBrowse from '../../../components/reusable/SearchInBrowse';
import getAllItemsLimit from '../../../api/ItemsApi.js';
import Grid from '@material-ui/core/Grid';
import { IEntity, EntityString } from '../../../types/common';
import { makeStyles } from '@material-ui/core';
import { getAllStoresLimit, getMapStores, getStoresLimitByDistance } from '../../../api/StoresApi';
import { getAllPublicListLimit } from '../../../api/ListApi';
import FilterBadges from '../filterBrowse/FilterModalBadges';
import FilterBadgesStore from '../filterBrowse/FilterModalBadgesStore';
import Button from '@material-ui/core/Button';
import loadingGif from '../../../assets/images/general/loading.gif';
import mapIcon from '../../../assets/images/general/map.webp';
import ArrowDownward from '../../../assets/images/general/ArrowDownwards.png';
import ArrowUpward from '../../../assets/images/general/ArrowUpwards.png';
import IStore from '../../../types/dto/store';
import SortingOption from './sortingOption/SortingOption';
import AlertDialog from '../../../components/reusable/AlertDialog';
import getUserLocation from './storesMap/location/getUserLocation';
import useIsEmptyObject from '../../../hooks/useIsEmptyObject';

const browseStyles = makeStyles(() => ({
  leftListPanel: {
    minHeight: '78vh',
    paddingRight: '1.5em',
    paddingLeft: '1.5em',
  },
  rightMapPanel: {
    minHeight: '78vh',
  },
  listScrollContainer: {
    position: 'relative',
    height: '100%',
  },
  listScrollColumn: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  loadingGif: {
    textAlign: 'center',
    width: '10px',
  },
  imageIcon: {
    height: '60px',
    width: '60px'
  }
}));

const Browse = () => {
  const limit = 10;
  const [input, setInput] = useState<string>('');
  const [currentTab, setCurrentTab] = useState<EntityString>('store');
  const [selectedCard, setSelectedCard] = useState<SelectedCard>(null);
  const [itemCounter, setItemCounter] = useState(0);
  const [itemFilters, setItemFilters] = useState({});
  const [storeCounter, setStoreCounter] = useState(0);
  const [storeFilters, setStoreFilters] = useState({});
  const [listCounter, setListCounter] = useState(0);
  const [sortOption, setSortOption] = useState('');
  const [hasMoreStores, setHasMoreStores] = useState(true);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [hasMoreLists, setHasMoreLists] = useState(true);

  const [items, setItems] = useState<IEntity[]>([]);
  const [stores, setStores] = useState<IEntity[]>([]);
  const [lists, setLists] = useState<IEntity[]>([]);
  const [storesMap, setStoresMap] = useState<IStore[]>([]);
  const [searchedMap, setSearchedMap] = useState<IStore[]>([])

  const classes = browseStyles();
  const { t } = useTranslation();
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [isLoadingLists, setIsLoadingLists] = useState(false);
  const [hasError, setHasError] = useState(false);
  const scrollStoreContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollItemContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollListContainerRef = useRef<HTMLDivElement | null>(null);
  const storeObserver = useRef<IntersectionObserver | null>(null);
  const itemObserver = useRef<IntersectionObserver | null>(null);
  const listObserver = useRef<IntersectionObserver | null>(null);
  const lastStoreCounterRef = useRef(storeCounter);
  const lastItemCounterRef = useRef(itemCounter);
  const lastListCounterRef = useRef(listCounter);
  const lastInputRef = useRef(input);
  const searchTerm = new URLSearchParams(window.location.search).get('search');

  const setAllCountersToZero = () => {
    setItemCounter(0);
    setStoreCounter(0);
    setListCounter(0);
    lastItemCounterRef.current = 0;
    lastStoreCounterRef.current = 0;
    lastListCounterRef.current = 0;
  }

  function updateMetaTags() {
    document.querySelector('meta[name="og:title"]')?.setAttribute('content', 'Stocate - Browse');
    document.querySelector('meta[name="og:image"]')?.setAttribute('content', defaultPreviewLinkImage);
    document.querySelector('meta[name="og:url"]')?.setAttribute('content', 'https://stocate.com/browse');
    document.querySelector('meta[name="og:description"]')?.setAttribute('content', defaultPreviewLinkDescription);
  }

  function searchOnLoad() {
    if (searchTerm) {
      setInput(searchTerm);
      const urlWithoutSearchParam = window.location.href.split('?')[0];
      window.history.replaceState({}, document.title, urlWithoutSearchParam);
      const event = new KeyboardEvent('keydown', {
        key: 'Enter',
        keyCode: 13,
        code: 'Enter',
      });
      document.dispatchEvent(event);
    }
  }

  useEffect(() => {
    sendPageView(paths.BROWSE);
    setAllCountersToZero();
    setItems([]);
    setLists([]);
    lastInputRef.current = input;
    fetchDataItem();
    if (storeSort.field === 'name')
      fetchDataStore()
    fetchDataList();
    updateMetaTags();
  }, [input, itemFilters, storeFilters]);

  useEffect(() => {
    searchOnLoad();
    fetchMapStores();
    getUserLocation(setCoords, setGeoLocPermission, setShowAlert);
  }, []);

  useEffect(() => {
    lastItemCounterRef.current = itemCounter;
  }, [itemCounter]);

  useEffect(() => {
    lastStoreCounterRef.current = storeCounter;
  }, [storeCounter]);

  useEffect(() => {
    lastListCounterRef.current = listCounter;
  }, [listCounter]);

  const fetchDataItem = async () => {
    if (isLoadingItems) return;
    setIsLoadingItems(true);
    try {
      const currentItemCount = lastItemCounterRef.current;
      const currentInput = lastInputRef.current;
      const data = await getAllItemsLimit(failCallback, currentItemCount, currentInput, sortOption, itemFilters);
      setItems((prevItems: IEntity[]) => {
        const updatedItems = [...prevItems, ...data];
        setHasMoreItems(data.length === limit);
        return updatedItems;
      });
      setItemCounter(prevItemCounter => {
        const newPrevCounter = prevItemCounter + 1;
        return newPrevCounter;
      });
      setHasError(false);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoadingItems(false);
    }
  };

  const [itemSort, setItemSort] = useState({ field: 'name', direction: 'asc' });
  const [storeSort, setStoreSort] = useState({ field: 'name', direction: 'asc' });
  const [listSort, setListSort] = useState({ field: 'name', direction: 'asc' });

  const [coords, setCoords] = useState({ lat: null, lon: null });
  const [geoLocPermission, setGeoLocPermission] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const fetchDataStore = async () => {
    if (isLoadingStores) return;
    setIsLoadingStores(true);
    try {
      const currentStoreCounter = lastStoreCounterRef.current; // Access the latest value from the ref
      const currentInput = lastInputRef.current;
      const data = await getAllStoresLimit(failCallback, currentStoreCounter, currentInput, sortOption, storeFilters);
      setStores((prevStores: IEntity[]) => {
        const updatedItems = [...prevStores, ...data];
        setHasMoreStores(data.length === limit);
        return updatedItems;
      });
      setStoreCounter(prevStoreCounter => {
        const newStoreCounter = prevStoreCounter + 1;
        return newStoreCounter;
      });
      setHasError(false);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoadingStores(false);
    }
  }

  const fetchDataList = async () => {
    if (isLoadingLists) return;
    setIsLoadingLists(true);
    try {
      const currentListCounter = lastListCounterRef.current; // Access the latest value from the ref
      const currentInput = lastInputRef.current;
      const data = await getAllPublicListLimit(failCallback, currentListCounter, currentInput, sortOption);
      const listsFromData = data.lists || [];
      setLists((prevLists: IEntity[]) => {
        const updatedItems = [...prevLists, ...listsFromData];
        setHasMoreLists(listsFromData.length === limit);
        return updatedItems;
      });
      setListCounter(prevListCounter => {
        const newListCounter = prevListCounter + 1;
        return newListCounter;
      });
      setHasError(false);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoadingLists(false);
    }
  }

  const onClickEntity = (entity: IEntity, type: EntityString) => () => {
    setSelectedObjectCell(entity);
    setSelectedCard({ type, entity });
  };

  const getCurrentTabEntities = (): IEntity[] => {
    switch (currentTab) {
      case 'store':
        return stores;
      case 'item':
        return items;
      case 'list':
        return lists;
    }
  };

  const getCurrentTabEntitiesLoading = (): boolean => {
    switch (currentTab) {
      case 'store':
        return isLoadingStores;
      case 'item':
        return isLoadingItems;
      case 'list':
        return isLoadingLists;
    }
  };

  const lastStore = useCallback(lastNode => {
    if (isLoadingStores || !hasMoreStores) return;
    if (storeObserver.current) {
      storeObserver.current.disconnect();
    }
    // disconnect previous last element
    storeObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        fetchDataStore();
      }
    })
    if (lastNode) storeObserver.current.observe(lastNode);
  }, [isLoadingStores, hasMoreStores]);

  const lastItem = useCallback(lastNode => {
    if (isLoadingItems || !hasMoreItems) return;
    if (itemObserver.current) {
      itemObserver.current.disconnect();
    }
    itemObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        fetchDataItem();
      }
    })
    if (lastNode) itemObserver.current.observe(lastNode);
  }, [isLoadingItems, hasMoreItems]);

  const lastList = useCallback(lastNode => {
    if (isLoadingLists || !hasMoreLists) return;
    if (listObserver.current) {
      listObserver.current.disconnect();
    }

    listObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        fetchDataList();
      }
    })
    if (lastNode) listObserver.current.observe(lastNode);
  }, [isLoadingLists, hasMoreLists]);

  const card: any = useRef(null);
  const failCallback = () => {
    setHasError(true);
    setIsLoadingItems(false);
    setIsLoadingLists(false);
    setIsLoadingStores(false);
  }
  const containerRef =
    currentTab === 'item'
      ? scrollItemContainerRef
      : currentTab === 'store'
        ? scrollStoreContainerRef
        : scrollListContainerRef;

  const lastObserver =
    currentTab === 'item'
      ? lastItem
      : currentTab === 'store'
        ? lastStore
        : lastList;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalStoreIsOpen, setModalStoreIsOpen] = useState(false);
  const [selectedObjectCell, setSelectedObjectCell] = useState<IEntity | null>(null);

  const handleSortChangeStore = (option: string) => {
    setStores([]);
    setAllCountersToZero();
    setSortOption(option);
  };
  const handleSortChangeItem = (option: string) => {
    setAllCountersToZero();
    setItems([]);
    setSortOption(option);
  };
  const handleSortChangeList = (option: string) => {
    setAllCountersToZero();
    setLists([]);
    setSortOption(option);
  };
  useEffect(() => {
    if (sortOption !== '') {
      switch (currentTab) {
        case 'item':
          fetchDataItem();
          break;
        case 'store':
          if (storeSort.field === 'name')
            fetchDataStore();
          break;
        case 'list':
          fetchDataList();
          break;
      }
    }
  }, [sortOption, currentTab]);

  const handleClickSortAlphaItem = () => {
    const newDirection = itemSort.direction === 'asc' ? 'desc' : 'asc';
    setItemSort({ field: 'name', direction: newDirection });
    handleSortChangeItem(`name_${newDirection}`);
  };

  const handleClickSortAlphaStore = () => {
    const newDirection = storeSort.direction === 'asc' ? 'desc' : 'asc';
    setStoreSort({ field: 'name', direction: newDirection });
    handleSortChangeStore(`name_${newDirection}`);
  };

  const handleClickSortAlphaList = () => {
    const newDirection = listSort.direction === 'asc' ? 'desc' : 'asc';
    setListSort({ field: 'name', direction: newDirection });
    handleSortChangeList(`name_${newDirection}`);
  };

  const handleClickSortPriceItem = () => {
    const newDirection = itemSort.field === 'price' && itemSort.direction === 'asc' ? 'desc' : 'asc';
    setItemSort({ field: 'price', direction: newDirection });
    handleSortChangeItem(`price_${newDirection}`);
  };
  const handleTabChange = (newTab: EntityString) => {
    // Always reset sorting to ascending for name when changing tabs
    setItemSort({ field: 'name', direction: 'asc' });
    setStoreSort({ field: 'name', direction: 'asc' });
    setListSort({ field: 'name', direction: 'asc' });
    // Clear filters and reset sorting options and counters
    setSortOption('');
    setItemFilters({});
    setStoreFilters({});
    setAllCountersToZero();
    // Clear the lists to ensure fresh fetch when tab is changed
    if (newTab === 'item') {
      setItems([]);
    } else if (newTab === 'store') {
      setStores([]);
    } else if (newTab === 'list') {
      setLists([]);
    }
    setCurrentTab(newTab);
  };

  const handleMapIconClick = () => {
    setSelectedObjectCell(null);
    setSelectedCard(null);
  }

  const fetchMapStores = async () => {
    const storesList = await getMapStores(input, storeFilters, failCallback);
    if (storesMap.length === 0)
      setStoresMap(storesList)
    else
      setSearchedMap(storesList);
  }

  const handleClickDistanceSorting = useCallback(() => {
    const direction =
      storeSort.field === 'distance' && storeSort.direction === 'asc'
        ? 'desc'
        : 'asc';
    getUserLocation(setCoords, setGeoLocPermission, setShowAlert);
    setStoreSort({ field: 'distance', direction: direction });
    handleSortChangeStore(`distance_${direction}`);

  }, [storeSort, handleSortChangeStore]);

  useEffect(() => {
    if (
      storeSort.field === 'distance' &&
      coords.lat != null &&
      coords.lon != null &&
      geoLocPermission
    ) {
      fetchStoreByDistance();
    }
    fetchMapStores();
  }, [storeSort, input, storeFilters, geoLocPermission]);

  const fetchStoreByDistance = async () => {
    if (isLoadingStores) return;
    setIsLoadingStores(true);

    try {
      const bodyRequest = [storeFilters, coords];
      const data = await getStoresLimitByDistance(
        failCallback,
        lastListCounterRef.current,
        lastInputRef.current,
        sortOption,
        bodyRequest
      );

      setStores((prevStores: IEntity[]) => {
        const updatedItems = [...prevStores, ...data];
        setHasMoreStores(data.length === limit);
        return updatedItems;
      });
      setStoreCounter((prevStoreCounter) => {
        const newStoreCounter = prevStoreCounter + 1;
        return newStoreCounter;
      });
      setHasError(false);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoadingStores(false);
    }
  };

  return (
    <Grid container={true}>
      <Grid
        item={true}
        container={true}
        xs={12}
        md={6}
        direction='column'
        wrap='nowrap'
        className={classes.leftListPanel}
        style={{ paddingTop: '1em' }}
      >
        <Grid container={true} alignItems='center'>
          <Grid container={true} alignItems='center' spacing={1}>
            <Grid item={true} xs={true} style={{ marginRight: '0em' }}>
              <SearchInBrowse
                setInput={setInput}
                initialSearchTerm={input || ''}
                setModalIsOpen={setModalIsOpen}
                setModalStoreIsOpen={setModalStoreIsOpen}
                modalIsOpen={modalIsOpen}
                modalStoreIsOpen={modalStoreIsOpen}
                currentTab={currentTab}
                fullWidth={true}
                clearSearchResult={setStores}
              />
            </Grid>
            <Grid item={true}>
              <img src={mapIcon} alt='map' className={classes.imageIcon} onClick={handleMapIconClick} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item={true}
          container={true}
          justify='space-around'
          style={{
            marginTop: '0.5em',
            marginBottom: '0.5em',
          }}
        >

          <FilterBadges isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)} setItemFilters={setItemFilters} fetchDataItem={fetchDataItem} setAllCountersToZero={setAllCountersToZero} setItems={setItems}>
            <h2>{t('filter-sort.filterOption')}</h2>
          </FilterBadges>

          <FilterBadgesStore isOpen={modalStoreIsOpen} closeModal={() => setModalStoreIsOpen(false)} setStoreFilters={setStoreFilters} fetchDataStore={fetchDataStore} setAllCountersToZero={setAllCountersToZero} setStores={setStores}>
            <h2>{t('filter-sort.filterOption')}</h2>
          </FilterBadgesStore>

          <SectionTab
            selected={currentTab === 'item'}
            onClick={() => handleTabChange('item')}
          >
            {t('tab.item')}
          </SectionTab>
          <SectionTab
            selected={currentTab === 'store'}
            onClick={() => handleTabChange('store')}
          >
            {t('tab.store')}
          </SectionTab>
          <SectionTab
            selected={currentTab === 'list'}
            onClick={() => handleTabChange('list')}
          >
            {t('tab.list')}
          </SectionTab>
        </Grid>
        <Grid container={true} alignItems='center'>
          <Grid item={true} container={true} justify='center' spacing={6}>
            {
              currentTab === 'item' && (
                <>
                  <Grid item={true}>
                    <Button
                      style={{ textTransform: 'none' }}
                      onClick={handleClickSortAlphaItem}
                    >
                      {'A-Z'}
                      {itemSort.field === 'name' && (
                        itemSort.direction === 'asc' ? (<img src={ArrowUpward} alt='ArrowUpward' style={{ width: '11px', height: '11px', filter: 'brightness(0) invert(0)' }} />) : (<img src={ArrowDownward} alt='ArrowDownward' style={{ width: '11px', height: '11px', filter: 'brightness(0) invert(0)' }} />)
                      )}
                    </Button>
                  </Grid>
                  <Grid item={true}>
                    <Button
                      style={{ textTransform: 'none' }}
                      onClick={handleClickSortPriceItem}
                    >
                      {t('filter-sort.price')}
                      {itemSort.field === 'price' && (
                        itemSort.direction === 'asc' ? (<img src={ArrowUpward} alt='ArrowUpward' style={{ width: '11px', height: '11px', filter: 'brightness(0) invert(0)' }} />) : (<img src={ArrowDownward} alt='ArrowDownward' style={{ width: '11px', height: '11px', filter: 'brightness(0) invert(0)' }} />)
                      )}
                    </Button>
                  </Grid>
                </>
              )
            }
            {
              currentTab === 'store' && (
                <>
                  <Grid item={true}>
                    <Button
                      style={{ textTransform: 'none', justifyContent: 'left', minWidth: 55.3 }}
                      onClick={handleClickSortAlphaStore}
                    >
                      {'A-Z'}
                      {storeSort.field === 'name' && (storeSort.direction === 'asc' ? (<img src={ArrowUpward} alt='ArrowUpward' style={{ width: '11px', height: '11px', filter: 'brightness(0) invert(0)', margin: '0 3px' }} />) : (<img src={ArrowDownward} alt='ArrowDownward' style={{ width: '11px', height: '11px', filter: 'brightness(0) invert(0)', margin: '0 3px' }} />))}
                    </Button>
                  </Grid>
                  <Grid item={true}>
                    <SortingOption
                      sortingParam={storeSort}
                      sortingType='Distance'
                      clickSortingfunction={handleClickDistanceSorting}
                    />
                  </Grid>
                </>
              )
            }
            {
              currentTab === 'list' && (
                <Grid item={true}>
                  <Button
                    style={{ textTransform: 'none' }}
                    onClick={handleClickSortAlphaList}
                  >
                    {'A-Z'}
                    {listSort.direction === 'asc' ? (<img src={ArrowUpward} alt='ArrowUpward' style={{ width: '11px', height: '11px', filter: 'brightness(0) invert(0)' }} />) : (<img src={ArrowDownward} alt='ArrowDownward' style={{ width: '11px', height: '11px', filter: 'brightness(0) invert(0)' }} />)}
                  </Button>
                </Grid>
              )
            }
          </Grid>
        </Grid>
        <Grid item={true} className={classes.listScrollContainer}>
          <Grid
            container={true}
            spacing={0}
            data-cy='browseList'
            className={classes.listScrollColumn}
          >
            {!hasError && (
              <div
                ref={containerRef}
                style={{
                  overflowY: 'scroll',
                  width: '100%',
                }}
                data-cy='browseListContainer'
              >
                {
                  getCurrentTabEntities().reduce(
                    (visibleEntities: JSX.Element[], entity: IEntity, index: number) => {
                      const onClick = onClickEntity(entity, currentTab);
                      const isSelected = selectedObjectCell === entity;
                      if (searchShowing(entity, input))
                        visibleEntities.push(
                          <Grid item={true} xs={12} key={index} onClick={() => onClick()}>
                            {getCurrentTabEntities().length === index + 1 ? (
                              <div ref={lastObserver}><ObjectCell type={currentTab} entity={entity} selected={isSelected} /></div>
                            ) : (
                              <ObjectCell type={currentTab} entity={entity} selected={isSelected} />
                            )}
                          </Grid>
                        );
                      return visibleEntities;
                    },
                    [])
                }
              </div>
            )}
            {/* If no entities match the search, display a message */}
            {getCurrentTabEntities().length === 0 && input && !getCurrentTabEntitiesLoading() ? (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <p>No Results Found</p>
              </div>
            ) : null}
            {hasError && (
              <div>
                <p>An error occurred while loading list.</p>
              </div>
            )}
            {getCurrentTabEntitiesLoading() && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <img src={loadingGif} alt='Loading...' style={{ width: '1.5vw' }} />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid id={'card'} ref={card} item={true} xs={12} md={6} className={classes.rightMapPanel}>
        {selectedCard &&
          (() => {
            switch (selectedCard.type) {
              case 'store':
                return <StoreCard store={selectedCard.entity} tagHandler={setInput} />;
              case 'item':
                return <ItemCard item={selectedCard.entity} tagHandler={setInput} />;
              case 'list':
                return <ListCard list={selectedCard.entity} tagHandler={setInput} />;
            }
          })()}
        {/* We want to keep the StoresMap mounted, so just change isVisible */}
        <StoresMap stores={useIsEmptyObject(storeFilters) && input === '' ? storesMap : searchedMap} isVisible={!selectedCard} />
      </Grid>

      {(storeSort.field === 'distance' && !geoLocPermission) ?
        (
          <AlertDialog
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            msg={'Please give access to your location to display stores based on distance'} />
        ) :
        (null)
      }
    </Grid>
  );
};


type SelectedCard = {
  type: EntityString;
  entity: IEntity;
} | null;

export default Browse;