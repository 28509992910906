import React from 'react';
import ImageUploader from 'react-images-upload';
import { useTranslation } from 'react-i18next';

import useAddStoreStyles from '../addStore/SharedAddStoreStyles';
import cameraIcon from '../../../assets/images/general/camera.webp';
import SectionTitle from './SectionTitle';

import Box from '@material-ui/core/Box';

import IAddStore from '../../../types/add/IAddStore';
import IAddList from '../../../types/add/IAddList';
import IAddItem from '../../../types/add/IAddItem';

const ImagePicker = ({
  setEntity,
}: {
  setEntity:
    | React.Dispatch<React.SetStateAction<IAddItem>>
    | React.Dispatch<React.SetStateAction<IAddStore>>
    | React.Dispatch<React.SetStateAction<IAddList>>;
}) => {
  const classes = useAddStoreStyles();
  const { t } = useTranslation();

  const onImageChange = (files: File[], _: string[]) => {
    setEntity((prevStore: any) => ({
      ...prevStore,
      images: files,
    }));
  };

  return (
    <>
      <SectionTitle
        iconSrc={cameraIcon}
        title={t('addstore.add_photo_button')}
      />
      <Box className={classes.root}>
        <ImageUploader
          withIcon={false}
          withPreview={true}
          buttonText='Choose images'
          onChange={onImageChange}
          imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
          maxFileSize={5242880}
        />
      </Box>
    </>
  );
};

export default React.memo(ImagePicker);
