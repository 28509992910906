import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendPageView } from '../../../utils/GoogleAnalytics';
import { paths } from '../../../stocateConstants';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '75vh',
  },
  stocateBtn: {
    backgroundColor: '#01647B',
    textTransform: 'none',
    margin: theme.spacing(2),
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '300px',
  },
  text: {
    marginTop: theme.spacing(3),
    whiteSpace: 'pre-wrap',
  },
}));

const NoMoreLevels = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const sendToBrowse = () => history.push('/browse');

  useEffect(() => {
    sendPageView(paths.TESTING_CHECK_BACK_LATER);
  }, []);

  return (
    <Container maxWidth='sm' className={classes.container}>
      <Grid container={true} justify='center' style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item={true}>
          <img
            className={classes.logo}
            src={require('../../../assets/images/about/stocate-logo-transparent.png')}
            alt='Stocate Logo'
          />
        </Grid>
        <Grid item={true}>
          <Typography variant='h5' align='center' className={classes.text}>
            {t('pilot_testing.nomorelevels')}
          </Typography>
        </Grid>
        <Grid item={true} style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
          <Button
            variant='contained'
            onClick={sendToBrowse}
            classes={{ root: classes.stocateBtn }}
            style={{ color: 'white' }}
            data-cy='no_more_levels'
          >
            {t('titles.browse')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NoMoreLevels;
