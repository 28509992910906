import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useHistory } from 'react-router-dom';
import { IAuthState } from '../../context/IAuthContext.js';
import { paths } from '../../stocateConstants.js';
import defaultPic from '../../assets/images/navbar/whiteProfileIcon.png';
import i18n from '../../i18n';
import i18next from 'i18next';
import { addResizingListener } from '../../utils/Responsiveness.js';

const ProfileMenu = ({ authState }: { authState: IAuthState }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    addResizingListener(setIsMobile);
  }, []);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsDropdownOpen(false);
    }
  };
  return (
    <Dropdown
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      show={isDropdownOpen}
      onBlur={() => setIsDropdownOpen(false)}
    >
      <Dropdown.Toggle onClick={() => setIsDropdownOpen(!isDropdownOpen)} style={{position: 'relative', backgroundColor: 'transparent', border: 'none', outline: 'none', boxShadow: 'none'}}>
        <img
          src={authState.user?.profilePicture || defaultPic}
          style={{ width: 40 }}
          alt='profile icon'
          data-cy='profileNav'
        />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ position: 'absolute' }}>
        <Dropdown.Item onClick={() => {history.push(paths.PROFILE)}} data-cy='profileNav'>
            {i18next.t('profile.profile')}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => changeLanguage('en')} data-cy='enToggle'>
          EN
        </Dropdown.Item>
        <Dropdown.Item onMouseDown={() => changeLanguage('fr')} data-cy='frToggle'>
          FR
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileMenu;
